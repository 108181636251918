import { DOCUSEAL_ROLES } from 'utils'

export const CONTRACT_TYPES = {
  OFFER: 'offer',
  RESERVATION: 'reservation',
  SINGULAR_CONTRACT: 'singular-contract'
}

export const CONTRACT_STATUSES = {
  SIGNED: 'signed',
  AWAITING: 'awaiting',
  AWAITING_SIGNATURE: 'awaiting_signature'
}

export const CONTRACT_STATUS_CONFIG = {
  Started: {
    label: 'Started',
    bgColor: 'bg-yellow-200',
    textColor: 'text-yellow-900'
  },
  Viewed: {
    label: 'Viewed',
    bgColor: 'bg-blue-200',
    textColor: 'text-blue-900'
  },
  awaiting: {
    label: 'Pending',
    bgColor: 'bg-yellow-200',
    textColor: 'text-yellow-900'
  },
  awaiting_signature: {
    label: 'Pending',
    bgColor: 'bg-yellow-200',
    textColor: 'text-yellow-900'
  },
  signed: {
    label: 'Signed',
    bgColor: 'bg-green-200',
    textColor: 'text-green-900'
  },
  canceled: {
    label: 'Canceled',
    bgColor: 'bg-red-200',
    textColor: 'text-red-900'
  },
  Pending: {
    label: 'Pending',
    bgColor: 'bg-yellow-200',
    textColor: 'text-yellow-900'
  }
}

export const CONTRACT_ROLES_CONFIG = {
  [DOCUSEAL_ROLES.BUYER]: 'Buyer',
  [DOCUSEAL_ROLES.SALES_REP]: 'Sales Representative',
  [DOCUSEAL_ROLES.DEV_ADMIN]: 'Developer Admin',
  [DOCUSEAL_ROLES.PRIMARY_BUYER]: 'Primary Buyer',
  [DOCUSEAL_ROLES.SECONDARY_BUYER]: 'Secondary Buyer'
}
