import React from 'react'
import { Select, Tag } from 'antd'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'

const CustomSelect = ({
  label,
  value,
  handleChange,
  options,
  showSearch = false,
  isName = false,
  mode = '',
  placeholder = '',
  optionFilterProp = 'label',
  containerClassName = '',
  className = '',
  labelClassName = '',
  disabled = false,
  loading = false
}) => {
  const tagRender = (props) => {
    if (!(mode === 'multiple')) return null
    const { label, value, closable, onClose } = props
    const onPreventMouseDown = (event) => {
      event.preventDefault()
      event.stopPropagation()
    }
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        className='text-black'
      >
        {label}
      </Tag>
    )
  }

  return (
    <div
      className={`w-full flex flex-col gap-y-1.5 ${containerClassName}`}
    >
      {
        label?.length > 0 &&
        <div
          className={`text-softBlack_70/50 w-full text-xs top-6 font-normal uppercase ${labelClassName}`}
        >
          {label}
        </div>
      }
      {
        !mode?.length &&
        <Select
          showArrow
          value={value?.length ? value : null}
          onChange={handleChange}
          className={`border border-[#18181B] rounded-[5px] ${className}`}
          getPopupContainer={(node) => node.parentNode}
          suffixIcon={<DownIcon />}
          showSearch={showSearch}
          placeholder={loading ? 'loading...' : placeholder}
          loading={loading}
          optionFilterProp={optionFilterProp}
          disabled={disabled}
        >
          {options?.map((_item, index) => (
            <Select.Option value={isName ? _item._id : _item.value} key={index} label={isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}>
              <div className='flex gap-2 items-center'>
                {isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}
              </div>
            </Select.Option>
          ))}
        </Select>
      }
      {
        mode === 'multiple' &&
        <Select
          showArrow
          value={value}
          onChange={handleChange}
          className={`border border-[#18181B] rounded-[5px] ${className}`}
          getPopupContainer={(node) => node.parentNode}
          suffixIcon={<DownIcon />}
          showSearch={showSearch}
          mode={mode}
          placeholder={placeholder}
          tagRender={tagRender}
          maxTagCount='responsive'
          optionFilterProp={optionFilterProp}
          disabled={disabled}
        >
          {options?.map((_item, index) => (
            <Select.Option value={isName ? _item._id : _item.value} key={index} label={isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}>
              <div className='flex gap-2 items-center'>
                {isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}
              </div>
            </Select.Option>
          ))}
        </Select>
      }
    </div>
  )
}

export default CustomSelect
