/* eslint-disable */
// @ts-nocheck

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import useCreateReservation from 'hooks/useCreateReservation'
import { classNames } from 'utils'
import useSendBuyerToSign from 'hooks/useSendBuyerToSign'
import useGetReservationDetail from 'hooks/useGetReservationDetail'
import { CreateReservationContext } from '../context/CreateReservationContext'
import ReservationCreated from '../DocumentSign/ReservationCreated'
import SendBuyer from '../DocumentSign/SendBuyer'
import RecordPayment from '../DocumentSign/RecordPayment'
import { toast } from 'react-hot-toast'
import useUpdateReservationDocument from 'hooks/useUpdateReservationDocument'
import useCancelReservation from 'hooks/useCancelReservation'
import { UserPlus, CreditCard, CircleX, ChevronLeft } from 'lucide-react'
import CustomSelect from './components/CustomSelect'
import Button from './components/Button'
import CreatingReservation from '../DocumentSign/CreatingReservation'
import useGetProjectUnits from 'hooks/useGetProjectUnits'
import ContractSigningModal from '../DocumentSign/ContractSigningModal'
import useGetEscrowAgents from 'hooks/useGetEscrowAgents'
import useGetFilterUsers from 'hooks/useGetFilterUsers'
import CustomButton from 'components/CustomButton'
import ReservationCard from '../ReservationCard'
import { IoMdClose } from 'react-icons/io'
import { Drawer, makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { isNumber } from 'lodash'
import moment from 'moment'
import CustomInput from 'components/CustomInput'
import { CheckIcon } from 'components'
import DocusealConfigModel from 'pages/desktopMode/docusealConfig/component/DocusealModelConfig'
import useDocusealConfigModal from 'hooks/useDocusealConfigModal'
import useGetProjectInventoriesV2 from 'hooks/useGetProjectInventoryV2'
import useGetInventories from 'hooks/useGetInventories'

export const SectionWrapper = ({ children, className = '' }) => (
  <div className={`rounded-xl border bg-card text-card-foreground shadow bg-white ${className}`}>
    {children}
  </div>
)

const DEFAULT_LOT_CHOICES = [
  {
    choice: 1,
    lotId: []
  },
  {
    choice: 2,
    lotId: []
  },
  {
    choice: 3,
    lotId: []
  },
  {
    choice: 4,
    lotId: []
  },
  {
    choice: 5,
    lotId: []
  },
]

const ReservationForm = ({
  reservation,
  step,
  manualReservationDate,
  setManualReservationDate,
  filtering,
  filteredUsers,
  selectedReservationHolders,
  handleReservationHoldersChange,
  contractLanguage,
  setContractLanguage,
  contractLanguages,
  loadingEscrowAgents,
  selectedEscrowCompany,
  handleEscrowCompanyChange,
  escrowCompaniesOptions,
  manualReservationFee,
  setManualReservationFee,
  paymentMethod,
  setPaymentMethod,
  paymentMethods,
  unitsLoading,
  lotChoices,
  deleteLotChoices,
  handleLotChoicesChange,
  lotsInventoryOptions,
  handleAddLotChoices,
  handleReservationDateChange,
}) => {
  const reservationLotChoiceNames = [
    'Reserved (Can be many?)',
    'Alternate (Can be many or one?)',
    'back-up lots 1',
    'back-up lots 2',
    'back-up lots 3'
  ]
  return (
    <div className={`${step === 1 ? 'order-2' : 'order-1'} md:order-1 md:h-full text-lg font-bold grid gap-x-12 gap-y-4 w-full md:w-[50%] grid-cols-1 thin-scrollbar overflow-y-auto`}>
      <div className='col-span-1 w-full flex flex-col gap-3 md:gap-6'>
        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Reservation Date</h4>
            <div className='self-stretch justify-start items-start gap-6 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                  <CustomInput
                    containerClasses='w-full border border-[#18181B] rounded overflow-hidden'
                    value={moment(manualReservationDate).format('YYYY-MM-DD')}
                    type='date'
                    onChange={(e) => {
                      let date = moment(e.target.value, 'YYYY-MM-DD').startOf('day').toISOString();
                      setManualReservationDate(date);
                    }}
                    // placeholder='Enter reservation date'
                    min={moment('1970-01-01', 'YYYY-MM-DD').format('YYYY-MM-DD')}
                  />
                </div>
                {/* {
                  manualReservationDate !== moment(reservation?.manualReservationDate).format('YYYY-MM-DD') && (
                    <Button
                      handleClick={handleReservationDateChange}
                      className='self-end'
                      btnText='Save'
                      Icon={CheckIcon}
                    />
                  )
                } */}
              </div>
            </div>
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Select Reservation Holder(s)</h4>
            <div className='self-stretch justify-start items-start gap-6 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                  <div className='self-stretch text-zinc-800 text-sm font-normal leading-[21px] tracking-tight'>
                    {
                      !filtering
                        ? `${filteredUsers?.data?.length} Contacts`
                        : 'Loading...'
                    }
                  </div>
                  <CustomSelect
                    value={selectedReservationHolders}
                    handleChange={handleReservationHoldersChange}
                    options={filteredUsers?.data}
                    showSearch
                    isName
                    mode='multiple'
                    placeholder='Search contacts'
                  />
                </div>
              </div>
            </div>
            <Button
              btnText='Add Reservation Holder'
              Icon={UserPlus}
            />
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Contract Language</h4>
            <div className='self-stretch justify-start items-start gap-6 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                  <CustomSelect
                    value={contractLanguage}
                    handleChange={(value) => setContractLanguage(value)}
                    options={[...contractLanguages.map((elm) => ({ label: elm, value: elm }))]}
                    showSearch
                    placeholder='Select language'
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Escrow Company</h4>
            {
              loadingEscrowAgents
                ? <div className='h-12 flex items-center'>Loading...</div>
                : <div className='self-stretch justify-start items-start gap-6 inline-flex'>
                  <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                    <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                      <CustomSelect
                        value={selectedEscrowCompany._id}
                        handleChange={handleEscrowCompanyChange}
                        options={escrowCompaniesOptions}
                        placeholder='Select escrow company'
                      />
                    </div>
                  </div>
                </div>
            }
            {
              selectedEscrowCompany?._id &&
              <div className='text-base md:text-lg'>
                <div>Escrow Company Name: <span className='font-normal'>{selectedEscrowCompany?.escrowAgentData?.companyName}</span></div>
                <div>Escrow Agent Name: <span className='font-normal'>{selectedEscrowCompany?.escrowAgentData?.firstName + ' ' + selectedEscrowCompany?.escrowAgentData?.lastName}</span></div>
                <div>Address: <span className='font-normal'>{selectedEscrowCompany?.escrowAgentData?.address}</span></div>
                <div>Phone Number: <span className='font-normal'>{selectedEscrowCompany?.escrowAgentData?.phoneNumber}</span></div>
              </div>
            }
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Reservation Fee</h4>
            <div className='self-stretch justify-start items-start gap-6 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                  <CustomInput
                    containerClasses='w-full border border-[#18181B] rounded'
                    value={manualReservationFee}
                    onChange={(e) => setManualReservationFee(Number(e.target.value))}
                    placeholder='Enter reservation fee'
                    type='number'
                    style={{ marginBottom: '0px' }}
                  />
                </div>
              </div>
            </div>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Payment Method</h4>
            <div className='self-stretch justify-start items-start gap-6 inline-flex'>
              <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                <div className='self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                  <CustomSelect
                    value={paymentMethod}
                    handleChange={(value) => setPaymentMethod(value)}
                    options={[...paymentMethods.map((elm) => ({ label: elm, value: elm }))]}
                    placeholder='Select payment method'
                  />
                </div>
              </div>
            </div>
            <Button
              btnText='Skip'
              Icon={CreditCard}
              type='outline'
            />
          </div>
        </SectionWrapper>

        <SectionWrapper>
          <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
            <h4 className='text-zinc-800 text-xl md:text-2xl font-semibold md:font-bold md:leading-9 mb-0'>Lot Choices</h4>
            {
              unitsLoading
                ? <>Loading...</>
                : <>
                  {
                    lotChoices?.length > 0 &&
                    <>
                      {
                        lotChoices.map(({ choice }, index) => (
                          <div key={choice} className='self-stretch justify-start items-start gap-6 inline-flex'>
                            <div className='grow shrink basis-0 flex-col justify-start items-start gap-2.5 inline-flex'>
                              <div className='group self-stretch max-w-[450px] flex-col justify-start items-start gap-1.5 flex'>
                                <div className='w-full flex justify-between items-center'>
                                  <div className='text-zinc-800 text-lg md:text-[25px] font-normal md:leading-[37.50px] md:tracking-wide'>
                                    {reservationLotChoiceNames[index]}
                                  </div>
                                  {
                                    (index === (lotChoices.length - 1) && index !== 0) &&
                                    <CircleX
                                      onClick={() => deleteLotChoices(choice)}
                                      className='md:opacity-0 md:group-hover:opacity-100 bg-zinc-100 hover:bg-white rounded-full cursor-pointer transition-all'
                                    />
                                  }
                                </div>
                                <CustomSelect
                                  value={lotChoices[index].lotId}
                                  handleChange={(val) => handleLotChoicesChange(index, val)}
                                  options={lotsInventoryOptions?.filter((lot) => !(lotChoices.filter((elm) => ((elm.choice !== choice) && (elm.lotId?.includes(lot.value)))).length > 0))}
                                  showSearch
                                  placeholder='Search lots inventory'
                                  mode='multiple'
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </>
                  }
                  {
                    lotChoices.length < 5 &&
                    <Button
                      btnText='Add Lot Choice'
                      Icon={UserPlus}
                      handleClick={handleAddLotChoices}
                    />
                  }
                </>
            }
          </div>
        </SectionWrapper>
      </div>
    </div>
  )
}

const NewVIPLotReservationV2 = () => {
  const { id } = useParams()
  const { step, setStep } = useContext(CreateReservationContext)
  const {
    loading: isReservationLoading,
    reservation,
    refetch: getReservationDetail
  } = useGetReservationDetail()
  const history = useHistory()
  const { signDocument, loading: signInLoading } = useSendBuyerToSign()
  const [createOrUpdateReservation, loading, data] = useCreateReservation()
  const [cancelReservation, cancelInLoading] = useCancelReservation()
  const { deleteAPI } = useUpdateReservationDocument()
  const [reservationId, setReservationId] = useState()
  const { filteredUsers, filtering } = useGetFilterUsers('', 'Buyer', {})
  const [notes, setNotes] = useState()
  const [currency, setCurrency] = useState()
  const [paymentMethod, setPaymentMethod] = useState()
  const [contractLanguage, setContractLanguage] = useState('English')
  const [isDocSignInProgress, setIsDocSignInProgress] = useState(false)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [lotChoices, setLotChoices] = useState([
    {
      choice: 1,
      lotId: []
    }
  ])
  const [selectedReservationHolders, setSelectedReservationHolders] = useState([])
  // const { units, unitsLoading } = useGetProjectUnits(projectId)
  const { inventories: units, loading: unitsLoading } = useGetInventories(`project-wise/${projectId}`)
  const [isShowContractModal, setIsShowContractModal] = useState(false)
  const { escrowAgents, loadingEscrowAgents } = useGetEscrowAgents()
  const [escrowCompaniesOptions, setEscrowCompaniesOptions] = useState([])
  const [selectedEscrowCompany, setSelectedEscrowCompany] = useState('')
  const [escrowAgentIndex, setEscrowAgentIndex] = useState(null)
  const [isReservationDetailedDrawerOpen, setIsReservationDetailedDrawerOpen] = useState(false)
  const [manualReservationDate, setManualReservationDate] = useState(moment().format('YYYY-MM-DD'))
  const [manualReservationFee, setManualReservationFee] = useState()
  const [signatureMode, setSignatureMode] = useState('')
  const { docusealData,loading:isDocusealDataLoading } = useDocusealConfigModal()
   const [isDocusealModalOpen, setIsDocusealModalOpen] = useState(false)

  const isCreatingReservation = useMemo(() => {
    return id?.indexOf('new') !== -1
  }, [id])

  const refetchReservationDetails = useCallback(() => {
    getReservationDetail(id)
  }, [id])

  useEffect(() => {
    if (!isCreatingReservation) {
      // get reservation detail and set it.
      refetchReservationDetails(id)
    }
  }, [id, isCreatingReservation])

  useEffect(() => {
    if (reservation) {
      setReservationId(reservation?._id)
      setCurrency(reservation.currencyType)
      setPaymentMethod(reservation.paymentMethod)
      setContractLanguage(reservation.contractLanguage)
      setNotes(reservation.notes)
      setEscrowAgentIndex(reservation?.escrowAgentIndex ?? null)
      setStep(2)
      setManualReservationDate(moment(reservation?.manualReservationDate).format('YYYY-MM-DD') ?? moment().format('YYYY-MM-DD'))
      setManualReservationFee(Number(reservation?.manualReservationFee) ?? null)
      setSignatureMode(reservation?.signatureMode ?? '')

      const modifiedObject = {
        firstChoiceInventory: [...reservation?.firstChoiceInventory ?? []],
        secondChoiceInventory: [...reservation?.secondChoiceInventory ?? []],
        thirdChoiceInventory: [...reservation?.thirdChoiceInventory ?? []],
        fourthChoiceInventory: [...reservation?.fourthChoiceInventory ?? []],
        fifthChoiceInventory: [...reservation?.fifthChoiceInventory ?? []]
      }

      const lotChoicesArray = Object.entries(modifiedObject).map(([key, lotId]) => {
        const choiceMap = {
          firstChoiceInventory: 1,
          secondChoiceInventory: 2,
          thirdChoiceInventory: 3,
          fourthChoiceInventory: 4,
          fifthChoiceInventory: 5
        };

        return {
          choice: choiceMap[key],
          lotId
        };
      })?.filter(lot => lot?.lotId?.length > 0);

      if (lotChoicesArray?.length > 0) {
        setLotChoices([...lotChoicesArray])
      }

      if (reservation?.escrowAgent && escrowAgents) {
        setSelectedEscrowCompany(escrowAgents.filter((elm) => elm._id === reservation.escrowAgent)[0])
      }
      if (reservation?.reservationHolders?.length > 0) {
        setSelectedReservationHolders(reservation?.reservationHolders)
      }
    }
  }, [reservation, escrowAgents])

  const paymentMethods = ['Cash', 'Wire', 'Credit Card', 'Check']
  const contractLanguages = ['English', 'Spanish']
  // const currencyArr = [
  //   { id: 'Mexicon Pesos', value: 'MXN' },
  //   { id: 'USD', value: 'USD' }
  // ]

  const lotsInventoryOptions = useMemo(() => {
    return units.inventory?.map((unit) => {
      return { label: `${unit.number}`, value: unit._id }
    })
  }, [units])

  const reservationStatuses = [
    {
      id: 'reservationHolders',
      title: 'Reservation holders',
      subFields: [
        {
          id: 'missingFields',
          title: 'Missing fields',
          badges: [
            {
              id: 'foreignTaxNumber',
              text: 'Foreign tax number',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            },
            {
              id: 'corporationCertificate',
              text: 'Corporation Certificate',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            },
            {
              id: 'placeOfBirth',
              text: 'Place of birth',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            }
          ]
        },
        {
          id: 'kycAndIdentification',
          title: 'KYC & Identification',
          badges: [
            {
              id: 'identifyDocumentsNotChecked',
              text: 'Identify Documents Not Checked',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            },
            {
              id: 'corporateDocumentsApproved',
              text: 'Corporate Documents Approved',
              bgColor: '#2ADC26',
              textColor: '#000000'
            }
          ]
        }
      ]
    },
    {
      id: 'demandSummary',
      title: 'Demand Summary',
      subFields: [
        {
          id: 'totalReservationsInLotInterest',
          title: 'Total Reservations In Lot Interest',
          badges: [
            {
              id: 'choice-1',
              text: 'Choice 1',
              bgColor: '#09090B',
              textColor: '#FFFFFF'
            },
            {
              id: 'choice-2',
              text: 'Choice 2',
              bgColor: '#09090B',
              textColor: '#FFFFFF'
            },
            {
              id: 'choice-3',
              text: 'Choice 3',
              bgColor: '#09090B',
              textColor: '#FFFFFF'
            }
          ]
        }
      ]
    },
    {
      id: 'paymentMethods',
      title: 'Payment Methods',
      subFields: [
        {
          id: 'missingFields',
          title: 'Missing fields',
          badges: [
            {
              id: 'foreignTaxNumber',
              text: 'Foreign tax number',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            },
            {
              id: 'corporationCertificate',
              text: 'Corporation Certificate',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            },
            {
              id: 'placeOfBirth',
              text: 'Place of birth',
              bgColor: '#DC2626',
              textColor: '#FFFFFF'
            }
          ]
        }
      ]
    }
  ]

  const isUpdateInProgress = useMemo(() => {
    return signInLoading || loading || cancelInLoading
  }, [signInLoading, loading, cancelInLoading])

  const handleReservationDateChange = async (e) => {
    try {
      console.log('manualReservationDate', manualReservationDate)
      console.log(reservationId)
      const NewReservation = {
        manualReservationDate,
        _id: reservationId,
      }
      await createOrUpdateReservation(projectId, NewReservation)
      toast.success('VIP reservation date updated successfully')
    } catch {
      toast.error('Failed to update VIP reservation date')
    }
    refetchReservationDetails()
  }

  const handleReservation = () => {
    if (isUpdateInProgress) {
      return
    }

    const toastId = toast.loading(reservation?._id ? 'Updating VIP reservation...' : 'Creating VIP reservation...')
    const modifiedLotChoices = DEFAULT_LOT_CHOICES
      .map((lot) => {
        const tempIndex = lotChoices.findIndex((e) => (e.choice === lot.choice))
        if (tempIndex !== -1) {
          return lotChoices[tempIndex]
        }
        return lot
      })
      .reduce((acc, item) => {
        const choiceMap = {
          1: 'firstChoiceInventory',
          2: 'secondChoiceInventory',
          3: 'thirdChoiceInventory',
          4: 'fourthChoiceInventory',
          5: 'fifthChoiceInventory'
        };

        const key = choiceMap[item.choice];
        if (key) {
          acc[key] = item.lotId;
        }

        return acc;
      }, {});

    try {
      const NewReservation = {
        currency,
        paymentMethod,
        escrowAgent: selectedEscrowCompany._id,
        contractLanguage,
        notes,
        reservationHolders: selectedReservationHolders,
        escrowAgentIndex,
        ...modifiedLotChoices,
        manualReservationFee,
        manualReservationDate,
        _id: reservationId,
        // lotChoices: lotChoices.filter(elm => elm?.lotId?.length > 0).map(elm => elm.lotId)
      }
      createOrUpdateReservation(projectId, NewReservation)
      toast.dismiss(toastId)
      toast.success(reservation?._id ? 'VIP reservation updated successfully' : 'VIP reservation created successfully')
    } catch {
      toast.dismiss(toastId)
    }
    if (!(reservationId && reservationId?.length > 0)) {
      setStep(1)
    }
  }

  useEffect(() => {
    if (data?._id?.length > 0) {
      setReservationId(data?._id)
    }
  }, [data])

  const handleDocumentSign = useCallback(async () => {
    // if (isUpdateInProgress) {
    //   return
    // }
    if (!docusealData){
      setIsDocusealModalOpen(true)
    }else{
      if (isUpdateInProgress) {
          return
        }
      const toastId = toast.loading('Sending document to buyer...')
      setIsDocSignInProgress(true)
      try {
        await signDocument(reservationId)
        toast.dismiss(toastId)
        toast.success('Document sent to buyer successfully')
        history.replace(`/admin/reservations/${reservationId}`)
        setStep(2)
      } catch {
        toast.dismiss(toastId)
      }
      setIsDocSignInProgress(false)
    }
  }, [toast, reservationId, isUpdateInProgress])

  const handleManuallySign = useCallback(async () => {
    if (isUpdateInProgress) {
      return
    }
    setIsDocSignInProgress(true)
    try {
      const dataObject = {
        _id: reservationId,
        signatureMode: 'manual'
      }
      await createOrUpdateReservation(projectId, dataObject)
      history.replace(`/admin/reservations/${reservationId}`)
      setStep(2)
    } catch { }
    setIsDocSignInProgress(false)
  }, [reservationId, isUpdateInProgress])

  useEffect(() => {
    if (escrowAgents?.length) {
      setEscrowCompaniesOptions([
        ...escrowAgents.map((elm) => {
          return { label: elm.escrowAgentData.companyName, value: elm._id }
        })
      ])
    }
  }, [escrowAgents])

  const handleAddLotChoices = () => {
    setLotChoices([
      ...lotChoices,
      {
        choice: lotChoices[lotChoices.length - 1].choice + 1 ?? 1
      }
    ])
  }

  const deleteLotChoices = (choice) => {
    setLotChoices([
      ...lotChoices.filter((elm) => elm.choice !== choice)
    ])
  }

  const handleLotChoicesChange = (crrIndex, selectedLot) => {
    const temp = lotChoices.map((lot, index) => {
      if (index === crrIndex) {
        return {
          ...lot,
          lotId: selectedLot
        }
      }
      return lot
    })
    setLotChoices(temp)
  }

  const handleReservationHoldersChange = (val) => {
    if (val.length <= 2) {
      setSelectedReservationHolders(val)
    }
  }

  const handleEscrowCompanyChange = (id) => {
    setSelectedEscrowCompany({
      ...escrowAgents.filter((elm) => elm._id === id)[0]
    })
    setEscrowAgentIndex(escrowAgents.findIndex((elm) => elm._id === id))
  }

  const useStyles = makeStyles((theme) => ({
    drawerPaper: {
      width: isMobile ? '100%' : '1083px',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    }
  }))

  const classes = useStyles()

  return (
    <div className='w-full md:h-full flex flex-col bg-white'>
      <div
        className='h-[64px] md:h-[100px] w-full flex justify-between items-center border-b-2 border-black px-4 sm:px-6 lg:px-8'
      >
        <div className='font-semibold md:font-bold text-2xl md:text-3xl'>
          {
            isReservationLoading
              ? <>Loading...</>
              : <>
                {selectedReservationHolders?.length > 0 && (!isCreatingReservation || data)
                  ? 'Reservation'
                  : 'Create Reservation'}
              </>
          }
        </div>
        {selectedReservationHolders?.length > 0 && (!isCreatingReservation || data) && (
          <div className='flex-1 flex justify-end items-center gap-4'>
            {
              signatureMode === 'manual' &&
              <CustomButton handleClick={handleReservation}>
                <span className='font-medium py-2 px-4 text-sm md:text-base flex gap-1 items-center'>
                  Save Reservation
                </span>
              </CustomButton>
            }
            <CustomButton variant='outline' handleClick={() => history.push('/admin/reservations')}>
              <span className='font-medium py-2 px-4 text-sm md:text-base flex gap-1 items-center'>
                Go to Reservation List
              </span>
            </CustomButton>
          </div>
        )}
        {!(selectedReservationHolders?.length > 0 && (!isCreatingReservation || data)) && (
          <CustomButton variant='outline' handleClick={() => history.push('/admin/reservations')}>
            <span className='font-medium py-2 px-4 text-sm md:text-base flex gap-1 items-center'>
              Cancel
            </span>
          </CustomButton>
        )}
      </div>
      <div className={classNames((isDocSignInProgress || loading) && 'disabled opacity-30', 'md:flex-1 flex flex-col md:overflow-hidden')}>
        <div className='md:flex-1 w-full p-4 md:p-8 md:overflow-hidden'>
          <div className='w-full h-full flex flex-col md:flex-row gap-2 md:gap-10'>
            {
              isMobile && (data || !isCreatingReservation) && step > 1
                ? <div className='w-full p-3 md:p-6 flex-col justify-start items-start gap-2 md:gap-4 inline-flex'>
                  {
                    isReservationLoading
                      ? <div className='w-full text-lg font-medium flex justify-center items-center p-8'>
                        Loading...
                      </div>
                      : <ReservationCard
                        reservationHolders={filteredUsers?.data?.filter(
                          (user) => reservation?.reservationHolders?.includes(user?._id)
                        ).map((user) => {
                          return (user.firstName && user.lastName) ? user.firstName + ' ' + user.lastName : ''
                        }).filter((e) => e).join(', ')}
                        date={reservation?.createdAt}
                        // signatureStatus={props.cell.row.original.contractsSignatureStatus}
                        // paymentStatus={props.cell.row.original.paymentStatus}
                        // reservationStatus={props.cell.row.original.reservationStatus}
                        btnAction={() => setIsReservationDetailedDrawerOpen(true)}
                        btnText='View more'
                        isTitled
                      />
                  }
                </div>
                : <ReservationForm
                  reservation={reservation}
                  step={step}
                  manualReservationDate={manualReservationDate}
                  setManualReservationDate={setManualReservationDate}
                  filtering={filtering}
                  filteredUsers={filteredUsers}
                  selectedReservationHolders={selectedReservationHolders}
                  handleReservationHoldersChange={handleReservationHoldersChange}
                  contractLanguage={contractLanguage}
                  setContractLanguage={setContractLanguage}
                  contractLanguages={contractLanguages}
                  loadingEscrowAgents={loadingEscrowAgents}
                  selectedEscrowCompany={selectedEscrowCompany}
                  handleEscrowCompanyChange={handleEscrowCompanyChange}
                  escrowCompaniesOptions={escrowCompaniesOptions}
                  manualReservationFee={manualReservationFee}
                  setManualReservationFee={setManualReservationFee}
                  paymentMethod={paymentMethod}
                  setPaymentMethod={setPaymentMethod}
                  paymentMethods={paymentMethods}
                  unitsLoading={unitsLoading}
                  lotChoices={lotChoices}
                  deleteLotChoices={deleteLotChoices}
                  handleLotChoicesChange={handleLotChoicesChange}
                  lotsInventoryOptions={lotsInventoryOptions}
                  handleAddLotChoices={handleAddLotChoices}
                  handleReservationDateChange={handleReservationDateChange}
                />
            }
            <SectionWrapper className={`${step === 1 ? 'order-1' : 'order-2'} md:order-2 md:h-full md:flex-1 flex-col justify-start items-start inline-flex thin-scrollbar overflow-y-auto`}>
              {
                !(data || !isCreatingReservation) && (
                  <CreatingReservation
                    loading={loading || (!isNumber(escrowAgentIndex) || escrowAgentIndex < 0)}
                    handleReservation={handleReservation}
                    reservationStatuses={reservationStatuses}
                  />
                )
              }
              {selectedReservationHolders?.length > 0 && (data || !isCreatingReservation) && (
                <>
                  {step === 1 && (
                    <ReservationCreated
                      onSentToSignClicked={handleDocumentSign}
                      onManuallySignClicked={handleManuallySign}
                      loading={isDocSignInProgress}
                    />
                  )}
                  {step === 2 && (
                    <SendBuyer
                      onClick={handleDocumentSign}
                      setStep={setStep}
                      reservation={reservation}
                      createOrUpdateReservation={createOrUpdateReservation}
                      refetchReservationDetails={refetchReservationDetails}
                      cancelReservation={cancelReservation}
                      deleteReservation={deleteAPI}
                      loading={isDocSignInProgress}
                      setIsShowContractModal={setIsShowContractModal}
                    />
                  )}
                  {step === 3 && (
                    <RecordPayment
                      setStep={setStep}
                      reservation={reservation}
                      createOrUpdateReservation={createOrUpdateReservation}
                      selectedReservationHolders={selectedReservationHolders}
                      refetchReservationDetails={refetchReservationDetails}
                    />
                  )}
                </>
              )}
            </SectionWrapper>
          </div>
        </div>
        <ContractSigningModal
          showingContracts={isShowContractModal}
          setShowingContracts={setIsShowContractModal}
          gettingContracts={false}
          reservationId={reservation?._id}
          onClose={() => setIsShowContractModal(false)}
        />
      </div>
      <Drawer
        open={isReservationDetailedDrawerOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className='pt-16 relative h-full'>
          <button
            onClick={() => setIsReservationDetailedDrawerOpen(false)}
            className='absolute left-2 md:left-4 z-[10] top-[5.2rem]'>
            {
              isMobile
                ? <ChevronLeft className='w-5 h-5' />
                : <IoMdClose fontSize='22px' />
            }
          </button>
          <div className='w-full h-full p-4 bg-gray-2 flex flex-col overflow-hidden'>
            <h3 className='pl-6 text-xl font-semibold'>
              Reservation details
            </h3>
            <div className='flex-1 overflow-y-auto'>
              <ReservationForm
                step={step}
                manualReservationDate={manualReservationDate}
                setManualReservationDate={setManualReservationDate}
                filtering={filtering}
                filteredUsers={filteredUsers}
                selectedReservationHolders={selectedReservationHolders}
                handleReservationHoldersChange={handleReservationHoldersChange}
                contractLanguage={contractLanguage}
                setContractLanguage={setContractLanguage}
                contractLanguages={contractLanguages}
                loadingEscrowAgents={loadingEscrowAgents}
                selectedEscrowCompany={selectedEscrowCompany}
                handleEscrowCompanyChange={handleEscrowCompanyChange}
                escrowCompaniesOptions={escrowCompaniesOptions}
                manualReservationFee={manualReservationFee}
                setManualReservationFee={setManualReservationFee}
                paymentMethod={paymentMethod}
                setPaymentMethod={setPaymentMethod}
                paymentMethods={paymentMethods}
                unitsLoading={unitsLoading}
                lotChoices={lotChoices}
                deleteLotChoices={deleteLotChoices}
                handleLotChoicesChange={handleLotChoicesChange}
                lotsInventoryOptions={lotsInventoryOptions}
                handleAddLotChoices={handleAddLotChoices}
              />
            </div>
          </div>
        </div>
      </Drawer>
      <DocusealConfigModel open={isDocusealModalOpen} close={() =>{ 
        setIsDocusealModalOpen(false)
        }} />
    </div>
  )
}
export default NewVIPLotReservationV2
