// @ts-nocheck
import Logo from 'components/LoginPage/Logo'
import React from 'react'
import backgroundVideo from '../../assets/videos/aires-public-screen-bg-video.mp4'
import { Oval } from 'react-loader-spinner'
import CustomButton from 'components/CustomButton'

const WelcomeScreen = ({ onClick, isLoadingData, projectLogo = '', userDetails = null, contractsText, showSignNowButton = false, projectName = '' }) => {
  return (
    <div className='h-screen w-full flex'>
      <div className='p-8 bg-white w-full md:w-[548px]'>
        <div className='relative flex flex-col justify-center items-center h-full'>
          <Logo />
          <div className='flex-1 max-w-md w-full mx-auto flex flex-col justify-center gap-6'>
            {isLoadingData && (
              <div className='flex flex-col gap-4 w-full items-center justify-center content-center center'>
                <Oval height='48' width='48' color='black' />
                <h1 className='text-xl font-bold'>Loading contract...</h1>
              </div>
            )}
            {!isLoadingData && (
              <>
                {projectLogo?.length > 0 &&
                  <div className='w-fit flex flex-col gap-4 justify-start items-start mb-10'>
                    <img
                      src={projectLogo}
                      className='h-[120px] object-contain'
                    />
                    <h2 className='text-2xl font-medium mb-0'>{projectName}</h2>
                  </div>
                }
                <div className='w-full flex flex-col gap-3 pb-4'>
                  <h1 className='text-2xl font-normal capitalize mb-0'>
                    Welcome
                    {
                      userDetails && (userDetails?.firstName || userDetails?.lastName) &&
                      <span className='ml-2 font-bold'>
                        {`${userDetails.firstName + ' ' + userDetails.lastName}`}
                      </span>
                    }
                    ,
                  </h1>
                  {contractsText}
                </div>
                {
                  showSignNowButton &&
                  <div className='w-full'>
                    <CustomButton
                      handleClick={onClick}
                      className='w-full'
                      disabled={isLoadingData}
                    >
                      <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
                        {!isLoadingData ? 'Sign Now' : 'Loading data...'}
                      </span>
                    </CustomButton>
                  </div>
                }
              </>
            )}
          </div>
        </div>
      </div>
      <div className='w-0 md:flex-1 md:flex h-full'>
        <video
          src={backgroundVideo}
          className='w-full h-full object-cover'
          autoPlay
          muted
          loop
        />
      </div>
    </div>
  )
}

export default WelcomeScreen
