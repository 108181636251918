/* eslint-disable */
// @ts-nocheck
import QuickForm from "components/Inventory/components/QuickForm"
import QuickFormV2Inventory from "components/Inventory/components/QuickFormV2Inventory"
import React from "react"
import { useParams } from "react-router-dom"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"
import { checkV2Inventory } from "utils/checkV2Inventory"

const PurchaseForm = () => {
  const location = useLocation()
  const defaultSelectedUnit = new URLSearchParams(location.search).get(
    "defaultSelectedUnit"
  )
  return (
    <div className="bg-[#F6F5F1]">
      {
        checkV2Inventory()  ? 
        <QuickFormV2Inventory defaultSelectedUnit={defaultSelectedUnit} /> : 
        <QuickForm defaultSelectedUnit={defaultSelectedUnit} />
      }
    </div>
  )
}

export default PurchaseForm
