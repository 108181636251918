/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import { useGetCorporateDemandNotes } from 'hooks/useGetCorporateDemandNotes';
import TableGrid from 'components/TableGrid/Table';
import { Drawer } from 'antd';
import { getCorporatDemandNotesById, getCorporatDemandNotesUsers } from 'store/actions/demandNotes';

const TableBody = ({ setIsEdit, setInitialValues, data, setDeleteModal, deleteModal }) => {
  return (
    <tbody className='divide-y divide-gray-200 bg-white'>
      {data &&
        data.map((item, index) => {
          return (
            <tr>
              <td className='relative w-25 px-6 sm:w-16 sm:px-8'>
                <div className='absolute inset-y-0 left-0 w-0.5 bg-indigo-600'></div>
                <input
                  type='checkbox'
                  className='absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer'
                />
              </td>
              <td className='flex flex-row gap-3 items-center whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6 py-5'>
                {item.label}
              </td>
              <td className='whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6'>
                <div
                  className='underline cursor-pointer'
                  onClick={() => {
                    setInitialValues({
                      label: item.label,
                      options: item.options,
                      _id: item._id,
                      isMulti: item.isMulti,
                    });
                    setIsEdit(true);
                  }}
                >
                  Edit
                </div>
              </td>
              <td className='whitespace-nowrap w-25 text-sm font-medium text-gray-900 pr-6'>
                <div
                  onClick={() => {
                    setDeleteModal(true);
                    setInitialValues({
                      label: item.label,
                      options: item.options,
                      _id: item._id
                    });
                  }}
                  className='underline cursor-pointer'
                >
                  Delete
                </div>
              </td>
              <td>
                <ViewDemandNoteUsers demandNoteId={item.id} />
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};
function ViewDemandNoteUsers(props) {

  const [visible, setVisible] = useState(false);
  function onClose() {
    setVisible(false);
  };

  return <div>
    <button onClick={() => setVisible(true)} className='whitespace-nowrap'>View Users</button>
    <Drawer
      width={"50%"}
      bodyStyle={{
        padding: '5px',
        margin: '0'
      }}
      title={'Corporate Demand Note'} placement='right' size='large' closable={true} onClose={onClose} visible={visible}>
      <ViewDemandNoteUsersContent demandNoteId={props.demandNoteId} />
    </Drawer>
  </div>
}

function ViewDemandNoteUsersContent(props) {
  const [demandNoteId, setDemandNoteId] = useState(props.demandNoteId);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [demandNote, setDemandNote] = useState(null);

  const [demenandNoteUsers, setDemandNoteUsers] = useState([]);
  useEffect(() => {
    getCorporatDemandNotesById(demandNoteId).then((response) => {
      setDemandNote(response);
    }).finally(() => {
      setLoading(false);
    })
  }, [demandNoteId]);

  useEffect(() => {
    setLoadingUsers(true);
    getCorporatDemandNotesUsers(demandNoteId).then((response) => {
      setDemandNoteUsers(response);
    }).finally(() => {
      setLoadingUsers(false);
    })
  }, [demandNote]);

  return <div className='px-2 py-1'>
    {loading && <p>Loading...</p>}
    {!loading && demandNote && <div>
      <h1 className='text-lg font-bold'>{demandNote?.label}</h1>
      <div>
        Total Users: <span className='font-bold text-xl'>{demenandNoteUsers.length}</span>
      </div>
      <div>
        <TableGrid
          height={"90%"}
          columnDefs={[
            {
              headerName: '#',
              field: 'index',
              width: 50,
              cellRenderer: (params) => {
                return <span className='max-w-[30px]'>
                  {params.rowIndex + 1}
                </span>
              }
            },
            {
              headerName: 'Email',
              field: 'email',
              flex: 1
            },
            {
              headerName: 'Name',
              field: 'name',
              flex: 1,
              cellRenderer: ({ data }) => {
                return `${data.firstName} ${data.lastName}`
              }
            },
            {
              headerName: 'Demand Note',
              field: '_id',
              cellRenderer: ({ data }) => {
                const item = data;
                const userType = String(item.userType).toLowerCase();
                const dataKey = userType == 'buyer' ? 'buyerData' : 'leadBrokerData';
                const demandNotes = item?.[dataKey]?.['corporateDemands'] ?? []
                const currentDemandNote = demandNotes.find((item) => item.label == demandNoteId);
                const email = item.email;
                const name = `${item.firstName} ${item.lastName}`;
                const currentNoteValues = currentDemandNote?.values ?? [];
                const labels = demandNote.options.filter((item) => currentNoteValues.includes(item.id));
                return <div>{labels.map((item) => item.label).join(', ')}</div>
              }
            }
          ]}
          getData={() => {
            return new Promise((resolve) => {
              resolve(demenandNoteUsers)
            })
          }}
          pagination={false}
        />
      </div>
    </div>
    }
  </div>

}


export default TableBody;
