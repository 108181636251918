/* eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect, useRef, useState } from 'react'

import { TPlusPrimaryIcon, TFilledPenIcon } from 'components/Icons'
// import { ReactComponent as Done } from '../../../../../assets/icons/done.svg'
import { Oval } from 'react-loader-spinner'

import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'
import { isArray } from 'lodash-es'

import Option from './Option'
import { useSelector } from 'react-redux'

import {
  createOrUpdateProject,
  getProjects,
  createOrUpdateProjectOption,
  // createOrUpdateProjectOptionContractDetail
} from 'store/actions/projectActions'
import CreateProjectContext from '../../context/CreateProjectProvider'
import CustomButton from 'components/CustomButton'
import { Check } from 'lucide-react'
// import { getProjectContractTemplates } from 'store/actions/contractTemplateActions'

const LoadingProjectpOptions = () => (
  <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
    <Searching title='Loading Project Details...' />
  </FadedAnimatedDiv>
)

const CustomSectionOptions = ({ _id, customSection, type }) => {
  const mountedRef = useRef(true)
  const projectContext = useContext(CreateProjectContext)
  const newProjectId = projectContext.newProjectId
  const appProject = useSelector((state) => state.appReducer.appProject)

  const projectId = newProjectId || appProject

  const [editMode] = useState(true)
  const [loading, setLoading] = useState(false)
  // const [latestContractTemplateId, setLatestContractTemplateId] = useState([])

  const [projectData, setProjectData] = useState({})
  const [deleteHandler, setDeleteHandler] = useState(0)
  const [isLoading, setIsLoading] = useState(true)
  // const [allContractTemplates, setAllContractTemplates] = useState([])

  const optionValue = {
    name: '',
    description: '',
    value: '',
    maxAmountPerUnit: '',
    images: [],
    // contractDetail: [],
    displayImages: [],
    additionalFields: {}
  }
  const [pOptions, setPOptions] = useState([
    type === 'edit' && { ...optionValue }
  ])

  const [errorValue, setErrorValue] = useState({
    name: false,
    description: false,
    value: false,
    maxAmountPerUnit: false,
    images: false,
    // contractDetail: false
  })
  const [errors, setErrors] = useState([type === 'edit' && { ...errorValue }])
  // const [contractParams, setContractParams] = useState([])

  // Getting all latest contracts
  // const getTemplates = () => {
  //   getProjectContractTemplates(appProject)
  //     .then((contractTemplates) => {
  //       if (!mountedRef.current) return null
  //       setAllContractTemplates(contractTemplates)
  //     })
  //     .catch((error) => console.log(error))
  // }

  // useEffect(() => {
  //   getTemplates()
  // }, [])

  const validate = () => {
    let isValid = true
    const _errors = [...errors]
    const CHECKABLE_FIELD = ['name', 'value', 'maxAmountPerUnit']

    for (let index = 0; index < pOptions.length; index++) {
      Object.entries(pOptions[index]).forEach(function ([key, val]) {
        if (CHECKABLE_FIELD.includes(key)) {
          if (val?.length === 0 || val === '' || val === undefined) {
            _errors[index][key] = true
          } else {
            _errors[index][key] = false
          }
        }
      })
      Object.entries(errors[index]).forEach(([, val]) => { if (val) isValid = false })
    }

    setErrors([...errors])

    return isValid
  }

  const handleSubmit = async () => {
    const isValid = validate()
    if (!isValid) {
      return
    }

    const oldPOptions = pOptions
      .filter((pOption) => pOption?._id)
      .map((pOption) => {
        if (pOption?.additionalFields && typeof pOption.additionalFields === "object") {
          Object.keys(pOption.additionalFields).forEach((key) => {
            if (Array.isArray(pOption.additionalFields[key])) {
              pOption.additionalFields[key] = pOption.additionalFields[key].filter((v) => (v !== '' && v !== null));
            }
          });
        }
        return pOption;
      });

    const newPOptions = pOptions
      .filter((pOption) => !pOption?._id)
      .map((pOption) => {
        if (pOption?.additionalFields && typeof pOption.additionalFields === "object") {
          Object.keys(pOption.additionalFields).forEach((key) => {
            if (Array.isArray(pOption.additionalFields[key])) {
              pOption.additionalFields[key] = pOption.additionalFields[key].filter((v) => (v !== '' && v !== null));
            }
          });
        }
        return pOption;
      });

    // const newpOptions = pOptions.filter(
    //   (pOption) => pOption?.displayImages.length > 0 && !pOption?._id
    // )
    setLoading(true)
    if (oldPOptions.length > 0) {
      let { options } = projectData
      const results = options.filter(
        ({ _id: id1 }) => !oldPOptions.some(({ _id: id2 }) => id2 === id1)
      )
      const tmpoption = results.concat(oldPOptions)
      options = [...tmpoption]
      const params = {
        ...projectData,
        options
      }
      // Update
      try {
        await createOrUpdateProject({ params })
      } catch (err) {
        setLoading(false)
      }
      setLoading(false)

      // const _contractParams = [...contractParams]
      // const oldContractParams = _contractParams.splice(0, oldPOptions.length)

      // oldContractParams.forEach(async (contractParam, index) => {
      //   if (Object.keys(contractParam).length > 0) {
      //     try {
      // await createOrUpdateProjectOptionContractDetail(contractParam)
      //     } catch (err) {
      //       console.log(err)
      //     }
      //   }
      // })
    }
    if (newPOptions.length) {
      newPOptions.forEach(async (pOption, index) => {
        const params = {
          ...pOption,
          ...{ type, projectId }
        }

        await createOrUpdateProjectOption(params).then((res) => {
          // const { optionId } = res

          // const contractIndex = oldPOptions.length + index
          // const temp = {
          //   ...contractParams[contractIndex],
          //   optionId
          // }
          // if (typeof contractParams[contractIndex] !== 'undefined' && Object.keys(contractParams[contractIndex]).length > 0) {
          // createOrUpdateProjectOptionContractDetail(temp).then((data) => {
          //     let { contractDetail } = data.options.filter(
          //       (option) =>
          //         option.type === type && option._id === optionId
          //     ).pop()
          //     const contractId = contractDetail?.[0]?._id
          //     if (contractId?.length > 0) {
          //       const temps = [
          //         ...latestContractTemplateId,
          //         contractId
          //       ]
          //       setLatestContractTemplateId(temps)
          //     }
          //   }).catch((error) => console.log(error))
          // }
          getProjectDetail(projectId)
        })
        setLoading(false)
      })
    }
  }

  const addOptionHandler = () => {
    setErrors([...errors, { ...errorValue }])
    setPOptions([...pOptions, { ...optionValue }])
  }

  const getProjectDetail = (projectId) => {
    setIsLoading(true)
    getProjects(projectId).then((project) => {
      if (!mountedRef.current) return null
      const { options } = project
      if (isArray(options)) {
        let tempPOptions = options.filter((option) => option.type === type)

        tempPOptions = tempPOptions.map((pOption) => ({
          ...pOption,
          displayImages: []
        }))
        setPOptions(tempPOptions)
        const tmpErrors = []
        tempPOptions.forEach(function () {
          tmpErrors.push(errorValue)
        })
        setErrors(tmpErrors)
      }
      setProjectData({
        ...project,
        developer: project.developer._id,
        id: project._id,
        createdBy: project.createdBy._id
      })
      setIsLoading(false)
    })
  }
  useEffect(() => {
    getProjectDetail(projectId)
  }, [projectId, _id])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    const { options } = projectData
    if (isArray(options)) {
      let tmppOptions = options.filter((option) => option.type === type)

      tmppOptions = tmppOptions.map((pOption) => ({
        ...pOption,
        displayImages: []
      }))
      setPOptions(tmppOptions)
    }
  }, [projectData, _id])

  return (
    <>
      {isLoading && <LoadingProjectpOptions />}
      {!isLoading &&
        pOptions.map((option, index) => (
          <Option
            key={index}
            index={index}
            pOptions={pOptions}
            setPOptions={setPOptions}
            lengthOfOptions={pOptions.length}
            values={option}
            editMode={editMode}
            setProjectData={setProjectData}
            projectData={projectData}
            setDeleteHandler={setDeleteHandler}
            type={type}
            // allContractTemplates={allContractTemplates || null}
            errors={errors}
            errorValue={errors[index]}
            setErrors={setErrors}
            // params={contractParams}
            // setParams={setContractParams}
            // latestContractTemplateId={latestContractTemplateId}
            // setLatestContractTemplateId={setLatestContractTemplateId}
            fields={customSection?.dataFields?.filter((field) => (!field?.isDeleted))}
            sectionName={customSection?.title ?? ''}
          />
        ))}
      <div className='my-10 w-full flex items-center justify-center'>
        <CustomButton
          variant='outline'
          className='flex items-center font-medium capitalize'
          onClick={addOptionHandler}
        >
          <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
            <TPlusPrimaryIcon className='w-4 h-4 mr-1' />
            Add {customSection?.title ?? ''}
          </span>
        </CustomButton>
      </div>

      <div>
        <CustomButton handleClick={handleSubmit} className='col-span-2 mt-6 ml-auto'>
          <span className='font-medium py-2.5 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height="18" width="18" color="white" />}
            {!loading && (
              <Check className='h-5 w-5' />
            )}
            Save
          </span>
        </CustomButton>
      </div>
    </>
  )
}

export default CustomSectionOptions
