/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Typography, Card, message } from "antd";
// import axios from "axios"; // Assuming you're using axios
import {
  getAllDealTargets,
  getAllLeadStatuses,
  updateDealTargetsAll,
  updateLeadStatusAll,
} from "store/actions/leadStatusAction";
import Checkbox from "components/Checkbox";
import { MdDragHandle } from "react-icons/md";
import CustomSelect from "pages/tasks/components/CustomSelect";

const { Text } = Typography;

const LeadStatusSettings = () => {
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [dealTargets, setDealTargets] = useState([]);
  const dataSourceOptions = [
    { label: "Lead", value: "lead" },
    { label: "Deal", value: "deal" },
  ];
  const [dataSource, setDataSource] = useState("lead");
  useEffect(() => {
    if (dataSource === "lead") {
      fetchLeadStatuses();
    } else {
      fetchDealTargets();
    }
  }, [dataSource]);

  const fetchDealTargets = async () => {
    try {
      const response = await getAllDealTargets();
      const sortedStatuses = response?.sort((a, b) => a.position - b.position);
      setDealTargets(sortedStatuses);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchLeadStatuses = async () => {
    try {
      const response = await getAllLeadStatuses();
      const sortedStatuses = response?.sort((a, b) => a.position - b.position);
      setLeadStatuses(sortedStatuses);
    } catch (error) {
      console.log(error);
      message.error("Failed to fetch lead statuses");
    }
  };

  const onDragEnd = async (result) => {
    const { source, destination } = result;
    if (!destination) return;

    const newItems = Array.from(
      dataSource === "lead" ? leadStatuses : dealTargets
    );
    const [removed] = newItems.splice(source.index, 1);
    newItems.splice(destination.index, 0, removed);

    const updatedStatuses = newItems.map((status, index) => ({
      ...status,
      position: index + 1,
    }));
    if (dataSource === "lead") {
      setLeadStatuses(updatedStatuses);
    } else {
      setDealTargets(updatedStatuses);
    }
    try {
      if (dataSource === "lead") {
        await updateLeadStatusAll(updatedStatuses);
      } else {
        await updateDealTargetsAll(updatedStatuses);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (status) => {
    if (dataSource === "lead") {
      const updatedStatuses = leadStatuses.map((s) => ({
        ...s,
        selected: s.id === status.id ? !status.selected : s.selected,
      }));
      setLeadStatuses(updatedStatuses);
      try {
        await updateLeadStatusAll(updatedStatuses);
      } catch (error) {
        console.log(error);
      }
    } else {
      const updatedStatuses = dealTargets.map((s) => ({
        ...s,
        selected: s.id === status.id ? !status.selected : s.selected,
      }));
      setDealTargets(updatedStatuses);
      try {
        await updateDealTargetsAll(updatedStatuses);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="p-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold mb-6">
          {dataSource === "lead" ? "Lead" : "Deal"} Settings
        </h1>
        <div className="w-[200px]">
          <CustomSelect
            options={dataSourceOptions}
            value={dataSource}
            handleChange={(value) => setDataSource(value)}
            placeholder="Select Data Source"
          />
        </div>
      </div>
      <Card className="max-w-2xl">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="lead-statuses">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {dataSource === "lead"
                  ? leadStatuses.map((status, index) => (
                      <Draggable
                        key={status.id}
                        draggableId={status.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`bg-white p-4 border rounded-lg flex items-center gap-4 ${
                              snapshot.isDragging ? "shadow-lg" : ""
                            }`}
                          >
                            <Checkbox
                              handleChange={(e) => handleStatusChange(status)}
                              checked={status.selected}
                            />
                            <Text className="flex-1">{status.name}</Text>
                            <div
                              {...provided.dragHandleProps}
                              className="cursor-move text-xl text-gray hover:text-gray-600"
                            >
                              <MdDragHandle />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                  : dealTargets.map((status, index) => (
                      <Draggable
                        key={status.id}
                        draggableId={status.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`bg-white p-4 border rounded-lg flex items-center gap-4 ${
                              snapshot.isDragging ? "shadow-lg" : ""
                            }`}
                          >
                            {" "}
                            <Checkbox
                              handleChange={(e) => handleStatusChange(status)}
                              checked={status.selected}
                            />
                            <Text className="flex-1">{status.name}</Text>
                            <div
                              {...provided.dragHandleProps}
                              className="cursor-move text-xl text-gray hover:text-gray-600"
                            >
                              <MdDragHandle />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Card>
    </div>
  );
};

export default LeadStatusSettings;
