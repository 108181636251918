// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'

import Header from './components/Header'
import ProgressSteps from './components/ProgressSteps'

import { BuildingIcon } from 'components/Icons'
import SelectParking from './components/SelectParking'
import { CreateOfferProvider } from './context/CreateOfferContext'
import Step1 from './components/Step1'
import SelectBuyer from './components/SelectBuyer'
import SelectExtraBuyer from './components/SelectExtraBuyer'
import SelectDeposit from './components/SelectDeposit'
import SelectUpgrades from './components/SelectUpgradeAndOptions'
import SelectUpgradeAndOptionsInventory from './components/SelectUpgradeAndOptionsInventory'
import SelectPrivacy from './components/SelectPrivacy'
import Fintrac from './components/Fintrac'
import SelectReview from './components/SelectReview'
import { useSelector } from 'react-redux'
import SelectCustomSection from './components/SelectCustomSection'
import DocusealModelContent from 'pages/desktopMode/docusealConfig/component/DocusealModelContent'
import useDocusealConfigModal from 'hooks/useDocusealConfigModal'
import { PageLoader } from 'pages/contractTemplates'
import { checkV2Inventory } from 'utils/checkV2Inventory'
import Step1Inventory from './components/Step1Inventory'
import SelectReviewV2 from './components/SelectReviewV2'
import SelectDepositV2 from './components/SelectDepositV2'
import { CreateOfferProviderInventory } from './context/CreateOfferContextInventory'
import SelectBuyerInventory from './components/SelectBuyerInventory'
import SelectExtraBuyerInventory from './components/SelectExtraBuyerInventory'
import SelectParkingInventory from './components/SelectParkingInventory'
import SelectPrivacyInventory from './components/SelectPrivacyInventory'
import FintracInventory from './components/FintracInventory'
import SelectCustomSectionV2 from './components/SelectCustomSectionV2'

const ABOVE_OFFER_STEPS = [
  { text: 'Unit Details', Icon: <BuildingIcon />, Component: Step1 },
  { text: 'Buyer', Component: SelectBuyer },
  { text: 'Additional Buyers', Component: SelectExtraBuyer },
  { text: 'Parking', Component: SelectParking },
  { text: 'Upgrades and Options', Component: SelectUpgrades }
]

const ABOVE_OFFER_STEPS_INVENTORY = [
  { text: 'Unit Details', Icon: <BuildingIcon />, Component: Step1Inventory },
  { text: 'Buyer', Component: SelectBuyerInventory },
  { text: 'Additional Buyers', Component: SelectExtraBuyerInventory },
  { text: 'Parking', Component: SelectParkingInventory },
  { text: 'Upgrades and Options', Component: SelectUpgradeAndOptionsInventory }
]


const BELOW_OFFER_STEPS = [
  { text: 'Deposit Options', Component: SelectDeposit },
  { text: 'Privacy Consent', Component: SelectPrivacy },
  { text: 'Fintrac', Component: Fintrac },
  { text: 'Review', Component: SelectReview }
]

const BELOW_OFFER_STEPS_V2 = [
  { text: 'Deposit Options', Component: SelectDepositV2 },
  { text: 'Privacy Consent', Component: SelectPrivacyInventory },
  { text: 'Fintrac', Component: FintracInventory },
  { text: 'Review', Component: SelectReviewV2 }
]

const CreateOffer = () => {
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [step, setStep] = useState(0)
  const [offerSteps, setOfferSteps] = useState([])
  const { docusealData, loading: isDocusealDataLoading } = useDocusealConfigModal()

  const getComponent = useCallback(() => {
    if (offerSteps?.length > 0) {
      const currentStep = offerSteps?.[step]
      const Component = currentStep.Component
      if (currentStep?.isAdditionalStep) {
        return <Component setStep={setStep} key={currentStep.text} _id={currentStep.customSectionId} name={currentStep.text} />
      }
      return <Component setStep={setStep} key={currentStep.text} />
    }
    return null
  }, [step, offerSteps])

  useEffect(() => {
    if (customSections) {
      const isInventoryVersionIsV2 = checkV2Inventory()

      const tempOfferSteps = customSections?.map((section) => {
        return {
          text: section.title,
          isAdditionalStep: true,
          customSectionId: section._id,
          Component: isInventoryVersionIsV2
            ? SelectCustomSectionV2
            : SelectCustomSection
        }
      })

      isInventoryVersionIsV2
        ? setOfferSteps([
          ...ABOVE_OFFER_STEPS_INVENTORY,
          ...tempOfferSteps,
          ...BELOW_OFFER_STEPS_V2,
        ])
        : setOfferSteps([
          ...ABOVE_OFFER_STEPS,
          ...tempOfferSteps,
          ...BELOW_OFFER_STEPS,
        ]);
    }
  }, [customSections])


  if (checkV2Inventory()) {
    return (
      <>
        <CreateOfferProviderInventory>
          <div className="h-[calc(100vh-64px)] flex flex-col overflow-hidden">
            <Header />
            {offerSteps?.length > 0 && (
              <div className="h-full mt-[64px] p-6 flex gap-4 overflow-hidden">
                <div className="h-full p-3 border rounded-xl overflow-hidden bg-white">
                  <ProgressSteps
                    steps={offerSteps}
                    currentStepIndex={step}
                    className="w-[300px]"
                  />
                </div>
                <div className="h-full p-3.5 flex-1 bg-white grow overflow-auto border rounded-xl">
                  {getComponent()}
                </div>
              </div>
            )}
          </div>
        </CreateOfferProviderInventory>
      </>
    )
  } else {
    return (
      <>
        <CreateOfferProvider>
          <div className="h-[calc(100vh-64px)] flex flex-col overflow-hidden">
            <Header />
            {offerSteps?.length > 0 && (
              <div className="h-full mt-[64px] p-6 flex gap-4 overflow-hidden">
                <div className="h-full p-3 border rounded-xl overflow-hidden bg-white">
                  <ProgressSteps
                    steps={offerSteps}
                    currentStepIndex={step}
                    className="w-[300px]"
                  />
                </div>
                <div className="h-full p-4 flex-1 bg-white grow overflow-auto border rounded-xl">
                  {getComponent()}
                </div>
              </div>
            )}
          </div>
        </CreateOfferProvider>
      </>
    )
  }
}

export default CreateOffer
