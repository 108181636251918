/* eslint-disable */
// @ts-nocheck

import React, { useState } from 'react'
import { DndContext, closestCenter, DragOverlay, useSensors, PointerSensor, useSensor } from '@dnd-kit/core'
import { arrayMove, SortableContext, horizontalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { GripVertical, X, ChevronRight, Plus } from 'lucide-react'
import { DOCUSEAL_ROLES } from 'utils'

const ContractRolesInstructions = ({
  containerClassName = '',
  rolesOrder = [],
  setRolesOrder = () => { },
  contractType = ''
}) => {
  const [removedItems, setRemovedItems] = useState([])
  const [activeId, setActiveId] = useState(null)

  const handleDragStart = (event) => {
    setActiveId(event.active.id)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setRolesOrder((items) => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)
        return arrayMove(items, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }

  const handleRemove = (id) => {
    setRolesOrder((items) => items.filter((item) => item !== id))
    setRemovedItems((items) => [...items, id])
  }

  const handleAdd = (id) => {
    setRemovedItems((items) => items.filter((item) => item !== id))
    setRolesOrder((items) => [...items, id])
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  )

  return (
    <div className={`h-full flex flex-col gap-4 ${containerClassName}`}>
      <div className="w-full">
        <h2 className="text-lg font-medium mb-0">Contract roles instructions</h2>
        <p className="text-zinc-600 mb-0">
          The contract will be signed in the following order
        </p>
      </div>

      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
      >
        <div className='w-full flex gap-[4px] overflow-x-auto pb-2'>
          {
            rolesOrder?.length > 0
              ? <SortableContext items={rolesOrder} strategy={horizontalListSortingStrategy}>
                {rolesOrder.map((role, index) => (
                  <SortableItem
                    key={role}
                    id={role}
                    value={role}
                    index={index}
                    total={rolesOrder.length}
                    onRemove={handleRemove}
                  />
                ))}
              </SortableContext>
              : <div className='w-full h-[42px] bg-zinc-100 rounded-lg flex justify-center items-center text-center text-zinc-500'>
                {contractType ? 'Please select at least one role for signing to proceed' : 'Please select contract type to proceed'}
              </div>
          }
        </div>

        <DragOverlay adjustScale={false} dropAnimation={null}>
          {activeId ? (
            <SortableItem
              id={activeId}
              value={activeId}
              index={rolesOrder.indexOf(activeId)}
              total={rolesOrder.length}
              onRemove={handleRemove}
              isDragging
            />
          ) : null}
        </DragOverlay>
      </DndContext>

      {removedItems.length > 0 && (
        <div className='w-full'>
          <h3 className='text-sm font-medium text-gray-500 mb-3'>Removed Roles</h3>
          <div className='flex flex-wrap gap-3'>
            {removedItems.map((role) => (
              <button
                key={role}
                onClick={() => handleAdd(role)}
                className='flex items-center gap-2 px-4 py-2 bg-white border rounded-lg hover:bg-gray-50 transition-colors focus:!outline-none focus:!right-0'
              >
                <Plus className='w-4 h-4 text-gray-400' />
                <span className='text-sm'>{role}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const SortableItem = ({ id, value, index, total, onRemove, isDragging }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 1,
    position: isDragging ? "relative" : "static",
  }

  return (
    <div
      ref={setNodeRef}
      // @ts-ignore
      style={style}
      className='flex items-center gap-[4px]'
    >
      <div
        className={`
          flex items-center gap-[4px] py-[8px] px-[4px] bg-white border rounded-lg
          ${isDragging ? 'shadow-lg opacity-50' : ''}
        `}
      >
        <button className='!cursor-move flex items-center p-1 rounded hover:bg-gray-100 focus:!outline-none focus:!right-0' {...attributes} {...listeners}>
          <GripVertical className='w-4 h-4 text-gray-400 hover:text-gray-600 transition-all' />
        </button>

        <span className='flex items-center gap-2'>
          <span className='flex items-center justify-center w-6 h-6 rounded-full bg-gray-100 text-sm font-medium'>{index + 1}</span>
          <span className='text-sm font-medium'>{value}</span>
        </span>

        <button className='p-1 rounded hover:bg-gray-100 focus:!outline-none focus:!right-0' onClick={() => onRemove(id)}>
          <X className='w-4 h-4 text-gray-400 hover:text-gray-600 transition-all' />
        </button>
      </div>
      {index < total - 1 && <ChevronRight className='w-4 h-4 flex-shrink-0 text-zinc-400' />}
    </div>
  )
}

export default ContractRolesInstructions
