import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import propTypes from 'prop-types'
import {
  PdfWrapper,
  PdfRoundedWrapper,
  ButtonsWrapper,
  PdfReviwedContainer,
  PdfInternalWrappers,
  ContractsWrapper,
  ContractSignatureStatusWrapper
} from './styles'
import {
  PdfIcon, CheckCircleIcon,
  HelloSignDoc, FadedAnimatedDiv, LottieWrapper, AllDocsSigned, BuyerPageWithImage, AnimatedList
} from 'components'
import { Title, TButton } from 'ui'
import { H5, H7 } from 'ui/texts/headings'
import standardStyle from 'assets/css/standardStyle'
import { getOfferContracts, getRefreshedHellosignLink } from 'store/actions/offerActions'
import { AnimatePresence, motion } from 'framer-motion'
import { initCardAnimator } from './cardAnimation'
import { formatDate, getUserType } from 'utils'
import loadingContractsAnimation from 'assets/lottieJsons/loadingDoc.json'
import { connect } from 'react-redux'

const smallDevice = (window.matchMedia(standardStyle.mediaqueries.xs).matches ||
  window.matchMedia(standardStyle.mediaqueries.sm).matches)

const DocumentSigned = ({ signedDate }) => (
  <PdfReviwedContainer>
    <CheckCircleIcon style={{ color: standardStyle.colors.green, fontSize: 30, marginRight: 10 }} />
    Signed at {formatDate(signedDate)}
  </PdfReviwedContainer>
)

const signatureStatus = {
  awaiting_signature: 'Awaiting Signature',
  signed: 'Signed',
  canceled: 'Canceled'
}

const getDocumentStatus = (signature) => {
  const status = signature.status
  const signatureUserType = signature.userType

  return `${getUserType(signatureUserType)}: ${signatureStatus[status]}`
}

const DocumentWaitingSignature = ({ signatures }) => (
  <AnimatedList wrapperClassName='mt-5 space-y-1.5'>
    {
      signatures?.length && signatures?.map((signature) => (
        <ContractSignatureStatusWrapper
          className={`rounded px-2 text-white font-semibold ${signature.status === 'signed' ? 'bg-green-500' : 'bg-yellow-600'}`}
        >
          {getDocumentStatus(signature)}
        </ContractSignatureStatusWrapper>
      ))
    }
  </AnimatedList>
)

const PdfLargeButton = memo(({
  contract: {
    description, updatedAt, client_id: clientId, signatures, contractTemplateId
  }, onClick, loading, userObject, gettingHelloSignLink, selectedContractTemplateId
}) => {
  let needsToSign = false
  let signatureId = ''

  if (signatures && signatures.length) {
    const userDocuments = signatures
      .filter(signature => signature.userType === userObject.userType && signature.status === 'awaiting_signature')

    if (userDocuments && userDocuments.length) {
      const { signatureId: documentSignatureId } = userDocuments[0]

      needsToSign = true
      signatureId = documentSignatureId
    }
  }

  return (
    <PdfWrapper
      // className={`${smallDevice ? cardAnimationStyles.verticalStackCardsItem : cardAnimationStyles.horizontalStackCardsItem} js-stack-cards__item`}
    >
      <motion.div
        style={{ margin: smallDevice ? '1px' : '0px', height: '100%' }}
        whileHover={{ scale: 1.05 }}
      >
        <PdfRoundedWrapper className='py-3'>
          <PdfInternalWrappers className='px-3 justify-start'>
            <PdfIcon style={{ fontSize: 50, marginBottom: 10 }} />
            <H7 style={{ fontWeight: 'bold' }}>{description}</H7>
          </PdfInternalWrappers>
          <PdfInternalWrappers className='px-3 justify-end'>
            <DocumentWaitingSignature signatures={signatures}/>
            {
              needsToSign && (
                <TButton
                  onClick={() => onClick({ description, signatureId, clientId, contractTemplateId })}
                  className='w-full'
                  loading={loading}
                >
                  Sign
                </TButton>
              )
            }
          </PdfInternalWrappers>
        </PdfRoundedWrapper>
      </motion.div>
    </PdfWrapper>
  )
})

const ContractsHeaderTitle = memo(({ title, subtitle, allDocsSigned, executeWhenAllDocsSigned }) => (
  <div className='flex flex-col justify-center md:h-1/2 pl-5'>
    <Title>{title}</Title>
    <span className='text-2xl text-left'>{subtitle}</span>

    {
      allDocsSigned && !executeWhenAllDocsSigned && (
        <motion.div
          initial={{ x: -15, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          className='px-3 py-1 rounded text-white top-1.5 font-semibold bg-green-500 max-w-52 mt-3'
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 }
          }}
        >
          All of your pending docs were signed!
        </motion.div>
      )
    }
  </div>
))

const ListOfContracts = memo(({
  offerContracts, userObject, onClickSign,
  selectedContractTemplateId, gettingHelloSignLink
}) =>
  <ButtonsWrapper className='grid grid-cols-3 gap-3 py-5 w-full overflow-y-auto h-full'>
    {
      offerContracts.map((contract, index) => {
        return (
          <PdfLargeButton
            key={index}
            contract={contract}
            userObject={userObject}
            onClick={(data) => {
              const { description, signatureId, clientId, contractTemplateId } = data

              onClickSign({ description, signatureId, clientId, contractTemplateId })
            }}
            selectedContractTemplateId={selectedContractTemplateId}
            gettingHelloSignLink={gettingHelloSignLink}
            loading={((selectedContractTemplateId === contract.contractTemplateId) && (gettingHelloSignLink))}
          />
        )
      }
      )
    }
  </ButtonsWrapper>
)

const Docs = (props) => {
  const { projectData, offerId, executeWhenAllDocsSigned, userObject, onReturnToOffersClick } = props

  const [offer, setOffer] = useState('')
  const [offerContracts, setOfferContracts] = useState([])
  const [gettingContracts, setGettingContracts] = useState(false)
  const [showingDoc, setShowingDoc] = useState(false)
  const [clientId, setClientId] = useState('')
  const [helloSignUrl, setHelloSignUrl] = useState('')
  const [gettingHelloSignLink, setGettingHelloSignLink] = useState(false)
  const [selectedContractTemplateId, setSelectedContractTemplateId] = useState('')
  const [selectedDocumentTitle, setSelectedDocumentTitle] = useState()
  const [allDocsSigned, setAllDocsSigned] = useState(false)

  const stackcards = useRef()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (offerId) {
      if (offerId) {
        setOffer(offerId)
      }
    }
  }, [offerId])

  useEffect(() => {
    // Check if all contracts were already signed
    const userDocs = offerContracts
      .filter((contract) => {
        return contract.mustSign && contract.signatures.filter(signature => signature.userType === userObject.userType).length > 0
      })
      .map((contract) => {
        const tmpContract = { ...contract }

        tmpContract.signed = false

        const contractSigned = tmpContract.signatures
          .filter(signature => signature.userType === userObject.userType && signature.status === 'signed')

        tmpContract.signed = Boolean(contractSigned.length)

        return tmpContract
      })

    const pendingDocs = Boolean(userDocs.filter(doc => !doc.signed).length)

    if (offerContracts.length && !pendingDocs) {
      setAllDocsSigned(true)
      if (executeWhenAllDocsSigned) executeWhenAllDocsSigned()
    }
  }, [offerContracts, userObject, executeWhenAllDocsSigned])

  const _getOfferContracts = useCallback(() => {
    if (offer) {
      setGettingContracts(true)

      getOfferContracts(offer)
        .then((contracts) => {
          // const customContracts = Array.from({ length: 9 }, () => ({ description: 'Teste', signed: false, updatedAt: new Date() }))

          setOfferContracts(contracts)
          setGettingContracts(false)
        })
        .catch(() => setGettingContracts(false))
    }
  }, [offer])

  useEffect(() => {
    _getOfferContracts()
  }, [_getOfferContracts])

  const openDocument = (signatureId, clientId, contractTemplateId) => {
    setGettingHelloSignLink(true)
    setSelectedContractTemplateId(contractTemplateId)
    getRefreshedHellosignLink(signatureId)
      .then((response) => {
        setHelloSignUrl(response.url)
        setClientId(clientId)
        setGettingHelloSignLink(false)
        setShowingDoc(true)
      })
      .catch(() => {
        setGettingHelloSignLink(false)
      })
  }

  const closeModal = () => {
    setShowingDoc(false)
    setHelloSignUrl('')
    setSelectedContractTemplateId('')
  }

  const setDocSigned = useCallback(() => {
    const tmpContracts = offerContracts.map((contract) => {
      const tmpContract = { ...contract }
      if (contract.contractTemplateId === selectedContractTemplateId) {
        tmpContract.signatures = tmpContract.signatures?.map((signature) => {
          const tmpSignature = { ...signature }

          if (tmpSignature.status === 'awaiting_signature' && tmpSignature.userType === userObject.userType) {
            tmpSignature.status = 'signed'
          }

          return tmpSignature
        })
        tmpContract.signed = true
      }
      return tmpContract
    })

    setOfferContracts(tmpContracts)
    closeModal()
  }, [offerContracts, userObject, selectedContractTemplateId])

  const executeOnClickSign = useCallback(({ description, signatureId, clientId, contractTemplateId }) => {
    setSelectedDocumentTitle(description)
    openDocument(signatureId, clientId, contractTemplateId)
  }, [])

  return (
    <BuyerPageWithImage
      image={projectData && projectData.images[3]}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {
          allDocsSigned && executeWhenAllDocsSigned
            ? (
                <AllDocsSigned
                  onNextButtonClick={() => executeWhenAllDocsSigned()}
                  nextButtonClassName='h-9 md:h-16 text-base md:text-2xl px-10'
                />
              )
            : (
              <AnimatePresence exitBeforeEnter initial={false}>
                {
                  !showingDoc
                    ? (
                      <FadedAnimatedDiv
                        key='contractsPage'
                        className='flex flex-col mt-5 md:mt-0 md:justify-center h-full text-center'
                      >
                        <div
                          className='w-full flex items-start px-10 pt-20'
                        >
                          <span
                            onClick={onReturnToOffersClick ? () => onReturnToOffersClick() : null}
                            className='text-lg underline hover:cursor-pointer'
                          >
                            Return to your offers
                          </span>
                        </div>

                        <ContractsHeaderTitle
                          title='Contracts Review'
                          subtitle='Here are the contracts generated for your offer.'
                          allDocsSigned={allDocsSigned}
                          executeWhenAllDocsSigned={executeWhenAllDocsSigned}
                        />

                        <ContractsWrapper id='listcontractsWrapper' className='mt-10 md:mt-0 h-1/2 px-2 w-full'>
                          <AnimatePresence
                            onExitComplete={() => {
                              if (offerContracts.length) {
                                initCardAnimator(stackcards)
                              }
                            }}
                            exitBeforeEnter
                            initial={false}
                          >
                            {
                              gettingContracts
                                ? (
                                  <FadedAnimatedDiv
                                    key='loadingContracts'
                                    className='w-full h-full flex flex-col items-center'
                                  >
                                    <LottieWrapper
                                      animation={loadingContractsAnimation}
                                      loop
                                      autoplay
                                      className='max-w-xs'
                                    />
                                    <H5 className='text-center'>Loading...</H5>
                                  </FadedAnimatedDiv>
                                  )
                                : (
                                  <FadedAnimatedDiv
                                    key='contractsList'
                                    className='flex flex-col items-start h-full w-full'
                                  >
                                    <ListOfContracts
                                      offerContracts={offerContracts}
                                      userObject={userObject}
                                      onClickSign={executeOnClickSign}
                                      selectedContractTemplateId={selectedContractTemplateId}
                                      gettingHelloSignLink={gettingHelloSignLink}
                                    />

                                  </FadedAnimatedDiv>
                                  )
                            }
                          </AnimatePresence>
                        </ContractsWrapper>
                      </FadedAnimatedDiv>
                      )
                    : (
                      <FadedAnimatedDiv
                        style={{
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column'
                        }}
                        className='mt-5 md:mt-10'
                      >
                        <ContractsHeaderTitle
                          title='Sign your contract below'
                          subtitle={`You are signing the ${selectedDocumentTitle} document`}
                        />
                        <HelloSignDoc
                          clientId={clientId}
                          helloSignUrl={helloSignUrl}
                          executeAfterSign={setDocSigned}
                          onUserCancel={() => {
                            setShowingDoc(false)
                            setSelectedDocumentTitle('')
                          }}
                        />
                      </FadedAnimatedDiv>
                      )
                }
              </AnimatePresence>

              )
        }

      </AnimatePresence>
    </BuyerPageWithImage>
  )
}

const mapStateToProps = state => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Docs)

DocumentSigned.propTypes = {
  signedDate: propTypes.string
}

Docs.propTypes = {
  offerId: propTypes.string,
  projectData: propTypes.object,
  executeWhenAllDocsSigned: propTypes.func,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  onReturnToOffersClick: propTypes.func
}

PdfLargeButton.propTypes = {
  contract: propTypes.shape({
    description: propTypes.string,
    signed: propTypes.bool,
    updatedAt: propTypes.string,
    client_id: propTypes.string,
    signatureId: propTypes.string,
    signatures: propTypes.array,
    contractTemplateId: propTypes.string
  }),
  onClick: propTypes.func,
  loading: propTypes.bool,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  gettingHelloSignLink: propTypes.bool,
  selectedContractTemplateId: propTypes.string
}

ContractsHeaderTitle.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  allDocsSigned: propTypes.bool,
  executeWhenAllDocsSigned: propTypes.func
}

DocumentWaitingSignature.propTypes = {
  signatures: propTypes.array
}

ListOfContracts.propTypes = {
  offerContracts: propTypes.array,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  onClickSign: propTypes.func,
  selectedContractTemplateId: propTypes.string,
  gettingHelloSignLink: propTypes.bool
}
