import React from 'react'
// import SingularDocumentsSign from './SingularDocumentsSign'
import ContractSigningScreen from 'components/ContractSigningScreen'
import { CONTRACT_TYPES } from 'components/ContractSigningScreen/constants'

const Contract = ({ singularContract, onClose, handleGoBack, role }) => {
  return (
    <ContractSigningScreen
      isPublicPath
      contracts={singularContract?.contracts}
      modalType={CONTRACT_TYPES.SINGULAR_CONTRACT}
      currentUserType={role}
      setCloseDocument={onClose}
      handleGoBack={handleGoBack}
    />
  )
}

export default Contract
