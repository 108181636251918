// import { OfferDocuments } from 'components'
import ContractSigningScreen from 'components/ContractSigningScreen'
import { CONTRACT_TYPES } from 'components/ContractSigningScreen/constants'
import React from 'react'
// import { sendOfferContractsToSelectedRoleV1, sendOfferContractsToSelectedRoleV2 } from 'store/actions/offerActions'

const Contract = ({ contractData, onClose, handleGoBack = () => { } }) => {
  const role = contractData?.role ?? null

  return (
    <ContractSigningScreen
      isPublicPath
      contracts={contractData?.offer?.contracts}
      modalType={CONTRACT_TYPES.OFFER}
      currentUserType={role}
      setCloseDocument={onClose}
      isGroupedContracts
      handleGoBack={handleGoBack}
    />
  )
}

export default Contract
