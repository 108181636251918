// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import { Input } from 'components/ShadcnComponents/input'
import CustomSelect from 'components/Inventory/components/CustomSelect'
import LoadingAnimation from 'assets/lottieJsons/tableLoading.json'
import {
  createInventoryStatus,
  getAllInventoryStatuses,
  getInventorieCountProject,
  getInventorySettings,
  updateInventory,
  updateInventoryStatusAll
} from 'store/actions/inventoryActions'
import toast from 'react-hot-toast'
import Checkbox from 'components/Checkbox'
import { CloseIcon, LottieWrapper } from 'components'
import { Save, Settings, X, Copy, Plus, Minus, Pencil, Trash } from 'lucide-react'
import { AgGridReact } from 'ag-grid-react'
import CustomModal from 'components/Modal'
import {
  getBuildings,
  getBuildingsByProjectId
} from 'store/actions/buildingActions'
import { Drawer } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { IoMdClose } from 'react-icons/io'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { MdDragHandle } from 'react-icons/md'
import { SketchPicker as ChromePicker } from 'react-color'
import { RiDraggable } from "@remixicon/react";
import { getUserTableView, updateUserTableView } from 'store/actions/usersActions'


function makeid(length) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

const InventoryFieldsTable = ({
  staticFields,
  additionalFields,
  onFieldToggle,
  onAddField,
  onEditField,
  onDeleteField,
  selectedInventoryType,
  projectId
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false)
  const [buildings, setBuildings] = useState([])
  const [editingField, setEditingField] = useState(null)
  const [fieldForm, setFieldForm] = useState({
    name: '',
    dataType: 'text'
  })
  const [orderedRows, setOrderedRows] = useState([]);

  const columnDefs = [
    {
      headerName: 'Name',
      field: 'name',
      width: 340,
      cellRenderer: (params) => (
        <div
          className={
            !params.data.isStatic ? 'cursor-pointer hover:text-blue-600' : ''
          }
          onClick={() => {
            if (!params.data.isStatic) {
              setEditingField(params.data)
              setFieldForm({
                name: params.data.name,
                dataType: params.data.dataType.toLowerCase()
              })
              setIsModalOpen(true)
            }
          }}
        >
          {params.data.name}
        </div>
      )
    },
    {
      headerName: 'Type',
      field: 'type',
      width: 120,
      cellRenderer: (params) => (
        <div className='flex items-center'>
          {params.data.isStatic ? (
            <span className='px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800'>
              Default
            </span>
          ) : (
            <span className='px-2 py-1 text-xs rounded-full bg-purple-100 text-purple-800'>
              Custom
            </span>
          )}
        </div>
      )
    },
    {
      headerName: 'Data type',
      field: 'dataType',
      width: 150,
      cellRenderer: (params) => (
        <div className='text-gray-600'>{params.data.dataType}</div>
      )
    },
    {
      headerName: 'Enabled',
      field: 'enabled',
      width: 140,
      cellRenderer: (params) => (
        <Checkbox
          checked={params.data.enabled}
          handleChange={() =>
            onFieldToggle(params.data.index, params.data.isStatic, 'enabled')
          }
          className='h-5 w-5'
        />
      )
    },
    {
      headerName: 'API',
      field: 'api',
      width: 100,
      cellRenderer: (params) => (
        <Checkbox
          checked={params.data.api}
          disabled={!params.data.enabled}
          handleChange={() =>
            onFieldToggle(params.data.index, params.data.isStatic, 'api')
          }
          className='h-5 w-5'
        />
      )
    },
    {
      headerName: 'Inventory Card',
      field: 'inventoryCard',
      width: 100,
      cellRenderer: (params) => (
        <Checkbox
          checked={params.data.inventoryCard}
          disabled={!params.data.enabled}
          handleChange={() =>
            onFieldToggle(params.data.index, params.data.isStatic, 'inventoryCard')
          }
          className='h-5 w-5'
        />
      )
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 120,
      cellRenderer: (params) => (
        !params.data.isStatic && (
          <Button
            variant="ghost"
            onClick={() => onDeleteField(params.data.index)}
            className="p-2 hover:text-red-600"
          >
            <Trash className="w-4 h-4" />
          </Button>
        )
      )
    }
  ]

  const rowData = [
    ...additionalFields.map((field, index) => ({
      name: field?.name,
      type: 'Custom',
      dataType: field?.type || field?.dataType || 'Text',
      enabled: field?.enabled || false,
      api: field?.api || false,
      inventoryCard: field?.inventoryCard || false,
      index,
      isStatic: false
    })),
    ...staticFields.map((field, index) => ({
      name: field?.name,
      type: 'Default',
      dataType: field?.type,
      enabled: field?.enabled || false,
      api: field?.api || false,
      inventoryCard: field?.inventoryCard || false,
      index,
      isStatic: true
    }))
  ]

  const handleModalSubmit = () => {
    if (editingField) {
      onEditField(editingField.index, fieldForm)
    } else {
      onAddField(fieldForm)
    }
    setIsModalOpen(false)
    setEditingField(null)
    setFieldForm({ name: '', dataType: 'text' })
  }

  const handleCopyApiUrl = (projectId, buildingId) => {
    const allFields = [
      ...staticFields.map((field, index) => ({
        ...field,
        isStatic: true,
        index
      })),
      ...additionalFields.map((field, index) => ({
        ...field,
        isStatic: false,
        index
      }))
    ]

    const apiFields = allFields.filter((field) => field.api)
    const fieldsParam = apiFields.map((field) => field.id).join(',')

    const baseUrl = `${process.env.REACT_APP_BE_URL}/inventory/list/${projectId}/${buildingId}`

    const apiUrl = `${baseUrl}`
    navigator.clipboard
      .writeText(apiUrl)
      .then(() => {
        toast.success('API URL copied to clipboard')
      })
      .catch(() => {
        toast.error('Failed to copy API URL')
      })
  }

  const handleCopyPublicApiUrl = (projectId, buildingId) => {
    const allFields = [
      ...staticFields.map((field, index) => ({ ...field, isStatic: true, index })),
      ...additionalFields.map((field, index) => ({ ...field, isStatic: false, index }))
    ];

    const apiFields = allFields.filter(field => field.api);
    const fieldsParam = apiFields
      .map(field => field.id)
      .join(',');

    const baseUrl = `${process.env.REACT_APP_BE_URL}/inventory/list/public/${projectId}/${buildingId}`;

    const apiUrl = `${baseUrl}`;
    navigator.clipboard.writeText(apiUrl)
      .then(() => {
        toast.success('API URL copied to clipboard');
      })
      .catch(() => {
        toast.error('Failed to copy API URL');
      });
  };

  useEffect(() => {
    if (isCopyUrlModalOpen) {
      getBuildingsByProjectId(projectId)
        .then((response) => {
          setBuildings(response)
        })
        .catch((error) => {
          toast.error('Failed to fetch buildings')
        })
    }
  }, [isCopyUrlModalOpen, projectId])

  const CopyUrlModal = () => (
    <CustomModal
      isOpen={isCopyUrlModalOpen}
      closeModal={() => setIsCopyUrlModalOpen(false)}
      customStyles={{
        width: '600px'
      }}
    >
      <div className='p-4'>
        <div className='flex justify-between items-center mb-4'>
          <h3 className='text-lg font-semibold'>Copy API URL</h3>
          <X
            className='cursor-pointer'
            onClick={() => setIsCopyUrlModalOpen(false)}
          />
        </div>
        <div className='space-y-4'>
          {buildings.map((building) => (
            <div
              key={building._id}
              className='flex justify-between items-center p-3 border rounded-lg'
            >
              <span>{building.name}</span>
              <div className="flex items-center gap-2">
              <Button
                variant="outline"
                onClick={() => handleCopyPublicApiUrl(projectId,building._id)}
                className="flex items-center gap-2"
              >
                <Copy className="w-4 h-4" />
                Public URL
              </Button>
              <Button
                variant='outline'
                onClick={() => handleCopyApiUrl(projectId, building._id)}
                className='flex items-center gap-2'
              >
                <Copy className="w-4 h-4" />
                API URL
              </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  )

  const handleCopyButtonClick = () => {
    setIsCopyUrlModalOpen(true)
  }

  useEffect(() => {
    fetchRowOrder();
  }, [additionalFields, staticFields]);

  const fetchRowOrder = async () => {
    try {
      const response = await getUserTableView(projectId, 'tableView');
      const currentRows = [
        ...additionalFields.map((field, index) => ({
          name: field?.name,
          type: 'Custom',
          dataType: field?.type || field?.dataType || 'Text',
          enabled: field?.enabled || false,
          api: field?.api || false,
          inventoryCard: field?.inventoryCard || false,
          index,
          isStatic: false,
          field: selectedInventoryType+'Data.additionalFields.'+field.id
        })),
        ...staticFields.map((field, index) => ({
          name: field?.name,
          type: 'Default',
          dataType: field?.type,
          enabled: field?.enabled || false,
          api: field?.api || false,
          inventoryCard: field?.inventoryCard || false,
          index,
          isStatic: true,
          field: selectedInventoryType+'Data.'+field.id
        }))
      ];


      if (response?.columns) {
        const savedOrder = response.columns;
        const ordered = savedOrder
          .map(s => currentRows.find(r => r.field === s.field))
          .filter(Boolean);
        
        const finalOrder = ordered.concat(
          currentRows.filter(r => !ordered.find(o => o.field === r.field))
        );
        setOrderedRows(finalOrder);
      } else {
        setOrderedRows(currentRows);
      }
    } catch (err) {
      console.error('Error fetching row order:', err);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newRows = Array.from(orderedRows);
    const [reorderedItem] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, reorderedItem);
    setOrderedRows(newRows);
    try {
      const columns = newRows.map(row => ({
        field: row.field,
        hide: !row.enabled
      }));

      await updateUserTableView({
        projectId,
        type: 'tableView',
        columns: columns
      });
    } catch (err) {
      console.error('Error updating row order:', err);
    }
  };

  return (
    <div className='w-full h-full flex flex-col'>
      <div className='mb-4 flex justify-between items-center'>
        <div className='flex gap-2'>
          <Button
            onClick={() => {
              setEditingField(null)
              setFieldForm({ name: '', dataType: 'text' })
              setIsModalOpen(true)
            }}
            className='flex items-center gap-2'
          >
            <Plus className='w-4 h-4' />
            Add Field
          </Button>
        </div>
        <Button
          variant='outline'
          onClick={handleCopyButtonClick}
          className='flex items-center gap-2'
        >
          <Copy className='w-4 h-4' />
          API URL
        </Button>
      </div>

      <CustomModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false)
          setEditingField(null)
          setFieldForm({ name: '', dataType: 'text' })
        }}
        customStyles={{
          width: '400px'
        }}
      >
        <div className='p-4'>
          <div
            onClick={() => {
              setIsModalOpen(false)
              setEditingField(null)
              setFieldForm({ name: '', dataType: 'text' })
            }}
            className='absolute right-3 top-2'
          >
            <CloseIcon />
          </div>
          <h3 className='text-lg font-semibold mb-4'>
            {editingField ? 'Edit Field' : 'Add New Field'}
          </h3>
          <div className='space-y-4'>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Field Name
              </label>
              <Input
                value={fieldForm.name}
                onChange={(e) =>
                  setFieldForm((prev) => ({ ...prev, name: e.target.value }))
                }
                placeholder='Enter field name'
              />
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700 mb-1'>
                Data Type
              </label>
              <CustomSelect
                options={[
                  { value: 'text', label: 'Text' },
                  { value: 'number', label: 'Number' }
                ]}
                value={fieldForm.dataType}
                handleChange={(value) =>
                  setFieldForm((prev) => ({ ...prev, dataType: value }))
                }
              />
            </div>
            <div className='flex justify-end gap-2 mt-6'>
              <Button variant='outline' onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleModalSubmit}
                disabled={!fieldForm.name.trim()}
              >
                {editingField ? 'Save Changes' : 'Add Field'}
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CopyUrlModal />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="inventory-fields">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="border w-full rounded-md overflow-auto"
            >
              {/* Header */}
              <div className="grid grid-cols-[200px,100px,100px,100px,100px,100px,80px] border-b w-full py-1">
                <div className="pr-2 pl-3 py-2 text-sm font-medium">Name</div>
                <div className="px-2 py-2 text-sm font-medium">Type</div>
                <div className="px-2 py-2 text-sm font-medium">Data type</div>
                <div className="px-2 py-2 text-sm font-medium">Enabled</div>
                <div className="px-2 py-2 text-sm font-medium">API</div>
                <div className="px-2 py-2 text-sm font-medium">Inventory Card</div>
                <div className="px-2 py-2 text-center text-sm font-medium">Actions</div>
              </div>

              {/* Rows */}
              {orderedRows.map((row, index) => (
                <Draggable
                  key={row.field}
                  draggableId={row.field}
                  index={index}
                >
                  {(provided, snapshot) => (
                     <div
                     ref={provided.innerRef}
                     {...provided.draggableProps}
                     {...provided.dragHandleProps}
                     className="grid grid-cols-[200px,100px,100px,100px,100px,100px,80px] border-b hover:bg-gray-50"
                   >
                     <div className="px-2 py-2 text-sm flex items-center gap-2">
                        <RiDraggable className="w-3 h-3 text-gray-400 flex-shrink-0" />
                       {row.name}
                     </div>
                     <div className="px-2 py-2">
                        <span className={`px-2 py-1 text-xs rounded-full ${
                          row.isStatic 
                            ? "bg-blue-100 text-blue-800" 
                            : "bg-purple-100 text-purple-800"
                        }`}>
                          {row.isStatic ? "Default" : "Custom"}
                        </span>
                      </div>
                      <div className="px-2 py-2 text-sm text-gray-600">
                        {row.dataType}
                      </div>
                      <div className="px-2 py-2">
                        <Checkbox
                          checked={row.enabled}
                          handleChange={() => onFieldToggle(row.index, row.isStatic, 'enabled')}
                          className="h-3.5 w-3.5"
                        />
                      </div>
                      <div className="px-2 py-2">
                        <Checkbox
                          checked={row.api}
                          disabled={!row.enabled}
                          handleChange={() => onFieldToggle(row.index, row.isStatic, 'api')}
                          className="h-3.5 w-3.5"
                        />
                      </div>
                      <div className="px-2 py-2">
                        <Checkbox
                          checked={row.inventoryCard}
                          disabled={!row.enabled}
                          handleChange={() => onFieldToggle(row.index, row.isStatic, 'inventoryCard')}
                          className="h-3.5 w-3.5"
                        />
                      </div>
                      <div className="px-2 py-2 flex items-center justify-center">
                        {!row.isStatic && (
                          <div
                            onClick={() => onDeleteField(row.index)}
                            className="hover:text-red-600"
                          >
                            <Trash className="w-3.5 h-3.5" />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

const defaultStatuses = [
  { name: 'Available', color: '#4ECDC4', flag: 'default' },
  { name: 'Reserved', color: '#FFEEAD', flag: 'default' },
  { name: 'UnderContract', color: '#FF6B6B', flag: 'default' },
  { name: 'Sold', color: '#45B7D1', flag: 'default' }
]

const InventoryStatusDrawer = ({ isOpen, onClose, projectId }) => {
  const [statuses, setStatuses] = useState([])
  const [newStatusName, setNewStatusName] = useState('')
  const [editingStatus, setEditingStatus] = useState(null)
  const [error, setError] = useState('')
  const width80 = window.innerWidth * 0.6

  const defaultColors = [
    '#808080', // Gray (default)
    '#FF6B6B', // Red
    '#4ECDC4', // Teal
    '#45B7D1', // Blue
    '#96CEB4', // Green
    '#FFEEAD', // Yellow
    '#D4A5A5', // Pink
    '#9B59B6', // Purple
    '#E67E22'  // Orange
  ]

  const [showColorPicker, setShowColorPicker] = useState(false)
  const [selectedColor, setSelectedColor] = useState(defaultColors[0])
  const [activeColorPickerId, setActiveColorPickerId] = useState(null)

  const useStyles = makeStyles((theme) => ({
    drawer: {
      width: width80,
      flexShrink: 0
    },
    drawerPaper: {
      width: width80,
      height: '100%'
    }
  }))

  const classes = useStyles()

  useEffect(() => {
    if (isOpen) {
      fetchStatuses()
    }
  }, [isOpen])

  const fetchStatuses = async () => {
    try {
      const response = await getAllInventoryStatuses(projectId)
      if (!response || response.length === 0) {
        const defaultStatusesWithPosition = defaultStatuses.map((status, index) => ({
          ...status,
          project: projectId,
          selected: true,
          position: index + 1,
          id: makeid(10)
        }))
        for (const status of defaultStatusesWithPosition) {
          await createInventoryStatus(status)
        }
        setStatuses(defaultStatusesWithPosition)
      } else {
        const sortedStatuses = response?.sort((a, b) => a.position - b.position)
        setStatuses(sortedStatuses)
      }
    } catch (error) {
      console.error(error)
      toast.error('Failed to fetch inventory statuses')
    }
  }

  const checkDuplicateName = (name, excludeId = null) => {
    return statuses.some(status => 
      status.name.toLowerCase() === name.toLowerCase() && 
      status.id !== excludeId
    )
  }

  const handleAddStatus = async () => {
    try {
      const trimmedName = newStatusName.trim()
      
      if (checkDuplicateName(trimmedName)) {
        setError('Status with this name already exists')
        return
      }

      await createInventoryStatus({ 
        name: trimmedName, 
        projectId, 
        id: makeid(10), 
        selected: true, 
        position: statuses.length + 1,
        color: selectedColor || defaultColors[0],
        flag: 'custom',
        project: projectId
      })
      setNewStatusName('')
      setError('')
      setSelectedColor(defaultColors[0])
      setShowColorPicker(false)
      setActiveColorPickerId(null)
      await fetchStatuses()
      toast.success('Status added successfully')
    } catch (error) {
      toast.error('Failed to add status')
    }
  }

  const handleEditStatus = async (status, color = '') => {
    try {
      const trimmedName = editingStatus.name.trim()
      
      if (status.flag === 'default') {
        const updatedStatuses = statuses.map(s =>   
          s.id === status.id 
            ? { ...s, color: color || editingStatus.color }
            : s
        )
        
        await updateInventoryStatusAll(updatedStatuses)
        setEditingStatus(null)
        setShowColorPicker(false)
        await fetchStatuses()
        toast.success('Status updated successfully')
        return
      }

      if (checkDuplicateName(trimmedName, status.id)) {
        toast.error('Status with this name already exists')
        return
      }

      const updatedStatuses = statuses.map(s =>   
        s.id === status.id 
          ? { ...s, name: trimmedName, color: color || editingStatus.color }
          : s
      )
      
      await updateInventoryStatusAll(updatedStatuses)
      setEditingStatus(null)
      setShowColorPicker(false)
      await fetchStatuses()
      toast.success('Status updated successfully')
    } catch (error) {
      toast.error('Failed to update status')
    }
  }

  const handleStatusToggle = async (status) => {
    try {
      const updatedStatuses = statuses.map((s) => ({
        ...s,
        selected: s.id === status.id ? !status.selected : s.selected,
      }))
      setStatuses(updatedStatuses)
      await updateInventoryStatusAll(updatedStatuses)
    } catch (error) {
      toast.error('Failed to update status')
    }
  }

  const onDragEnd = async (result) => {
    const { source, destination } = result
    if (!destination) return

    const newItems = Array.from(statuses)
    const [removed] = newItems.splice(source.index, 1)
    newItems.splice(destination.index, 0, removed)

    const updatedStatuses = newItems.map((status, index) => ({
      ...status,
      position: index + 1,
    }))
    
    setStatuses(updatedStatuses)
    try {
      await updateInventoryStatusAll(updatedStatuses)
    } catch (error) {
      console.error(error)
      toast.error('Failed to update status positions')
    }
  }

  const handleStartEditing = (status) => {
    setEditingStatus({
      ...status,
      color: status.color || defaultColors[0] // Ensure there's always a color
    })
  }

  const handleColorPickerToggle = (id) => {
    if (activeColorPickerId === id) {
      setShowColorPicker(false)
      setActiveColorPickerId(null)
    } else {
      setShowColorPicker(true)
      setActiveColorPickerId(id)
    }
  }

  return (
    <Drawer
      open={isOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className='h-screen mt-16 relative overflow-hidden'>
        <button 
          onClick={onClose} 
          className='absolute top-3 left-5'
        >
          <IoMdClose fontSize='22px' />
        </button>

        <div className='p-6'>
        <h1 className='text-2xl text-center my-6 font-bold'>Inventory Status Settings</h1>
          <div className='flex gap-8'>
            
            {/* Create New Status Form */}
            <div className='bg-white basis-1/3 items-center h-fit rounded-lg p-4 border'>
              <h3 className='text-lg font-semibold mb-4'>Create New Status</h3>
              <div className='space-y-4'>
                <div>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Name
                  </label>
                  <Input
                    value={newStatusName}
                    onChange={(e) => {
                      setNewStatusName(e.target.value)
                      setError('')
                    }}
                    placeholder='Enter name'
                    className='w-full'
                  />
                  {error && (
                    <span className='text-red-500 text-sm mt-1'>{error}</span>
                  )}
                </div>
                <div>
                  <label className='block text-sm font-medium text-gray-700 mb-1'>
                    Color
                  </label>
                  <div className='flex flex-wrap gap-2 mb-2'>
                    {defaultColors.map((color) => (
                      <div
                        key={color}
                        onClick={() => setSelectedColor(color)}
                        className={`w-8 h-8 rounded cursor-pointer border-2 ${
                          selectedColor === color ? 'border-black' : 'border-transparent'
                        }`}
                        style={{ backgroundColor: color }}
                      />
                    ))}
                    <div
                      onClick={() => handleColorPickerToggle('new')}
                      className='w-8 h-8 rounded cursor-pointer border-2 border-gray-300 flex items-center justify-center'
                    >
                      <Plus className='w-4 h-4' />
                    </div>
                  </div>
                  {showColorPicker && activeColorPickerId === 'new' && (
                    <div className="absolute z-50 mt-2">
                      <div className="fixed inset-0" onClick={() => handleColorPickerToggle('new')} />
                      <ChromePicker
                        disableAlpha
                        color={selectedColor}
                        onChange={(color) => setSelectedColor(color.hex)}
                      />
                    </div>
                  )}
                </div>
                <Button
                  onClick={handleAddStatus}
                  disabled={!newStatusName.trim()}
                  className='w-full bg-black text-white hover:bg-gray-800'
                >
                  Create New Status
                </Button>
              </div>
            </div>

           <Card className='basis-2/3 w-full p-4'>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='inventory-statuses'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='space-y-1'
                  >
                    {statuses.map((status, index) => (
                      <Draggable
                        key={status.id}
                        draggableId={status.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`bg-white p-4 border rounded-lg flex items-center gap-4 ${
                              snapshot.isDragging ? "shadow-lg" : ""
                            }`}
                          >
                            <Checkbox
                              checked={status.selected}
                              handleChange={() => handleStatusToggle(status)}
                              className='h-4 w-4'
                            />
                            {editingStatus?.id === status.id ? (
                              <>
                                <Input
                                  value={editingStatus.name}
                                  onChange={(e) => setEditingStatus({
                                    ...editingStatus,
                                    name: e.target.value
                                  })}
                                  disabled={status.flag === 'default'}
                                  className='flex-1'
                                  onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                      handleEditStatus(status)
                                    } else if (e.key === 'Escape') {
                                      setEditingStatus(null)
                                      setShowColorPicker(false)
                                      setActiveColorPickerId(null)
                                    }
                                  }}
                                  autoFocus
                                />
                                <div className='flex flex-wrap gap-2'>
                                  {defaultColors.map((color) => (
                                    <div
                                      key={color}
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        setEditingStatus({
                                          ...editingStatus,
                                          color: color
                                        })
                                        handleEditStatus(status, color)
                                      }}
                                      className={`w-6 h-6 rounded cursor-pointer border-2 ${
                                        editingStatus.color === color ? 'border-black' : 'border-transparent'
                                      }`}
                                      style={{ backgroundColor: color }}
                                    />
                                  ))}
                                  <div
                                    onClick={() => handleColorPickerToggle(status.id)}
                                    className='w-6 h-6 rounded cursor-pointer border-2 border-gray-300 flex items-center justify-center'
                                  >
                                    <Plus className='w-3 h-3' />
                                  </div>
                                </div>
                                {showColorPicker && activeColorPickerId === status.id && (
                                  <div className="absolute z-50 mt-2">
                                    <div className="fixed inset-0" onClick={() => handleColorPickerToggle(status.id)} />
                                    <ChromePicker
                                      disableAlpha
                                      color={editingStatus.color}
                                      presetColors={[]}
                                      onChange={(color) => setEditingStatus({
                                        ...editingStatus,
                                        color: color.hex
                                      })}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <div className='flex-1 flex items-center gap-2'>
                                  <div
                                    className='w-4 h-4 rounded'
                                    style={{ backgroundColor: status.color }}
                                  />
                                  <span className='text-sm'>{status.name}</span>
                                  {status.flag === 'default' && (
                                    <span className='text-xs bg-gray-100 px-2 py-1 rounded'>Default</span>
                                  )}
                                </div>
                                <div className='flex items-center gap-3'>
                                  <Pencil 
                                    className='w-4 h-4 text-gray-400 hover:text-blue-600 cursor-pointer' 
                                    onClick={() => handleStartEditing(status)}
                                  />
                                  <div
                                    {...provided.dragHandleProps}
                                    className='text-gray-400 cursor-move'
                                  >
                                    <MdDragHandle className='w-5 h-5' />
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
           </Card>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

const EditProjectInventoryManagement = () => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [selectedInventoryType, setSelectedInventoryType] = useState(null)
  const [inventoryFields, setInventoryFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [staticFields, setStaticFields] = useState([])
  const [additionalFields, setAdditionalFields] = useState([])
  const [newFieldName, setNewFieldName] = useState('')
  const [inventoryCount, setInventoryCount] = useState(0)

  const [inventoryData, setInventoryData] = useState([])
  const [isStatusDrawerOpen, setIsStatusDrawerOpen] = useState(false)

  useEffect(() => {
    fetchInventorySettings()
  }, [projectId])

  useEffect(() => {
    getInventorieCountProject('project/' + projectId).then((response) => {
      setInventoryCount(response)
    })
  }, [projectId])

  const fetchInventorySettings = async () => {
    setIsLoading(true)
    try {
      const settings = await getInventorySettings(projectId)
      if (settings && settings.length > 0 && settings[0].type) {
        const data = settings[0]
        setInventoryData(data)
        if (data.type) {
          setSelectedInventoryType(data.type)
          processInventoryData(data.type, data[`${data.type}Data`])
        }
      } else {
        const data = settings[0]
        setInventoryData(data)
        setSelectedInventoryType(null)
      }
    } catch (error) {
      console.error('Error fetching inventory settings:', error)
      toast.error('Failed to load inventory settings')
    } finally {
      setIsLoading(false)
    }
  }
  const processInventoryData = (type, fieldData) => {
    const mappedStaticFields = Object.entries(fieldData)
      .filter(([key]) => key !== 'additionalFields')
      .map(([key, value]) => ({
        name: key
          .replace(/([A-Z])/g, ' $1')
          .replace(/^./, (str) => str.toUpperCase()),
        id: key,
        enabled: value.enabled,
        type: value?.type || 'default',
        api: value.api,
        inventoryCard: value?.inventoryCard || false,
      }))

    const additionalFieldsData = fieldData.additionalFields || []

    setStaticFields(mappedStaticFields)
    setAdditionalFields(additionalFieldsData)
  }

  const handleInventoryTypeChange = (value) => {
    setSelectedInventoryType(value)
    if (inventoryData && inventoryData[`${value}Data`]) {
      processInventoryData(value, inventoryData[`${value}Data`])
    } else {
      setStaticFields([])
      setAdditionalFields([])
    }
  }

  const handleFieldToggle = (index, isStatic, field = 'enabled') => {
    if (isStatic) {
      setStaticFields((prev) =>
        prev.map((f, i) => {
          if (i === index) {
            if (field === 'enabled' && !f.enabled) {
              return { ...f, enabled: true }
            } else if (field === 'enabled' && f.enabled) {
              return { ...f, enabled: false }
            }
            return { ...f, [field]: !f[field] }
          }
          return f
        })
      )
    } else {
      setAdditionalFields((prev) =>
        prev.map((f, i) => {
          if (i === index) {
            if (field === 'enabled' && !f.enabled) {
              return { ...f, enabled: true }
            } else if (field === 'enabled' && f.enabled) {
              return { ...f, enabled: false }
            }
            return { ...f, [field]: !f[field] }
          }
          return f
        })
      )
    }
  }

  const handleDeleteField = (index) => {
    setAdditionalFields((prev) => prev.filter((_, i) => i !== index))
  }

  const handleAddNewField = (fieldData) => {
    const newField = {
      name: fieldData.name.trim(),
      enabled: false,
      api: false,
      inventoryCard: false,
      type: fieldData.dataType === 'number' ? 'number' : 'text',
      id: makeid(10)
    }
    setAdditionalFields((prev) => [newField, ...prev])
  }

  const handleEditField = (index, fieldData) => {
    setAdditionalFields((prev) =>
      prev.map((field, i) =>
        i === index
          ? {
              ...field,
              name: fieldData.name.trim(),
              dataType: fieldData.dataType === 'number' ? 'Number' : 'Text'
            }
          : field
      )
    )
  }

  const refetch = async () => {
    await fetchInventorySettings()
  }

  const handleSave = async () => {
    try {
      const updatedStaticFields = staticFields.reduce((acc, field) => {
        acc[field.id] = {
          enabled: field.enabled,
          type: field.type,
          api: field.api,
          inventoryCard: field.inventoryCard,
        }
        return acc
      }, {})

      const updatedData = {
        id: inventoryData?._id || null,
        type: selectedInventoryType,
        [`${selectedInventoryType}Data`]: {
          ...updatedStaticFields,
          additionalFields: additionalFields
        }
      }

      await updateInventory(updatedData)
      await refetch()
      toast.success('Inventory settings updated successfully')
    } catch (error) {
      console.error('Error updating inventory settings:', error)
      toast.error('Error updating inventory settings')
    }
  }
  return (
    <div className='bg-gray-100 min-h-screen'>
      <div className=' mx-auto px-4 sm:px-6 lg:px-8 py-10'>
        <div className='flex flex-col gap-4 mb-6'>
          <h1 className='text-3xl font-bold text-gray-900'>
            Inventory Management
          </h1>

          <div className='flex justify-between items-end'>
            <div className='max-w-[400px] w-full'>
              <CustomSelect
                options={[
                  { value: 'lot', label: 'Lot' },
                  { value: 'condo', label: 'Condo' },
                  { value: 'townhome', label: 'Townhome' }
                ]}
                value={selectedInventoryType}
                placeholder='Select inventory type'
                handleChange={handleInventoryTypeChange}
                disabled={inventoryCount > 0}
              />
            </div>
            <Button
              onClick={() => setIsStatusDrawerOpen(true)}
              className='flex items-center gap-2'
            >
              <Settings className='w-4 h-4' />
              Edit Inventory Status
            </Button>
          </div>
        </div>
        <Card className='bg-white rounded-lg overflow-hidden'>
          <div className='p-6'>{renderContent()}</div>
        </Card>
      </div>
    </div>
  )

  function renderContent() {
    if (!selectedInventoryType) {
      return (
        <div className='flex flex-col items-center justify-center h-64 text-gray-500'>
          <Settings className='w-16 h-16 mb-4' />
          <p className='text-xl font-medium'>
            Select an inventory type to manage fields
          </p>
        </div>
      )
    }

    if (isLoading) {
      return (
        <div className='flex flex-col items-center justify-center h-64'>
          <LottieWrapper
            animation={LoadingAnimation}
            loop
            className='h-24 w-24'
          />
          <p className='mt-4 text-gray-600 text-xl'>Loading fields...</p>
        </div>
      )
    }

    return (
      <div>
        <div className='flex justify-between items-center mb-6'>
          <h2 className='text-2xl font-semibold text-gray-800'>
            Edit {selectedInventoryType} Fields
          </h2>
        </div>

        <InventoryFieldsTable
          staticFields={staticFields}
          additionalFields={additionalFields}
          onFieldToggle={handleFieldToggle}
          onAddField={handleAddNewField}
          onEditField={handleEditField}
          onDeleteField={handleDeleteField}
          selectedInventoryType={selectedInventoryType}
          projectId={projectId}
        />

        <InventoryStatusDrawer
          isOpen={isStatusDrawerOpen}
          onClose={() => setIsStatusDrawerOpen(false)}
          projectId={projectId}
        />

        <div className='flex justify-end mt-6'>
          <Button
            onClick={handleSave}
            className='bg-blue-600 text-white hover:bg-blue-700 px-6 py-3 rounded-md text-lg font-semibold transition duration-200 flex items-center'
          >
            <Save className='w-5 h-5 mr-2' />
            Save Changes
          </Button>
        </div>
      </div>
    )
  }
}

export default EditProjectInventoryManagement
