/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { PhoneIcon } from "@heroicons/react/solid";
import useGetParticipantCalls from "hooks/useGetParticipantCalls";
import Skeleton from "react-loading-skeleton";
import { CallModal } from "./CallModal";
import AircallPhone from "aircall-everywhere";
import { IoMdClose } from "react-icons/io";
import CustomButton from "./CustomButton";
import moment from "moment";
import { convert } from "html-to-text";
import {
  bindChannel,
  subscribeToChannel,
  unbindChannel,
  unsubscribeToChannel,
} from "services/pusher";
import { deleteCallNew, getCalls } from "store/actions/callsActions";
import { ChevronDownIcon, ChevronUpIcon, Pen, Trash } from "lucide-react";
import mixpanel, { MIXPANEL_EVENTS } from "services/mixpanel";
import ConfirmDeleteModal from 'pages/desktopMode/Events/CreateAndEditEvent/components/ConfirmDeleteModal';
import { message } from "antd";

export const CallTab = ({ contact }) => {
  const [open, setOpen] = useState(false);
  const [isAircallDialogOpen, setIsAircallDialogOpen] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  const [selectedCall, setSelectedCall] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);
  const [ringcentralPhone, setRingcentralPhone] = useState(false);
  const [ringCentralConfig, setRingCentralConfig] = useState({});
  const [callConfig, setCallConfig] = useState("aircall");
  const onSuccess = () => {
    setRefetch(!refetch);
  };

  const aircallPhone = new AircallPhone({
    onLogin: () => {
      // console.log('Logged-in successfully.')
      aircallPhone.send(
        "dial_number",
        {
          phone_number: contact.buyerData.phoneNumber,
        },
        false
      );
    },
    onLogout: () => console.log("Logged-out successfully."),
    // domToLoadPhone: '#phone'
  });

  useEffect(() => {
    if (contact?.id) {
      setLoading(true);
      getCalls("", `?participants=${contact?.id}`)
        .then((data) => {
          let callData = data?.map((call) => {
            return {
              id: call._id,
              date: moment(call.date).format("MMM DD, YYYY hh:mm A"),
              event: call.description,
              duration: call.duration ?? "-",
              time: call.date,
              notes: call?.notes,
              callStatus: call?.callStatus,
              callActivities: call?.callActivities,
            };
          });
          setCallLogs(callData);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [contact?.id, refetch]);

  const onNewCallCallBack = useCallback(
    (newCall) => {
      setCallLogs((callLogs) => {
        let newCallLogs = callLogs;
        if (newCall?.description === "call.created") {
          newCallLogs = [
            {
              id: newCall?.id || Math.floor(Math.random() * 101),
              date: moment(newCall?.date).format("MMM DD, YYYY hh:mm A"),
              event: newCall?.description,
              duration: "-",
              time: newCall?.date,
              notes: newCall?.notes || "",
              callStatus: newCall?.callStatus,
              callActivities: newCall?.callActivities || [],
            },
            ...callLogs,
          ];
        } else {
          newCallLogs = callLogs.map((call) => {
            if (call.id === newCall?.id) {
              return {
                ...call,
                duration: newCall?.duration,
                callActivities: newCall?.callActivities,
              };
            }
            return call;
          });
        }
        return newCallLogs;
      });
      if (newCall?.description === "call.created") {
        setOpen(true);
        setSelectedCall({
          id: newCall?.id || Math.floor(Math.random() * 101),
          date: moment(newCall?.date).format("MMM DD, YYYY hh:mm A"),
          event: newCall?.description,
          duration: "-",
          time: newCall?.date,
          notes: newCall?.notes || "",
          callStatus: newCall?.callStatus,
          callActivities: newCall?.callActivities || [],
        });
      }
    },
    [callLogs, setCallLogs]
  );
  useEffect(() => {
    setRingCentralConfig({
      clientId: process.env.REACT_APP_RINGCENTRAL_CLIENT_ID,
    });
  }, []);


  useEffect(() => {
    let callChannel = null;
    if (contact?.id) {
      callChannel = subscribeToChannel(`call-${contact?.id}`);
      if (callChannel) {
        bindChannel(callChannel, onNewCallCallBack);
      }
    }
    return () => {
      if (contact?.id) {
        if (callChannel) {
          unbindChannel(callChannel, onNewCallCallBack);
        }
        unsubscribeToChannel(`call-${contact?.id}`);
      }
    };
  }, [contact?.id, onNewCallCallBack]);

  const CallLogList = ({ callLogs }) => {
    return (
      <div className="flex flex-col border">
        <div className="bg-gray-100 w-full gap-x-6 flex flex-nowrap justify-between text-sm font-medium border text-gray-700">
          <div className="p-4 min-w-[224px] text-start">Date</div>
          <div className="p-4 min-w-[150px] text-start">Events</div>
          <div className="p-4 min-w-[100px] text-start block">Duration</div>
          <div className="p-4 min-w-[250px] text-start flex flex-wrap">
            Call Notes
          </div>
          <div className="p-4 min-w-[200px] text-start block">Call Status</div>
          <div className="flex-1"></div>
        </div>
        <div className="divide-y divide-gray-200">
          {callLogs.map((call) => (
            <CallLogItem key={call.id} call={call} />
          ))}
        </div>
      </div>
    );
  };

  const CallLogItem = ({ call }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const isRecentCall =
      (new Date().valueOf() - new Date(call.time).valueOf()) / (1000 * 60) <= 1;

    const handleDeleteClick = (e) => {
      e.stopPropagation();
      setShowDeleteModal(true);
    };

    const handleDelete = async (id) => {
      try {
        await deleteCallNew(id);
        setRefetch(!refetch);
        setShowDeleteModal(false);
      } catch (error) {
        message.error('You must be host of this call')
        console.error('Error deleting call:', error);
      }
    };

    return (
      <>
        <div
          onClick={(e) => {
            setIsExpanded(!isExpanded);
            e.stopPropagation();
          }}
          className={`flex cursor-pointer flex-col ${
            isRecentCall ? "bg-yellow-50" : ""
          }`}
        >
          <div className="items-center gap-x-6 px-6 flex py-3">
            <div className="flex items-center min-w-[224px] gap-x-2 flex-shrink-0">
              {call.event === "Missed call" ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4694 8.46937L16.1897 6.74999L14.4694 5.03062C14.3287 4.88989 14.2496 4.69902 14.2496 4.49999C14.2496 4.30097 14.3287 4.1101 14.4694 3.96937C14.6101 3.82864 14.801 3.74958 15 3.74958C15.199 3.74958 15.3899 3.82864 15.5306 3.96937L17.25 5.68968L18.9694 3.96937C19.1101 3.82864 19.301 3.74958 19.5 3.74958C19.699 3.74958 19.8899 3.82864 20.0306 3.96937C20.1714 4.1101 20.2504 4.30097 20.2504 4.49999C20.2504 4.69902 20.1714 4.88989 20.0306 5.03062L18.3113 6.74999L20.0306 8.46937C20.1003 8.53905 20.1556 8.62178 20.1933 8.71282C20.231 8.80387 20.2504 8.90145 20.2504 8.99999C20.2504 9.09854 20.231 9.19612 20.1933 9.28717C20.1556 9.37821 20.1003 9.46094 20.0306 9.53062C19.961 9.6003 19.8782 9.65558 19.7872 9.69329C19.6961 9.731 19.5986 9.75041 19.5 9.75041C19.4015 9.75041 19.3039 9.731 19.2128 9.69329C19.1218 9.65558 19.0391 9.6003 18.9694 9.53062L17.25 7.81031L15.5306 9.53062C15.3899 9.67135 15.199 9.75041 15 9.75041C14.801 9.75041 14.6101 9.67135 14.4694 9.53062C14.3287 9.38989 14.2496 9.19902 14.2496 8.99999C14.2496 8.80097 14.3287 8.6101 14.4694 8.46937ZM20.8444 14.8556L16.4278 12.8766L16.4156 12.8709C16.1864 12.7729 15.9362 12.7335 15.6879 12.7564C15.4396 12.7793 15.2009 12.8638 14.9934 13.0022C14.969 13.0183 14.9455 13.0358 14.9231 13.0547L12.6441 15C11.1984 14.2978 9.70595 12.8166 9.00376 11.3897L10.9519 9.07312C10.9706 9.04968 10.9884 9.02624 11.0053 9.00093C11.1407 8.79403 11.2229 8.55686 11.2445 8.31053C11.2661 8.0642 11.2264 7.81636 11.1291 7.58906V7.57781L9.14438 3.15374C9.0157 2.8568 8.79444 2.60944 8.51362 2.44859C8.2328 2.28774 7.9075 2.22202 7.58626 2.26124C6.31592 2.42841 5.14986 3.05228 4.30588 4.01633C3.4619 4.98039 2.99771 6.2187 3.00001 7.49999C3.00001 14.9437 9.05626 21 16.5 21C17.7813 21.0023 19.0196 20.5381 19.9837 19.6941C20.9477 18.8501 21.5716 17.6841 21.7388 16.4137C21.7781 16.0926 21.7125 15.7674 21.5518 15.4866C21.3911 15.2058 21.144 14.9845 20.8472 14.8556H20.8444Z"
                    fill="#BF5716"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4694 9.53062C14.3997 9.46096 14.3443 9.37825 14.3066 9.2872C14.2688 9.19615 14.2494 9.09856 14.2494 8.99999C14.2494 8.90143 14.2688 8.80384 14.3066 8.71279C14.3443 8.62174 14.3997 8.53902 14.4694 8.46937L17.6897 5.24999H15.75C15.5511 5.24999 15.3603 5.17098 15.2197 5.03032C15.079 4.88967 15 4.69891 15 4.49999C15 4.30108 15.079 4.11032 15.2197 3.96966C15.3603 3.82901 15.5511 3.74999 15.75 3.74999H19.5C19.6989 3.74999 19.8897 3.82901 20.0303 3.96966C20.171 4.11032 20.25 4.30108 20.25 4.49999V8.24999C20.25 8.44891 20.171 8.63967 20.0303 8.78032C19.8897 8.92098 19.6989 8.99999 19.5 8.99999C19.3011 8.99999 19.1103 8.92098 18.9697 8.78032C18.829 8.63967 18.75 8.44891 18.75 8.24999V6.31031L15.5306 9.53062C15.461 9.60035 15.3783 9.65567 15.2872 9.69341C15.1962 9.73116 15.0986 9.75058 15 9.75058C14.9014 9.75058 14.8039 9.73116 14.7128 9.69341C14.6218 9.65567 14.539 9.60035 14.4694 9.53062ZM20.8444 14.8556L16.4278 12.8766L16.4156 12.8709C16.1864 12.7729 15.9362 12.7335 15.6879 12.7564C15.4396 12.7793 15.2009 12.8638 14.9934 13.0022C14.969 13.0183 14.9455 13.0358 14.9231 13.0547L12.6441 15C11.1984 14.2978 9.70595 12.8166 9.00376 11.3897L10.9519 9.07312C10.9706 9.04968 10.9884 9.02624 11.0053 9.00093C11.1407 8.79403 11.2229 8.55686 11.2445 8.31053C11.2661 8.0642 11.2264 7.81636 11.1291 7.58906V7.57781L9.14438 3.15374C9.0157 2.8568 8.79444 2.60944 8.51362 2.44859C8.2328 2.28774 7.9075 2.22202 7.58626 2.26124C6.31592 2.42841 5.14986 3.05228 4.30588 4.01633C3.4619 4.98039 2.99771 6.2187 3.00001 7.49999C3.00001 14.9437 9.05626 21 16.5 21C17.7813 21.0023 19.0196 20.5381 19.9837 19.6941C20.9477 18.8501 21.5716 17.6841 21.7388 16.4137C21.7781 16.0926 21.7125 15.7674 21.5518 15.4866C21.3911 15.2058 21.144 14.9845 20.8472 14.8556H20.8444Z"
                    fill="#0E9F6E"
                  />
                </svg>
              )}
              <div className="font-medium">{call.date}</div>
            </div>
            <div
              style={{}}
              className={
                call.event === "Missed call"
                  ? "text-red-600 font-semibold min-w-[150px]"
                  : "min-w-[150px]"
              }
            >
              {call.event}
            </div>
            <div className="block min-w-[100px]">{call.duration}</div>
            <div className="block min-w-[250px]">{call.notes}</div>
            <div className="block min-w-[200px]">{call.callStatus}</div>
            <div className="flex items-center space-x-2">
              <CustomButton
                className="flex items-center justify-center"
                handleClick={() => {
                  setSelectedCall(
                    callLogs.filter((e) => e.id === call.id)?.[0] ?? {}
                  );
                  setOpen(true);
                }}
              >
                <span className="italic flex gap-x-2 font-medium py-2 px-4 text-xs md:text-sm">
                  <Pen className="h-4 w-4" />
                  Edit
                </span>
              </CustomButton>
              <CustomButton
                variant="outline"
                className="flex items-center justify-center"
                handleClick={handleDeleteClick}
              >
                <span className="italic flex gap-x-2 font-medium py-2 px-4 !text-[red] text-xs md:text-sm">
                  <Trash className="h-4 w-4" />
                  Delete
                </span>
              </CustomButton>
              {call.callActivities && call.callActivities.length > 0 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                  }}
                  className="p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  {isExpanded ? (
                    <ChevronUpIcon className="w-5 h-5" />
                  ) : (
                    <ChevronDownIcon className="w-5 h-5" />
                  )}
                </button>
              )}
            </div>
          </div>
          {isExpanded && call.callActivities.length > 0 && (
            <div className="bg-gray-50 font-bold relative px-10 py-2 space-y-3">
              {call.callActivities.map((activity, index) => (
                <div className="flex gap-x-8 text-sm">
                  <div>{moment(activity.date).format("hh:mm A")}</div>
                  <div key={index} className="text-gray-600">
                    {activity.event}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <ConfirmDeleteModal
          show={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onSuccess={handleDelete}
          deleteId={call.id}
        />
      </>
    );
  };

  const handleCallButtonClick = () => {
    let tempCallProvider = ''
    if (ringCentralConfig.clientId) {
      setCallConfig("ringcentral");
      tempCallProvider = 'ringcentral'
    } else {
      setCallConfig('aircall');
      tempCallProvider = 'aircall'
    }
    // Track call start event
    mixpanel.track(MIXPANEL_EVENTS.CALL_START, {
      callProvider: tempCallProvider
    })
    setOpen(true);
  }

  return (
    <>
      {loading ? (
        <div className="flex flex-col gap-6 px-4 pt-6">
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          {callLogs.length > 0 ? (
            <>
              <div className="w-full flex justify-between items-center p-3 md:p-6">
                <h3 className="m-0 text-xl font-semibold">Call log</h3>
                <div className="text-center flex gap-x-4">
                  <CustomButton handleClick={handleCallButtonClick}>
                    <span className="font-semibold px-4 py-2 rounded text-base">
                      Call {contact.firstName}
                    </span>
                  </CustomButton>
                  <CustomButton handleClick={handleCallButtonClick}>
                    <span className="font-semibold px-4 py-2 rounded text-base">
                      Log Call
                    </span>
                  </CustomButton>
                </div>
              </div>
              <div className="w-full flex">
                <CallLogList callLogs={callLogs} />
              </div>
            </>
          ) : (
            <div className="font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded">
              <PhoneIcon className="h-10 w-10" />
              <div className="font-semibold mb-2 mt-4">
                You don't have any calls yet
              </div>
              <div className="text-sm text-softBlack70">
                Start a call register by clicking the button below
              </div>
              <div className="text-center pt-4 flex gap-x-4">
                 <CustomButton handleClick={handleCallButtonClick}>
                  <span className="font-semibold px-4 py-2 rounded text-base">
                    Call {contact.firstName}
                  </span>
                </CustomButton>
                <CustomButton handleClick={handleCallButtonClick}>
                  <span className="font-semibold px-4 py-2 rounded text-base">
                    Log Call
                  </span>
                </CustomButton>
              </div>
            </div>
          )}
        </>
      )}
      {/* {isAircallDialogOpen && (
        <div className="fixed z-10 top-0 left-0 w-full md:w-screen h-screen flex justify-center items-center bg-black/25">
          <div className="relative pt-10 bg-[#FCFCFC] rounded shadow-lg overflow-hidden flex flex-col w-[90%] md:w-fit h-[82%]">
            <button
              onClick={() => setIsAircallDialogOpen(false)}
              className="absolute right-6 top-6 z-[10]"
            >
              <IoMdClose fontSize="22px" />
            </button>
            <div id="phone">
              <iframe
                allow="microphone; autoplay; clipboard-read; clipboard-write; hid"
                src="https://phone.aircall.io?integration=generic"
                className="w-full md:w-[376px] h-full md:h-[666px]"
              ></iframe>
            </div>
          </div>
        </div>
      )} */}
      {/* {ringcentralPhone && (
        <div className="fixed z-10 top-0 left-0 w-full md:w-screen h-screen flex justify-center items-center bg-black/25">
          <div className="relative pt-10 bg-[#FCFCFC] rounded shadow-lg overflow-hidden flex flex-col w-[90%] md:w-fit h-[80%]">
            <button
              onClick={() => setRingcentralPhone(false)}
              className="absolute right-6 top-6 z-[10]"
            >
              <IoMdClose fontSize="22px" />
            </button>
            <div id="phone">
              <iframe
                width="300"
                height="500"
                id="rc-widget"
                allow="autoplay; microphone"
                src={`https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/app.html?appServer=${process.env.REACT_APP_RINGCENTRAL_APP_SERVER}&clientId=${process.env.REACT_APP_RINGCENTRAL_CLIENT_ID}&redirectUri=${process.env.REACT_APP_RINGCENTRAL_REDIRECT_URI}`}
              ></iframe>
            </div>
          </div>
        </div>
      )} */}
      <CallModal
        setIsAircallDialogOpen={setIsAircallDialogOpen}
        show={open}
        setClose={() => {
          setOpen(false);
          setSelectedCall({});
          setIsAircallDialogOpen(false);
        }}
        contact={contact}
        onSuccess={onSuccess}
        call={selectedCall}
        callDialogOpen={isAircallDialogOpen}
        callConfig={callConfig}
      />
    </>
  );
};
