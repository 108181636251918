import React, { useState, useEffect, useCallback } from 'react'
import propTypes from 'prop-types'
import { H3, InputLabel, Modal, TButton, Title } from 'ui'
import { connect } from 'react-redux'
import JwtDecode from 'jwt-decode'
import { useHistory, useLocation } from 'react-router-dom'
import { message, Row } from 'antd'
import { ArrowCircleLeftIcon } from '@heroicons/react/solid'

import {
  LoginWrapper,
  FieldsWrapper,
  TitleWrapper,
  BackgroundVideo,
  VideoWrapper
} from './styles'
import {
  handleLogin,
  handleChangeUserPassword
} from 'store/actions/authActions'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import {
  AVAILABILITY_BOARD,
  SET_PASSWORD,
  BROKER_HOME_PAGE,
  BUYER_HOME_DASHBOARD,
  ADMIN_DASHBOARD_DEALS,
  ADMIN_DASHBOARD_SALESREP,
  // ADMIN_DASHBOARD_WHITECAP,
  ADMIN_DASHBOARD_CORPORATE
} from 'routes'
import { updateLoggedUserObject } from 'utils'
import { isAuthenticated } from 'services/auth'
import { FadedAnimatedDiv, GlassedWrapper } from 'components'
import { LoginPage, ResetPasswordPage } from 'components/LoginPage'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'

const backgroundVideos = {
  0: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo0.mp4',
  1: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo1.mp4',
  2: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo2.mp4',
  3: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo3.mp4',
  4: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo4.mp4',
  5: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo5.mp4',
  6: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo6.mp4',
  7: 'https://pxpdemo-images.s3.us-east-2.amazonaws.com/loginVideo7.mp4'
}

const wrapperAnimatedvalues = {
  fadeOut: {
    opacity: 0
  },
  fadeIn: {
    opacity: 1
  }
}

const FormTitle = ({ children }) => (
  <span className='mt-1 text-center text-lg'>{children}</span>
)
const FadedWrapper = ({ key, children }) => (
  <FadedAnimatedDiv className='flex justify-center flex-col' key={key}>
    {children}
  </FadedAnimatedDiv>
)

const LabelButton = ({ children, onClick }) => (
  <div className='flex w-full justify-end mb-3'>
    <button className='hover:underline focus:outline-none' onClick={onClick}>
      {children}
    </button>
  </div>
)

const MainButton = ({ onClick, label, loading, disabled }) => (
  <TButton
    className='mt-3 w-full'
    loading={loading}
    onClick={onClick}
    disabled={disabled}
  >
    {label}
  </TButton>
)

const BackButton = ({ onClick, pageStatus }) => (
  <AnimatePresence exitBeforeEnter initial={false}>
    {pageStatus === 'forgotMyPassword' && (
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -50 }}
        key='backButton'
      >
        <ArrowCircleLeftIcon
          className='h-9 w-9 -mt-6 -ml-3 absolute transition-all transform hover:cursor-pointer hover:scale-110'
          onClick={() => onClick()}
        />
      </motion.div>
    )}
  </AnimatePresence>
)

const Login = (props) => {
  const [userEmail, setUserEmail] = useState('')
  const [userPassword, setUserPassword] = useState('')
  const [loginInProgress, setLoginInProgress] = useState(false)
  const [renderVideo, setRenderVideo] = useState(true)
  const [currentBackgroundVideoControl, setCurrentBackgroundVideoControl] =
    useState(0)
  const [currentBackgroundVideo, setCurrentBackgroundVideo] = useState(
    backgroundVideos[currentBackgroundVideoControl]
  )
  // const [recoveryPasswordEmailAddress, setRecoveryPasswordEmailAddress] = useState('')
  const [requestingRecoveryPassword, setRequestingRecoveryPassword] =
    useState(false)
  const [pageStatus, setPageStatus] = useState('login')

  const [preparingForm, setPreparingForm] = useState(false)
  const [tmpToken, setTmpToken] = useState('')
  const [userId, setUserId] = useState('')
  const [userNewPassword, setUserNewPassword] = useState('')
  const [userConfirmationNewPassword, setUserConfirmationNewPassword] =
    useState('')
  const [changePasswordInProgress, setChangePasswordInProgress] =
    useState(false)

  const videoAnimatedWrapper = useAnimation()
  const loginAnimatedWrapper = useAnimation()

  const { handleLogin, userObject } = props

  const history = useHistory()
  const pathnameSplit = history.location.pathname.split('/')
  const isResetPasswordPage = '/' + pathnameSplit[1] === SET_PASSWORD
  let resetToken = ''
  if (isResetPasswordPage && pathnameSplit.length > 1) {
    resetToken = pathnameSplit[2]
  }

  // const location = useLocation()

  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  const hash = query.get('value')

  // Check if it's inside of the Recovery Password Process
  useEffect(() => {
    setPreparingForm(true)

    // If has a hash value means that came from and email to reset the password
    if (hash) {
      setPageStatus('resetingPassword')
      const decoded = JwtDecode(hash)
      console.log('**** decoded', decoded)
      // @ts-ignore
      const { _id, email, token_data: tokenData } = decoded

      handleLogin(email, tokenData, false)
        .then((token) => {
          setTmpToken(token)
          setUserId(_id)
          setPreparingForm(false)
        })
        .catch(() => {
          // @ts-ignore
          setUserId(-1)
          setPageStatus('login')
          setPreparingForm(false)
        })
    }
  }, [handleLogin, hash])

  const changePassword = async () => {
    if (userNewPassword !== userConfirmationNewPassword) {
      message.warning('Both passwords must be equal')
      return
    }

    if (userNewPassword.length < 6) {
      message.warning('Password must have at least 6 characters')
      return
    }

    setChangePasswordInProgress(true)

    handleChangeUserPassword(userId, userNewPassword, tmpToken)
      .then(() => {
        setChangePasswordInProgress(false)
        setPageStatus('login')
      })
      .catch(() => {
        setChangePasswordInProgress(false)
      })
  }

  const goToBuyerPage = useCallback(() => {
    history.push({
      pathname: BUYER_HOME_DASHBOARD,
      state: { creationType: 'offerCreated', fromDocumentsMenu: true }
    })
  }, [history])

  const goToBrokerPage = useCallback(() => {
    history.push({
      pathname: BROKER_HOME_PAGE
      // state: { creationType: 'offerCreated', fromDocumentsMenu: true }
    })
  }, [history])

  const goToDesktopMode = useCallback(() => {
    if (!userObject) return
    const { userType } = userObject
    if (userType === 'CoopBroker') {
      return history.push({
        pathname: AVAILABILITY_BOARD,
        state: { showTabsFilter: true }
      })
    }

    if (userType === 'SalesRep') {
      return history.push({
        pathname: ADMIN_DASHBOARD_SALESREP
      })
    }

    if (process.env.REACT_APP_AVESDO_FLAG === 'true') {
      return history.push(ADMIN_DASHBOARD_DEALS)
    } else {
      return history.push(ADMIN_DASHBOARD_CORPORATE)
    }
  }, [history, userObject])

  useEffect(() => {
    if (userObject && Object.keys(userObject).length && isAuthenticated()) {
      const { userType } = userObject
      console.log('*** userType', userType)
      // If it's a buyer, call the PXP buyer side
      if (userType === 'Buyer') {
        goToBuyerPage()
      } else if (userType === 'LeadBroker') {
        goToBrokerPage()
      } else {
        goToDesktopMode()
      }
    }
  }, [userObject, goToBuyerPage, goToDesktopMode])

  const goToNextRoute = useCallback(() => {
    updateLoggedUserObject()
  }, [])

  useEffect(() => {
    if (isAuthenticated()) {
      goToNextRoute()
    }
  }, [goToNextRoute])

  useEffect(() => {
    const timer = setInterval(() => {
      changeBackgroundVideo()
    }, 8000)

    // clearing interval
    return () => clearInterval(timer)
  })

  const changeBackgroundVideo = useCallback(() => {
    videoAnimatedWrapper.start('fadeOut')
    setTimeout(() => {
      setRenderVideo(false)
    }, 2000)

    setTimeout(() => {
      let newVideoControl = currentBackgroundVideoControl + 1
      if (newVideoControl === 8) newVideoControl = 0

      setCurrentBackgroundVideoControl(newVideoControl)
      setCurrentBackgroundVideo(backgroundVideos[newVideoControl])
      setRenderVideo(true)
    }, 2300)

    setTimeout(() => {
      videoAnimatedWrapper.start('fadeIn')
    }, 2500)
  }, [currentBackgroundVideoControl, videoAnimatedWrapper])

  useEffect(() => {
    setTimeout(() => {
      videoAnimatedWrapper.start('fadeIn')
    }, 1000)
  }, [videoAnimatedWrapper])

  const login = async () => {
    setLoginInProgress(true)

    handleLogin(userEmail, userPassword, true)
      .then(() => {
        mixpanel.setCustomDetails({
          userEmail: userEmail
        })

        mixpanel.track(MIXPANEL_EVENTS.USER_LOGGED_IN)

        setLoginInProgress(false)
        videoAnimatedWrapper.start('fadeOut', { duration: 0.7 })
        loginAnimatedWrapper.start('fadeOut', { duration: 0.7 })

        goToNextRoute()
      })
      .catch(() => {
        setLoginInProgress(false)
      })
  }

  const requestRecovery = useCallback(() => {
    setRequestingRecoveryPassword(true)
  }, [userEmail])

  const onBackButtonClick = () => {
    setPageStatus('login')
    // setRecoveryPasswordEmailAddress('')
  }

  if (requestingRecoveryPassword) {
    return (
      <ResetPasswordPage
        setRequestingRecoveryPassword={setRequestingRecoveryPassword}
        userEmail={userEmail}
      />
    )
  }

  return (
    <LoginPage
      email={{
        value: userEmail,
        onChange: (e) => setUserEmail(e.target.value)
      }}
      password={{
        value: userPassword,
        onChange: (e) => setUserPassword(e.target.value)
      }}
      onLoggingIn={() => {
        login()
      }}
      loginProgress={loginInProgress}
      onForgotPassword={requestRecovery}
      H3={H3}
      InputLabel={InputLabel}
      Modal={Modal}
      Title={Title}
      Row={Row}
      LoginWrapper={LoginWrapper}
      FieldsWrapper={FieldsWrapper}
      TitleWrapper={TitleWrapper}
      BackgroundVideo={BackgroundVideo}
      VideoWrapper={VideoWrapper}
      GlassedWrapper={GlassedWrapper}
      wrapperAnimatedvalues={wrapperAnimatedvalues}
      renderVideo={renderVideo}
      otherProps={{
        currentBackgroundVideo,
        requestingRecoveryPassword,
        pageStatus,
        preparingForm,
        setUserNewPassword,
        setUserConfirmationNewPassword,
        changePasswordInProgress,
        changePassword,
        requestRecovery,
        onBackButtonClick
      }}
      isResetPasswordPage={isResetPasswordPage}
      resetToken={resetToken}
    />
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {
  handleLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)

Login.propTypes = {
  handleLogin: propTypes.func,
  userObject: propTypes.shape({
    userType: propTypes.string
  })
}

FormTitle.propTypes = {
  children: propTypes.string
}

FadedWrapper.propTypes = {
  key: propTypes.string,
  children: propTypes.string
}

LabelButton.propTypes = {
  children: propTypes.string,
  onClick: propTypes.func
}

MainButton.propTypes = {
  onClick: propTypes.func,
  label: propTypes.string,
  loading: propTypes.bool,
  disabled: propTypes.string
}

BackButton.propTypes = {
  onClick: propTypes.func,
  pageStatus: propTypes.string
}
