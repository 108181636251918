// @ts-nocheck

import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import CustomRadio from 'components/CustomRadio'

import LineText from './LineText'
import ParkingCard from 'components/ParkingCard'
import useGetProjectParkings from 'hooks/useGetProjectParkings'
import BottomActions from './BottomActions'
import { CreateOfferContextInventory } from '../context/CreateOfferContextInventory'

const SelectParkingInventory = ({ setStep }) => {
  const { selectedUnit, selectedParkings, setSelectedParkings } = useContext(CreateOfferContextInventory)

  const [purchaseParking, setPurchaseParking] = useState(true)
  const { loading, parkings } = useGetProjectParkings()
  const [unitParkingQty, setUnitParkingQty] = useState(0)
  const [unitParkingType, setUnitParkingType] = useState('')

  const onBack = () => setStep((prev) => prev - 1)

  const onNext = () => {
    ; ((onlySelectedParkings().length > 0 && purchaseParking) ||
      !purchaseParking) &&
      setStep((prev) => prev + 1)
  }

  const onlySelectedParkings = () =>
    selectedParkings.filter((parking) => parking.isSelected)

  const onChangeParkingOption = (value) => setPurchaseParking(value)

  const onUpdateCheck = (id, value) => {
    const updatedData = selectedParkings.map((parking) =>
      parking._id === id
        ? { ...parking, isSelected: value, count: value ? 1 : 0 }
        : parking
    )
    setSelectedParkings(updatedData)
  }

  const onIncreaseCount = (id, value) => {
    const updatedData = selectedParkings.map((parking) =>
      parking._id === id
        ? { ...parking, count: value, isSelected: value }
        : parking
    )
    setSelectedParkings(updatedData)
  }

  const onDecreaseCount = (id, value) => {
    if (value >= 0) {
      const updatedData = selectedParkings.map((parking) =>
        parking._id === id
          ? { ...parking, count: value, isSelected: value }
          : parking
      )
      setSelectedParkings(updatedData)
    }
  }

  useEffect(() => {
    if (selectedUnit.floorPlan) {
      const { parkingQty, parkingType } = selectedUnit.floorPlan

      setUnitParkingQty(parkingQty)
      setUnitParkingType(parkingType)
    }
  }, [selectedUnit])

  useEffect(() => {
    if (selectedParkings.length === 0) {
      const temp = parkings.map((parking) => ({
        ...parking,
        isSelected: false,
        count: 0
      }))
      setSelectedParkings(temp)
    }
  }, [parkings])

  return (
    <div className='h-full flex flex-col gap-6'>
      <div className="pb-4 border-b-2 border-softBlack">
        <p className="my-0 text-xl font-bold">Parking</p>
      </div>
      <div className="mt-4">
        <h2 className="font-medium text-lg">
          {Number(unitParkingQty) > 0
            ? `Your Purchase of ${selectedUnit.unitNumber} includes ${unitParkingQty}
            ${unitParkingType}. Would you like to purchase another parking spot?`
            : 'Your Unit has no Parking Spot included. Would you like to purchase a parking spot?'}
        </h2>

        <div className="flex gap-10 mt-6">
          <CustomRadio
            label="Yes"
            checked={purchaseParking}
            onClick={() => onChangeParkingOption(true)}
          />
          <CustomRadio
            label="No"
            checked={!purchaseParking}
            onClick={() => onChangeParkingOption(false)}
          />
        </div>
      </div>

      {purchaseParking && (
        <>
          <LineText title="select parking" />

          <div className="grid grid-cols-2 xl:grid-cols-3 gap-6">
            {loading &&
              Array.from(Array(3).keys()).map((a, index) => (
                <Skeleton key={index} height={280} width="100%" />
              ))}
            {!loading &&
              selectedParkings.map((parking, index) => (
                <ParkingCard
                  key={index}
                  checked={selectedParkings[index].isSelected}
                  setChecked={() => {
                    onUpdateCheck(parking._id, !parking.isSelected)
                  }}
                  count={selectedParkings[index].count}
                  increaseCount={() => {
                    onIncreaseCount(parking._id, parking.count + 1)
                  }}
                  decreaseCount={() => {
                    onDecreaseCount(parking._id, parking.count - 1)
                  }}
                  isActive={false}
                  checkboxTitle={'Add Parking'}
                  id={parking._id}
                  description={parking.description}
                  imgSrc={parking.images?.[0]}
                  price={parking.value}
                  title={parking.name}
                />
              ))}
          </div>
        </>
      )}
      <BottomActions
        hasYPadding={purchaseParking}
        onBack={onBack}
        onNext={onNext}
        isNextBtnDisabled={!((onlySelectedParkings().length > 0 && purchaseParking) || !purchaseParking)}
      />
    </div>
  )
}

export default SelectParkingInventory
