/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import propTypes from 'prop-types'
import { StartExternalProviderConfiguration } from 'components'
import {
  getSharedEmails,
  getUserEmails,
  getUserThreads
} from 'store/actions/usersActions'
import { sanitizeCode } from 'utils'
import EmailModal from 'pages/contacts/EmailModal'
import { isMobile } from 'react-device-detect'
import EmailsCard from 'components/EmailCard/version_2'
import DashboardProvider from 'pages/dashboard/context/DashboardContext'
import { Drawer, makeStyles, Tabs, Tab } from '@material-ui/core'


const EmailInbox = (props) => {
  const [showEmailBox, setShowEmailBox] = useState(false)
  const [gettingListOfEmails, setGettingListOfEmails] = useState(false)
  const [listOfEmails, setListOfEmails] = useState([])
  const [composingEmailSubject, setComposingEmailSubject] = useState('')
  const [composingEmailRecipient, setComposingEmailRecipient] = useState([])
  const [composingEmailBody, setComposingEmailBody] = useState('')
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(0)
  const [selectedThreadId, setSelectedThreadId] = useState('')
  const [selectedThreadContent, setSelectedThreadContent] = useState(null)
  const [gettingThreadContent, setGettingThreadContent] = useState(false)
  const [showMessageList, setShowMessageList] = useState(isMobile)
  const [searchFilter, setSearchFilter] = useState('')
  const refLocalListOfEmails = useRef(listOfEmails)
  const [selectedEmail, setSelectedEmail] = useState({})
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [sharedEmails, setSharedEmails] = useState([])
  const [activeTab, setActiveTab] = useState('personal')
  const tabMenus = [
    {
      id: 'personal',
      name: 'Personal'
    },
    {
      id: 'shared',
      name: 'Shared'
    }
  ]

  const updateListOfEmails = (newState) => {
    refLocalListOfEmails.current = newState
    setListOfEmails(newState)
  }

  const {
    userObject: { providerToken }
  } = props

  useEffect(() => {
    if (selectedEmailIndex !== -1 && refLocalListOfEmails.current) {
      const selectedEmailThreadId =
        refLocalListOfEmails.current[selectedEmailIndex]?.messages?.[0]
          ?.threadId ?? null
      setSelectedThreadId(selectedEmailThreadId)
    }
  }, [selectedEmailIndex])

  const getEmails = () => {
    if (selectedThreadId) {
      if (activeTab === 'personal') {
        setGettingThreadContent(true)
        getUserEmails({ id: selectedThreadId, search: searchFilter })
          .then(({ data }) => {
            setSelectedThreadContent({
              subject: data[0].subject,
              senderName: `${data[0].from[0].name && data[0].from[0].name}`,
              senderEmail: data[0].from[0].email,
              items: data.map((email, index) => {
                const emailDate = new Date(
                  (email?.createdAt || email?.date) * 1000
                )
                return {
                  id: index,
                  messageId: email.id,
                  snippet: email.snippet,
                  threadId: email.threadId,
                  author: email.from[0].name || 'Unknown sender',
                  date: `${emailDate.toLocaleDateString('en-US', {
                    weekday: 'long'
                  })} at ${emailDate.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}`,
                  datetime: email?.createdAt || email?.date,
                  body: sanitizeCode(email.body),
                  files: email.attachments
                }
              })
            })
            setGettingThreadContent(false)
          })
          .catch(() => setGettingThreadContent(false))
      } else {
        if(sharedEmails.length > 0) {
        const getSelectedSharedEmail = sharedEmails.find(
          (email) => email.threadId === selectedThreadId
        )?.data[0]?.data
        setSelectedThreadContent({
          subject: getSelectedSharedEmail?.[0]?.subject,
          senderName: `${
            getSelectedSharedEmail?.[0]?.from?.[0]?.name ?
            getSelectedSharedEmail?.[0]?.from?.[0]?.name : getSelectedSharedEmail?.[0]?.from?.[0]?.email
          }`,
          senderEmail: getSelectedSharedEmail?.[0]?.from?.[0]?.email,
          items: getSelectedSharedEmail?.map((email, index) => {
            const emailDate = new Date((email?.createdAt || email?.date) * 1000)
            return {
              id: index,
              messageId: email.id,
              snippet: email.snippet,
              threadId: email.threadId,
              author: email.from?.[0]?.name || 'Unknown sender',
              date: `${emailDate.toLocaleDateString('en-US', {
                weekday: 'long'
              })} at ${emailDate.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit'
              })}`,
              datetime: email?.createdAt || email?.date,
              body: sanitizeCode(email.body),
              files: email.attachments
            }
          })
        })
        setGettingThreadContent(false)
      }
      }
    }
  }

  useEffect(getEmails, [selectedThreadId, activeTab])

  const getThreads = useCallback((manually, withQuery) => {
    setListOfEmails([])
    refLocalListOfEmails.current = []
    setGettingListOfEmails(true)
    if (activeTab === 'personal') {
      if(providerToken) {
      getUserThreads({
        offset: manually ? 0 : refLocalListOfEmails.current.length,
        limit: 50,
        search: withQuery ? searchFilter : ''
      })
        .then((newEmails) => {
          let currentEmails = []

          if (!manually) {
            currentEmails = [...refLocalListOfEmails.current, ...newEmails.data]
          } else {
            currentEmails = [...newEmails.data]
          }

          const listOfEmails = currentEmails.map((email) => ({
            lastMessageTimestamp:
              email.last_message_timestamp || email.latestMessageReceivedDate,
            messages: [email.latestDraftOrMessage],
            snippet: email.snippet,
            subject: email.subject,
          }))
          setGettingListOfEmails(false)
          updateListOfEmails(listOfEmails)
          if (listOfEmails.length) {
            setSelectedEmailIndex(-1)
            setTimeout(() => {
              setSelectedEmailIndex(0)
            }, 500)
          }
        })
        .catch(() => setGettingListOfEmails(false))
      } else {
        setGettingListOfEmails(false)
        setListOfEmails([])
        setSelectedEmailIndex(-1)
      }
    } else {
      fetchSharedEmails()
    }
  }, [providerToken, activeTab, searchFilter])

  useEffect(() => {
      getThreads(true, true)
  }, [providerToken, activeTab, searchFilter])

  const childProps = {
    selectedEmailIndex,
    setSelectedEmailIndex,
    selectedThreadContent,
    setSelectedThreadContent,
    composingEmailSubject,
    setComposingEmailSubject,
    showMessageList,
    setShowMessageList,
    isMobile,
    refLocalListOfEmails,
    gettingListOfEmails,
    getEmails,
    selectedThreadId,
    setSelectedThreadId,
    showEmailBox,
    setShowEmailBox,
    gettingThreadContent,
    setGettingThreadContent,
    composingEmailBody,
    setComposingEmailBody,
    composingEmailRecipient,
    setComposingEmailRecipient,
    listOfEmails,
    searchFilter,
    setSearchFilter,
    showFilterOption: false,
    showLoadMore: false,
    selectedEmail,
    setSelectedEmail,
    activeTab,
    tabMenus,
    setActiveTab,
    providerToken
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  // useEffect(() => {
  //   if (activeTab === 'shared') {
  //     fetchSharedEmails()
  //   }
  // }, [activeTab])

  const fetchSharedEmails = async () => {
    try {
      const response = await getSharedEmails(appProject)
      setSharedEmails(response)
      const formattedEmails = response.map((data) => {
        let email = data?.data[0]?.data[0]
        if(!email) return null
        return {
          subject: email.subject || 'Untitled',
          lastMessageTimestamp: new Date(data.updatedAt).getTime() / 1000,
          messages: [email],
          snippet: email.snippet,
          salesRep: data.salesRep
        }
      })

      const filteredEmails = formattedEmails.filter((email) => email)
      setGettingListOfEmails(false)
      updateListOfEmails(filteredEmails)
      if (formattedEmails.length) {
        setSelectedEmailIndex(-1)
        setTimeout(() => {
          setSelectedEmailIndex(0)
        }, 500)
      }
    } catch (error) {
      console.error('Error fetching shared emails:', error)
    } finally {
    }
  }


  return (
    <DashboardProvider>
      {showEmailBox && (
        <EmailModal
          composingEmailSubject={composingEmailSubject}
          messageId={selectedThreadContent?.items?.[0]?.messageId}
          show={showEmailBox}
          handleClose={() => setShowEmailBox(false)}
          contact={
            composingEmailRecipient
              ? {
                  firstName: composingEmailRecipient?.[0]?.name,
                  email: composingEmailRecipient?.[0]?.email
                }
              : null
          }
          setComposingEmailSubject={setComposingEmailSubject}
          setComposingEmailRecipient={setComposingEmailRecipient}
        />
      )}
        <>
            <div className='p-2 flex flex-col gap-2'>
              <EmailsCard childProps={childProps} showUserInfo />
            </div>
        </>
    </DashboardProvider>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(EmailInbox)

EmailInbox.propTypes = {
  userObject: propTypes.shape({
    providerToken: propTypes.string,
    email: propTypes.string,
    firstName: propTypes.string,
    lastName: propTypes.string
  })
}
