/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import EmailMessagesList from './EmailMessagesList'
import SelectedMailSection from './SelectedMailSection'
import { getSharedEmails, searchByEmail } from 'store/actions/usersActions'
import ContactCard from '../../../pages/dashboard/Sidebar/ContactCard/ContactCard'
import useSidebar from 'hooks/useSidebar'
import { isMobile } from 'react-device-detect'
import { Drawer, makeStyles, Tabs, Tab } from '@material-ui/core'
import { useSelector } from 'react-redux'
// import axios from 'axios'

const EmailsCard = ({ childProps, showUserInfo = false }) => {
  const [selectedContactObject, setSelectedContactObject] = useState(null)
  const { Sidebar, fullScreen, setFullScreen, showPanel, hidePanel } = useSidebar()
  
  const [loadingShared, setLoadingShared] = useState(false)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const appProject = useSelector((state) => state.appReducer.appProject)

  useEffect(() => {
    if (childProps?.selectedThreadContent?.senderEmail?.length) {
      setSelectedContactObject(null)
      searchByEmail(childProps?.selectedThreadContent?.senderEmail)
        .then((result) => {
          const userObject = result?.data?.[0]
          if (userObject && Object.keys(userObject)?.length) {
            setSelectedContactObject(userObject)
          }
        })
        .catch((e) => {})
    }
  }, [childProps.selectedThreadContent])

 
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const onFullScreenClick = useCallback(() => {
    if (fullScreen) {
      setFullScreen(false)
      hidePanel()
    } else {
      setFullScreen(true)
      showPanel()
    }
  }, [fullScreen, showPanel, hidePanel])

  const ContactChild = useCallback(() => {
    if (!showUserInfo || !selectedContactObject) {
      return null
    }

    return (
      <>
        <div className='flex flex-col rounded-lg border-2 border-gray-1 p-2 overflow-y-scroll max-h-screen'>
          <button
            className='flex justify-center items-center m-4 py-4 bg-volt rounded gap-2 italic text-black'
            onClick={() => {
              onFullScreenClick()
            }}
          >
            See Full Profile
          </button>
          <ContactCard
            fullScreen={false}
            setSelectedContact={null}
            contact={selectedContactObject}
            tasksList={[]}
            contactId={selectedContactObject?._id}
          />
        </div>
        <Sidebar title={'User Info'} isFullScreen={fullScreen}>
          <div className='flex flex-row gap-2 w-full'>
            <div className='flex flex-row w-full overflow-y-auto rounded-lg border-2 border-gray-1 p-4'>
              <ContactCard
                fullScreen={true}
                setSelectedContact={null}
                contact={selectedContactObject}
                tasksList={[]}
                contactId={selectedContactObject?._id}
              />
            </div>
          </div>
        </Sidebar>
      </>
    )
  }, [showUserInfo, selectedContactObject, fullScreen, Sidebar])

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: '100%',
      flexShrink: 0
    },
    drawerPaper: {
      top: showUserInfo ? '64px' : '175px',
      width: '100%',
      height: showUserInfo ? 'calc(100vh - 64px)' : 'calc(100vh - 175px)',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()
  return (
    <div className='flex-1 flex flex-col overflow-auto h-full w-full'>
      {/* <div className='border-b border-gray-200'>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Personal" value="personal" />
          <Tab label="Shared" value="shared" />
        </Tabs>
      </div> */}
      
      <main className='min-w-0 flex-1 flex'>
        {childProps.isMobile && childProps.showMessageList && (
          <EmailMessagesList 
            {...childProps}
          />
        )}
        {!childProps.isMobile && (
          <EmailMessagesList 
            {...childProps}
          />
        )}
        {/* {isMobile && (
          <Drawer
            open={!childProps.showMessageList}
            variant="persistent"
            anchor='right'
            classes={{
              paper: classes.drawerPaper
            }}
            // className='z-[15] flex-col justify-start items-start gap-4'
          >
            <SelectedMailSection {...childProps} showUserInfo />
          </Drawer>
        )}
        {!childProps.isMobile && <SelectedMailSection {...childProps} showUserInfo />} */}
      </main>
      {ContactChild()}
    </div>
  )
}

export default EmailsCard
