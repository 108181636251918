// import { message } from 'antd'
import { api, ApiErrors } from 'services/api'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'

export const getBuyerNotes = (buyerId) =>
  new Promise((resolve, reject) => {
    if (!buyerId || !buyerId?.length) {
      return reject()
    }
    api.get(`notes/buyer/${buyerId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateNote = (props) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `notes/${_id}` : 'notes'

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        // message.success(`This note was ${_id ? 'successfully updated' : 'created successfully'}`)

        if (!_id) {
          // Track note save event
          mixpanel.track(MIXPANEL_EVENTS.NOTE_CREATE, {
            createdNoteId: response?.data?._id
          })
        }

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getNotes = (id = '') => new Promise((resolve, reject) => {
  api.get(`notes/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteNote = (id = '') => new Promise((resolve, reject) => {
  api.delete(`notes/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
