// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ReactComponent as ActivityIcon } from '../../assets/icons/icn_ballot.svg';
import { CheckIcon, Download, EyeIcon, LinkIcon, RefreshCcw, StarIcon, Trash2, XIcon } from "lucide-react";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import { getUserListsByContactId, removeContactFromList, resubscribeContactToList, unsubscribeContactFromList } from "store/actions/usersActions";
import Skeleton from "react-loading-skeleton";
import CustomButton from "components/CustomButton";
import TableGrid from 'components/TableGrid/Table';
import { api } from 'services/api';
import { Button } from "components/ShadcnComponents/button";
import { Drawer, makeStyles } from '@material-ui/core'
import { isMobile } from "react-device-detect";
import { IoMdClose } from "react-icons/io";
import TableWithPagination from "components/TableWithPagination";
import { useGetUsersFromList } from "hooks/useGetLists";

const IndividualListDrawer = ({ isOpen, onClose, list }) => {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [limit, setLimit] = React.useState(10)

  const {
    users,
    totalUsers,
    isLoading: isUsersLoading,
    forceRefetch: forceRefetchUsers
  } = useGetUsersFromList(list?._id, {
    page: currentPage,
    limit
  })

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      flexShrink: 0
    },
    drawerPaper: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  return (
    <Drawer
      open={isOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={onClose}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
    >
      <div className='pt-16 h-screen flex flex-col overflow-hidden'>
        <div className='w-full h-full overflow-y-auto p-4'>
          <div className='w-full flex items-center justify-between overflow-hidden'>
            <h1 className='text-2xl font-bold truncate'>Users in {list?.title} list</h1>
            <div className='flex-1 flex items-center justify-end gap-x-2'>
              <Button onClick={forceRefetchUsers} disabled={isUsersLoading} className='w-fit flex items-center gap-2 text-base font-medium !py-2.5'>
                <RefreshCcw size={18} />{' '}
                {isUsersLoading ? 'Refreshing...' : 'Refresh Data'}
              </Button>
              <Button variant='secondary' onClick={onClose} className='w-fit flex items-center gap-2 text-base font-medium !py-2.5'>
                <IoMdClose fontSize='20px' />
                Close
              </Button>
            </div>
          </div>
          <div className='flex-1 w-full flex flex-col gap-4 pt-2 pb-4'>
            <TableWithPagination
              columns={[
                {
                  headerName: 'Email',
                  field: 'email',
                  flex: 3,
                  onCellClicked: (params) => {
                    if (params.data._id) {
                      // setSelectedContact({ _id: params.data._id })
                    }
                  }
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  flex: 1,
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  flex: 1,
                  sortable: true
                }
              ]}
              rowData={users}
              totalCount={totalUsers}
              page={currentPage}
              setPage={setCurrentPage}
              limit={limit}
              setLimit={setLimit}
              loading={isUsersLoading}
            />
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export const ListsTab = ({ contact }) => {
  const { state, toggleCheckContact } = useContactContext()
  const [lists, setLists] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [removingFromList, setRemovingFromList] = useState({})
  const [unsubscribingFromList, setUnsubscribingFromList] = useState({})
  const [resubscribingToList, setResubscribingToList] = useState({})
  const [unsubscribeGroups, setUnsubscribeGroups] = useState([])
  const [contactDetails, setContactDetails] = useState({})
  const [count, setCount] = useState(0)
  const [selectedList, setSelectedList] = useState(null)

  useEffect(() => {
    // @ts-ignore
    const sendGrid = contactDetails?.sendGrid
    const unsubscribedGroups = sendGrid?.unsubscribedGroups || []
    setUnsubscribeGroups(unsubscribedGroups)
  }, [contactDetails])

  useEffect(() => {
    api.get(`/users/${contact._id}`).then((res) => {
      const resData = res.data?.data
      if (!resData) return
      setContactDetails(resData)
    })
  }, [count])

  useEffect(() => {
    if (!state.isAddToListDrawerOpen) {
      setIsLoading(true)
      getUserListsByContactId(contact?._id).then((res) => {
        const lists = res.data
        const sortedByListType = lists.sort((a, b) => {
          if (a.listType === 'smart') return 1
          if (b.listType === 'smart') return -1
          return 0
        })
        setLists(sortedByListType)
        setCount(count + 1)
        setIsLoading(false)
      })
    }
  }, [state])

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col gap-6 px-4 pt-6">
          <Skeleton height={100} />
        </div>
      ) : (
        <>
          {lists.length > 0 ? (
            <>
              <div className="w-full flex justify-between items-center px-4 pt-3 pb-0 mb-0">
                <h3 className="m-0 text-xl font-semibold">Lists</h3>
                <div className="text-center flex gap-x-4">
                  <CustomButton handleClick={() => toggleCheckContact(contact?._id, true)}>
                    <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                      <Download className='w-5 h-6' />
                      Add to Lists
                    </span>
                  </CustomButton>
                </div>
              </div>
              <div className='flex-1 p-1.5'>
                <TableGrid
                  columnDefs={[
                    {
                      headerName: '#',
                      field: 'index',
                      maxWidth: 70,
                      cellRenderer: (params) => {
                        const isFavourite = params.data?.isFavourite
                        return <div className='flex gap-1 items-center'>
                          <div>
                            {isFavourite && (
                              <StarIcon size={18} className='text-yellow-300' />
                            )}
                          </div>
                          <div>
                            {params.node.rowIndex + 1}
                          </div>
                        </div>
                      }
                    },
                    {
                      headerName: 'List',
                      field: 'title',
                      flex: 1,
                      minWidth: 400,
                      cellRenderer: (params) => {
                        return (
                          <div className="flex items-center gap-x-3">
                            <Button
                              size="sm"
                              className="flex items-center gap-x-2"
                              onClick={() => setSelectedList(params?.data)}
                            >
                              <EyeIcon size={16} />
                              View
                            </Button>
                            {params?.data?.title}
                          </div>
                        )
                      },
                    },
                    {
                      headerName: "List Type",
                      field: "listType",
                      resizable: true,
                      width: 150,
                      cellRenderer: ({ value }) => {
                        return (
                          <div>
                            {value === "static" && (
                              <div className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                                Standard
                              </div>
                            )}
                            {value === "smart" && (
                              <div className="bg-blue-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                                Smart
                              </div>
                            )}
                          </div>
                        )
                      },
                    },
                    {
                      headerName: "SendGrid",
                      width: 150,
                      field: "sendGrid",
                      resizable: true,
                      cellRenderer: (params) => {
                        const sendGrid = params?.data?.sendGrid
                        const listId = sendGrid?.listId
                        const isSynced = sendGrid && listId
                        const linkUrl = `https://mc.sendgrid.com/contacts/lists/${listId}`
                        return <div>
                          {isSynced && (
                            <div className='flex items-center gap-x-2'>
                              <div className="flex gap-x-1 items-center bg-green-500 text-white border border-border px-1.5 py-0.5 rounded-full text-xs">
                                <CheckIcon size={16} />
                                <div>
                                  Synced
                                </div>
                              </div>
                              <a href={linkUrl} target="_blank" rel="noreferrer" className="text-blue-500 underline flex gap-x-1 items-center">
                                <LinkIcon size={16} />
                              </a>
                            </div>
                          )}
                          {!isSynced && (
                            <div className="flex gap-x-1 items-center bg-red-500 text-white border border-border px-1.5 py-0.5 rounded-full text-xs">
                              <XIcon size={16} />
                              <div>
                                Not synced
                              </div>
                            </div>
                          )}
                        </div>
                      },
                    },
                    {
                      headerName: "Subscribed",
                      width: 150,
                      field: "sendGrid",
                      resizable: true,
                      cellRenderer: (params) => {
                        const sendGrid = params?.data?.sendGrid
                        const listId = sendGrid?.listId
                        const isSynced = sendGrid && listId
                        const unsubscribeGroupId = sendGrid?.unsubscribeGroupId
                        const isUnSubscribed = sendGrid && unsubscribeGroupId && unsubscribeGroups.includes(unsubscribeGroupId)
                        return <div>
                          {isUnSubscribed && (
                            <div className='flex gap-x-1 items-center bg-red-500 text-white border border-border px-1.5 py-0.5 rounded-full text-xs'>
                              <XIcon size={16} />
                              <div>
                                Unsubscribed
                              </div>
                            </div>
                          )}
                          {!isUnSubscribed && (
                            <div className="flex gap-x-1 items-center bg-green-500 text-white border border-border px-1.5 py-0.5 rounded-full text-xs">
                              <CheckIcon size={16} />
                              <div>
                                Subscribed
                              </div>
                            </div>
                          )}
                        </div>
                      },
                    },
                    // {
                    //   headerName: "Project",
                    //   width: 200,
                    //   field: "project",
                    //   resizable: true,
                    //   cellRenderer: (params) => {
                    //     const project = params?.data?.projectId
                    //     return <div className="flex gap-x-2">{project?.projectName}</div>
                    //   },
                    // },
                    {
                      headerName: 'Actions',
                      field: '_id',
                      flex: 1,
                      cellRenderer: (params) => {
                        const isSmartList = params.data?.listType === 'smart'
                        const sendGrid = params?.data?.sendGrid
                        const unsubscribeGroupId = sendGrid?.unsubscribeGroupId
                        const isUnSubscribed = sendGrid && unsubscribeGroupId && unsubscribeGroups.includes(unsubscribeGroupId)
                        return (
                          <div className='flex items-center gap-2'>
                            {!isUnSubscribed && unsubscribeGroupId && (
                              <CustomButton
                                className='text-white'
                                disabled={unsubscribingFromList[params.data._id]}
                                handleClick={() => {
                                  if (unsubscribingFromList[params.data._id]) return
                                  if (!confirm('Are you sure you want to unsubscribe this contact from this list?'))
                                    return
                                  setUnsubscribingFromList({
                                    ...unsubscribingFromList,
                                    [params.data._id]: true
                                  })
                                  unsubscribeContactFromList(
                                    params.data._id,
                                    contact?._id,
                                  )
                                    .then(() => {
                                      alert('Contact unsubscribed from list')
                                      getUserListsByContactId(contact?._id).then((res) => {
                                        const lists = res.data
                                        const sortedByListType = lists.sort((a, b) => {
                                          if (a.listType === 'smart') return 1
                                          if (b.listType === 'smart') return -1
                                          return 0
                                        })

                                        setLists(sortedByListType)
                                        setUnsubscribingFromList({})
                                        setCount(count + 1)
                                      })
                                    })
                                }}>
                                <span className='font-medium placeholder py-1 px-1.5 text-base flex gap-2 items-center'>
                                  <Trash2 size={18} />
                                  Unsubscribe
                                </span>
                              </CustomButton>
                            )}
                            {isUnSubscribed && unsubscribeGroupId && (
                              <CustomButton
                                className='text-white bg-green-600'
                                disabled={resubscribingToList[params.data._id]}
                                handleClick={() => {
                                  if (resubscribingToList[params.data._id]) return
                                  if (!confirm('Are you sure you want to unsubscribe this contact from this list?'))
                                    return
                                  setResubscribingToList({
                                    ...resubscribingToList,
                                    [params.data._id]: true
                                  })
                                  resubscribeContactToList(
                                    params.data._id,
                                    contact?._id,
                                  )
                                    .then(() => {
                                      alert('Contact unsubscribed from list')
                                      getUserListsByContactId(contact?._id).then((res) => {
                                        const lists = res.data
                                        const sortedByListType = lists.sort((a, b) => {
                                          if (a.listType === 'smart') return 1
                                          if (b.listType === 'smart') return -1
                                          return 0
                                        })
                                        setLists(sortedByListType)
                                        setResubscribingToList({})
                                        setCount(count + 1)
                                      })
                                    })
                                }}>
                                <span className='font-medium placeholder py-1 px-1.5 text-base flex gap-2 items-center'>
                                  Re Subscribe
                                </span>
                              </CustomButton>
                            )}
                            {!isSmartList && (
                              <CustomButton
                                className='text-white bg-red-500'
                                disabled={removingFromList[params.data._id]}
                                handleClick={() => {
                                  if (removingFromList[params.data._id]) return
                                  if (!confirm('Are you sure you want to remove this contact from this list?'))
                                    return
                                  setRemovingFromList({
                                    ...removingFromList,
                                    [params.data._id]: true
                                  })
                                  removeContactFromList(
                                    params.data._id,
                                    contact?._id,
                                  ).then(() => {
                                    alert('Contact removed from list')
                                    getUserListsByContactId(contact?._id).then((res) => {
                                      const lists = res.data
                                      const sortedByListType = lists.sort((a, b) => {
                                        if (a.listType === 'smart') return 1
                                        if (b.listType === 'smart') return -1
                                        return 0
                                      })
                                      setLists(sortedByListType)
                                      setRemovingFromList({})
                                      setCount(count + 1)
                                    })
                                  }
                                  )
                                }}>
                                <span className='font-medium placeholder py-1 px-1.5 text-base flex gap-2 items-center'>
                                  <Trash2 size={18} />
                                  Delete
                                </span>
                              </CustomButton>
                            )}
                          </div>

                        )
                      },
                    }
                  ]}
                  getData={async () => {
                    return new Promise((resolve) => {
                      resolve(lists)
                    })
                  }}
                >
                </TableGrid>
              </div>
              <IndividualListDrawer
                isOpen={selectedList?._id && selectedList?._id?.length > 0}
                onClose={() => setSelectedList(null)}
                list={selectedList}
              />
            </>
          ) : (
            <div className="font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded">
              <ActivityIcon className="h-10 w-10" />
              <div className="font-semibold mb-2 mt-4">
                This contact is not part of any list
              </div>
              <div className="text-center pt-4 flex gap-x-4">
                <CustomButton handleClick={() => toggleCheckContact(contact?._id, true)}>
                  <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
                    <Download className='w-5 h-6' />
                    Add to Lists
                  </span>
                </CustomButton>
              </div>
            </div>
          )}
        </>
      )
      }
    </>
  );
};
