import mixpanel from 'mixpanel-browser'
import { getServerUrl } from 'store/actions/googleAdsActions'

// Centralized event names object
export const MIXPANEL_EVENTS = {
  APP_LOADED: 'App Loaded',
  PROJECT_SWITCHED: 'Project Switched',
  USER_LOGGED_IN: 'User Logged In',
  USER_LOGGED_OUT: 'User Logged Out',
  CONTACT_PAGE_VIEW: 'Viewed Contact Page',
  CONTACT_CREATE_START: 'Started Creating Contact',
  CONTACT_CREATE_TYPE_SELECTED: 'Selected Contact Type',
  CONTACT_CREATE_SAVE: 'Contact Created Successfully',
  CALL_START: 'Started Call',
  CALL_SAVE: 'Call Saved Successfully',
  EMAIL_CREATE: 'Started Email Creation',
  EMAIL_SEND: 'Email Sent Successfully',
  NOTE_CREATE: 'Note Created Successfully',
  TASK_CREATE_START: 'Started Task Creation',
  TASK_CREATE_SAVE: 'Task Saved Successfully',
  SALES_TRANSACTION_CREATE_START: 'Started Sales Transaction',
  SALES_TRANSACTION_QUICK_FORM_SUBMIT: 'Submitted Sales Transaction Form',
  SMS_BROADCAST_CREATE: 'Started SMS Broadcast',
  SMS_BROADCAST_SEND: 'SMS Broadcast Sent Successfully'
}

class MixpanelService {
  static instance = null
  static isMixpanelEnabled = Boolean(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) && (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '')?.length > 21
  superProperties = {
    appType: 'Web',
    server: getServerUrl()
  }

  constructor () {
    if (MixpanelService.instance) {
      return MixpanelService.instance
    }
    MixpanelService.instance = this
  }

  // Initialize Mixpanel
  initialize (token) {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.init(token, {
        debug: process.env.REACT_APP_ENVIRONMENT === 'development', // Enable debug in development
        record_sessions_percent: 100 // Track 100% of sessions
      })
      // mixpanel.init(token, {
      //   // record_mask_text_class: '^(mp-mask|fs-mask|amp-mask|rr-mask|ph-mask)$' // Mask sensitive data
      // })
    }
  }

  // Set global custom properties
  setCustomDetails (details) {
    if (MixpanelService.isMixpanelEnabled) {
      this.superProperties = { ...this.superProperties, ...details }
      mixpanel.register(this.superProperties) // Register as super properties
    }
  }

  // Update a specific custom property
  updateCustomDetail (key, value) {
    if (MixpanelService.isMixpanelEnabled) {
      this.superProperties[key] = value
      mixpanel.register({ [key]: value }) // Register the updated property
    }
  }

  // Identify user
  identify (userId) {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.identify(userId)
    }
  }

  // Track an event with merged global and specific properties
  track (event, properties = {}) {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.track(event, { ...this.superProperties, ...properties })
    }
  }

  // Set user properties
  setUserProperties (properties) {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.people.set(properties)
    }
  }

  // Increment numeric properties
  incrementProperty (property, value) {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.people.increment(property, value)
    }
  }

  // Register super properties
  registerSuperProperties (properties) {
    if (MixpanelService.isMixpanelEnabled) {
      this.superProperties = { ...this.superProperties, ...properties }
      mixpanel.register(this.superProperties)
    }
  }

  // Unregister a super property
  unregisterSuperProperty (property) {
    if (MixpanelService.isMixpanelEnabled) {
      delete this.superProperties[property]
      mixpanel.unregister(property)
    }
  }

  // // Flush events
  // flush () {
  //   mixpanel.flush()
  // }

  // Reset Mixpanel state (logout scenario)
  reset () {
    if (MixpanelService.isMixpanelEnabled) {
      mixpanel.reset()
      this.superProperties = { appType: 'Web', server: getServerUrl() } // Clear local super properties and reset server
    }
  }
}

export default new MixpanelService()
