import React from 'react'
import { Document, Page } from 'react-pdf'

const PdfDocument = ({
  documentProps,
  pageNumber,
  onLoadSuccess = () => { },
  pageProps
}) => {
  return (
    <div className='w-fit max-w-full'>
      <Document
        {...documentProps}
        file={documentProps.file}
        onLoadError={(error) => console.log('pdf load error', error)}
      >
        <Page
          pageNumber={Number(pageNumber)}
          onLoadSuccess={onLoadSuccess}
          {...pageProps}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document>
    </div>
  )
}

export default React.memo(PdfDocument)
