import propTypes from 'prop-types'
// @ts-nocheck
import React from 'react'
import { DocumentPagination } from '../styles'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import CustomButton from 'components/CustomButton'

const PdfPagination = ({
  pdfPageNumber,
  pdfNumPages,
  decreaseSelectedContractPage,
  increaseSelectedContractPage
}) => {
  return (
    <DocumentPagination className='h-full !items-center gap-2' justify='center'>
      <CustomButton variant="ghost" className="p-2 hover:!bg-zinc-200/80 transition-all" handleClick={(e) => decreaseSelectedContractPage(e)}>
        <ChevronLeft className="h-6 w-6" />
      </CustomButton>
      <span className='text-base mx-1 cursor-default'>Page</span>
      <span className='text-base'>{pdfPageNumber}</span>
      <span className='text-base'>{`of ${pdfNumPages}`}</span>
      <CustomButton variant="ghost" className="p-2 hover:!bg-zinc-200/80 transition-all" handleClick={(e) => increaseSelectedContractPage(e)}>
        <ChevronRight className="h-6 w-6" />
      </CustomButton>
    </DocumentPagination>
  )
}
export default React.memo(PdfPagination)

PdfPagination.propTypes = {
  pdfPageNumber: propTypes.number,
  pdfNumPages: propTypes.number,
  decreaseSelectedContractPage: propTypes.func,
  increaseSelectedContractPage: propTypes.func
}
