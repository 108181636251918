// @ts-nocheck
import CustomRadio from 'components/CustomRadio'
import React, { useContext, useState } from 'react'
import AdditionalBuyerItem from './AdditionalBuyerItem'
import BottomActions from './BottomActions'
import { CreateOfferContextInventory } from '../context/CreateOfferContextInventory'

const initialData = (defaultData) => {
  return {
    fullName: defaultData?.fullName ?? '',
    email: defaultData?.email ?? '',
    telephone: defaultData?.telephone ?? '',
    address: defaultData?.address ?? '',
    notificationAddress: defaultData?.notificationAddress ?? '',
    rfc: defaultData?.rfc ?? '',
    curp: defaultData?.curp ?? '',
    birthDate: defaultData?.birthDate ?? '',
    placeOfBirth: defaultData?.placeOfBirth ?? '',
    maritalStatus: defaultData?.maritalStatus ?? '',
    nationality: defaultData?.nationality ?? ''
  }
}

const SelectExtraBuyerInventory = ({ setStep }) => {
  const { additionalBuyers, setAdditionalBuyers } =
    useContext(CreateOfferContextInventory)
  const [haveAdditionalBuyer, setHaveAdditionalBuyer] = useState(false)

  const onBack = () => setStep((prev) => prev - 1)
  const onNext = () => {
    setStep((prev) => prev + 1)
  }

  const [buyer2Data, setBuyer2Data] = useState({
    ...initialData(additionalBuyers?.length > 0 ? additionalBuyers?.[0] : null)
  })
  const [buyer3Data, setBuyer3Data] = useState({
    ...initialData(additionalBuyers?.length > 1 ? additionalBuyers?.[1] : null)
  })

  const onSave = () => {
    if (haveAdditionalBuyer) {
      setAdditionalBuyers([
        {
          ...buyer2Data
        },
        {
          ...buyer3Data
        }
      ])
    }
    onNext()
  }

  return (
    <div className='h-full flex flex-col gap-6'>
      <div className='pb-4 border-b-2 border-softBlack'>
        <p className='my-0 text-xl font-bold'>Additional Buyers</p>
      </div>
      <div className='mt-4'>
        <h2 className='font-medium text-lg'>Are their additional buyer?</h2>
        <div className='flex gap-10 mt-6'>
          <CustomRadio
            label='Yes'
            checked={haveAdditionalBuyer}
            onClick={() => setHaveAdditionalBuyer(true)}
          />
          <CustomRadio
            label='No'
            checked={!haveAdditionalBuyer}
            onClick={() => setHaveAdditionalBuyer(false)}
          />
        </div>
      </div>

      {haveAdditionalBuyer && (
        <>
          <AdditionalBuyerItem
            buyerTag='Buyer 2 '
            index={0}
            defaultData={buyer2Data}
            onChange={(key, vaue) => {
              setBuyer2Data({
                ...buyer2Data,
                [key]: vaue
              })
            }}
          />
          <AdditionalBuyerItem
            buyerTag='Buyer 3 '
            index={1}
            defaultData={buyer3Data}
            onChange={(key, vaue) => {
              setBuyer3Data({
                ...buyer3Data,
                [key]: vaue
              })
            }}
          />
        </>
      )}
      <BottomActions
        hasYPadding={haveAdditionalBuyer}
        onBack={onBack}
        onNext={onSave}
      />
    </div>
  )
}

export default SelectExtraBuyerInventory
