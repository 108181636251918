import React from 'react'
// @ts-ignore
import { getCustomForCustomField, SUPPORTED_CUSTOM_FIELDS } from './setting'

const Component = React.forwardRef((props, ref) => {
  // @ts-ignore
  const label = props?.data?.label ?? 'Are you a REALTORS®/Broker?'
  return <div>
    <label>{label}</label>
    <div className="radio-wrapper">
      <div className="radio-group">
        <input type="radio" id="realtorYes" name="isRealtor" value="true" />
        <label htmlFor="realtorYes" className="radio-label">Yes</label>
      </div>
      <div className="radio-group">
        <input type="radio" id="realtorNo" name="isRealtor" value="false" />
        <label htmlFor="realtorNo" className="radio-label">No</label>
      </div>
    </div>
  </div>
})
const field = SUPPORTED_CUSTOM_FIELDS.REALTOR
const RealtorConfig = getCustomForCustomField(field, Component, {
  icon: 'fa fa-users',
  props: {}
})
export default RealtorConfig
