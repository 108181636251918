/*eslint-disable */
// @ts-nocheck
import React, { useEffect } from 'react'
import { LottieWrapper } from "components"
import loadingAnimation from "../../assets/lottieJsons/avesdoLoading.json";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { Tooltip } from 'antd'

const PAGE_SIZES = [10, 20, 50, 100]

const Loader = () => {
  return (
    <div
      className='w-full absolute h-full z-[100] top-[50px] bg-white flex flex-col gap-2 justify-center items-center rounded-b-md'
    // style={{ height }}
    >
      <LottieWrapper
        animation={loadingAnimation}
        autoplay
        loop={true}
        className="-mt-40 md:mt-0 max-w-sm h-[80px] w-[80px]"
      />
      <p className='text-center mb-0'>Loading...</p>
    </div>
  );
};

const Pagination = ({ totalCount, page, setPage, limit, setLimit, loading }) => {
  const totalPages = Math.ceil(totalCount / limit);

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setPage(newPage);
    }
  };

  const handleLimitChange = (e) => {
    const newLimit = parseInt(e.target.value, 10);
    setLimit(newLimit);
    setPage(0);
  };
  const start = page * limit + 1;
  const end = Math.min(start + limit - 1, totalCount);

  return (
    <div
      className="w-full flex gap-4 justify-end items-center rounded-b-md transition-all bg-white p-2 border-t border-[#babfc7]"
      aria-label="Pagination"
    >
      <div className="flex items-center gap-x-3">
        <div className="text-sm text-gray-700">Page Size: </div>
        <div>
          <select
            value={limit}
            className="rounded-md w-[80px] px-3.5 h-[38px] font-bold border-gray-300"
            onChange={handleLimitChange}
          >
            {
              PAGE_SIZES.map((value, index) => (
                <option key={value} value={value}>{value}</option>
              ))
            }
          </select>
        </div>
      </div>
      <div className="flex items-center gap-1 text-sm text-gray-700">
        <span className='font-medium text-softBlack'>{start}</span>
        to
        {
          loading
            ? <span className='animate-pulse rounded-sm bg-zinc-200 w-[20px] h-[20px]' />
            : <span className='font-medium text-softBlack'>{end}</span>
        }
        of
        {
          loading
            ? <span className='animate-pulse rounded-sm bg-zinc-200 w-[20px] h-[20px]' />
            : <span className='font-medium text-softBlack'>{totalCount}</span>
        }
      </div>

      <div className="flex items-center">
        <button
          onClick={() => handlePageChange(0)}
          className="relative inline-flex items-center border border-gray-300 rounded-l-md border-r-0 bg-white p-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
          disabled={page <= 0}
        >
          <Tooltip title="First Page">
            <span className="sr-only">First</span>
            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
          </Tooltip>
        </button>

        <button
          onClick={() => handlePageChange(page - 1)}
          className="relative inline-flex items-center border border-gray-300 border-r-0 bg-white p-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
          disabled={page <= 0}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        <div className="relative h-[38px] w-[38px] flex justify-center items-center border-[1px] border-gray-300 bg-white text-sm font-medium text-black hover:bg-gray-50 focus:z-20">
          {page + 1}
        </div>

        <button
          onClick={() => handlePageChange(page + 1)}
          className="relative inline-flex items-center border border-gray-300 border-l-0 bg-white p-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
          disabled={page >= totalPages - 1}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>

        <button
          onClick={() => handlePageChange(totalPages - 1)}
          className="relative inline-flex items-center rounded-r-md border border-gray-300 border-l-0 bg-white p-2 text-sm font-medium text-black hover:bg-gray-50 focus:z-20"
          disabled={page >= totalPages - 1}
        >
          <Tooltip title="Last Page">
            <span className="sr-only">Last</span>
            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
          </Tooltip>
        </button>
      </div>
    </div>
  );
};

const TableWithPagination = (props) => {
  const {
    columns,
    rowData,
    totalCount = 0,
    page = 0,
    setPage = () => { },
    limit = 100,
    setLimit = () => { },
    loading = false,
    hidePagination = false,
    rowHeight = 40,
    minHeight = 470,
    onCellClicked = () => { },
    setRef = (ref) => { }
  } = props

  const gridRef = React.useRef(null);
  const defaultColDef = React.useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  useEffect(() => {
    if (gridRef.current && setRef && typeof setRef === 'function')
      setRef(gridRef.current)
  }, [gridRef])

  return (
    <div className='w-full flex flex-col'>
      <div
        className="relative w-full ag-theme-alpine bg-white rounded-lg"
        style={{ minHeight }}
      >
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          pagination={false}
          suppressPaginationPanel={true}
          columnDefs={columns}
          rowData={rowData}
          paginateChildRows
          defaultColDef={defaultColDef}
          rowHeight={rowHeight}
          onCellClicked={onCellClicked}
        />
        {
          loading &&
          <Loader />
        }
      </div>
      {
        !hidePagination &&
        <Pagination
          totalCount={totalCount}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
          loading={loading}
        />
      }
    </div>
  )
}

export default TableWithPagination
