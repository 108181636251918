// @ts-nocheck
import React, { useState } from 'react'
import { Drawer, makeStyles } from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { IoMdClose } from 'react-icons/io'
import { EventDetailsContent } from 'pages/desktopMode/Events/EventName'
import { Button } from 'components/ShadcnComponents/button'
import { CreateEditEventForm } from 'pages/desktopMode/Events/CreateAndEditEvent'

const EditEventDrawer = ({ isOpen, onClose, eventId }) => {
  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      flexShrink: 0
    },
    drawerPaper: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  return (
    <Drawer
      open={isOpen}
      variant='persistent'
      anchor='right'
      classes={{
        paper: classes.drawerPaper
      }}
      onClose={onClose}
      ModalProps={{
        BackdropProps: {
          invisible: true
        }
      }}
    >
      <div className='pt-16 h-screen flex flex-col overflow-hidden'>
        <CreateEditEventForm
          eventId={eventId}
          asDrawer
          onClose={onClose}
        />
      </div>
    </Drawer>
  )
}

const EventDetailsDrawer = ({ isOpen, onClose, eventId }) => {
  const [isEditEventDrawerOpen, setIsEditEventDrawerOpen] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)

  const useStyles = makeStyles((theme) => ({
    hide: {
      display: 'none'
    },
    drawer: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      flexShrink: 0
    },
    drawerPaper: {
      width: isMobile ? '100%' : 'calc(100% - 280px)',
      height: '100vh',
      overflow: 'hidden',
      boxShadow: '-10px 0px 15px 0px rgba(46, 43, 46, 0.05)'
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end'
    }
  }))

  const classes = useStyles()

  const handleEditEventDrawerClose = () => {
    setIsEditEventDrawerOpen(false)
    setRefreshCount((prev) => prev + 1)
  }

  return (
    <>
      <Drawer
        open={isOpen}
        variant='persistent'
        anchor='right'
        classes={{
          paper: classes.drawerPaper
        }}
        onClose={onClose}
        ModalProps={{
          BackdropProps: {
            invisible: true
          }
        }}
      >
        <div className='absolute right-[0.7rem] md:right-3 top-[5rem] md:top-[5rem] z-[10]'>
          <Button variant='secondary' onClick={onClose} className='w-fit flex items-center gap-2 text-base font-medium !py-2.5'>
            <IoMdClose fontSize='20px' />
            Close
          </Button>
        </div>
        <div className='pt-16 h-screen overflow-hidden'>
          <EventDetailsContent
            eventId={eventId}
            asDrawer
            onEditEvent={() => setIsEditEventDrawerOpen(true)}
            refreshCount={refreshCount}
          />
        </div>
      </Drawer>
      <EditEventDrawer
        isOpen={isEditEventDrawerOpen}
        onClose={handleEditEventDrawerClose}
        eventId={eventId}
      />
    </>
  )
}

export default EventDetailsDrawer
