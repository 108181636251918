/* eslint-disable */
// @ts-nocheck
import { OFFER_STATUS_OPTIONS, PAYMENT_METHOD, SUBJECT_TYPE } from "components/Inventory/components/QuickForm"
import { Badge } from "components/ShadcnComponents/badge"
import { Button } from "components/ShadcnComponents/button"
import CustomSelect from "../../../../reservation/NewReservation/components/CustomSelect"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import { ExternalLink, Plus, Trash2, Upload } from "lucide-react"
import moment from "moment-timezone"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { createOrUpdateOfferV2, updateOffers } from "store/actions/offerActions"
import { offerStatus } from "components/ProjectOffers"
import useUpdateOffers from "hooks/useUpdateOffers"
import { useSelector } from "react-redux"
import toast from "react-hot-toast"
import { deletePrivateDocs } from "store/actions/privateDocumentsActions"
import ConfirmDeleteModal from "pages/desktopMode/Events/CreateAndEditEvent/components/ConfirmDeleteModal"
import { Input } from "components/ShadcnComponents/input"
import CustomInput from "components/CustomInput"
import { Textarea } from "components/ShadcnComponents/textarea"
import DeleteModal from 'components/DeleteModal'
import { formatMoney } from "utils"
import DocumentUpload from "./DocumentUpload"

const transactionStatus = [
  {
    id: "draft",
    label: "Draft",
    value: "draft",
  },
  {
    id: "prepared",
    label: "Prepared",
    value: "prepared",
  },
  {
    id: "pending-approval",
    label: "Pending Approval",
    value: "pending-approval",
  },
  {
    id: "approved-pending-execution",
    label: "approved-pending execution",
    value: "approved-pending-execution",
  },
  {
    id: "executed-pending-dev-approval",
    label: "Executed-pending dev-approval",
    value: "executed-pending-dev-approval",
  },
  {
    id: "approved-send-for-signature",
    label: "Approved-Send for signature",
    value: "approved-send-for-signature",
  },
  {
    id: "conveyed",
    label: "Conveyed",
    value: "conveyed",
  },
  {
    id: "rescission",
    label: "Rescission",
    value: "rescission",
  },
  {
    id: "subjects",
    label: "Subjects",
    value: "subjects",
  },
  {
    id: "firm",
    label: "Firm",
    value: "firm",
  },
  {
    id: "lost",
    label: "Lost",
    value: "lost",
  },
]

const contractType = [
  {
    id: "offer-contract",
    title: "Offer Contract",
  },
  {
    id: "unit-contract",
    title: "Unit Contract",
  },
  {
    id: "parking-contract",
    title: "Parking Contract",
  },
  {
    id: "storage-contract",
    title: "Storage Contract",
  },
  {
    id: "other-docs",
    title: "Other Documents",
  },
]

const TransactionOverviewTab = ({ transactionData, fetchTransaction, closeContractDetailDrawer }) => {
  const [selectedOfferStatus, setSelectedOfferStatus] = useState(
    transactionData?.status || null
  )
  const projectId = useSelector((state) => state.appReducer.appProject)
  const listOfProjects = useSelector((state) => state.appReducer.listOfProjects)
  const [isUpdating, setIsUpdating] = useState(false)
  const [selectedContractId, setSelectedContractId] = useState(null)
  const [deposits, setDeposits] = useState([])
  const [subjects, setSubjects] = useState([])
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  useEffect(() => {
    if (transactionData?.deposit?.installments?.length > 0) {
      setDeposits(transactionData.deposit.installments)
    }
    if (transactionData?.subjects?.length > 0) {
      setSubjects(transactionData.subjects)
    }
  }, [transactionData])

  const {
    data: offerData,
    loading,
    update: updateOffer,
    deleteOffer,
    updateContractStatus: updateContractStatusAPI,
    refetch: fetchOfferData,
    uploadDocument,
  } = useUpdateOffers()

  const updateTransactionAPICall = async (params) => {
    const toastId = toast.loading('Updating transaction details...');
    await updateOffer(params, true)
      .then((response) => {
        setIsUpdating(false)
        toast.dismiss(toastId);
        toast.success('Transaction updated successfully');
      })
      .catch((error) => {
        setIsUpdating(false)
        fetchTransaction()
        toast.dismiss(toastId);
        console.error("Transaction couldn't be updated");
      })
  }

  const deleteTransactionAPICall = async (params) => {
    const toastId = toast.loading('Deleting transaction details...');
    await deleteOffer(params)
      .then(() => {
        setIsUpdating(false)
        toast.dismiss(toastId);
        toast.success('Transaction deleted successfully');
        setIsDeleteModal(false)
        closeContractDetailDrawer?.()
      })
      .catch(() => {
        setIsUpdating(false)
        fetchTransaction()
        toast.dismiss(toastId);
        console.error("Transaction couldn't be deleted");
      })
  }

  const handleChange = (value) => {
    setSelectedOfferStatus(value)
    setIsUpdating(true)
    const updateStatusParams = {
      status: value,
      _id: transactionData?._id,
      inventory: transactionData?.inventory?._id,
    }

    updateTransactionAPICall(updateStatusParams)
  }

  const updateOfferObject = useCallback(
    (key, value) => {
      updateOffers({
        _id: transactionData?._id,
        [key]: value,
      }).then((data) => {
        fetchTransaction(transactionData?._id)
      })
      // .finally(() => {
      //   setLoading(false)
      // })
    },
    [transactionData]
  )

  const onDocumentUploaded = useCallback(
    (type, data) => {
      if (transactionData) {
        updateOfferObject("signedContracts", [
          ...transactionData?.signedContracts,
          data?._id,
        ])
      }
    },
    [transactionData]
  )

  const onDocumentDeleteClicked = useCallback(
    async (id) => {
      if (transactionData) {
        const toastId = toast.loading("Deleting Document...")
        try {
          await deletePrivateDocs(id)
          updateOfferObject("signedContracts", [
            ...transactionData.signedContracts.filter((item) => {
              return item?._id !== id
            }),
          ])
          toast.success("Document deleted successfully")
          setSelectedContractId(null)
        } catch (e) {
        } finally {
          toast.dismiss(toastId)
        }
      }
    },
    [transactionData]
  )

  const handleDepositOptionsInputChange = (index, field, value) => {
    setDeposits((prevDeposits) => {
      const updatedDeposits = [...prevDeposits]
      const extraField = {}
      updatedDeposits[index] = {
        ...updatedDeposits[index],
        [field]: value,
      }
      return updatedDeposits
    })
  }

  const handleUpdateDepositOptions = () => {
    setIsUpdating(true)
    const updateParams = {
      _id: transactionData?._id,
      deposit: {
        ...transactionData?.deposit,
        installments: deposits
      }
    }

    updateTransactionAPICall(updateParams)
  }

  const handleSubjectsInputChange = (index, field, value) => {
    const updatedSubjects = [...subjects]
    updatedSubjects[index] = { ...updatedSubjects[index], [field]: value }
    setSubjects(updatedSubjects)
  }

  const addSubject = () => {
    setSubjects([...subjects, { description: "", type: "", dueDate: "" }])
  }

  const deleteSubject = (index) => {
    setSubjects((prevSubjects) => prevSubjects.filter((_, i) => i !== index))
  }

  const handleUpdateSubjects = () => {
    setIsUpdating(true)
    const updateParams = {
      _id: transactionData?._id,
      subjects
    }

    updateTransactionAPICall(updateParams)
  }

  const handleDeleteOffer = () => {
    setIsUpdating(true)
    const updateParams = {
      _id: transactionData?._id,
    }
    deleteTransactionAPICall(updateParams)
  }

  const currentProjectName = useMemo(() => {
    return listOfProjects?.filter((project) => (project?._id === projectId))?.[0]?.projectName || "-"
  }, [listOfProjects, projectId])


  return (
    <>
    <div>
      <div className="mb-6 flex flex-col">
      <div className='flex justify-between items-center gap-2'>
        <div className="flex-1">
          <div className="text-2xl font-semibold">Transaction Overview</div>
          <p className="text-sm text-muted-foreground">
            Inventory-{transactionData?.inventory?.[transactionData?.inventory?.type + 'Data']?.number} -{" "}
            {transactionData?.buyer[0]?.firstName}{" "}
            {transactionData?.buyer[0]?.lastName}
          </p>
        </div>
        <button
              onClick={() => { setIsDeleteModal(true) }}
              className="bg-red-500 text-white px-4 py-2 rounded-lg font-semibold"
            >
              Delete transaction
            </button>
        </div>
        <div className="flex justify-between items-center gap-2">
          <div
            className={`p-2 px-4 text-center flex justify-center items-center text-white rounded-full ${offerStatus[selectedOfferStatus].backgroundColor}`}
          >
              {!isUpdating ? offerStatus[selectedOfferStatus].text : 'Updating...'}
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex gap-[2px] text-zinc-400">Change Status</div>
            <CustomSelect
              value={selectedOfferStatus}
              handleChange={(value) => handleChange(value)}
              options={OFFER_STATUS_OPTIONS}
              placeholder="Select Offer Status"
              className="min-w-[250px]"
              disabled={isUpdating}
            />
          </div>
        </div>
      </div>

      <div className="grid gap-6">
        <Card>
          <CardHeader>
            <CardTitle>Basic Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Inventory Number</label>
                  <p className="text-sm text-muted-foreground">
                    {transactionData?.inventory?.[transactionData?.inventory?.type + 'Data']?.number}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Offer Date</label>
                  <p className="text-sm text-muted-foreground">
                    {moment(transactionData?.createdAt).format("LL")}
                  </p>
                </div>
                <div>
                  <label className="text-sm font-medium">Home Design</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Sale Price</label>
                  <p className="text-sm text-muted-foreground">
                    {/* {transactionData?.unit?.priceCurrencyType}{" "} */}
                    {transactionData?.inventory?.[transactionData?.inventory?.type + 'Data']?.price}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Project</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Offer Version</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Buyer Information</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <label className="text-sm font-medium">Buyer 1</label>
                <p className="text-sm text-muted-foreground">
                  {transactionData?.buyer[0]?.firstName}{" "}
                  {transactionData?.buyer[0]?.lastName}
                </p>
              </div>
              <div>
                <label className="text-sm font-medium">Buyer 2</label>
                {transactionData?.buyer?.length >= 1 ? (
                  <p className="text-sm text-muted-foreground">
                    {transactionData?.buyer[1]?.firstName}{" "}
                    {transactionData?.buyer[1]?.lastName}
                  </p>
                ) : (
                  <p className="text-sm text-muted-foreground">-</p>
                )}
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Realtor Details</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Realtor Name</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Email</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Move-in Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Brokerage</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Phone</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    Commission Rate 2
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Key Dates</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid gap-6 md:grid-cols-2">
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Offer Date</label>
                  <p className="text-sm text-muted-foreground">
                    {moment(transactionData?.createdAt).format("LL")}
                  </p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Firm Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">Completion Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
              <div>
                <div className="mb-4">
                  <label className="text-sm font-medium">Accepted Date</label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div className="mb-4">
                  <label className="text-sm font-medium">
                    Estimated Completion
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
                <div>
                  <label className="text-sm font-medium">
                    Not set Possession Date
                  </label>
                  <p className="text-sm text-muted-foreground">-</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* <Card>
          <CardHeader>
            <CardTitle>Subjects</CardTitle>
          </CardHeader>
          <CardContent>
            {transactionData?.subjects.length > 0 ? (
              <div className="rounded-lg border">
                <div className="flex items-center justify-between p-4">
                  <div className="space-y-1">
                    <div className="text-sm font-medium">
                      {transactionData?.subjects[0]?.subject_type}
                    </div>
                    <div className="text-sm text-muted-foreground">
                      {transactionData?.subjects[0]?.description}
                    </div>
                    <Badge variant="outline" className="text-xs">
                      {transactionData?.subjects[0]?.status}
                    </Badge>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="text-sm text-muted-foreground">
                      {moment(transactionData?.subjects[0]?.created_at).format(
                        "LL"
                      )}
                    </div>
                    <Button variant="ghost" size="icon" className="h-8 w-8">
                    <Trash2 className="h-4 w-4" />
                    <span className="sr-only">Delete financing</span>
                  </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-zinc-500 text-base">No subjects added</div>
            )}
          </CardContent>
        </Card> */}
    <Card className="bg-white">
            <CardHeader className="flex flex-col">
              <div className="flex justify-between">
                <CardTitle className="">Subjects</CardTitle>
                <div className="flex items-center gap-4">
                  <Button variant="outline" onClick={addSubject} disabled={isUpdating}>
                    <Plus className="mr-2 h-4 w-4" />
                    Add Subject
                  </Button>
                  <Button disabled={isUpdating} onClick={handleUpdateSubjects}>
                    Update
                  </Button>
                </div>
              </div>
            </CardHeader>
            <CardContent className="space-y-4">
              {
                subjects?.length > 0
                  ? <>
                    {subjects.map((subject, index) => (
                      <div
                        key={index}
                        className={`flex flex-col gap-3 ${subjects.length - 1 === index ? "" : "border-b pb-4"
                          }`}
                      >
                        <div className="w-full flex items-center justify-end">
                          <Button
                            variant="ghost"
                            size="icon"
                            className=" h-6 w-6 text-muted-foreground hover:text-foreground"
                            onClick={() => deleteSubject(index)}
                            disabled={isUpdating}
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>

                        <div className="grid gap-4 md:grid-cols-2">
                          <div className="space-y-2 flex flex-col gap-1">
                            <div className="text-sm font-medium " htmlFor="type">
                              Type
                            </div>
                            <CustomSelect
                              value={subject.type}
                              handleChange={(value) => {
                                const updatedSubjects = [...subjects]
                                updatedSubjects[index] = {
                                  ...updatedSubjects[index],
                                  type: value,
                                }
                                setSubjects(updatedSubjects)
                              }}
                              options={SUBJECT_TYPE}
                              placeholder="Select Subject Type"
                              disabled={isUpdating}
                            />
                          </div>
                          <div className="space-y-2 flex flex-col gap-1">
                            <div className="text-sm font-medium" htmlFor="due-date">
                              Due Date
                            </div>
                            <CustomInput
                              containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                              value={moment(subjects[index].dueDate).format(
                                "YYYY-MM-DD"
                              )} // Access the correct subject's date
                              type="date"
                              onChange={(e) => {
                                const updatedSubjects = [...subjects]
                                const date = moment(e.target.value, "YYYY-MM-DD")
                                  .startOf("day")
                                  .toISOString()
                                updatedSubjects[index] = {
                                  ...updatedSubjects[index],
                                  dueDate: date,
                                }
                                setSubjects(updatedSubjects) // Update the state for the correct index
                              }}
                              placeholder="Enter Purchase Date"
                              min={moment("1970-01-01", "YYYY-MM-DD").format(
                                "YYYY-MM-DD"
                              )}
                              disabled={isUpdating}
                            />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div
                            className="text-sm font-medium text-destructive"
                            htmlFor="description"
                          >
                            Description
                          </div>
                          <div className="relative flex gap-2">
                            <Textarea
                              id={`description-${index}`}
                              placeholder="Enter subject description"
                              className="min-h-[100px] resize-none border p-2 rounded"
                              value={subject.description}
                              onChange={(e) =>
                                handleSubjectsInputChange(
                                  index,
                                  "description",
                                  e.target.value
                                )
                              }
                              disabled={isUpdating}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                  : <div className="text-zinc-500 text-base text-center">No subjects found, add new one</div>
              }

            </CardContent>
          </Card>

          {
            deposits?.length > 0 &&
            <Card className="bg-white">
              <CardHeader className="flex flex-row items-center justify-between space-y-0">
                <CardTitle className="mb-0">Deposits</CardTitle>
                <Button disabled={isUpdating} onClick={handleUpdateDepositOptions}>
                  Update
                </Button>
              </CardHeader>
              <CardContent className="space-y-4">
                {deposits.map((deposit, index) => (
                  <div key={index} className="relative flex items-center border rounded-md p-3 overflow-hidden">
                    <div className="absolute top-0 left-0 text-xs bg-black text-white px-1 rounded-br-md">
                      {index + 1}
                    </div>
                    <div className="flex flex-col gap-4 flex-1">
                      <div className="grid gap-4 md:grid-cols-2">
                        <div className="space-y-2">
                          <label
                            htmlFor={`name-${index}`}
                            className="text-sm font-medium"
                          >
                            Name
                          </label>
                          <Input
                            id={`name-${index}`}
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Name"
                            value={deposit.name || ""}
                            onChange={(e) =>
                              handleDepositOptionsInputChange(index, "name", e.target.value)
                            }
                            disabled={isUpdating}
                          />
                        </div>
                        <div className="space-y-2">
                          <label
                            htmlFor={`value-${index}`}
                            className="text-sm font-medium"
                          >
                            Amount
                          </label>
                          <Input
                            id={`value-${index}`}
                            type="number"
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Amount"
                            value={deposit.value || ""}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="grid gap-4 md:grid-cols-3">
                        <div className="space-y-2">
                          <label
                            htmlFor={`percentage-${index}`}
                            className="text-sm font-medium"
                          >
                            Percentage
                          </label>
                          <Input
                            id={`percentage-${index}`}
                            type="number"
                            className="border rounded px-3 h-[40px] !py-[14px] w-full"
                            placeholder="Enter Percentage"
                            max={100}
                            value={
                              deposit.percentage !== undefined &&
                                deposit.percentage !== null
                                ? deposit.percentage
                                : 0
                            }
                            disabled
                          />
                        </div>
                        <div className="space-y-2">
                          <label
                            htmlFor={`payment-method-${index}`}
                            className="text-sm font-medium"
                          >
                            Payment Method
                          </label>
                          <CustomSelect
                            value={deposit.paymentMethodType || ""}
                            handleChange={(value) =>
                              handleDepositOptionsInputChange(
                                index,
                                "paymentMethodType",
                                value
                              )
                            }
                            options={PAYMENT_METHOD}
                            placeholder="Select Payment Method"
                            disabled={isUpdating}
                          />
                        </div>
                        <div className="space-y-2">
                          <label
                            htmlFor={`due-date-${index}`}
                            className="text-sm font-medium"
                          >
                            Due Date
                          </label>
                          <CustomInput
                            containerClasses="w-full border border-[#18181B] rounded overflow-hidden"
                            value={
                              deposit.dueDate
                                ? moment(deposit.dueDate).format("YYYY-MM-DD")
                                : ""
                            }
                            type="date"
                            onChange={(e) => {
                              const date = moment(e.target.value, "YYYY-MM-DD")
                                .startOf("day")
                                .toISOString()
                              handleDepositOptionsInputChange(index, "dueDate", date)
                            }}
                            placeholder="Enter Due Date"
                            min="1970-01-01"
                            disabled={isUpdating}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </CardContent>
            </Card>
          }
        <Card>
          <CardHeader>
            <CardTitle>Documents</CardTitle>
          </CardHeader>
          <CardContent>
            {/* <div className="mb-6">
              <h3 className="mb-4 text-sm font-medium">ID Documents</h3>
              <div className="grid gap-4 md:grid-cols-2">
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Primary ID</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Secondary ID</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col gap-4">
            {contractType.map(({ title, id }) => {
                  return (
                    <DocumentUpload
                      offerData={transactionData}
                      projectId={projectId}
                      title={title}
                      onUploadDocument={uploadDocument}
                      type={id}
                      key={id}
                      onUploadDocumentDone={onDocumentUploaded}
                      onDocumentDeleteClicked={setSelectedContractId}
                    />
                  )
                })}
              {/* <h3 className="mb-4 text-sm font-medium">Contract Documents</h3> */}
              {/* <div className="grid gap-4 md:grid-cols-3">
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Purchase Agreement</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Disclosure Statement</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
                <div>
                  <div className="mb-2 flex items-center justify-between">
                    <span className="text-sm">Addendums</span>
                    <Badge variant="outline">pending</Badge>
                  </div>
                  <Button variant="outline" className="w-full">
                    <Upload className="mr-2 h-4 w-4" />
                    Upload
                  </Button>
                </div>
              </div> */}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
    <ConfirmDeleteModal
        onClose={() => setSelectedContractId(null)}
        onSuccess={onDocumentDeleteClicked}
        show={selectedContractId}
        deleteId={selectedContractId}
      />
      <DeleteModal
        title='Confirm deletion?'
        description={`Are you sure? do you want to delete transaction?`}
        confirmButtonTitle='Yes, delete it'
        cancelButtonTitle='No'
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={handleDeleteOffer}
        loading={loading}
      />
      </>
  )
}

export default TransactionOverviewTab
