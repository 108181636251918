/* eslint-disable */
// @ts-nocheck
import FilterBySearch from 'pages/contacts/FilterBySearch'
import React, { useMemo } from 'react'
import { isMobile } from 'react-device-detect'
import { TButton } from 'ui'
import { PlusIcon } from '@heroicons/react/solid'
import moment from 'moment'
import { ReactComponent as LinkIcon } from '../../../assets/icons/icn_link.svg'
import CustomButton from 'pages/contacts/CustomButton'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'
import StartExternalProviderConfiguration from 'components/StartExternalProviderConfiguration'
import { Drawer } from 'antd'
import SelectedMailSection from './SelectedMailSection'
import { Badge } from 'components/ShadcnComponents/badge'

const EmailDate = ({ date }) => {
  const today = new Date()
  const emailDate = new Date(date * 1000)
  const isToday = today.toDateString() === emailDate.toDateString()
  const showYear = today.getFullYear() !== emailDate.getFullYear()
  const result = isToday
    ? moment(emailDate).format('LT')
    : !showYear
    ? moment(emailDate).format('DD MMM, hh:mm a')
    : moment(emailDate).format('DD MMM YY, hh:mm a')
  return result
}

export const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab, gettingListOfEmails }) => (
  <div className='w-[350px] flex items-center md:justify-center rounded-lg bg-white p-1.5 text-zinc-600'>
    {tabMenus?.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          if (gettingListOfEmails) return
          setSelectedTab(tab.id)
        }}
        style={{
          opacity: gettingListOfEmails ? 0.7 : 1
        }}
        className={`flex flex-1 w-full min-w-[110px] md:w-fit md:min-w-[160px] max-w-[180px] text-center justify-center items-center just px-4 py-[8px] cursor-pointer rounded-[4px] font-semibold text-[14px] ${
          selectedTab === tab.id
            ? 'bg-[#18181B] text-white shadow-sm'
            : 'text-zinc-600 hover:bg-zinc-100'
        } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
)

const EmailMessagesList = (childProps) => {
  const {
    showFilterOption = true,
    showLoadMore = true,
    gettingListOfEmails,
    setShowMessageList,
    refLocalListOfEmails,
    selectedThreadId,
    setSelectedEmailIndex,
    searchFilter,
    setSearchFilter,
    getThreads,
    getEmails,
    refFilterBySearch,
    setShowEmailBox,
    setSelectedEmail,
    activeTab,
    tabMenus,
    setActiveTab,
    providerToken
  } = childProps
  const EmailAttachmentBadge = ({ file }) => {
    return (
      <div
        key={file.id}
        className='w-fit rounded-full bg-[#2e2b2e0d] px-2.5 py-0.5 flex items-center gap-x-1'
      >
        <LinkIcon className='h-4 w-4' />
        <p className='text-xs font-normal m-0'>
          {file.filename?.length < 10
            ? file.filename
            : file.filename.slice(0, 9) + '...'}
        </p>
      </div>
    )
  }

  const EmailAttachmentBadgesWrapper = ({ files, isMore }) => {
    return (
      <div className='flex flex-wrap gap-1'>
        {files.map((file) => (
          <EmailAttachmentBadge file={file} />
        ))}
        {isMore && <>more</>}
      </div>
    )
  }

  const unreadCount = useMemo(() => {
    let result = 0
    refLocalListOfEmails.current.forEach((elm) => {
      result += elm.messages.filter((message) => message.unread)?.length
    })
    return result
  }, [refLocalListOfEmails.current])

  const handleEmailClick = (email, index) => {
    setShowMessageList(false)
    setSelectedEmailIndex(index)
    setSelectedEmail(
      email.messages.map((elm) => ({
        cc: elm?.cc || [],
        from: elm?.from || [],
        to: elm?.to || []
      }))
    )
  }

  const handleCreateEmailClick = () => {
    // Track start email creation event
    mixpanel.track(MIXPANEL_EVENTS.EMAIL_CREATE, {
      emailMode: 'create'
    })

    setShowEmailBox(true)
  }

  return (
    <div
      className={`flex flex-col flex-shrink-0 h-full ${
        isMobile ? 'w-full' : 'w-full'
      }`}
    >
      <TabWrapper
        tabMenus={tabMenus}
        selectedTab={activeTab}
        setSelectedTab={setActiveTab}
        gettingListOfEmails={gettingListOfEmails}
      />
      {!providerToken && refLocalListOfEmails.current?.length === 0 ? (
        <div className='mt-40'>
          <StartExternalProviderConfiguration pageName='Inbox' />
        </div>
      ) : (
        <div className='flex flex-shrink-0 h-full'>
          <div
            className={`h-full sticky top-0 flex flex-col ${
              isMobile ? 'w-full' : 'w-[350px]'
            } border-r border-gray-200`}
          >
            <div className='bg-white p-4 flex flex-col justify-center border-b'>
              <div className='flex flex-col gap-y-4'>
                <div className='flex flex-col gap-y-2'>
                  <h2 className='text-xl font-medium text-[#2E2B2E] m-0'>
                    Inbox
                  </h2>
                  <p className='text-sm font-normal text-softBlack_70/50 m-0'>
                    {refLocalListOfEmails.current?.length} Message
                    {refLocalListOfEmails.current?.length > 0 && 's'},{' '}
                    {unreadCount} Unread{unreadCount > 0 && 's'}
                  </p>
                </div>

                {activeTab === 'personal' && (
                  <CustomButton handleClick={handleCreateEmailClick}>
                    <span className='font-semibold px-4 py-2 rounded text-base flex items-center gap-2'>
                      <PlusIcon className='h-[22px] w-[22px]' />
                      Compose new email
                    </span>
                  </CustomButton>
                )}
                {/* {isMobile && (
              <TButton onClick={() => setShowMessageList(false)}>
                Back
              </TButton>
            )} */}
              </div>
              {showFilterOption && (
                <FilterBySearch
                  searchFilter={searchFilter}
                  setSearchFilter={setSearchFilter}
                  label={'Search Email'}
                  loading={gettingListOfEmails}
                  ref={refFilterBySearch}
                />
              )}
            </div>

            <nav
              id='listOfEmails'
              aria-label='Message list'
              className='min-h-0 overflow-y-scroll thin-scrollbar w-full h-screen'
            >
              <div className='max-w-full m-0 flex flex-col gap-2 p-3 bg-white'>
                {refLocalListOfEmails.current.map((email, index) => (
                  <div
                    key={email.messages?.[0]?.threadId ?? index}
                    onClick={() => handleEmailClick(email, index)}
                    className={`flex flex-col items-start gap-2 rounded-lg border p-3 cursor-pointer text-left text-sm transition-all ${
                      isMobile
                        ? 'bg-white'
                        : email.messages[0].threadId === selectedThreadId
                        ? 'bg-[#F4F4F4]'
                        : 'bg-white hover:bg-[#F4F4F4]'
                    }`}
                  >
                    <div className='flex w-full flex-col gap-1'>
                      {email.salesRep && (
                        <Badge
                          className='w-fit text-white'
                          variant='black'
                        >
                          {email?.salesRep?.firstName + ' ' + email?.salesRep?.lastName}
                        </Badge>
                      )}
                      <div className='w-full flex justify-between items-center gap-x-2 overflow-x-hidden'>

                        <h4 className='flex-1 overflow-x-hidden text-base font-medium text-[#2E2B2E] m-0 line-clamp-2'>
                         {email?.subject?.length ? email.subject : 'Untitled'}
                        </h4>
                        <time
                          dateTime={email.last_message_timestamp}
                          className='whitespace-nowrap text-xs font-normal text-softBlack_70/50'
                        >
                          {EmailDate({ date: email.lastMessageTimestamp })}
                        </time>
                      </div>
                      <p className='text-sm font-normal text-softBlack_70/50 m-0'>
                        {`From: ${email.messages[0].from[0].email}`}
                      </p>
                    </div>
                    {email?.snippet?.length && (
                      <p className='line-clamp-2 text-xs text-softBlack_70 m-0'>
                        {email.snippet?.length < 100
                          ? email.snippet
                          : email.snippet.slice(0, 100) + '...'}
                      </p>
                    )}
                    {email.messages[0]?.files?.length > 0 && (
                      <EmailAttachmentBadgesWrapper
                        files={
                          email.messages[0].files?.length > 3
                            ? email.messages[0].files.slice(0, 3)
                            : email.messages[0].files
                        }
                        isMore={email.messages[0].files?.length > 3}
                      />
                    )}
                  </div>
                ))}
              </div>
            </nav>
            {!searchFilter?.length && showLoadMore && (
              <div className='flex w-full'>
                <div className='flex w-full items-center justify-center content-center p-2 bg-white'>
                  <TButton
                    className='overflow-hidden'
                    loading={gettingListOfEmails}
                    onClick={() => {
                      if (showFilterOption) {
                        refFilterBySearch.current?.clearQuery?.()
                      }
                      if (getThreads) {
                        getThreads(false, true)
                      } else {
                        getEmails()
                      }
                    }}
                  >
                    Load More
                  </TButton>
                </div>
              </div>
            )}
          </div>
          {isMobile && (
            <Drawer
              open={!childProps.showMessageList}
              variant='persistent'
              anchor='right'
              classes={{
                paper: classes.drawerPaper
              }}
              // className='z-[15] flex-col justify-start items-start gap-4'
            >
              <SelectedMailSection {...childProps} showUserInfo />
            </Drawer>
          )}
          {!childProps.isMobile && (
            <SelectedMailSection {...childProps} showUserInfo />
          )}
        </div>
      )}
    </div>
  )
}

export default EmailMessagesList
