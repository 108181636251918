/* eslint-disable space-before-function-paren */
import React, { useCallback, useEffect, useRef } from 'react'
import 'react-form-builder2/dist/app.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import CustomButton from 'components/CustomButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useCustomForms from 'hooks/useCustomForms'
import { ArrowLeft } from 'lucide-react'
import PublicForm from '../publicForm'
import TableGrid from 'components/TableGrid/Table'
import { SUPPORTED_CUSTOM_FIELDS } from '../edit/components/setting'
import FormActionButtons from '../formActionButtons'
import FormSettingsDrawer from '../formSettings'
import { api } from 'services/api'

// eslint-disable-next-line space-before-function-paren
export default function FormAnalytics() {
  const { getCustomForm, getCustomFormSubmissions, updateCustomForm } = useCustomForms()
  const [deletingSubmission, setDeletingSubmission] = React.useState({})
  // get formId from query params using react-router-dom
  const history = useHistory()
  const formId = new URLSearchParams(history.location.search).get('formId')
  const [form, setForm] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  const [editing, setEditing] = React.useState(false)
  const [formName, setFormName] = React.useState('')
  const [updating, setUpdating] = React.useState(false)
  const [refreshCount, setRefreshCount] = React.useState(0)
  const [downloading, setDownloading] = React.useState(false)

  const tableRef = useRef(null)
  const [submissions, setSubmissions] = React.useState([])

  const [analytics, setAnalytics] = React.useState({
    totalSubmissions: 0,
    totalCustomers: 0,
    totalViews: 0
  })

  const getFormData = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (formId) {
        setLoading(true)
        getCustomForm(formId).then((response) => {
          resolve(response.data)
          setFormName(response?.data?.name)
        }).finally(() => {
          setLoading(false)
        })
      } else {
        resolve(null)
      }
    })
  }, [formId, getCustomForm])

  useEffect(() => {
    getFormData().then((response) => {
      setForm(response)
    })
  }, [getFormData, formId])

  function updateFormName(formName) {
    if (updating) { return }
    setUpdating(true)
    updateCustomForm(formId, { name: formName }).then((response) => {
      setFormName(formName)
      setForm(response)
    }).finally(() => {
      setEditing(false)
      setUpdating(false)
    })
  }

  const getFormSubmissions = useCallback(() => {
    getCustomFormSubmissions(formId).then((response) => {
      const submissions = response ?? []
      const totalSubmissions = submissions.length
      const totalCustomers = new Set(submissions.map((submission) => submission.customerData[SUPPORTED_CUSTOM_FIELDS.EMAIL.key])).size
      const totalViews = 0
      setSubmissions(submissions.sort(
        (a, b) => new Date(b.createdAt)?.getTime() - new Date(a.createdAt)?.getTime()
      ))
      setAnalytics({
        totalSubmissions,
        totalCustomers,
        totalViews
      })
    }).finally(() => { })
  }, [formId, getCustomFormSubmissions])

  useEffect(() => {
    getFormSubmissions()
  }, [getFormSubmissions, refreshCount])

  const handleSuccessfulSubmit = useCallback(() => {
    reloadSubmissions()
  }, [reloadSubmissions])

  function reloadSubmissions() {
    setRefreshCount(refreshCount + 1)
  }

  if (loading) {
    return <div>Loading...</div>
  }
  const cards = [{
    title: 'Total Contacts',
    value: analytics.totalCustomers
  }, {
    title: 'Total Submissions',
    value: analytics.totalSubmissions
  }, {
    title: 'Total Views',
    value: analytics.totalViews
  }]

  const submissionsFieldKeys = submissions?.reduce((acc, submission) => {
    const keys = Object.keys(submission.customerData)
    let final = [...acc, ...keys]
    // remove duplicates
    final = final.filter((item, index) => final.indexOf(item) === index)
    return final
  }, [])

  const downloadAsCsv = () => {
    setDownloading(true)
    const headers = ['User', ...submissionsFieldKeys, 'Timestamp']
    const csv = submissions.map((row) => {
      const values = headers.map((header) => {
        let value = ''
        if (header === 'User') {
          value = row.user
        } else if (header === 'Timestamp') {
          value = new Date(row.createdAt).toLocaleString().replace(/,/g, ' ')
        } else {
          value = row.customerData[header] || ''
          // make sure it csv safe
          value = String(value).replace(/"/g, '""')
          if (value.includes(',')) {
            value = `"${value}"`
          }
        }
        return value
      })
      return values.join(',')
    }).join('\n')
    const blob = new Blob([headers.join(',') + '\n' + csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = `${formName}-submissions.csv`
    a.click()
    setTimeout(() => {
      setDownloading(false)
    }, 2000)
  }

  function deleteSubmission(submissionId) {
    // delete submission
    setDeletingSubmission({
      ...deletingSubmission,
      [submissionId]: true
    })
    api.delete(`/customforms/${formId}/submissions/${submissionId}`).then((response) => {
      reloadSubmissions()
    }).finally(() => {
      setDeletingSubmission({
        ...deletingSubmission,
        [submissionId]: false
      })
    })
  }

  return (
    <div className='py-2 flex flex-col h-full'>
      <div className='flex items-center justify-between w-full px-4'>
        <div className='flex items-center justify-center gap-x-2'>
          <div
            className='flex items-center justify-center cursor-pointer'
            onClick={() => {
              history.goBack()
            }}
          >
            <ArrowLeft size={12} /> <span>
              Go back
            </span>
          </div>
          <h1 className='text-2xl font-bold'>
            {editing && (
              <div className='flex items-center justify-center gap-x-2'>
                <input type='text' className='min-w-[300px]' value={formName}
                  disabled={updating}
                  onChange={(e) => {
                    if (updating) return
                    setFormName(e.target.value)
                  }} />
                <button
                  className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                  disabled={updating}
                  onClick={() => {
                    updateFormName(formName)
                  }}
                >
                  Update
                </button>
                <button
                  className='py-0.5 px-1.5 text-sm bg-red-500 text-white rounded-md'
                  disabled={updating}
                  onClick={() => {
                    setFormName(form?.name)
                    setEditing(false)
                  }}
                >
                  cancel
                </button>
              </div>
            )}
            {!editing && <div className='flex items-center justify-center gap-x-2'>
              <div>
                {formName}
              </div>
              <div>
                <button
                  className='py-0.5 px-1.5 text-sm bg-blue-500 text-white rounded-md'
                  onClick={() => {
                    setEditing(true)
                  }}>Edit</button>
              </div>
            </div>}
          </h1>
        </div>
        <div className='flex items-center gap-x-2'>
          <FormActionButtons form={form} />
          <CustomButton
            className='py-1 px-2'
            handleClick={() => {
              history.push(`/admin/forms/edit?formId=${formId}`)
            }}
          >
            Edit Form
          </CustomButton>
          <FormSettingsDrawer formId={formId} onClose={() => { }} />
        </div>
      </div>
      <div className='border border-border h-[1px] bg-black w-full mt-1 flex'>
      </div>
      <div className='flex flex-1'>
        <div className='h-full w-full flex gap-2'>
          <div className='h-full w-[35%]'>
            <PublicForm
              key={form?.name || 'public-form'}
              formId={formId}
              onSuccessfulSubmit={handleSuccessfulSubmit}
            />
          </div>
          <div className='flex-1 h-full overscroll-y-auto py-1.5 px-2'>
            <div className='grid grid-cols-3 gap-x-4 py-2'>
              {cards.map((card, index) => (
                <div key={index} className='flex flex-col gap-y-1 px-4 py-4 items-center justify-center bg-gray-200 rounder border border-border'>
                  <div className='text-3xl font-bold'>{card.value}</div>
                  <div className='text-sm text-gray-500'>{card.title}</div>
                </div>
              ))}
            </div>
            <div>
              <div className='flex justify-end'>
                <CustomButton
                  className='py-1 px-3'
                  handleClick={() => {
                    if (downloading) return
                    downloadAsCsv()
                  }}>
                  {downloading ? 'Downloading...' : 'Download CSV'}
                </CustomButton>
                <CustomButton
                  className='py-1 px-3'
                  handleClick={() => {
                    reloadSubmissions()
                  }}>
                  Reload
                </CustomButton>
              </div>
              <TableGrid
                style={{ height: '470px', minHeight: '470px' }}
                rowSelectKey='_id'
                columnDefs={[
                  {
                    headerName: '#',
                    field: '_id',
                    width: 80,
                    sortable: false,
                    cellRenderer: (params) => {
                      const userId = params.data?.user
                      if (!userId) {
                        return ''
                      }
                      return <div className='flex items-center gap-x-2'>
                        <button
                          className='py-0.5 px-1.5 text-sm bg-red-500 text-white rounded-md'
                          onClick={() => {
                            if (deletingSubmission[params.data?._id]) return
                            const confirmDelete = window.confirm('Are you sure you want to delete this submission? \n This action cannot be undone.')
                            if (confirmDelete) {
                              deleteSubmission(params.data?._id)
                            }
                          }
                          }
                        >
                          {deletingSubmission[params.data?._id] ? 'Deleting...' : 'Delete'}
                        </button>
                      </div>
                    }
                  },
                  {
                    headerName: 'User',
                    field: 'user',
                    width: 100,
                    sortable: false,
                    cellRenderer: (params) => {
                      const userId = params.data?.user
                      if (!userId) {
                        return ''
                      }
                      return <div className='flex items-center gap-x-2'>
                        <a href={`/admin/contacts?_id=${userId}&tab=activity`} target='_blank' rel='noreferrer'>
                          View User
                        </a>
                      </div>
                    }
                  },
                  ...submissionsFieldKeys.map((key) => {
                    return {
                      headerName: key,
                      field: `customerData.${key}`,
                      sortable: false,
                      minWidth: 200,
                      resizable: true
                    }
                  }),
                  {
                    headerName: 'Timestamp',
                    field: 'createdAt',
                    sortable: false,
                    cellRenderer: (params) => {
                      return <div>
                        {new Date(params.data?.createdAt).toLocaleString()}
                      </div>
                    }
                  }
                ]}
                getData={async (
                  filter,
                  pagination,
                  sorting
                ) => {
                  // eslint-disable-next-line no-async-promise-executor
                  return new Promise(async (resolve) => {
                    resolve(submissions)
                  })
                }} >
                {(params) => {
                  tableRef.current = params
                }}
              </TableGrid>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}
