// @ts-nocheck
/* eslint-disable */
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getInventories, getActiveInventoryStatuses } from 'store/actions/inventoryActions'
import { checkV2Inventory } from 'utils/checkV2Inventory'

const useGetInventories = (id = '') => {
  const [inventories, setInventories] = useState([])
  const [loading, setLoading] = useState(true)
  const [statusColorMap, setStatusColorMap] = useState({})
  const projectId = useSelector((state) => state.appReducer.appProject);
  const fetchStatusColors = async () => {
    try {
      const statuses = await getActiveInventoryStatuses(projectId)
      return statuses.reduce((acc, status) => {
        acc[status.name.toLowerCase()] = status.color || '#808080'
        return acc
      }, {})
    } catch (error) {
      console.error('Failed to fetch status colors:', error)
      return {}
    }
  }

  const fetchInventories = async () => {
    setLoading(true)
    try {
      let colorMap = {}
      if (checkV2Inventory()) {
        colorMap = await fetchStatusColors()
      }
      const data = await getInventories(id)
      const processedInventories = data.inventory.map(inventory => ({
        ...inventory,
        statusColor: colorMap[inventory[inventory?.type + 'Data']?.status?.toLowerCase()] || '#808080'
      }))
      setInventories({
        inventory: processedInventories
      })
      setStatusColorMap(colorMap)
    } catch (error) {
      console.error('Error fetching inventories:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      fetchInventories()
    }
  }, [id])

  const refetch = () => {
    fetchInventories()
  }

  return { inventories, loading, refetch, statusColorMap }
}

export default useGetInventories
