/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable */
// @ts-nocheck
import React, { memo, useEffect, useState } from 'react';

// import SelectContract from 'components/SelectContract'
import standardStyle from 'assets/css/standardStyle';

import CustomInput from 'components/CustomInput';
import CustomNumberInput from 'components/CustomNumberInput';
import CustomTextArea from 'components/CustomTextArea';
import { ImageUploaderPrimary } from 'components/ImageUploader';

// import { TFilledTrashIcon } from 'components/Icons';

import {
  changeProjectOptionImages,
  deleteProjectOptionImage,
  // createOrUpdateProjectOptionContractDetail,
  deleteProjectOption,
  // deleteProjectOptionContractDetail
} from 'store/actions/projectActions';
import CustomSelect from 'components/CustomSelect';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import { Plus, Trash } from 'lucide-react';
import CustomButton from 'components/CustomButton';
import CustomModal from 'ui/customModal';
import { Oval } from 'react-loader-spinner';

const ImageUpload = ({ index, imgsrc, onDeleteImage }) => {
  return (
    <div className='mt-4'>
      <ImageUploaderPrimary
        imgsrc={imgsrc}
        imgTitle={imgsrc.substring(imgsrc.lastIndexOf('/') + 1)}
        imgFileType={imgsrc
          ?.substring(imgsrc?.lastIndexOf('.') + 1)
          ?.toUpperCase()}
        onDelete={() => onDeleteImage(index)}
      />
    </div>
  );
};

const ConfirmDeleteModal = memo(({
  showModal,
  onCancel,
  onOk,
  isLoading = false,
  message = 'Do you want to delete?'
}) => (
  <CustomModal
    showModal={showModal}
    onCancel={onCancel}
    onOk={onOk}
    minHeight={false}
  >
    <div className='min-w-[95%] md:min-w-[400px] flex flex-col gap-4'>
      <h2 className='text-2xl mb-0'>Are you sure?</h2>
      <h4 className='text-xl mb-2'>{message}</h4>
      <div className='w-full mt-2 flex items-center justify-center md:justify-end gap-3'>
        <CustomButton variant='outline' handleClick={onCancel} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            Cancel
          </span>
        </CustomButton>
        <CustomButton handleClick={onOk} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            {isLoading && <Oval height='18' width='18' color='white' />}
            Yes, delete it.
          </span>
        </CustomButton>
      </div>
    </div>
  </CustomModal>
))

const Option = ({
  values,
  pOptions,
  setPOptions,
  editMode,
  index,
  setProjectData,
  setDeleteHandler,
  projectData,
  type,
  // allContractTemplates,
  errors,
  errorValue,
  setErrors,
  // params,
  // setParams,
  lengthOfOptions,
  // latestContractTemplateId,
  // setLatestContractTemplateId,
  fields,
  sectionName
}) => {
  const [deletedToBeItem, setIsDeletedToBeItem] = useState(null)
  const [deletedToBeOption, setIsDeletedToBeOption] = useState(null)

  const onChange = (field, value) => {
    setPOptions([
      ...pOptions?.map((option, i) =>
        i === index ? { ...option, [field]: value } : option
      ),
    ]);
  };

  const AddImages = async ({ e }) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(e.target.files[0]);
    if (values._id) {
      const imageParams = {
        images: [file],
        optionId: values._id,
        projectId: projectData._id,
      };
      changeProjectOptionImages(imageParams)
        .then((projectData) => {
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    const tmpErrors = [...errors];
    tmpErrors[index].images = false;
    setErrors([...tmpErrors]);
    setPOptions([
      ...pOptions.map((option, i) =>
        i === index
          ? {
            ...option,
            images: [...option.images, file],
            displayImages: [...option.displayImages, url],
          }
          : option
      ),
    ]);
  };

  const onDeleteImage = (imageIndex) => {
    const filter = [...pOptions].filter((value, i) => index === i)[0];
    if (values._id) {
      const imageParams = {
        url: filter.images[imageIndex],
        projectId: projectData._id,
        optionId: values._id,
      };
      deleteProjectOptionImage(imageParams)
        .then((projectData) => {
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    const temp = [
      ...filter.images.slice(0, imageIndex),
      ...filter.images.slice(imageIndex + 1),
    ];
    onChange('images', temp);
  };

  const onDeleteHandler = (index) => {
    const params = { optionId: values._id, projectId: projectData._id };
    if (values._id) {
      deleteProjectOption(params)
        .then((projectData) => {
          setIsDeletedToBeItem(null)
          setProjectData(projectData);
        })
        .catch(() => { });
    }
    setPOptions(pOptions.filter((_, i) => i !== index));
  };

  const onChangeAdditionalFields = (field, value) => {
    setPOptions([
      ...pOptions?.map((option, i) =>
        i === index
          ? {
            ...option,
            additionalFields: {
              ...option.additionalFields,
              [field]: value
            }
          }
          : option
      ),
    ]);
  };

  const onChangeOfAdditionalFieldArray = (field, mode, arrayIndex = null, value = '') => {
    let tempOptions = [...pOptions]

    if (mode === 'addNewOption') {
      tempOptions = [
        ...pOptions?.map((option, i) =>
          i === index
            ? {
              ...option,
              additionalFields: {
                ...option.additionalFields,
                [field]: [...(Array.isArray(option?.additionalFields?.[field]) ? option?.additionalFields?.[field] : []), '']
              }
            }
            : option
        ),
      ]
    } else if (mode === 'removeOption') {
      tempOptions = [
        ...pOptions?.map((option, i) =>
          i === index
            ? {
              ...option,
              additionalFields: {
                ...option.additionalFields,
                [field]: [...(Array.isArray(option?.additionalFields?.[field]) ? option?.additionalFields?.[field] : [])?.filter((_, crrIndex) => (crrIndex !== arrayIndex))]
              }
            }
            : option
        ),
      ]
    } else if (mode === 'updateOption') {
      tempOptions = [
        ...pOptions?.map((option, i) =>
          i === index
            ? {
              ...option,
              additionalFields: {
                ...option.additionalFields,
                [field]: [...(Array.isArray(option?.additionalFields?.[field]) ? option?.additionalFields?.[field] : [])?.map((oldValue, crrIndex) => {
                  if (crrIndex === arrayIndex) {
                    return value
                  }

                  return oldValue
                })]
              }
            }
            : option
        ),
      ]
    }

    setPOptions([...tempOptions])
    setIsDeletedToBeOption(null)
  }

  // const [contractsData, setContractsData] = useState([]);

  // useEffect(() => {
  //   if (allContractTemplates.length > 0 && contractsData.length === 0) {
  //     const contractsData = allContractTemplates.map((contract) => ({
  //       id: contract._id,
  //       value: contract.name,
  //     }));
  //     setContractsData(contractsData);
  //   }
  // }, [allContractTemplates]);

  // const onContractChange = (value) => {
  //   setPOptions([
  //     ...pOptions?.map((option, i) =>
  //       i === index
  //         ? {
  //           ...option,
  //           contractDetail: [
  //             {
  //               contractTemplate: { _id: value }
  //             }
  //           ]
  //         }
  //         : option
  //     ),
  //   ]);
  //   const contractParams = {
  //     _id: latestContractTemplateId[index]
  //       ? latestContractTemplateId[index]
  //       : '',
  //     optionId: values._id,
  //     projectId: projectData._id,
  //     contractTemplate: value,
  //   }

  //   const temp = [];
  //   for (let i = 0; i < lengthOfOptions; i++) {
  //     if (i === index) {
  //       temp.push(contractParams);
  //     } else {
  //       temp.push(params[i] ? params[i] : {});
  //     }
  //   }
  //   setParams(temp);
  // }

  return (
    <div className='mt-10 p-3 rounded-xl border bg-card text-card-foreground shadow'>
      <div className='grid grid-cols-4 gap-6 border-0 p-0 rounded-none shadow-none'>
        {
          fields.filter((field) => (!field?.isAdditional)).map((field, index) => (
            <>
              {
                field.fieldType === 'text' &&
                <CustomInput
                  key={index}
                  label={sectionName + ` ${field.title} *`}
                  labelClassName='text-xs font-openSans font-light'
                  placeholder={'Enter ' + sectionName + ' name'}
                  value={values.name}
                  onChange={(e) => onChange('name', e.target.value)}
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  required='required'
                  error={errorValue?.name}
                  containerClasses='col-span-2'
                />
              }
              {
                field.fieldType === 'number' &&
                <CustomInput
                  key={index}
                  label={`${field.title} *`}
                  labelClassName='text-xs font-openSans font-light'
                  placeholder='Enter value'
                  value={values.value}
                  onChange={(e) => onChange('value', Number(e.target.value))}
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  type='number'
                  required='required'
                  min={0}
                  errorMessage={'This field is required'}
                  error={errorValue?.value}
                  containerClasses='col-span-1'
                />
              }
              {
                field.fieldType === 'count' &&
                <CustomNumberInput
                  key={index}
                  label={`${field.title} *`}
                  labelClassName='text-xs font-openSans font-light'
                  value={values.maxAmountPerUnit}
                  onChange={(e) => {
                    onChange('maxAmountPerUnit', Number(e.target.value));
                  }}
                  required='required'
                  classes='font-openSans text-base'
                  style={{ maxHeight: '48px' }}
                  disabled={!editMode}
                  decrementHandler={() => {
                    if (values.maxAmountPerUnit > 0) {
                      onChange(
                        'maxAmountPerUnit',
                        values.maxAmountPerUnit !== '' &&
                          values.maxAmountPerUnit !== 0
                          ? Number(values.maxAmountPerUnit - 1)
                          : 0
                      )
                    }
                  }}
                  type='number'
                  min={0}
                  incrementHandler={() =>
                    onChange(
                      'maxAmountPerUnit',
                      values.maxAmountPerUnit !== '' || values.maxAmountPerUnit !== NaN
                        ? Number(values.maxAmountPerUnit + 1)
                        : 1
                    )
                  }
                  error={errorValue?.maxAmountPerUnit}
                  containerClasses='col-span-1'
                />
              }
              {
                field.fieldType === 'textarea' &&
                <CustomTextArea
                  key={index}
                  label={sectionName + ` ${field.title} *`}
                  placeholder='Type description here'
                  labelClasses='text-xs font-openSans font-light'
                  value={values.description}
                  onChange={(e) => onChange('description', e.target.value)}
                  style={{ height: '144px', color: standardStyle.colors.softBlack }}
                  textareaClasses='font-openSans text-softBlack'
                  disabled={!editMode}
                  // required='required'
                  error={errorValue?.description}
                  containerClasses='col-span-4'
                />
              }
              {
                field.fieldType === 'images' &&
                <div key={index} className='col-span-4'>
                  <div className='text-xs font-openSans font-light uppercase'>{sectionName} {field.title} *</div>
                  <div>
                    {values?.images?.length > 0 &&
                      values?.images?.map(
                        (image, index) =>
                          typeof image === 'string' && (
                            <ImageUpload
                              key={index}
                              index={index}
                              imgsrc={image}
                              key={index}
                              onDeleteImage={() => onDeleteImage(index)}
                            />
                          )
                      )}
                    {values?.displayImages?.map((image, index) => {
                      return (
                        <ImageUpload
                          key={index}
                          index={index}
                          imgsrc={image}
                          onDeleteImage={() => onDeleteImage(index)}
                        />
                      );
                    })}
                  </div>
                  <div className='my-4'>
                    <ImageUploaderPrimary
                      onFileChange={(e) => AddImages({ e })}
                      uploader={true}
                      required='required'
                    />
                  </div>
                  {errorValue?.images && (
                    <p className='text-red-500'>Please upload at least 1 image</p>
                  )}
                </div>
              }
            </>
          ))
        }
      </div>
      {
        fields.filter((field) => (field?.isAdditional))?.length > 0 &&
        <>
          <h2 className='mt-4'>
            Additional Fields
          </h2>
          <div className='border-b-2 border-black my-2' />
          <div className='grid grid-cols-4 gap-6 border-0 p-0 mt-4 rounded-none shadow-none'>
            {
              fields.filter((field) => (field?.isAdditional)).map((field, index) => (
                <>
                  {
                    field.fieldType === 'text' &&
                    <CustomInput
                      key={index}
                      label={`${field.title} *`}
                      labelClassName='text-xs font-openSans font-light'
                      placeholder={'Enter ' + sectionName + ` ${field.title}`}
                      value={values?.additionalFields?.[field.customFieldName] ?? ''}
                      onChange={(e) => onChangeAdditionalFields(field.customFieldName, e.target.value)}
                      classes='font-openSans text-base'
                      style={{ maxHeight: '48px' }}
                      disabled={!editMode}
                      containerClasses='col-span-2'
                    />
                  }
                  {
                    field.fieldType === 'number' &&
                    <CustomInput
                      key={index}
                      label={`${field.title} *`}
                      labelClassName='text-xs font-openSans font-light'
                      placeholder={`Enter ${field.title}`}
                      value={values?.additionalFields?.[field.customFieldName]}
                      onChange={(e) => onChangeAdditionalFields(field.customFieldName, Number(e.target.value))}
                      classes='font-openSans text-base'
                      style={{ maxHeight: '48px' }}
                      disabled={!editMode}
                      type='number'
                      min={0}
                      containerClasses='col-span-1'
                    />
                  }
                  {
                    field.fieldType === 'textarea' &&
                    <CustomTextArea
                      key={index}
                      label={`${field.title} *`}
                      placeholder={`Enter ${field.title}`}
                      labelClasses='text-xs font-openSans font-light'
                      value={values?.additionalFields?.[field.customFieldName] ?? ''}
                      onChange={(e) => onChangeAdditionalFields(field.customFieldName, e.target.value)}
                      style={{ height: '144px', color: standardStyle.colors.softBlack }}
                      textareaClasses='font-openSans text-softBlack'
                      disabled={!editMode}
                      containerClasses='col-span-4'
                    />
                  }
                  {
                    field.fieldType === 'array' &&
                    <div className='col-span-4 flex flex-col gap-3 pt-3'>
                      <div className='w-full flex justify-between items-center'>
                        <span className='text-lg font-medium'>
                          {field.title}
                        </span>
                        <CustomButton
                          variant='outline'
                          handleClick={() => onChangeOfAdditionalFieldArray(field.customFieldName, 'addNewOption')}
                          disabled={!editMode}
                          className='w-fit h-fit'
                        >
                          <span className='font-medium p-[11px] text-base flex gap-2 items-center truncate'>
                            <Plus className='w-5 h-5' />
                            Add new option
                          </span>
                        </CustomButton>
                      </div>
                      <div className='w-full grid grid-cols-2 gap-3'>
                        {
                          (Array.isArray(values?.additionalFields?.[field.customFieldName]) ? values?.additionalFields?.[field.customFieldName] : [])?.map((val, optionIndex) => (
                            <div className='col-span-1 flex items-end gap-4'>
                              <CustomInput
                                placeholder='Enter value'
                                labelClassName='text-xs mb-[6px]'
                                value={val ?? ''}
                                onChange={(e) => onChangeOfAdditionalFieldArray(field.customFieldName, 'updateOption', optionIndex, e.target.value)}
                                type='text'
                                disabled={!editMode}
                                containerClasses='flex-1'
                                classes='!mb-0'
                              />
                              <CustomButton
                                variant='danger'
                                handleClick={() => setIsDeletedToBeOption({ customFieldName: field.customFieldName, index: optionIndex })}
                                disabled={!editMode || (optionIndex === 0)}
                                className='w-fit h-fit'
                              >
                                <span className='font-medium p-[11px] text-base flex gap-2 items-center truncate'>
                                  <Trash className='text-white w-5 h-5' />
                                </span>
                              </CustomButton>
                            </div>
                          ))
                        }
                        {
                          !((Array.isArray(values?.additionalFields?.[field.customFieldName]) ? values?.additionalFields?.[field.customFieldName] : [])?.length > 0) && <div className='col-span-2 flex justify-center items-center h-10 rounded bg-zinc-100'>No options found, add new one</div>
                        }
                      </div>
                    </div>
                  }
                </>
              ))
            }
          </div>
          <div className='border-b-2 border-black my-4' />
        </>
      }

      {/* New contracts component */}
      {/* <div className='mt-4'>
        <CustomSelect
          label='Connect contract template *'
          labelClasses='text-left text-xs font-openSans font-light'
          inputClasses='h-10 bg-white font-openSans'
          selectedOption={values?.contractDetail[0]?.contractTemplate?._id ?? null}
          options={contractsData}
          setSelectedOption={(value) => onContractChange(value)}
          fonts={'font-openSans'}
          fieldName={'contracts'}
        // disabled={!editMode || viewMode === 'create'}
        />
      </div>
      {errorValue?.contractDetail && (
        <div className='absolute'>
          <p className='my-0 text-xs mt-1 text-red-500'>
            Contract template is required
          </p>
        </div>
      )} */}
      <div className='my-8 flex items-center justify-center'>
        <CustomButton
          variant='danger'
          handleClick={() => setIsDeletedToBeItem({ index })}
          className='w-fit h-fit'
        >
          <span className='font-medium p-[11px] text-base flex gap-2 items-center truncate'>
            <Trash className='text-white w-5 h-5' />
            Delete {sectionName}
          </span>
        </CustomButton>
      </div>

      <ConfirmDeleteModal
        showModal={!!(deletedToBeOption?.customFieldName)}
        message='Do you want to delete this option?'
        onCancel={() => setIsDeletedToBeOption(null)}
        onOk={() => onChangeOfAdditionalFieldArray(deletedToBeOption?.customFieldName ?? '', 'removeOption', deletedToBeOption?.index)}
      />
      <ConfirmDeleteModal
        showModal={!!(deletedToBeItem)}
        message='Do you want to delete this item?'
        onCancel={() => setIsDeletedToBeItem(null)}
        onOk={() => onDeleteHandler(deletedToBeItem?.index)}
      />
    </div>
  );
};

export default Option;
