import React from 'react'
import { Check } from 'lucide-react'
import CustomButton from 'components/CustomButton'

const ContractSignedPanel = ({
  contract,
  onCloseScreenClicked,
  isAllSigned,
  onSignNextContractClicked
}) => {
  const documentName = contract?.contractTemplate?.name ?? null
  if (!contract) {
    return null
  }

  return (
    <div className='h-full w-[95%] md:w-2/3 md:max-w-[650px] flex-1 justify-center content-center items-center mx-auto'>
      <div className='bg-white flex flex-col gap-5 justify-center items-center rounded-xl shadow-md border py-8 px-2.5 md:px-8'>
        <div className='h-24 w-24 rounded-full bg-green-500 flex justify-center items-center p-3 mt-4'>
          <Check className='w-14 h-14 text-white' strokeWidth={2.5} />
        </div>
        <div className='flex flex-col items-center text-center'>
          <h2 className='text-xl md:text-2xl mb-0'>Thank you!</h2>
          <h2 className='text-xl md:text-2xl lowercase first-letter:capitalize'>{documentName ? documentName + ' ' : ''}Signed Successfully!</h2>
        </div>
        {!isAllSigned && (
          <>
            <p className='text-md text-center justify-center content-center mb-0 lowercase first-letter:capitalize'>
              You have signed all your contracts
              <br />
              Please wait for sales Representative to contact you for next steps
            </p>
          </>
        )}
        <div className='w-full flex flex-wrap justify-center items-center gap-4'>
          <a
            href={contract.url}
            target='_blank'
            className='border text-black cursor-pointer font-semibold px-6 py-2.5 rounded text-lg flex justify-center items-center gap-2'
          >
            Download a Copy
          </a>

          <CustomButton handleClick={isAllSigned ? onCloseScreenClicked : onSignNextContractClicked}>
            <span className='font-semibold  px-6 py-2.5 rounded text-lg flex items-center gap-2'>
              {isAllSigned ? 'Close this Screen' : 'Sign Next Document'}
            </span>
          </CustomButton>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ContractSignedPanel)
