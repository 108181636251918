/*eslint-disable */
// @ts-nocheck
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { Oval } from 'react-loader-spinner'
import * as Yup from 'yup'
import { message, Select } from 'antd'
// import { Button } from '@material-ui/core'
import CustomInput from 'components/CustomInput'
import CustomRadio from 'components/CustomRadio'
import CustomModal from 'components/Modal'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ContactContext } from 'pages/contacts/context/Contacts'
import useGetLeadBrokers from 'hooks/useGetLeadBrokers'
import useGetSalesReps from 'hooks/useGetSalesReps'
import useBrokerageCompanies from 'hooks/useBrokerageCompanies'
import useUpdateUsers from 'hooks/useUpdateUsers'
import useLeadSource from 'hooks/useLeadSource'
import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'
import useContactTypes from 'hooks/useContactTypes'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import { MailIcon, User } from 'lucide-react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import useBuyerCompanies from 'hooks/useBuyerCompanies'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'

const initialEmptyValues = {
  firstName: '',
  lastName: '',
  email: '',
  notes: '',
  phoneNumber: '',
  userType: 'Buyer'
}

// const buttonStyle = {
//   backgroundColor: '#EDDF65',
//   height: '45px'
// }

const Button = ({ title = '', Icon, onClick }) => {
  return (
    <button
      className={clsx(
        'rounded border border-zinc-800 border-opacity-50 justify-start items-center gap-2 flex',
        title?.length > 0 ? 'px-4 py-2' : 'p-2'
      )}
      onClick={onClick}
    >
      {Icon}
      {title?.length > 0 && (
        <div className='text-center text-zinc-800 text-base font-normal leading-snug'>
          {title}
        </div>
      )}
    </button>
  )
}

const CallIcon = () => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_16_93)'>
        <path
          d='M5.02983 8.63995C6.06796 10.6802 7.74049 12.3455 9.78069 13.3908L11.3667 11.8048C11.5614 11.6101 11.8497 11.5453 12.1021 11.6318C12.9095 11.8985 13.7818 12.0427 14.6757 12.0427C15.0722 12.0427 15.3967 12.3671 15.3967 12.7636V15.2796C15.3967 15.6761 15.0722 16.0005 14.6757 16.0005C7.9063 16.0005 2.4201 10.5143 2.4201 3.74491C2.4201 3.3484 2.74452 3.02399 3.14102 3.02399H5.66424C6.06075 3.02399 6.38516 3.3484 6.38516 3.74491C6.38516 4.64606 6.52935 5.51116 6.79609 6.31859C6.87539 6.57091 6.81771 6.85207 6.61586 7.05393L5.02983 8.63995Z'
          fill='#2E2B2E'
        />
      </g>
      <defs>
        <clipPath id='clip0_16_93'>
          <rect
            width='17.3021'
            height='17.3021'
            fill='white'
            transform='translate(0.257385 0.861206)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

const AddOrEditContact = ({
  show,
  setShow,
  contact,
  onSuccess,
  broker = '',
  userType,
  invalideCacheOnUpdate = false
}) => {
  const { createOrUpdateUser } = useUpdateUsers(invalideCacheOnUpdate)
  const projectSelector = (state) => state.appReducer.appProject
  const appProject = useSelector(projectSelector)
  const userSelector = (state) => state.authReducer.userObject
  const userObject = useSelector(userSelector)
  const initialValues = {
    ...initialEmptyValues,
    userType: userType?.length ? userType : 'Buyer'
  }
  const { setSelectedContact, setIsLoading } = useContext(ContactContext)
  const { data: brokersData, refetchBrokers } = useGetLeadBrokers()
  const { formattedLeadsources: leadSources } = useLeadSource()
  const [selectedLeadSource, setSelectedLeadSource] = useState('None')
  const [brokers, setBrokers] = useState([])
  const [selectedBroker, setSelectedBroker] = useState('')
  const [selectedContactType, setSelectedContactType] = useState('')
  const [brokerageCompany, setBrokerageCompany] = useState('')
  const [state, setState] = useState({ ...initialValues })
  const [loading, setLoading] = useState(false)
  const [selectedSalesRep, setSelectedSalesRep] = useState(null)
  const [newBrokerageCompany, setNewBrokerageCompany] = useState('')
  const { salesReps, refetch } = useGetSalesReps()
  const { companies: brokerageCompanies, update: updateBrokerageCompany } = useBrokerageCompanies()
  const { companies: buyerCompanies, update: updateBuyerCompany, refetch: refetchBuyerCompanies, isLoading: isBuyerCompaniesLoading } = useBuyerCompanies()
  const [errorModalVisible, setErrorModalVisible] = useState(false)
  const [existingUserData, setExistingUserData] = useState(null)
  const { contactTypeFormattedData } = useContactTypes()
  const { view } = useContactContext()
  const history = useHistory()
  const [buyerCompaniesOptions, setBuyerCompaniesOptions] = useState([])
  const [buyerCompanyName, setBuyerCompanyName] = useState('')
  const [newBuyerCompanyName, setNewBuyerCompanyName] = useState(null)
  const [isAddingNewBuyerCompany, setIsAddingNewBuyerCompany] = useState(false)

  useEffect(() => {
    refetchBuyerCompanies()
  }, [])

  useEffect(() => {
    setBuyerCompaniesOptions([
      ...buyerCompanies?.values?.map((option) => (
        { value: option, label: option }
      )) || []
    ])
  }, [buyerCompanies?.values])

  useEffect(() => {
    setBrokers(brokersData)
  }, [brokersData])

  useEffect(() => {
    setSelectedBroker(broker)
  }, [broker])

  useEffect(() => {
    if (show && !brokersData?.length) refetchBrokers()
    if (
      show &&
      !salesReps?.length &&
      userObject?.userType === 'DeveloperAdmin'
    ) {
      refetch()
    }
  }, [
    brokersData?.length,
    refetch,
    refetchBrokers,
    salesReps?.length,
    show,
    userObject?.userType
  ])

  const onChange = useCallback(
    (fieldName, value) => {
      setState((prevState) => ({ ...prevState, [fieldName]: value }))
    },
    [setState]
  )

  const handleClose = () => {
    if (loading) {
      return
    }
    setState({ ...initialValues })
    setShow(false)
  }

  const onValidate = () => {
    const requiredFields = ['firstName', 'lastName']
    for (let i = 0; i < requiredFields.length; i++) {
      if (state[requiredFields[i]] === '') {
        return false
      }
    }
    if (
      (state.phoneNumber === undefined || state.phoneNumber === '') &&
      state.email === ''
    ) {
      return false
    }
    return true
  }

  const checkValidation = async () => {
    const isEmailValid = await Yup.string().email().isValid(state.email)
    if (!isEmailValid) {
      message.error('Please fill all the required field')
      return false
    }
    return true
  }

  const handleCloseErrorModal = () => {
    setErrorModalVisible(false)
    setExistingUserData(null)
  }

  const onSave = async () => {
    if (loading || !(await checkValidation())) {
      return
    }
    let success = false
    // If string has no valid number than return true
    if (!isNaN(parseInt(selectedLeadSource))) {
      success = true
    }
    let leadSource
    if (!success) {
      leadSource = leadSources?.[0]?.label
    } else {
      leadSource = leadSources?.[selectedLeadSource]?.label
    }
    const salesRep =
      userObject?.userType === 'SalesRep' && state.userType !== 'Buyer'
        ? userObject?._id
        : selectedSalesRep

    const buyerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      project: view === 'corporate' ? null : appProject,
      userType: state.userType,
      buyerData: {
        phoneNumber: state.phoneNumber,
        additionalFields: [
          {
            fieldType: 'LeadForm',
            name: 'Notes',
            project: appProject,
            value: state.notes
          }
        ],
        leadSource,
        assignments: [
          {
            salesRep
          }
        ],
        contactType: selectedContactType || undefined,
        companyName: buyerCompanyName || undefined
      },
      leadBroker: selectedBroker || undefined,
      salesRep
    }
    const brokerParams = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      userType: state.userType,
      leadBrokerData: {
        phoneNumber: state.phoneNumber,
        notes: state.notes,
        project: appProject,
        leadSource,
        brokerageCompany: brokerageCompany || undefined,
        assignments: [
          {
            salesRep
          }
        ]
      },
      project: view === 'corporate' ? null : appProject,
      salesRep
    }
    const params = state.userType === 'Buyer' ? buyerParams : brokerParams
    try {
      setLoading(true)
      const res = await createOrUpdateUser(params, false)
      if (!contact?._id) {
        setState({ ...initialEmptyValues })
      }
      try {
        if (res.status === 'exist') {
          setExistingUserData(res)
          setErrorModalVisible(true)
          setLoading(false)
        } else {
          setShow?.(false)
          setLoading(false)
          onSuccess?.(res._id)
          setSelectedContact?.(res._id)
          refetchBuyerCompanies()
          setIsLoading(true)

          // Track contact create save event
          mixpanel.track(MIXPANEL_EVENTS.CONTACT_CREATE_SAVE, {
            createdUserType: state.userType
          })

          message.success(`${res?.firstName} created successfully`)
        }
      } catch (error) {
        setLoading(false)
        console.error(error)
      }
    } catch (err) {
      setLoading(false)
      message.error(err?.response?.data?.message || 'Contact already exists')
    }
  }

  useEffect(() => {
    if (newBrokerageCompany) {
      setBrokerageCompany(newBrokerageCompany)
    }
  }, [brokerageCompanies, newBrokerageCompany])

  const heading = useMemo(() => {
    if (userType?.length) {
      if (userType === 'LeadBroker') {
        return contact?._id ? 'Update Broker' : 'New Broker'
      } else if (userType === 'Buyer') {
        return contact?._id ? 'Update Buyer' : 'New Buyer'
      }
    }
    return contact?._id ? 'Update Contact' : 'New Contact'
  }, [contact?._id, userType])

  useEffect(() => {
    onChange('userType', userType)
  }, [onChange, userType])

  const colors = [
    { text: "#2563eb", bg: "#DBEAFE", userType: 'LeadBroker' }, // Blue
    { text: "#171717", bg: "#D1D5DB", userType: 'Buyer' }, // Dark Grey
  ];

  const ContactTypeBadge = ({ value }) => {
    const badgeColor = colors.find((option) => option.userType === value);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <>
          {value && (
            <div
              className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
              style={badgeStyle}
            >
              <div className="text-center text-xs font-medium">
                {value || "-"}
              </div>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const onSearch = useCallback((input) => {
    const temp = buyerCompanies?.values?.map((value) => (value?.toString()?.toLowerCase())).includes(input.toLowerCase())

    if (!temp || !buyerCompanies?.values?.length) {
      setNewBuyerCompanyName(input)
    } else {
      setNewBuyerCompanyName(null)
    }
  }, [buyerCompanies?.values]);

  const handleNewBuyerCompanyNameSubmit = async () => {
    setIsAddingNewBuyerCompany(true)
    await updateBuyerCompany({ name: newBuyerCompanyName }).then(async () => {
      const temp = newBuyerCompanyName
      setNewBuyerCompanyName(null)
      setBuyerCompanyName(temp)
      await refetchBuyerCompanies()
    }).finally(() => {
      setIsAddingNewBuyerCompany(false)
    })
  }

  return (
    <>
      <CustomModal
        isOpen={errorModalVisible}
        closeModal={handleCloseErrorModal}
        contentLabel='Error Modal'
      >
        <div className='font-openSans relative bg-white p-4 md:p-7 lg:p-10 rounded shadow-lg w-full min-w-[450px]'>
          <button
            id='close-new-contact-form'
            className='absolute right-4 md:right-7 lg:right-10'
            onClick={handleCloseErrorModal}
          >
            <CrossIcon />
          </button>
          <h1 className='font-bold text-xl'>
            {existingUserData?.errorType === 'email'
              ? 'Email is already registered'
              : 'Phone number is already registered.'}
          </h1>
          {existingUserData && (
            <div className='mt-4 flex flex-col gap-4'>
              <div className='flex gap-x-4 items-center'>
                <User className='h-5 w-5' />
                <div>{`${existingUserData?.firstName || ''} ${existingUserData?.lastName || ''
                  }`}</div>
              </div>
              {existingUserData?.email && (
                <div className='flex gap-x-4 items-center'>
                  <MailIcon className='h-5 w-5' />
                  <div>{existingUserData?.email}</div>
                </div>
              )}
              {(existingUserData.buyerData?.phoneNumber
                || existingUserData.leadBrokerData?.phoneNumber) && (
                  <div className='flex gap-x-4 items-center'>
                    <CallIcon className='h-5 w-5' />
                    <div>
                      {existingUserData.userType === 'Buyer'
                        ? existingUserData.buyerData?.phoneNumber
                        : existingUserData.leadBrokerData?.phoneNumber}
                    </div>
                  </div>
                )}
              <div className='flex gap-x-4 items-center'>
                <ContactTypeBadge value={existingUserData.userType} />
              </div>
              <CustomButton
                className='w-fit self-center'
                handleClick={() => {
                  handleCloseErrorModal()
                  handleClose()
                  history.push({
                    pathname: history.location.pathname,
                    search: `?_id=${existingUserData._id}`,
                  });
                }}
              >
                <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                  View Contact
                </span>
              </CustomButton>

            </div>
          )}
        </div>
      </CustomModal>
      <CustomModal
        isOpen={show}
        closeModal={handleClose}
        contentLabel='Add Or Edit Contact Modal'
      >
        <div className='font-openSans relative bg-white p-4 md:p-7 lg:p-10 rounded shadow-lg w-full'>
          <button
            id='close-new-contact-form'
            className='absolute right-4 md:right-7 lg:right-10'
            onClick={handleClose}
          >
            <CrossIcon />
          </button>
          <h1 className='font-bold text-xl text-center p-0 m-0'>{heading}</h1>
          {!userType?.length && (
            <div>
              <p
                className={
                  'mt-0  text-softBlack70 text-xs font-medium uppercase'
                }
                style={{ letterSpacing: '0.16px' }}
              >
                Is Broker ?
              </p>
              <div className='flex'>
                <CustomRadio
                  label='Yes'
                  checked={state.userType === 'LeadBroker'}
                  onClick={(e) => {
                    onChange('userType', 'LeadBroker')
                  }}
                />
                <div className='ml-4'></div>
                <CustomRadio
                  label='No'
                  checked={state.userType === 'Buyer'}
                  onClick={(e) => {
                    onChange('userType', 'Buyer')
                  }}
                />
              </div>
            </div>
          )}
          <div className='flex flex-col md:flex-row gap-4 md:gap-5 mt-4'>
            <div className='flex flex-1 flex-col border-2 rounded-md border-gray-1 p-4 md:px-8'>
              <div className='text-xl font-bold'>Personal Info</div>
              <div className='pt-4 md:py-8 flex flex-col gap-6 w-full md:min-w-[250px]'>
                <CustomInput
                  id='firstName-contact'
                  label='First Name'
                  placeholder='Enter first name'
                  value={state.firstName}
                  onChange={(e) => {
                    onChange('firstName', e.target.value)
                  }}
                />
                <CustomInput
                  id='lastName-contact'
                  label='Last Name'
                  placeholder='Enter last name'
                  value={state.lastName}
                  onChange={(e) => {
                    onChange('lastName', e.target.value)
                  }}
                />
                <CustomInput
                  id='email-contact'
                  label='Email'
                  placeholder='Enter email'
                  value={state.email}
                  onChange={(e) => {
                    onChange('email', e.target.value)
                  }}
                  type='email'
                />
                <CustomInput
                  id='phone-contact'
                  type='phone'
                  label='Phone Number'
                  placeholder='Enter phone number'
                  value={state.phoneNumber}
                  onChange={(e) => {
                    onChange('phoneNumber', e.target.value)
                  }}
                />
              </div>
              {/* {!isMobile && (
                <CustomButton
                  id='save-or-update-contact-button'
                  className='w-fit mx-auto'
                  handleClick={onSave}
                  disabled={!onValidate()}
                >
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    {loading && <Oval height='18' width='18' color='white' />}
                    {contact?._id ? 'Update' : 'Save'}
                  </span>
                </CustomButton>
              )} */}
            </div>
            <div className='flex flex-1 flex-col gap-4 md:gap-5 border-2 rounded-md border-gray-1 p-4 md:px-8'>
              <div className='text-xl font-bold pb-3'>Lead Data</div>
              {state?.userType === 'Buyer' && (
                <div className='w-full md:min-w-[250px]'>
                  <p
                    className={
                      'mt-0 text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    Company name
                  </p>
                  <Select
                    id={`company-name-drop-down-${appProject}`}
                    value={buyerCompanyName}
                    showSearch
                    placeholder='Select company name'
                    onSearch={onSearch}
                    options={[...buyerCompaniesOptions]}
                    onChange={(value) => {
                      setBuyerCompanyName(value)
                      setNewBuyerCompanyName(null)
                    }}
                    className='ant-picker-company-name-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                    notFoundContent={
                      <div className="p-4 flex flex-col items-center gap-2 text-center !cursor-default">
                        <span className="text-zinc-500 text-sm">
                          No company found matching your search.
                        </span>
                        <span className="text-zinc-600 text-sm">
                          You can add a new company by clicking below:
                        </span>
                        <CustomButton
                          handleClick={handleNewBuyerCompanyNameSubmit}
                          disabled={isBuyerCompaniesLoading || isAddingNewBuyerCompany}
                          className='mt-3'
                        >
                          <span className='font-medium py-2 px-3 text-sm flex gap-2 items-center !cursor-pointer'>
                            {isAddingNewBuyerCompany && <Oval height='18' width='18' color='white' />}
                            Create Company
                          </span>
                        </CustomButton>
                      </div>
                    }
                  />
                </div>
              )}
              {state?.userType === 'LeadBroker' && (
                <div className='w-full md:min-w-[250px]'>
                  <p
                    className={
                      'mt-0  text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    Brokerage Companies
                  </p>
                  <Select
                    id='brokerage-companies-select'
                    value={brokerageCompany}
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setBrokerageCompany(value)
                    }}
                    className='ant-picker-brokerage-companies-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {brokerageCompanies?.values?.map?.((_item, index) => (
                      <Select.Option
                        key={index}
                        label={_item}
                        value={_item}
                        className={_item}
                      >
                        <div
                          id={`brokerage-companies-label-${index}`}
                          className='flex gap-2 items-center'
                        >
                          <span>{_item}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {state?.userType === 'LeadBroker' && (
                <div className='w-full md:min-w-[250px] flex flex-row gap-2 items-end'>
                  <div className='flex flex-1 flex-col'>
                    <CustomInput
                      id='add-new-brokerage-company-input'
                      label='Add New Brokerage Company'
                      placeholder='Enter Brokerage Company'
                      value={newBrokerageCompany}
                      onChange={(e) => {
                        setNewBrokerageCompany(e.target.value)
                      }}
                      type='text'
                      classes='!m-0'
                    />
                  </div>
                  <CustomButton
                    handleClick={() => updateBrokerageCompany({ name: newBrokerageCompany })}
                  >
                    <span className='font-medium py-2 px-3 text-base flex gap-2 items-center'>
                      Submit
                    </span>
                  </CustomButton>
                </div>
              )}
              {state.userType === 'Buyer' && (
                <div className='w-full md:min-w-[250px]'>
                  <p
                    className={
                      'mt-0  text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    Broker
                  </p>
                  <Select
                    id='broker-select'
                    showSearch={true}
                    value={selectedBroker}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedBroker(value)
                    }}
                    className='ant-picker-broker-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {brokers?.map?.((_item, index) => (
                      <Select.Option
                        key={index}
                        label={`${_item?.firstName} ${_item?.lastName}`}
                        value={_item._id}
                        className={_item._id}
                      >
                        <div
                          id={`broker-label-${index}`}
                          className='flex gap-2 items-center'
                        >
                          <span>{`${_item?.firstName} ${_item?.lastName}`}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {state.userType === 'Buyer' && (
                <div className='w-full md:min-w-[250px]'>
                  <p
                    className={
                      'mt-0  text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    Contact Type
                  </p>
                  <Select
                    id='contact-type'
                    showSearch={true}
                    value={selectedContactType}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedContactType(value)
                    }}
                    className='ant-picker-broker-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {contactTypeFormattedData?.map?.((_item, index) => (
                      <Select.Option
                        key={index}
                        label={`${_item?.label}`}
                        value={_item.label}
                        className={_item.label}
                      >
                        <div
                          id={`contact-type-label-${index}`}
                          className='flex gap-2 items-center'
                        >
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {userObject?.userType === 'DeveloperAdmin' && (
                <div className='w-full md:min-w-[250px]'>
                  <p
                    className={
                      'mt-0  text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    SalesRep
                  </p>
                  <Select
                    id='salesRep-select'
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={selectedSalesRep}
                    onChange={(value) => {
                      setSelectedSalesRep(value)
                    }}
                    className='ant-picker-salesRep-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {salesReps?.map?.((_item, index) => (
                      <Select.Option
                        key={index}
                        label={`${_item?.firstName} ${_item?.lastName}`}
                        value={_item._id}
                        className={_item._id}
                      >
                        <div
                          id={`salesRep-label-${index}`}
                          className='flex gap-2 items-center'
                        >
                          <span>{`${_item?.firstName} ${_item?.lastName}`}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              <div className='flex'>
                <div className='w-full'>
                  <p
                    className={
                      'mt-0  text-softBlack70 text-xs font-medium uppercase'
                    }
                    style={{ letterSpacing: '0.16px', marginBottom: '0.5rem' }}
                  >
                    Lead Source
                  </p>
                  <Select
                    id='lead-source-select'
                    value={selectedLeadSource}
                    showSearch={true}
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toString()
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(value) => {
                      setSelectedLeadSource(value)
                    }}
                    className='ant-picker-lead-source-contact w-full'
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {leadSources?.map?.((_item, index) => (
                      <Select.Option
                        value={_item?.value}
                        key={index}
                        label={_item?.label}
                        className={_item?.value}
                      >
                        <div
                          id={`lead-source-contact-label-${index}`}
                          className='flex gap-2 items-center'
                        >
                          <span>{_item.label}</span>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <CustomButton
            id='save-or-update-contact-button'
            className='w-fit ml-auto mt-4'
            handleClick={onSave}
            disabled={!onValidate()}
          >
            <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
              {loading && <Oval height='18' width='18' color='white' />}
              {contact?._id ? 'Update' : 'Save'}
            </span>
          </CustomButton>
        </div>
      </CustomModal>
    </>
  )
}

export default AddOrEditContact
