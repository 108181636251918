/* eslint-disable */
import React, { useEffect } from 'react'
import './style/formStyles.css'

const RenderHTMLForm = ({ html }) => {
  useEffect(() => {
    setTimeout(() => {
      try {
        if (document.getElementById('g-recaptcha')) {
          // @ts-ignore
          grecaptcha.enterprise.render('g-recaptcha')
        }
      } catch (error) {
        console.error(error);
      }
    }, 0)
  }, [])
  return (
    <div
      className='form-container'
      dangerouslySetInnerHTML={{ __html: html }}
    ></div>
  )
}

export default RenderHTMLForm
