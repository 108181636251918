/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { Document, Page } from 'react-pdf'
import UnitEditView from "./UnitEditView";
import {
  KeyRound,
  Bell,
  ChevronsUpDown,
  Pen,
  Check,
  Delete,
  Upload,
  DeleteIcon,
  EyeIcon,
  CrossIcon,
  ExternalLink,
  Trash,
} from "lucide-react";
import Checkbox from "./Checkbox";
import { Drawer, MenuItem, Modal, Select } from "@material-ui/core";
import { CloseIcon } from "components/Icons";
import {
  createOrUpdateParking,
  getAllParkingForBuildingByParkingType,
  getAvailableParkingsForBuildingByParkingType,
  getParkingInventory,
} from "store/actions/parkingActions";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { createOrUpdateUnit } from "store/actions/unitActions";
import toast from "react-hot-toast";
import DeleteButton from "components/HorizontalCardProfile/MobileCardComponents/DeleteButton";
import { getFilteredDeals } from "store/actions/dealAction";
import { StorageAllocatePage } from "./StorageAllocatePage";
import { useSelector } from "react-redux";
import { getProjects } from "store/actions/projectActions";
import { getReservationByInventoryChoice, getReservationByUnitChoice } from "store/actions/reservationActions";
import moment from "moment";
import CustomBadge from "./CustomBadge";
import useInventory from "../hooks/useInventory";
import UnitEditViewV2 from "./UnitEditViewV2";
import { Badge } from "components/ShadcnComponents/badge";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card";
import { getInventoryStatusLabel } from "store/actions/inventoryActions";
import UnitFileUploader from "components/UnitFileUploader";
import { TButton } from 'ui';
import { updateInventoryField } from "store/actions/inventoryActions";
import DeleteModal from "components/DeleteModal";
import { GrDocumentUpload } from "react-icons/gr";
import { uploadFile } from "store/actions/fileActions";
import { HiOutlineDocument } from "react-icons/hi";

export const AllocationBadges = ({ type: { count, title, bgColor } }) => (
  <div
    className="px-6 py-3 rounded-lg shadow border-2 border-zinc-200 justify-center items-center gap-3.5 inline-flex"
    style={{ backgroundColor: bgColor }}
  >
    <div className="text-black text-xl font-medium">
      {count} {title}
    </div>
  </div>
);

const HorizontalLine = ({ className }) => (
  <div className={`w-full h-[1px] bg-zinc-200 ${className}`} />
);

const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab }) => (
  <div className="w-full flex items-center md:justify-center rounded-lg bg-white p-1 text-zinc-600">
    {tabMenus.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          setSelectedTab(tab.id);
        }}
        className={`flex flex-1 w-full min-w-[120px] md:w-fit md:min-w-[180px] max-w-[200px] text-center justify-center items-center just px-4 py-[8px] cursor-pointer rounded-[4px] font-semibold text-[12px] ${
          selectedTab === tab.id
            ? "bg-[#18181B] text-white shadow-sm"
            : "text-zinc-600 hover:bg-zinc-100"
        } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
);

const ShadCNWrapper = ({ children, className = "" }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);

const CustomCheckBox = () => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Checkbox
      checked={isChecked}
      handleChange={() => setIsChecked(!isChecked)}
      iconClasses="self-start my-1"
    />
  );
};

const InventoryEditDrawerV2 = ({
  editDetails,
  setEditDetails,
  closeInfoDrawer,
  isInfoDrawerOpen,
  isAbleToPurchase = false,
  refetch,
  type = "",
  isFullHeight = false,
  selectedBuilding,
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  let unitVar = localStorage.getItem(`projectId_${projectId}`);
  const [isAdditionalDrawerOpen, setIsAdditionalDrawerOpen] = useState(false);
  const colors = ["#FFBE9A", "#16A34A40", "#FFD700", "#FF0000"];
  const [allocationModal, setAllocationModal] = useState(false);
  const [parkingType, setParkingType] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [assignParkingModal, setAssignParkingModal] = useState(false);
  const [pendingParkingAssignment, setPendingParkingAssignment] = useState(0);
  const [parkingAssignedOption, setParkingAssignedOption] = useState("");
  const [parkings, setParkings] = useState([]);
  const [selectedParking, setSelectedParking] = useState("");
  const [reassignedSelectedParking, setReassignedSelectedParking] =
    useState("");
  const [assignedParkingTableDataSource, setAssignedParkingTableDataSource] =
    useState([]);
  const [editParkingModal, setEditParkingModal] = useState(false);
  const [unassignParkingModal, setUnassignParkingModal] = useState(false);
  const [reassignParkingModal, setReassignParkingModal] = useState(false);
  const [avesdoParkings, setAvesdoParkings] = useState([]);
  const [selectedFloorPlan, setSelectedFloorPlan] = useState(undefined);
  const [unitVariable, setUnitVariable] = useState(unitVar || "Unit");
  const [avesdoWaitlistedParkings, setAvesdoWaitlistedParkings] = useState([]);
  const [
    avesdoUnAssignedWaitlistParkings,
    setAvesdoUnAssignedWaitlistParkings,
  ] = useState([]);
  const [avesdoSelectedParking, setAvesdoSelectedParking] = useState(null);
  const [avesdoUnit, setAvesdoUnit] = useState([]);
  const [tempAvesdoRefetct, setTempAvesdoRefetct] = useState(false);
  const tabMenus = [
    { id: "tab-1", name: "Information" },
    { id: "tab-2", name: "Transaction" },
    { id: "tab-3", name: "Parking" },
    { id: "tab-4", name: "Storage" },
    { id: "tab-5", name: "Reservation" },
    { id: "tab-6", name: "Files" },
  ];
  const [selectedFile, setSelectedFile] = useState();
  const [documentList, setDocumentList] = useState(editDetails?.files || []);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const fileInputRef = useRef(null);
  const {
    state: { statusColors },
  } = useInventory();
  const [selectedTab, setSelectedTab] = useState("tab-1");
  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };
  const userObject = useSelector((state) => state.authReducer.userObject);

  useEffect(() => {
    getProjects(projectId).then((project) => {
      const { unitVariable } = project;
      if (unitVariable) {
        setUnitVariable(unitVariable || "Unit");
      }
    });
  }, [projectId]);

  useEffect(() => {
    if (typesOfAllocatedParking.length === 0)
      return setPendingParkingAssignment(0);
    let total = typesOfAllocatedParking.reduce(
      (acc, curr) => acc + Number(curr.count),
      0
    );
    setPendingParkingAssignment(total);
  }, [typesOfAllocatedParking, editDetails]);
  const handleFilteredDeals = async () => {
    let data = await getFilteredDeals({
      unitNumber: editDetails?.unitNumber || null,
    });
    setAvesdoUnit(data);
    let allParkings = [];
    data.forEach((deal) => {
      const { options } = deal;
      const modifiedParkings = options
        .filter((option) => {
          return (
            option.label.toLowerCase().includes("parking") &&
            option.header !== "Parking Waitlist"
          );
        })
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? "Included" : currencyFormate(option.price),
          type: "-",
          id: option.id,
          flag: "avesdo",
        }));
      allParkings = allParkings.concat(modifiedParkings);
    });
    const filteredDeals = data.filter(
      (deal) =>
        deal.options.some((option) => option.header === "Parking Waitlist") &&
        deal.formatedAcceptedDate !== ""
    );
    let waitlistedParkings = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedParkings = options
        .filter((option) => option.header === "Parking Waitlist")
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? "Included" : currencyFormate(option.price),
          type: "-",
          id: option.id,
          flag: "avesdo",
        }));
      waitlistedParkings = waitlistedParkings.concat(modifiedParkings);
    });
    let unassignedWaitlistedParking = [];
    let assignedParkings = editDetails?.parkings?.assigned || [];
    waitlistedParkings.forEach((waitlistParking) => {
      const isAssigned = assignedParkings.some((assigned) => {
        return (
          assigned.isAvesdo && Number(assigned.avesdoId) === waitlistParking.id
        );
      });

      if (!isAssigned) {
        unassignedWaitlistedParking.push({
          ...waitlistParking,
        });
      }
    });
    setAvesdoWaitlistedParkings(waitlistedParkings);
    setAvesdoUnAssignedWaitlistParkings(unassignedWaitlistedParking);
    setAvesdoParkings(allParkings);
  };
  useEffect(() => {
    handleFilteredDeals();
  }, [editDetails, tempAvesdoRefetct]);
  useEffect(() => {
    let assignedParkings = editDetails?.parkings?.assigned || [];
    let allocatedParking = editDetails?.parkings?.allocated || [];
    let mappedAllocated = allocatedParking.map((parking, index) => {
      return {
        _id: parking._id,
        id: index,
        count: parking.quantity,
        title: parking.name,
        bgColor: colors[index % colors.length],
      };
    });
    setTypesOfAllocatedParking(mappedAllocated);
    setTempTypesOfAllocatedParking(mappedAllocated);
    let mappedAssigned = assignedParkings.map((parking, index) => {
      if (parking.isAvesdo) {
        let concatWaitlistAndAvesdo = [
          ...avesdoWaitlistedParkings,
          ...avesdoParkings,
        ];
        let avesdoParking = concatWaitlistAndAvesdo.filter((item) => {
          return item.id === Number(parking.avesdoId);
        })[0];
        return {
          avesdoId: parking.avesdoId,
          id: parking._id,
          stall: (parking?.stall || "").toString(),
          type: "Avesdo",
          option: avesdoParking?.label || "",
          price:
            avesdoParking?.price === "$0.00"
              ? "Included"
              : avesdoParking?.price || 0,
          isAssigned: true,
        };
      } else {
        return {
          id: parking._id,
          stall: (parking?.stall || "").toString(),
          type: (parking?.parkingType || "").toString(),
          price:
            parking.price === 0
              ? "Included"
              : currencyFormate(parking?.price).toString() || "",
          isAssigned: true,
        };
      }
    });
    let unassignedParking = [];
    let totalCount = mappedAllocated.reduce((acc, curr) => acc + curr.count, 0);
    let assignedCount = mappedAssigned.length;
    let unassignedCount = totalCount - assignedCount;
    for (let i = 0; i < unassignedCount; i++) {
      unassignedParking.push({
        id: `pending${i}_${type.title}`,
        stall: "N/a",
        type: "-",
        floor: "N/a",
        price: "N/a",
        isAssigned: false,
      });
    }

    let avesdoUnassigned = avesdoParkings.filter((item) => {
      return !mappedAssigned.some((t) => Number(t.avesdoId) === item.id);
    });

    setAssignedParkingTableDataSource([
      ...mappedAssigned,
      ...unassignedParking,
      ...avesdoUnassigned,
    ]);
  }, [editDetails, selectedBuilding, avesdoParkings]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: "",
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };
  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  const handleClose = () => {
    closeInfoDrawer();
    setIsAdditionalDrawerOpen(false);
  };
  const openAllocationModal = () => {
    setAllocationModal(true);
  };

  const openAssignParkignModal = () => {
    setAssignParkingModal(true);
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleParkingTypeChange = (id) => {
    const temp = parkingType.filter((option) => option.id === id)[0];
    onChange("parkingType", temp.title);
  };

  const onChange = (fieldName, newValue) => {
    setParkingType((prev) => {
      const newParkingType = prev.map((option) => {
        if (option.title === fieldName) {
          return { ...option, title: newValue };
        }
        return option;
      });
      return newParkingType;
    });
  };

  const getAvailableParkings = async () => {
    if (editParkingModal) {
      let data = await getAllParkingForBuildingByParkingType(
        selectedBuilding,
        "parkingType=" + parkingAssignedOption
      );
      let filtered = data?.map((parking) => {
        return {
          value: parking.stall,
          id: parking._id,
        };
      });
      setParkings(filtered);
    } else {
      let data = await getAvailableParkingsForBuildingByParkingType(
        selectedBuilding
      );
      let filtered = data?.map((parking) => {
        return {
          value: parking.stall,
          id: parking._id,
        };
      });
      if (filtered.length === 0)
        toast.error(
          "No parking spots available for" + " " + parkingAssignedOption
        );
      setParkings(filtered);
    }
  };

  useEffect(() => {
    if (assignParkingModal || editParkingModal) {
      getAvailableParkings();
    }
  }, [assignParkingModal, editParkingModal]);
  const [datesData, setDatesData] = useState([]);

  const handleWhishlistDate = (date) => {
    let datesData = avesdoUnit.filter((unit) =>
      unit?.options?.some((option) => option.header === "Parking Waitlist")
    );
    setDatesData(datesData);
  };
  useEffect(() => {
    handleWhishlistDate();
  }, [avesdoUnit]);

  const headersForStatic = [
    {
      name: "Reservation #",
      id: "reservationNumber",
    },
    {
      name: "Prospective Purchaser",
      id: "prospectivePurchaser",
    },
    {
      name: "Lot Choice",
      id: "lotChoiceName",
    },
    {
      name: "Date Reserved",
      id: "dateReserved",
    },
    {
      name: "Title Company: Deposit",
      id: "titleCompanyDeposit",
    },
    {
      name: "Broker",
      id: "broker",
    },
    {
      name: "Notes",
      id: "notes",
    },
  ];

  useEffect(() => {
    getReservationByInventoryChoice({
      lotChoice: editDetails?._id,
    }).then((data) => {
      let updatedRes = data.map((reservation) => {
        const temBuyers = reservation.reservation.reservationHolders.map(
          (user) =>
            user.firstName && user.lastName
              ? user.firstName + " " + user.lastName
              : "-"
        );
        return {
          ...reservation.reservation,
          reservation_holders: temBuyers.join(", "),
          lotChoiceName: reservation.foundIn,
        };
      });
      setReservationData(updatedRes);
    });
  }, [projectId, editDetails]);
  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <div className="items-center inline-flex">
            <CustomCheckBox />
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Parking stall
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "stall",
        dataIndex: "stall",
        Cell: (props) => (
          <div className="items-center flex">
            <CustomCheckBox />
            <p className="mb-0 text-black text-base font-bold truncate">
              {props?.cell?.value || "N/a"}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className="items-center max-w- inline-flex">
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Avesdo Name
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "option",
        dataIndex: "otion",
        Cell: (props) => (
          <div className="items-center max-w-[200px] flex-wrap flex">
            <p className="mb-0 text-black whitespace-normal text-base font-[500]">
              {props?.cell?.value || "N/a"}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className="items-center inline-flex">
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Type
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "type",
        dataIndex: "type",
        Cell: (props) => (
          <div className="px-3 py-1 rounded-md border border-zinc-200 justify-center items-center gap-2 inline-flex">
            <div className="text-zinc-950 text-sm font-medium leading-none capitalize">
              {props?.cell?.value || "-"}
            </div>
          </div>
        ),
      },
      {
        Title: (
          <div className="items-center inline-flex">
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Price
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "price",
        dataIndex: "price",
        Cell: (props) => (
          <p className="mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize">
            {props.cell?.value?.length ? props.cell.value : "—"}
          </p>
        ),
      },
      {
        Title: "",
        accessor: "isAssigned",
        Cell: (props) => (
          <div className="w-full flex flex-row justify-end items-center gap-2 text-center">
            {props.cell.value ? (
              <>
                <button
                onClick={() => {
                  if (props.row.original.flag === "avesdo") {
                    setAvesdoSelectedParking(props.row.original.id);
                  }
                  setEditParkingModal(true);
                  setSelectedParking(props.row.original.id);
                  setReassignedSelectedParking(props.row.original.id);
                  setParkingAssignedOption(props.row.original.type);
                }}
                className="self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center"
              >
                <Pen className="w-4 h-4" />
              </button>
              <button className="py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center">
                <Check className="w-4 h-4" />
                <p className="mb-0">Assigned</p>
              </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === "avesdo") {
                      setAvesdoSelectedParking(props.row.original.id);
                    }
                    openAssignParkignModal();
                  }}
                  className="py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center"
                >
                  <Bell height="16" width="16" color="black" />
                  <p className="mb-0">Assign Parking spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const tableColumnsAvesdoParking = useMemo(
    () => [
      {
        Title: (
          <div className="items-center inline-flex">
            <CustomCheckBox />
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Options
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "option",
        dataIndex: "otion",
        Cell: (props) => (
          <div className="items-center flex">
            <CustomCheckBox />
            <p className="mb-0 text-black text-base font-[500]">
              {props.cell.value}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className="items-center inline-flex">
            <p
              className="text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0"
              label="Document"
            >
              Price
            </p>
            <ChevronsUpDown className="w-4 h-4 text-zinc-500 dark:text-zinc-300" />
          </div>
        ),
        accessor: "price",
        dataIndex: "price",
        Cell: (props) => (
          <p className="mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize">
            {props.cell.value.length ? props.cell.value : "—"}
          </p>
        ),
      },
      {
        Title: "",
        accessor: "isAssigned",
        Cell: (props) => (
          <div className="w-full flex flex-row justify-end items-center gap-2 text-center">
            {props.cell.value ? (
              <>
                <button
                  onClick={() => {
                    setEditParkingModal(true);
                    setSelectedParking(props.row.original.id);
                    setReassignedSelectedParking(props.row.original.id);
                    setParkingAssignedOption(props.row.original.type);
                  }}
                  className="self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center"
                >
                  <Pen className="w-4 h-4" />
                </button>
                <button className="py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center">
                  <Check className="w-4 h-4" />
                  <p className="mb-0">Assigned</p>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === "avesdo") {
                      setAvesdoSelectedParking(props.row.original.id);
                    }
                    openAssignParkignModal();
                  }}
                  className="py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center"
                >
                  <Bell height="16" width="16" color="black" />
                  <p className="mb-0">Assign Parking spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const EditParkingModal = () => {
    return (
      <Modal open={editParkingModal} onClose={() => setEditParkingModal(false)}>
        <div className="relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <button
            onClick={() => {
              setEditParkingModal(false);
              setReassignParkingModal(false);
              setUnassignParkingModal(false);
              setParkingAssignedOption("");
              setSelectedParking("");
            }}
            className="absolute right-4 top-4"
          >
            <IoMdClose fontSize="22px" />
          </button>
          {!unassignParkingModal && !reassignParkingModal && (
            <div className="flex flex-col gap-y-3">
              <div className="text-[20px] text-center">
                Select a option to reassign the parking spot
              </div>
              <div className="flex gap-4 items-center justify-center">
                <button
                  onClick={() => {
                    setUnassignParkingModal(true);
                  }}
                  className="py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
                >
                  Unassign Parking
                </button>
                <button
                  onClick={() => {
                    setReassignParkingModal(true);
                    setParkingAssignedOption("");
                  }}
                  className="py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
                >
                  Reassign Parking
                </button>
              </div>
            </div>
          )}
          {unassignParkingModal && (
            <div className="flex flex-col items-center gap-y-4">
              <div className="text-[20px] text-center">
                Are you sure you want to unassign this parking spot?
              </div>
              <div className="flex gap-x-4 items-center">
                <button
                  onClick={async () => {
                    await createOrUpdateParking({
                      _id: selectedParking,
                      status: "Available",
                      unit: null,
                      isAvesdo: false,
                      avesdoId: null,
                    });
                    await createOrUpdateUnit({
                      ...editDetails,
                      parkings: {
                        ...editDetails.parkings,
                        assigned: editDetails.parkings.assigned.filter(
                          (item) => item._id !== selectedParking
                        ),
                      },
                    }).then(async (data) => {
                      refetch();
                      setEditDetails(data);
                      setUnassignParkingModal(false);
                      setEditParkingModal(false);
                    });
                  }}
                  className="py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setUnassignParkingModal(false);
                  }}
                  className="py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
                >
                  No
                </button>
              </div>
            </div>
          )}
          {reassignParkingModal && (
            <div className="flex flex-col gap-y-4">
              <div className="text-[20px] text-center">Reassign Parking</div>
              <CustomSelect
                label={"Select Parking Stall For"}
                options={parkings}
                selectedOption={reassignedSelectedParking}
                setSelectedOption={(value) => {
                  setReassignedSelectedParking(value);
                }}
              />
              <button
                onClick={async () => {
                  if (
                    assignedParkingTableDataSource.filter(
                      (t) =>
                        t.id === reassignedSelectedParking &&
                        t.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      "Parking spot is already assigned to a unit please select another or unassign it first"
                    );
                    return;
                  }
                  let newAssignedParkingArr = editDetails.parkings.assigned.map(
                    (item) => {
                      if (item._id === selectedParking) {
                        return reassignedSelectedParking;
                      }
                      return item._id;
                    }
                  );
                  let concatWaitlistAndAvesdo = [
                    ...avesdoWaitlistedParkings,
                    ...avesdoParkings,
                  ];
                  let avesdoParking = concatWaitlistAndAvesdo.find((item) => {
                    return item.id === avesdoSelectedParking;
                  });
                  await createOrUpdateParking({
                    _id: selectedParking,
                    status: "Available",
                    unit: null,
                    isAvesdo: false,
                    avesdoId: null,
                  });
                  await createOrUpdateParking({
                    _id: reassignedSelectedParking,
                    status: "Assigned",
                    unit: editDetails._id,
                    isAvesdo: avesdoParking ? true : false,
                    avesdoId: avesdoParking ? avesdoParking.id : null,
                  });

                  await createOrUpdateUnit({
                    ...editDetails,
                    parkings: {
                      ...editDetails.parkings,
                      assigned: newAssignedParkingArr,
                    },
                  }).then(async (data) => {
                    refetch();

                    setEditDetails(data);
                    setSelectedParking("");
                    setReassignedSelectedParking("");
                    setReassignParkingModal(false);
                    setEditParkingModal(false);
                  });
                }}
                className="py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
              >
                Save
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const AssignParkingModal = () => {
    return (
      <Modal
        open={assignParkingModal}
        onClose={() => setAssignParkingModal(false)}
      >
        <div className="relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <button
            onClick={() => {
              setAssignParkingModal(false);
              setParkingAssignedOption("");
              setSelectedParking("");
            }}
            className="absolute right-4 top-4"
          >
            <IoMdClose fontSize="22px" />
          </button>
          <div className="text-[20px] text-center">Assigned Parking</div>
          <CustomSelect
            disabled={parkings.length === 0}
            label={"Select Parking Stall"}
            options={parkings}
            selectedOption={selectedParking}
            setSelectedOption={(value) => {
              setSelectedParking(value);
            }}
          />
          <button
            onClick={async () => {
              if (selectedParking === "") {
                toast.error("Please select a parking stall");
                return;
              }
              let concatWaitlistAndAvesdo = [
                ...avesdoWaitlistedParkings,
                ...avesdoParkings,
              ];
              let avesdoParking = concatWaitlistAndAvesdo.find((item) => {
                return item.id === avesdoSelectedParking;
              });
              await createOrUpdateParking({
                _id: selectedParking,
                status: "Assigned",
                unit: editDetails._id,
                isAvesdo: avesdoParking ? true : false,
                avesdoId: avesdoParking ? avesdoParking.id : null,
              });
              await createOrUpdateUnit({
                ...editDetails,
                parkings: {
                  ...editDetails.parkings,
                  assigned: [...editDetails.parkings.assigned, selectedParking],
                },
              }).then(async (data) => {
                setEditDetails(data);
                refetch();
                setSelectedParking("");
                setParkingAssignedOption("");
                setAssignParkingModal(false);
                setTempAvesdoRefetct(!tempAvesdoRefetct);
              });
            }}
            className="py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  const AllocationModal = () => {
    return (
      <Modal open={allocationModal} onClose={() => setAllocationModal(false)}>
        <div className="relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <button
            onClick={() => {
              setTempTypesOfAllocatedParking(typesOfAllocatedParking);
              setAllocationModal(false);
            }}
            className="absolute right-4 top-4"
          >
            <IoMdClose fontSize="22px" />
          </button>
          <h3 className="text-[20px] text-center">Allocate Parking</h3>
          {tempTypesOfAllocatedParking.map((type) => (
            <div key={type.id} className="flex items-center gap-3">
              <div className="w-1/2">
                <CustomSelect
                  inputClasses="h-11"
                  labelClasses={undefined}
                  options={parkingType}
                  selectedOption={type.title}
                  setSelectedOption={(option) => {
                    setTempTypesOfAllocatedParking((prev) => {
                      prev.find((t) => t.id === type.id).title = option;
                      return [...prev];
                    });
                  }}
                />
              </div>
              <CustomInput
                type="number"
                value={type.count}
                onChange={(e) => {
                  if (
                    e.target.value <
                    assignedParkingTableDataSource.filter((item) => {
                      return (
                        item.type === type.title && item.isAssigned === true
                      );
                    }).length
                  ) {
                    toast.error(
                      "Can not reduce the count below the number of assigned parking spots for"
                    );
                    return;
                  }
                  let newTypes = tempTypesOfAllocatedParking.map((t) => {
                    if (t.id === type.id) {
                      return {
                        ...t,
                        count: e.target.value,
                      };
                    }
                    return t;
                  });
                  setTempTypesOfAllocatedParking(newTypes);
                }}
              />
              <button
                onClick={() => {
                  if (
                    assignedParkingTableDataSource.filter(
                      (item) =>
                        item.type === type.title && item.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      "Parking spots are already assigned for" +
                        " " +
                        type.title +
                        ". Please unassign them first"
                    );
                    return;
                  }
                  setTempTypesOfAllocatedParking((prev) => {
                    return prev.filter((t) => t.id !== type.id);
                  });
                }}
                className="h-full"
              >
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z"
                    fill="#2E2B2E"
                  />
                </svg>
              </button>
            </div>
          ))}
          <div
            className="text-[16px] cursor-pointer"
            onClick={() => {
              handleAddParking();
            }}
          >
            + Allocate Parking
          </div>
          <button
            onClick={async () => {
              if (
                tempTypesOfAllocatedParking.some((type) => type.count === 0)
              ) {
                toast.error("Please allocate parking");
                return;
              }
              const titles = new Set();
              for (const type of tempTypesOfAllocatedParking) {
                if (titles.has(type.title)) {
                  toast.error("Duplicate parking title found");
                  return;
                }
                titles.add(type.title);
              }
              await createOrUpdateUnit({
                ...editDetails,
                parkings: {
                  ...editDetails.parkings,
                  allocated: tempTypesOfAllocatedParking.map((type) => ({
                    quantity: type.count,
                    name: type.title,
                  })),
                  totalAllocatedParking: tempTypesOfAllocatedParking.reduce(
                    (acc, curr) => acc + Number(curr.count),
                    0
                  ),
                },
              }).then((data) => {
                refetch();
                setAllocationModal(false);
                let total = tempTypesOfAllocatedParking.reduce(
                  (acc, curr) => acc + Number(curr.count),
                  0
                );
                setPendingParkingAssignment(total);
                setEditDetails(data);
              });
            }}
            className="py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center"
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  useEffect(() => {
    if (editDetails?.files) {
      setDocumentList(editDetails.files);
    }
  }, [editDetails]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      let key = {
        'lot': 'lotData',
        'townhome': 'townhomeData',
        'condo': 'condoData',
      }[editDetails?.type]

      const uploadedFile = await uploadFile({
        projectId,
        docName: file.name,
        file,
        userId: userObject?._id
      });

      const newList = [...documentList, uploadedFile.url];
      setDocumentList(newList);
      let field = key + ".files"
      
      await updateInventoryField(editDetails._id, {
        [field]: newList,
      });
      toast.success("File uploaded successfully");
      
      refetch();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const handleDelete = async (index) => {
    setDeleteIndex(index);
    setIsDeleteModal(true);
  };

  const onDeleteConfirm = async () => {
    try {
      let key = {
        'lot': 'lotData',
        'townhome': 'townhomeData',
        'condo': 'condoData',
      }[editDetails?.type]
 
      let newList = [...documentList];
      newList.splice(deleteIndex, 1);
      newList = newList.filter(item => item !== null);

      let field = key + ".files"
      
      await updateInventoryField(editDetails._id, {
        [field]: newList,
      });
      toast.success("File deleted successfully");
      setIsDeleteModal(false);
      setSelectedFile(null); 
      setDocumentList(newList);
      refetch();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };

  useEffect(() => {
    if (editDetails?.files) {
      setSelectedFile(editDetails.files[0]);
    }
  }, [editDetails]);

  return (
    <div
      className={`right-0 fixed shadow-lg flex ${
        isFullHeight ? "h-[calc(100vh-64px)] bottom-0" : "h-[calc(100vh-100px)]"
      } overflow-hidden ${"w-[1360px]"} bg-white border-t border-l border-[#E4E4E7] ${
        isInfoDrawerOpen ? "translate-x-0" : "translate-x-full"
      } transition-all duration-300 ease-in-out max-w-[calc(100vw-80px)]`}
    >
      <>
        <div className="flex-1 border-l overflow-auto  border-[#E4E4E7] px-8 py-12 flex flex-col gap-4">
          <button
            className="absolute top-3 left-3"
            onClick={() => handleClose()}
          >
            <IoMdClose fontSize="22px" />
          </button>
          <div className="flex items-center gap-x-4">
            <div className="text-3xl font-semibold uppercase text-start">
              {editDetails?.type || type}
            </div>
            <div className="font-medium text-3xl">#{editDetails?.number}</div>
          </div>
          <div className="mb-4">
            <CustomBadge
              title={editDetails.status}
              bgColor={
                editDetails?.statusColor || "#000000"
              }
            />
          </div>
          <div className="w-fit">
            <TabWrapper
              tabMenus={tabMenus}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
            />
          </div>
          <div className="w-full h-[1px] bg-gray-300" />
          {selectedTab === "tab-2" && (
            <div className="flex-1 flex flex-col gap-y-4 overflow-hidden">
              {type === "Unit" && (
                <div>
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <h1 className="text-xl font-semibold">
                        Transaction Details
                      </h1>
                      <p className="text-sm text-muted-foreground flex gap-1">
                        {editDetails?.unitNumber} -
                        {editDetails?.offers?.length > 0 && (
                          <div>
                            {editDetails?.offers[0].buyer[0]?.firstName}{" "}
                            {editDetails?.offers[0].buyer[0]?.lastName}
                          </div>
                        )}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Badge
                        variant="success"
                        className="p-2 w-32 text-center flex justify-center items-center"
                      >
                        {editDetails?.status}
                      </Badge>
                    </div>
                  </div>
                  <Card>
                    <CardHeader>
                      <CardTitle className="text-lg">
                        Basic Information
                      </CardTitle>
                    </CardHeader>

                    <CardContent>
                      <div className="grid gap-6 md:grid-cols-2">
                        <div>
                          <div className="mb-4">
                            <label className="text-sm font-medium">
                              Unit Number
                            </label>
                            <p className="text-sm text-muted-foreground">
                              {editDetails?.unitNumber}
                            </p>
                          </div>
                          <div className="mb-4">
                            <label className="text-sm font-medium">
                              Offer Date
                            </label>
                            <p className="text-sm text-muted-foreground">
                              {moment(editDetails?.createdAt).format("LL")}
                            </p>
                          </div>
                          <div>
                            <label className="text-sm font-medium">
                              Home Design
                            </label>
                            <p className="text-sm text-muted-foreground">-</p>
                          </div>
                        </div>
                        <div>
                          <div className="mb-4">
                            <label className="text-sm font-medium">
                              Sale Price
                            </label>
                            <p className="text-sm text-muted-foreground">
                              {editDetails?.priceCurrencyType} $
                              {editDetails?.price}
                            </p>
                          </div>
                          <div className="mb-4">
                            <label className="text-sm font-medium">
                              Project
                            </label>
                            <p className="text-sm text-muted-foreground">-</p>
                          </div>
                          <div>
                            <label className="text-sm font-medium">
                              Offer Version
                            </label>
                            <p className="text-sm text-muted-foreground">-</p>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              )}
            </div>
          )}
          {selectedTab === "tab-1" && (
            <div className="flex-1 flex flex-col gap-y-4 overflow-hidden">
              {type === "Unit" && (
                <UnitEditViewV2
                  setSelectedFloorPlan={setSelectedFloorPlan}
                  setSelectedFile={setSelectedFile}
                  editDetails={{ ...editDetails, inventoryType: type }}
                  setEditDetails={setEditDetails}
                  refetch={() => {
                    handleClose();
                    refetch();
                  }}
                  building={null}
                  setShow={handleClose}
                  variableName={editDetails?.type}
                />
              )}
            </div>
          )}
          {selectedTab === "tab-3" && (
            <StorageAllocatePage
              type={type}
              selectedBuilding={selectedBuilding}
              refetch={refetch}
              editDetails={editDetails}
              setEditDetails={setEditDetails}
            />
          )}
          {selectedTab === "tab-4" && (
            <>
              <div className="w-full flex mb-8 overflow-hidden flex-col gap-y-6">
                <ShadCNWrapper>
                  <div className="flex flex-col gap-y-[12px] p-6 w-full ">
                    <div className="text-2xl font-semibold">Reservations</div>
                    <div className="overflow-auto mt-1">
                      <table className="w-full ">
                        <tr className="text-[#777777] relative uppercase w-full">
                          {headersForStatic.map((header, index) => {
                            return (
                              <th className="whitespace-nowrap" key={index}>
                                <div className="leading-none text-left text-sm font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                  {header.name}
                                </div>
                              </th>
                            );
                          })}
                          <HorizontalLine className="absolute -bottom-1 left-0" />
                        </tr>
                        {reservationData === [] && "No Data Available"}
                        {reservationData.map((reservation, index) => {
                          return (
                            <tr
                              key={index}
                              className="font-[300] pt-6 text-[#2E2B2E] text-[16px]"
                            >
                              <td className="text-[#2E2B2E] font-[400] ">
                                #{index}
                              </td>
                              <td className="truncate">
                                {reservation.reservation_holders}
                              </td>
                              <td>
                                <div
                                  style={{ backgroundColor: "#FFBE9A" }}
                                  className="w-fit px-[12px] py-1 bg-opacity-25 rounded-lg items-center flex"
                                >
                                  <div className="text-zinc-950 text-[14px] font-semibold">
                                    {reservation.lotChoiceName}
                                  </div>
                                </div>
                              </td>
                              <td className="text-[#2E2B2E] font-[400] ">
                                {moment(
                                  reservation.manualReservationDate
                                    ? reservation.manualReservationDate
                                    : reservation.createdAt
                                ).format("MM/DD/YYYY")}
                              </td>

                              <td className="max-w-[140px] truncate">
                                {reservation?.esscrowAgent?.escrowAgentData
                                  ?.companyName || "-"}
                              </td>
                              <td className="max-w-[140px] truncate"></td>

                              <td className="truncate">
                                {reservation?.notes ?? "-"}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </ShadCNWrapper>
              </div>
            </>
          )}
          {selectedTab === "tab-5" && (
            <>
              <div className="w-full flex mb-8 overflow-hidden flex-col gap-y-6">
                <ShadCNWrapper>
                  <div className="flex flex-col gap-y-[12px] p-6 w-full ">
                    <div className="text-2xl font-semibold">Reservations</div>
                    <div className="overflow-auto mt-1">
                      <table className="w-full ">
                        <tr className="text-[#777777] relative uppercase w-full">
                          {headersForStatic.map((header, index) => {
                            return (
                              <th className="whitespace-nowrap" key={index}>
                                <div className="leading-none text-left text-sm font-normal text-[#2E2B2E] text-opacity-70 capitalize">
                                  {header.name}
                                </div>
                              </th>
                            );
                          })}
                          <HorizontalLine className="absolute -bottom-1 left-0" />
                        </tr>
                        {reservationData === [] && "No Data Available"}
                        {reservationData.map((reservation, index) => {
                          return (
                            <tr
                              key={index}
                              className="font-[300] pt-6 text-[#2E2B2E] text-[16px]"
                            >
                              <td className="text-[#2E2B2E] font-[400] ">
                                #{index}
                              </td>
                              <td className="truncate">
                                {reservation.reservation_holders}
                              </td>
                              <td>
                                <div
                                  style={{ backgroundColor: "#FFBE9A" }}
                                  className="w-fit px-[12px] py-1 bg-opacity-25 rounded-lg items-center flex"
                                >
                                  <div className="text-zinc-950 text-[14px] font-semibold">
                                    {reservation.lotChoiceName}
                                  </div>
                                </div>
                              </td>
                              <td className="text-[#2E2B2E] font-[400] ">
                                {moment(
                                  reservation.manualReservationDate
                                    ? reservation.manualReservationDate
                                    : reservation.createdAt
                                ).format("MM/DD/YYYY")}
                              </td>

                              <td className="max-w-[140px] truncate">
                                {reservation?.esscrowAgent?.escrowAgentData
                                  ?.companyName || "-"}
                              </td>
                              <td className="max-w-[140px] truncate"></td>

                              <td className="truncate">
                                {reservation?.notes ?? "-"}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </ShadCNWrapper>
              </div>
            </>
          )}
          {selectedTab === "tab-6" && (
            <div className="flex-1 flex h-full overflow-hidden">
              <div className="w-[250px] flex flex-col border-r">
                <div className="p-4 border-b">
                  <TButton
                    onClick={() => fileInputRef.current?.click()}
                    className="w-full flex items-center justify-center gap-2"
                  >
                    <Upload className="w-4 h-4" />
                    Upload File
                  </TButton>
                  <input
                    type="file"
                    ref={fileInputRef}
                    onChange={handleFileUpload}
                    className="hidden"
                    accept=".jpg,.png,.jpeg,.pdf"
                  />
                </div>
                <div className="flex-1 overflow-y-auto p-4">
                  <div className="space-y-3">
                    {
                      selectedFloorPlan && <div
                        key={selectedFloorPlan?._id}
                        onClick={() => setSelectedFile(selectedFloorPlan?.image)}
                        className={`
                          relative min-h-[120px] border rounded overflow-hidden cursor-pointer
                          ${selectedFile === selectedFloorPlan?.image ? 'border-primary' : 'border-gray-200'}
                          hover:border-gray-300
                        `}
                      >
                        <div className="h-[120px] w-full">
                          {selectedFloorPlan?.image.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                            <img
                              src={selectedFloorPlan?.image}
                              alt={'Selected FloorPlan Image'}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center overflow-hidden">
                              <Document file={selectedFloorPlan?.image}>
                                <Page pageNumber={1} width={400} height={400} />
                              </Document>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                    {documentList.map((item, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedFile(item)}
                        className={`
                          relative min-h-[120px] border rounded overflow-hidden cursor-pointer
                          ${selectedFile === item ? 'border-primary' : 'border-gray-200'}
                          hover:border-gray-300
                        `}
                      >
                        <div className="h-[120px] w-full">
                          {item.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                            <img
                              src={item}
                              alt={`Document ${index + 1}`}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center bg-gray-50">
                              <Document file={item}>
                                <Page pageNumber={1} width={400} height={400} />
                              </Document>
                            </div>
                          )}

                          <div className="absolute top-2 right-2 flex gap-1">
                            <button
                              className="p-1.5 bg-white rounded shadow-sm hover:bg-gray-50"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(index);
                              }}
                            >
                              <Trash className="w-3.5 h-3.5 text-gray-600" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                    {!documentList?.length && (
                      <div className="text-center py-4 text-sm text-gray-500 border-2 border-dashed rounded">
                        No files uploaded yet
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-col overflow-auto">
                {selectedFile ? (
                  <>
                    <div className="flex items-center justify-between px-4 pt-3 bg-white">
                      <a
                        href={selectedFile}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-sm !text-primary hover:!text-primary/80"
                      >
                        <ExternalLink className="w-6 h-6" />
                      </a>
                    </div>
                    <div className="min-h-[800px] p-6">
                      <div className="h-full flex items-start justify-start">
                        {selectedFile.match(/\.(jpg|jpeg|png|gif)$/i) ? (
                          <img
                            src={selectedFile}
                            alt="Preview"
                            className="max-w-full max-h-full object-contain"
                          />
                        ) : (
                          <iframe
                            src={selectedFile}
                            title="Document preview"
                            className="w-full h-full bg-white"
                          />
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="h-full flex flex-col items-center justify-center text-gray-500">
                    <HiOutlineDocument className="w-12 h-12 mb-2" />
                    <p className="text-sm">Select a file to preview</p>
                  </div>
                )}
              </div>

              <DeleteModal
                title="Confirm deletion?"
                description="Are you sure you want to delete this file?"
                confirmButtonTitle="Yes"
                cancelButtonTitle="No"
                isOpen={isDeleteModal}
                setIsOpen={setIsDeleteModal}
                onConfirm={onDeleteConfirm}
                loading={false}
              />
            </div>
          )}
        </div>
      </>
      {allocationModal && <AllocationModal />}
      {assignParkingModal && <AssignParkingModal />}
      {editParkingModal && <EditParkingModal />}
    </div>
  );
};

export default InventoryEditDrawerV2;
