/* eslint-disable */
// @ts-nocheck
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/css/ag-theme-balham.css";
import { classNames } from "utils";
import { Card, Modal } from "@material-ui/core";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import { groupBy } from "..";
import { getFilteredDeals } from "store/actions/dealAction";
import {
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
} from "store/actions/unitActions";
import { useState } from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { getParkingInventory } from "store/actions/parkingActions";
import InventoryEditDrawer from "./InventoryEditDrawer";
import Skeleton from "react-loading-skeleton";
import { api } from "services/api";
import useGetInventories from "hooks/useGetInventories";
import { getActiveInventoryStatuses, getInventorySettings, updateInventoryField, getInventoryFloorPlan } from "store/actions/inventoryActions";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { checkV2Inventory } from 'utils/checkV2Inventory';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomButton from 'components/CustomButton';
import { getUserTableView, updateUserTableView } from "store/actions/usersActions";
import { RiDraggable } from "@remixicon/react";
import { CloseIcon } from "components/Icons";
import { message } from "antd";


const TEMP_AVAILABILITY_STATUS = {
  available: "available",
  open: "Open",
  sold: "sold",
  soldFirm: "SoldFirm",
  developerHeld: "DeveloperHeld",
  reserved: "reserved",
  reservedBroker: "ReservedBroker",
  reservedBuyer: "ReservedBuyer",
  closed: "Closed",
  underContract: "UnderContract",
  offerAccepted: "OfferAccepted",
};


const STATUS_OPTIONS = [
  ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
    return { value: value, label: value.toUpperCase() };
  }),
];



export default function TableViewInventory({
  selectedBuilding,
  projectId,
  showCards = true,
}) {
  const { inventories, refetch, loading } = useGetInventories(
    `building/${selectedBuilding}`
  );
  const [activeInventoryStatuses, setActiveInventoryStatuses] = useState([]);
  const [inventoryFloorPlans, setInventoryFloorPlans] = useState([]);
  const appProject = useSelector((state) => state.appReducer.appProject);
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingAllocationValues, setParkingAllocationValues] = useState({});
  const parkingColor = ["#59b099", "red", "yellow", "black"];
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const [inventoryFields, setInventoryFields] = useState([]);
  const [inventoryType, setInventoryType] = useState("");
  const [statusColors, setStatusColors] = useState({});
  const [showManageColumns, setShowManageColumns] = useState(false);
  const [internalCols, setInternalCols] = useState([]);

  function formatHeaderName(key) {
    let formatted = key.charAt(0).toUpperCase() + key.slice(1);  
    return formatted;
  }
  const gridRef = useRef(null)

  inventories?.inventory?.forEach((inventory) => {
    // if (inventory[`${inventoryType}Data`]?.hasOwnProperty('number')) {
    //   inventory[`${inventoryType}Data`].number = Number(inventory[`${inventoryType}Data`].number);
    // }

    if(inventory[`${inventoryType}Data`]?.floorPlan?.hasOwnProperty('name')) {
      inventory[`${inventoryType}Data`].floorPlan = inventory[`${inventoryType}Data`].floorPlan.name;
    }
  });

  useEffect(() => {
    fetchStatuses();
    fetchFloorPlans();
  }, [selectedBuilding, projectId, inventories]);

  const fetchStatuses = async () => {
    try {
      const data = await getActiveInventoryStatuses(projectId);
      let mapped = data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          id: item.id
        }
      })
      const colorMap = data.reduce((acc, status) => {
        acc[status.name.toLowerCase()] = status.color || '#808080';
        return acc;
      }, {});
      setActiveInventoryStatuses(mapped);
      setStatusColors(colorMap);
    } catch (error) {
      console.error('Error fetching inventory statuses:', error);
    }
  };

  const fetchFloorPlans = async () => {
    try {
      const data = await getInventoryFloorPlan(projectId);
      let mapped = data.map((item) => {
        return {
          value: item.id,
          label: item.name,
          id: item.id
        }
      })
      setInventoryFloorPlans(mapped);
    } catch (error) {
      console.error('Error fetching inventory statuses:', error);
    }
  };

  const DropdownCellEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);
    const tableRef = props.tableRef;
    const options = props.options
    const selectOptions = options.map((option) => ({
      value: option.id,
      label: option.label,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });
  
    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      setTimeout(() => {
        props.api.stopEditing();
      }, 0);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };
  
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);
  
    return (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      ></Select>
    );
  });

  // useEffect(() => {
  //   const fetchStatusColors = async () => {
  //     try {
  //       const statuses = await getActiveInventoryStatuses();
  //       const colorMap = statuses.reduce((acc, status) => {
  //         acc[status.name.toLowerCase()] = status.color || '#808080';
  //         return acc;
  //       }, {});
  //       setStatusColors(colorMap);
  //     } catch (error) {
  //       console.error('Failed to fetch status colors:', error);
  //     }
  //   };

  //   if (checkV2Inventory()) {
  //     fetchStatusColors();
  //   }
  // }, [activeInventoryStatuses]);

  useEffect(() => {
    if (activeInventoryStatuses.length > 0) {
      getInventorySettings(appProject).then((data) => {
        let type = data[0].type;
        setInventoryType(type);
        let fields = Object.keys(data[0][type + "Data"]).reduce((acc, key) => {
          if (key === "additionalFields") {
            const additionalFieldsMapped = data[0][type + "Data"][key].map(
              (item) => {
                if (item.enabled === true) {
                  return {
                    flag: "additionalFields",
                    headerName: formatHeaderName(item.name),
                    field: type+'Data.additionalFields.'+item.id,
                  };
                }
              }
            );
            return [...acc, ...additionalFieldsMapped];
          } else {
            if (data[0][type + "Data"][key].enabled === true) {
              return [
                ...acc,
                {
                  headerName: formatHeaderName(key),
                  field: type + "Data." + key,
                  editable: true,
                  sort: key === "number" ? 'asc' : null,
                  comparator: key === "number" ? (valueA, valueB, nodeA, nodeB, isDescending) => {
                    const isNumeric = (value) => !isNaN(value) && value.trim() !== "";
                    if (isNumeric(valueA) && isNumeric(valueB)) {  
                      const numA = Number(valueA);
                      const numB = Number(valueB);
                      return numA - numB;
                    }
                
                    if (isNumeric(valueA) && !isNumeric(valueB)) {
                      return isDescending ? 1 : -1;
                    }
                    if (!isNumeric(valueA) && isNumeric(valueB)) {
                        return isDescending ? -1 : 1;
                    }
                    return 0
                  }: null,
                  cellEditor: data[0][type + "Data"][key].type === 'dropdown' ? DropdownCellEditor : null,
                  cellEditorParams: data[0][type + "Data"][key].type === 'dropdown' && key === "status" ?
                  {
                    tableRef: gridRef,
                    options: activeInventoryStatuses
                  } : data[0][type + "Data"][key].type === 'dropdown' && key === "floorPlan" ? {
                    tableRef: gridRef,
                    options: inventoryFloorPlans
                  } : null,
                  cellRenderer: data[0][type + "Data"][key].type === 'dropdown' ? 
                    (params) => {
                      return (
                        <div style={getStatusCellStyle(params)}>
                          {params.value || 'N/A'}
                        </div>
                      );
                    } : null
                },
              ];
            } else {
              return acc;
            }
          }
        }, []);
        let filter = fields.filter((item) => item !== undefined);
        setInventoryFields(filter);
      });
    }
  }, [appProject, activeInventoryStatuses, inventoryFloorPlans]);

    
  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: "Available", value: value.totalParkings || 0 },
      { name: "Sold", value: 0 },
      { name: "Allocated", value: 0 },
      { name: "Assigend", value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const initialEmptyValues = {
    building: selectedBuilding,
    options: [],
    parkingLevels: ["P1", "P2"],
    totalParkings: null,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues?.options?.reduce(
      (sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      },
      0
    );
    setTotalAssignedParkingAllocate(totalParking);
  };

  const parkingAllocationStatus = [
    {
      name: "Allocated",
      value: totalAssignedParkingAllocate,
    },
    {
      name: "Unallocated",
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  useEffect(() => {
    const handleParkingInventory = async () => {
      const { data } = await api.get(
        `/parking/${selectedBuilding}/allocateInventory`
      );
      const parkingLevelsAllocate = data.data[0].parkingLevels;
      const parkingLevels = value.parkingLevels;
      const fetchedOptions = data.data[0].options;

      setParkingAllocationValues({
        ...data.data[0],
      });
    };
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(
          `/parking/${selectedBuilding}/inventory`
        );
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error("Error fetching building inventory:", error);
      }
    };
    handleBuildingInventory();
    handleParkingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkingsAllocate();
  }, [parkingAllocationValues]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true
    };
  }, []);

  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: "",
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = ["#FFBE9A", "#16A34A40", "#FFD700", "#FF0000"];
  useEffect(() => {
    setIsLoading(true);
    const units = getUnitsByBuildingIdTableView(selectedBuilding).then(
      (data) => {
        let mapped = data.map((unit) => {
          return {
            ...unit,
            unitNumber: unit.unitNumber,
            buyerName: unit.buyerName,
            soldAt: unit.avesdoData?.acceptedDate,
            floorNumber: unit.floorNumber,
            unitType: unit.unitType,
            status: unit.status,
            area: unit.area,
            isOnWaitlist: unit?.avesdoData,
            acceptedDate: unit?.avesdoData?.acceptedDate,
            totalParkingStalls:
              (unit?.numberParkingIncluded || 0) +
              (unit?.numberParkingOptional || 0),
          };
        });
        setFilteredUnits(mapped);
        setIsLoading(false);
      }
    );
  }, [selectedBuilding, tempRefetch]);

  const getRowId = useCallback((params) => String(params.data._id), []);

  const getStatusCellStyle = (params) => {
    let defaultStyle = {
      backgroundColor: '#E5E7EB',
      color: '#1F2937',
      border: '1px solid #E5E7EB',
      display: 'inline-block',
      padding: '2px 8px',
      borderRadius: '9999px',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    }
    if (!checkV2Inventory()) {
      const status = params.value?.toLowerCase()
      const availableTags = [
        ['available', { backgroundColor: '#3B82F6', color: 'white' }],
        ['soldfirm', { backgroundColor: '#EF4444', color: 'white' }],
        ['closed', { backgroundColor: '#EF4444', color: 'white' }],
        ['sold', { backgroundColor: '#EF4444', color: 'white' }],
        ['open', { backgroundColor: '#22C55E', color: 'white' }],
        ['assigned', { backgroundColor: '#22C55E', color: 'white' }],
        ['reservedbroker', { backgroundColor: '#22C55E', color: 'white' }],
        ['reservedbuyer', { backgroundColor: '#22C55E', color: 'white' }],
        ['under contract', { backgroundColor: '#F97316', color: 'white' }],
        ['undercontract', { backgroundColor: '#F97316', color: 'white' }],
        ['offeraccepted', { backgroundColor: '#F97316', color: 'white' }],
        ['developerheld', { backgroundColor: '#F97316', color: 'white' }]
      ]
      const matchingTag = availableTags.find(([tag]) => status?.includes(tag))
      return matchingTag
        ? matchingTag[1]
        : defaultStyle
    }

    const status = params.value?.toLowerCase();
    const color = statusColors[status];
    if (!color) return defaultStyle;

    return {
      backgroundColor: `${color}`, 
      border: `1px solid ${color}`,
      color: getTextColorFromBgColor(color),
      display: 'inline-block',
      padding: '2px 8px',
      borderRadius: '9999px',
      fontSize: '0.75rem',
      lineHeight: '1rem',
      textAlign: 'center',
      whiteSpace: 'nowrap'
    };
  };

  const getTextColorFromBgColor = (bgcolor) => {
    try {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(bgcolor);
      if (!result) return '#000000';
      
      const r = parseInt(result[1], 16);
      const g = parseInt(result[2], 16);
      const b = parseInt(result[3], 16);
      
      return (r * 299 + g * 587 + b * 114) / 1000 > 125 ? '#000000' : '#FFFFFF';
    } catch (error) {
      return '#000000';
    }
  };

  const handleGetUserColumnsView = async () => {
    try {
      const response = await getUserTableView(appProject, 'tableView');
      if (response?.columns) {
        const columnData = response?.columns;
        const savedCols = inventoryFields;
        const merged = savedCols.map((col) => {
          const match = columnData.find((s) => s.field === col.field);
          return match ? { ...col, hide: match.hide } : col;
        });

        const ordered = columnData
          .map((s) => merged.find((m) => m.field === s.field))
          .filter(Boolean);
        const finalCols = ordered.concat(
          merged.filter((m) => !ordered.includes(m))
        );

        setInternalCols(finalCols);
      } else {
        setInternalCols(inventoryFields);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const toggleColumn = (field) => {
    setInternalCols((prev) => {
      const updated = prev.map((col) =>
        col.field === field ? { ...col, hide: !col.hide } : col
      );
      return updated;
    });
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    setInternalCols((prev) => {
      const newCols = Array.from(prev);
      const [moved] = newCols.splice(result.source.index, 1);
      newCols.splice(result.destination.index, 0, moved);
      return newCols;
    });
  };

  const handleSaveSortedColumns = async () => {
    try {
      const columns = internalCols.map((col) => ({
        field: col.field,
        hide: col.hide || false,
      }));
      let params = {
        projectId: appProject,
        type: 'tableView',
        columns: columns
      };
      await updateUserTableView(params);
      setShowManageColumns(false);
      message.success('Columns updated successfully');
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleGetUserColumnsView();
  }, [inventoryFields]);

  // const onDragEndTable = async (result, colStateAfterMoved) => {
  //   try {
  //     result.columnApi.applyColumnState({
  //       state: colStateAfterMoved,
  //       applyOrder: true,
  //   });
  //   await handleSaveSortedColumnsFromTable(colStateAfterMoved);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleSaveSortedColumnsFromTable = async (colStateAfterMoved) => {
    try {
      const columns = colStateAfterMoved.map((col) => ({
        field: col.colId,
        hide: col.hide,
      }))
      let params = {
        projectId: appProject,
        type: 'tableView',
        columns: columns
      }
       await updateUserTableView(params);
      //  await handleGetUserColumnsView()
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="flex w-full h-full">
      {isLoading ? (
        <div className="h-full w-full flex flex-col gap-y-4">
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-y-5">
          {/* {userObject.userType === 'DeveloperAdmin' && (
          <div className="flex justify-end mb-4">
            <CustomButton
              handleClick={() => setShowManageColumns(true)}
              className="px-3 py-2 text-sm"
            >
              Manage Columns
            </CustomButton>
          </div>
)} */}
          <div
            className={classNames(
              "h-full ag-theme-alpine w-full transition-all relative",
              isDrawerOpen ? "mr-[350px]" : "mr-0"
            )}
          >
            <AgGridReact
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowHeight={40}
              ref={gridRef}
              columnDefs={internalCols}
              rowData={inventories?.inventory}
              getRowId={getRowId}
              // onColumnMoved={(params)=>{
              //   let colStateAfterMoved = params.columnApi.getColumnState()
              //   onDragEndTable(params, colStateAfterMoved)
              // }}
              onCellValueChanged={(e) => {
                if (e.column.colId.includes('status')) {
                  let newValue = e.newValue
                  let type = e.data.type
                  let label = activeInventoryStatuses.find((item) => item.value === newValue)?.label
                  let colId = e.column.colId
                  e.api.applyTransaction({
                    update: [{
                      ...e.data,
                      [type + 'Data']:{
                        ...e.data[type + 'Data'],
                        status: label
                      }
                    }],
                  });

                  e.api.refreshCells({
                    force: true,
                  });

                  updateInventoryField(e.data._id, {
                    [colId]: newValue,
                    type: inventoryType
                  })
                  return
                } else if(e.column.colId.includes(`${inventoryType}Data.floorPlan`)) {
                  let newValue = e.newValue
                  let type = e.data.type
                  let label = inventoryFloorPlans.find((item) => item.value === newValue)?.label
                  let colId = e.column.colId
                  e.api.applyTransaction({
                    update: [{
                      ...e.data,
                      [type + 'Data']:{
                        ...e.data[type + 'Data'],
                        floorPlan: label
                      }
                    }],
                  });

                  e.api.refreshCells({
                    force: true,
                  });

                  updateInventoryField(e.data._id, {
                    [colId]: newValue,
                    type: inventoryType
                  })
                  return
                } else {
                  let newValue = e.newValue
                  let colId = e.column.colId
                  e.api.applyTransaction({
                    update: [{
                      ...e.data,
                      [colId]: newValue
                    }],
                  });
                  e.api.refreshCells({
                    force: true,
                  });
                  updateInventoryField(e.data._id, {
                    [colId]: newValue,
                    type: inventoryType
                  })
                  return
                }
              }}
            />
          </div>
          {/* <Modal
            open={showManageColumns}
            onClose={() => setShowManageColumns(false)}
          >
            <div className="w-full h-full flex items-center justify-center">
              <div className="bg-white flex flex-col gap-y-3 p-4 relative min-w-[500px] h-fit w-fit">
                <div className="absolute cursor-pointer right-4 top-4">
                  <CloseIcon
                    onClick={() => setShowManageColumns(false)}
                  />
                </div>
                <h2 className="text-lg font-bold mb-4">Manage Columns</h2>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="manageColumns">
                    {(provided) => (
                      <div
                        className="p-3 space-y-1.5 bg-black/5 max-h-[400px] overflow-y-auto"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {internalCols.map((col, index) => (
                          <Draggable
                            key={col.field}
                            draggableId={col.field}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="group flex items-center bg-white p-2 rounded border border-black/10 hover:border-black/20 transition-all duration-200"
                              >
                                <div className="flex items-center gap-3 w-full">
                                  <input
                                    type="checkbox"
                                    checked={!col.hide}
                                    onChange={() => toggleColumn(col.field)}
                                    className="h-4 w-4 rounded border-black/20 text-black focus:ring-black/20"
                                  />
                                  <RiDraggable className="text-black/40 group-hover:text-black/60 transition-colors" />
                                  <span className="text-sm text-black font-medium">
                                    {col.headerName}
                                  </span>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <CustomButton handleClick={handleSaveSortedColumns}>
                  <span className="px-3 py-2">Save</span>
                </CustomButton>
              </div>
            </div>
          </Modal> */}
        </div>
      )}
      <div className="absolute right-0 top-0 bottom-0 z-[999]">
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={"Unit"}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
