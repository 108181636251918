/*eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import TaskRow from 'components/TaskCard';
// import TaskModal from 'components/TaskModal';
import Skeleton from 'react-loading-skeleton';
import useGetUserTasks from 'hooks/useGetUserTasks';
import CustomButton from './CustomButton';
import CustomSelect from 'pages/tasks/components/CustomSelect';
import { getAllSalesRepByProject } from 'store/actions/usersActions';
import { useSelector } from 'react-redux';
import { getTasks } from 'store/actions/taskActions';
import { set } from 'lodash';
import TaskModal from 'pages/tasks/components/TaskModal';
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel';

export const TaskTab = ({ contact }) => {
  const [loading, setLoading] = useState(true);
  const [salesRepData, setSalesRepData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [selectedSalesRep, setSelectedSalesRep] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const fetchAllTasks = async () => {
    let filter = '?buyer=' + contact?._id;
    if (selectedSalesRep === '') {
      setLoading(true);
      setTasks([]);
      await getTasks('', filter).then((res) => {
        setTasks(res);
      });
      setLoading(false);
    } else {
      setLoading(true);
      setTasks([]);
      await getTasks(selectedSalesRep, filter).then((res) => {
        setTasks(res);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllSalesRepByProject(projectId).then((res) => {
      setSalesRepData([
        ...res,
        { _id: 'all', firstName: 'All', lastName: 'Sales Reps' },
      ]);
    });
  }, []);

  useEffect(() => {
    fetchAllTasks();
  }, [selectedSalesRep]);

  const handleCreateTaskClick = () => {
    // Track start task creation event
    mixpanel.track(MIXPANEL_EVENTS.TASK_CREATE_START)

    setOpen(true)
  }

  return (
    <>
      {loading && (
        <div className='flex flex-col gap-6 mt-8 p-4 md:p-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!loading && (
        <div className='pt-4 px-4 md:pt-6 md:px-8 flex items-end justify-end'>
          <div className='w-[200px] text-start mt-6 flex flex-col gap-y-1'>
            <CustomSelect
              label='Select Sales Rep'
              value={
                selectedSalesRep === ''
                  ? 'Select Assigned To'
                  : selectedSalesRep
              }
              handleChange={(value) => {
                setSelectedSalesRep(value);
              }}
              options={salesRepData}
              isName
            />
          </div>
        </div>
      )}
      {!loading && tasks.length === 0 && (
        <div className='font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded p-4 md:p-8'>
          <div className='flex flex-col justify-center items-center'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M10 6.5H15V4.5H10V6.5ZM10 13.5H15V11.5H10V13.5ZM16 18H2C0.9 18 0 17.1 0 16V2C0 0.9 0.9 0 2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18ZM3 8H8V3H3V8ZM4 4H7V7H4V4ZM3 15H8V10H3V15ZM4 11H7V14H4V11Z'
                fill='#2E2B2E'
              />
            </svg>
            <div className='font-semibold mb-2 mt-4'>
              You don't have any tasks yet
            </div>
            <div className='text-sm text-softBlack70 mb-6'>
              Create your first task to Aaron
            </div>
            <CustomButton handleClick={handleCreateTaskClick}>
              <span className='font-semibold px-4 py-2 text-base'>
                Create Task
              </span>
            </CustomButton>
          </div>
        </div>
      )}

      {tasks.length > 0 && (
        <div className='pb-4 px-4 md:pb-8 md:px-8 mt-4'>
          <div className='flex flex-col gap-6'>
            <div className='w-full flex items-end gap-x-8 justify-end text-center'>
              {/* <div className='w-[200px] text-start mt-6 flex flex-col gap-y-1'>
                <CustomSelect
                  label='Select Sales Rep'
                  value={
                    selectedSalesRep === ''
                      ? 'Select Assigned To'
                      : selectedSalesRep
                  }
                  handleChange={(value) => {
                    setSelectedSalesRep(value);
                  }}
                  options={salesRepData}
                  isName
                />
              </div> */}
              <CustomButton handleClick={handleCreateTaskClick}>
                <span className='font-medium p-2 px-4 text-base'>
                  Create Task
                </span>
              </CustomButton>
            </div>

            {tasks.map((task, index) => {
              return (
                <TaskRow
                  refetch={fetchAllTasks}
                  key={`${task?._id}${index}`}
                  task={task}
                />
              );
            })}
          </div>
        </div>
      )}
      <TaskModal
        show={open}
        setClose={handleClose}
        contact={contact}
        refetch={fetchAllTasks}
      />
    </>
  );
};
