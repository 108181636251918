/* eslint-disable */
// @ts-nocheck
import { api, ApiErrors } from 'services/api'

export const getReservations = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`reservation/project/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservationsForAllSalesRep = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`reservation-all/project/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservationDetail = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`reservation/${id}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getDocuSealDocumentPreview = (reservationId = '', buyerId = '') =>
  new Promise((resolve, reject) => {
    api
      .get(`reservation/contracts/reservation/preview/${reservationId}/${buyerId}`)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const setReservationDetails = (props) => {
  return new Promise((resolve, reject) => {
    const { _id, ...params } = props
    const verb = _id ? 'put' : 'post'
    const url = _id ? `reservation/${_id}` : 'reservation'

    api.setHeader('Content-Type', 'application/json', 'Cache-Control', 'no-cache, no-store, must-revalidate', 'Pragma', 'no-cache', 'Expires', '0')
    params._cacheBuster = new Date().getTime()

    api[verb](url, params).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    }).catch((error) => {
      console.error('API call failed:', error)
      reject(error)
    })
  })
}

export const deleteReservationDocument = (reservationId, documentId) =>
  new Promise((resolve, reject) => {
    api
      .delete(`reservation/${reservationId}/document/${documentId}`)
      .then((response) => {
        if (response.ok) {
          resolve(response?.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const deleteReservation = (reservationId) =>
  new Promise((resolve, reject) => {
    api.delete(`reservation/${reservationId}`).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const cancelReservation = (reservationId) =>
  new Promise((resolve, reject) => {
    api.put(`reservation/cancel/${reservationId}`, {}).then((response) => {
      if (response.ok) {
        resolve(response?.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getPublicReservationData = (token) =>
  new Promise((resolve, reject) => {
    api.get('/reservation/public', { token }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservedUnitInterests = (token) =>
  new Promise((resolve, reject) => {
    api.get('reservation/interests/unit', { token }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservationByUnitChoice = (filters) =>
  new Promise((resolve, reject) => {
    const params = new URLSearchParams(filters).toString()
    api.get(`/reservation/lotchoice/unit?${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
 
export const getReservationByInventoryChoice = (filters) =>
    new Promise((resolve, reject) => {
      const params = new URLSearchParams(filters).toString()
      api.get(`/reservation/lotchoice/inventory?${params}`).then((response) => {
        if (response.ok) {
          resolve(response.data)
        } else {
          ApiErrors(response)
          reject()
        }
      })
    })
