/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getAllContractsByEmail } from 'store/actions/contractTemplateActions'
import CustomButton from 'components/CustomButton'
import { X } from 'lucide-react'
import { Modal } from '@material-ui/core'
// import SingularDocumentsSign from 'pages/buyerSignSinglePageContract/SingularDocumentsSign'
import { CONTRACT_TYPES } from 'components/ContractSigningScreen/constants'
import { DOCUSEAL_ROLES } from 'utils'
import ContractSigningScreen from 'components/ContractSigningScreen'

const convertFromCamelCase = (str) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .toLowerCase()
    .trim();
}

const ContractItem = ({
  contract,
  setSelectedContract,
  setSelectedContractType
}) => {
  return (
    <div
      className='border-b border-[#e6e6e6] grid grid-cols-5 overflow-hidden'
    >
      <div className='col-span-2 self-stretch text-zinc-950 text-sm font-medium leading-tight p-3 py-4'>{contract.contractTemplate.name}</div>
      <div className='col-span-1 self-stretch text-center text-zinc-950 text-sm font-medium leading-tight p-3 py-4 capitalize'>
        {convertFromCamelCase(contract.contractTemplate.contractType)}
      </div>
      <div className='col-span-1 text-zinc-950 text-sm font-medium leading-tight p-3 py-4 capitalize'>
        <div className={`w-fit mx-auto rounded-full px-3 py-1 font-semibold ${contract.status === 'signed' ? 'bg-green-200' : 'bg-yellow-200'}`}>
          {
            contract.status === 'awaiting_signature'
              ? 'Pending'
              : contract.status
          }
        </div>
      </div>
      <div className='col-span-1 flex items-center justify-end'>
        <CustomButton
          handleClick={() => {
            setSelectedContract(contract)
            setSelectedContractType(contract.contractTemplate.contractType)
          }}
          className='h-fit'
        >
          <span className="font-medium py-1 px-4 text-base flex gap-2 items-center">
            Open
          </span>
        </CustomButton>
      </div>
    </div>
  )
}

const Header = () => {
  return (
    <div
      className='border-b border-zinc-950 grid grid-cols-5 overflow-hidden'
    >
      <div className='col-span-2 self-stretch text-zinc-950 text-sm font-medium leading-tight p-3'>Title</div>
      <div className='col-span-1 self-stretch text-center text-zinc-950 text-sm font-medium leading-tight p-3'>
        Type
      </div>
      <div className='col-span-1 self-stretch text-center text-zinc-950 text-sm font-medium leading-tight p-3'>
        Status
      </div>
    </div>
  )
}

const DocumentSigningModal = ({ selectedContract, selectedContractType, handleOnModalClose }) => {
  return (
    <Modal
      open={selectedContract && selectedContract?._id?.length && selectedContractType?.length}
      onClose={handleOnModalClose}
      aria-labelledby='Offer Documents'
      aria-describedby='Offer documents list'
    >
      <div
        className='font-openSans relative bg-white py-0 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full md:h-[calc(100%-5px)] overflow-auto w-full md:w-[calc(100%-5px)]'
      >
        <button
          className='absolute right-4.5 top-4.5 !z-[500]'
          onClick={handleOnModalClose}
        >
          <X />
        </button>
        <ContractSigningScreen
          isPublicPath
          contracts={[selectedContract]}
          modalType={selectedContractType}
          currentUserType={DOCUSEAL_ROLES.BUYER}
          setCloseDocument={handleOnModalClose}
        />
      </div>
    </Modal>
  )
}

const BuyerDocuments = (props) => {
  const { userObject } = props
  const [isFetching, setIsFetching] = useState(true)
  const [documents, setDocuments] = useState([])
  const [selectedContract, setSelectedContract] = useState(null)
  const [selectedContractType, setSelectedContractType] = useState(null)

  const fetchContracts = async () => {
    setIsFetching(true)
    if (userObject?.email) {
      await getAllContractsByEmail(userObject.email).then((res) => {
        setDocuments(res)
        setIsFetching(false)
      }).catch(() => {
        setIsFetching(false)
      })
    }
  }

  useEffect(() => {
    fetchContracts()
  }, [userObject])

  const handleOnModalClose = () => {
    setSelectedContract(null)
    setSelectedContractType(null)
    fetchContracts()
  }

  return (
    <>
      <section className='w-full min-h-full p-7 flex flex-col overflow-y-auto bg-siteColor'>
        <Header />
        {
          isFetching
            ? <div className='min-h-[300px] w-full flex justify-center items-center p-4 text-zinc-600 text-base font-medium'>
              Loading...
            </div>
            : <>
              {
                documents.map((document, index) => (
                  <ContractItem
                    key={index}
                    contract={document}
                    setSelectedContract={setSelectedContract}
                    setSelectedContractType={setSelectedContractType}
                  />
                ))
              }
            </>
        }
      </section>
      <DocumentSigningModal
        selectedContract={selectedContract}
        selectedContractType={selectedContractType}
        handleOnModalClose={handleOnModalClose}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})
const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BuyerDocuments)
