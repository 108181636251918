// @ts-nocheck
import React, { useMemo, useState } from 'react'
import { Bell, ChevronDown, ChevronRight, X } from 'lucide-react'
import { CONTRACT_ROLES_CONFIG, CONTRACT_STATUS_CONFIG, CONTRACT_STATUSES } from 'components/ContractSigningScreen/constants'
import { Modal } from '@material-ui/core'
import CustomButton from 'components/CustomButton'
import { DOCUSEAL_ROLES } from 'utils'
import { Oval } from 'react-loader-spinner'

const SignatureHistoryModal = ({
  entityId = null,
  show = false,
  onClose = () => { },
  currentUserType,
  signatures = {},
  isUserSigned = false,
  isShowReminderUI = false,
  sendEmail = () => { }
}) => {
  const [isSending, setIsSending] = useState({
    [DOCUSEAL_ROLES.BUYER]: false,
    [DOCUSEAL_ROLES.DEV_ADMIN]: false,
    [DOCUSEAL_ROLES.SALES_REP]: false
  })

  const steps = useMemo(() => {
    if (signatures?.length > 0) {
      return signatures?.map((signature, index) => {
        const status = signature?.status || 'Pending'
        const isCurrentUser = signature.userType === currentUserType
        const config = CONTRACT_STATUS_CONFIG?.[status]
        const name = CONTRACT_ROLES_CONFIG?.[signature?.userType]

        return {
          ...signature,
          ...config,
          name,
          status,
          isCurrentUser
        }
      })
    }

    return []
  }, [signatures, currentUserType])

  const currentStep = useMemo(() => {
    const lastSignedIndex = steps.findLastIndex((step) => step?.status?.toLowerCase() === 'signed')
    return lastSignedIndex + 1
  }, [steps])

  const sendReminderViaEmail = async (role) => {
    if (!role || !entityId) return

    setIsSending((prev) => ({
      ...prev,
      [role]: true
    }))

    try {
      await sendEmail(entityId, role)
      onClose()
    } catch (error) {
      console.log(error)
    } finally {
      setIsSending((prev) => ({
        ...prev,
        [role]: false
      }))
    }
  }

  return (
    <Modal
      open={show}
      onClose={onClose}
      aria-labelledby='Signature History'
      aria-describedby='Signature History list'
    >
      <div
        className='relative bg-white rounded-lg shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-[95%] lg:min-w-[900px] lg:max-w-[1000px] h-[80dvh] md:h-[40vh] md:max-h-[400px] overflow-y-auto flex flex-col gap-6 p-[12px] md:!p-[20px]'
      >
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl md:text-xl font-semibold md:font-bold mb-0'>
            {
              !isShowReminderUI
                ? 'Signature History'
                : 'Send reminder'
            }
          </h2>
          <button onClick={onClose} className='text-zinc-500 hover:text-zinc-700 transition-all focus:!outline-none focus:!ring-0'>
            <X className='w-6 h-6' />
          </button>
        </div>
        {
          steps?.length &&
          <div className='flex-1 w-full flex flex-col'>
            <div className='flex-1 flex flex-col md:!flex-row gap-4 md:!gap-0 items-center justify-evenly md:justify-center'>
              {steps.map((step, index) => (
                <div key={step._id} className='relative flex md:!flex-col items-center w-[80%] md:!w-1/3 gap-5 md:gap-2'>
                  <div className='relative'>
                    <div
                      className={`w-12 h-12 rounded-full flex items-center justify-center font-bold ${step?.bgColor || 'bg-zinc-300'} ${step?.textColor || 'bg-zinc-900'}`}
                    >
                      {step.userType[0]}
                    </div>
                    {step.isCurrentUser && (
                      <span className='absolute -top-2 -right-2 px-2 py-1 text-xs font-semibold bg-blue-500 text-white rounded-full'>
                        YOU
                      </span>
                    )}
                  </div>
                  <div className='flex-1 flex flex-col md:items-center'>
                    <p className='w-fit text-center text-sm font-medium mb-0'>{step?.name}</p>
                    <p className='w-fit text-center mt-1 text-xs text-zinc-500 mb-0'>{step?.email}</p>
                    <p className={`w-fit text-center mt-1 md:mt-3 text-xs font-semibold text-zinc-700 mb-0 px-2.5 py-1 rounded-full ${step?.bgColor || 'bg-zinc-300'} ${step?.textColor || 'bg-zinc-900'}`}>
                      {step?.label || 'Pending'}
                    </p>
                    {
                      isShowReminderUI && step.status !== CONTRACT_STATUSES.SIGNED &&
                      <CustomButton
                        className='mt-3'
                        handleClick={() => sendReminderViaEmail(step.userType)}
                        disabled={isSending[step.userType]}
                      >
                        <span className='text-base flex items-center gap-2 px-2 py-1.5'>
                          {
                            isSending[step.userType]
                              ? <Oval height='16' width='16' color='white' />
                              : <Bell className='w-4 h-4' />
                          }
                          Send reminder
                        </span>
                      </CustomButton>
                    }
                  </div>
                  {
                    (index + 1) !== steps?.length &&
                    <>
                      <div className='absolute -right-2.5 top-1/2 transform -translate-y-1/2 hidden md:!block'>
                        <ChevronRight className='w-6 h-6 text-zinc-400' />
                      </div>
                      <div className='absolute -bottom-12 left-1/2 transform -translate-y-1/2 block md:!hidden'>
                        <ChevronDown className='w-6 h-6 text-zinc-400' />
                      </div>
                    </>
                  }
                </div>
              ))}
            </div>
            <div className='relative hidden md:!block h-[20px]'>
              <div className='absolute top-0 left-0 w-full h-1.5 bg-zinc-200 rounded-full'>
                <div
                  className='absolute top-0 left-0 h-full bg-green-500 rounded transition-all duration-300 ease-in-out'
                  style={{ width: `${(currentStep / (steps.length)) * 100}%` }}
                ></div>
              </div>
            </div>
            {
              !isUserSigned &&
              <p className='w-full text-center text-sm font-medium my-3'>You cannot sign the document until the previous signer has completed their signature.</p>
            }
          </div>
        }
      </div>
    </Modal>
  )
}

export default SignatureHistoryModal
