/* eslint-disable */
import { PlusIcon, SettingsIcon } from 'lucide-react'
import { Drawer } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomButton from 'components/CustomButton'
import useWorkflows from 'hooks/useCustomWorkflows'
import ReactFlowProviderComponent from 'components/ReactFlowProvider'
import FormSettingsComponent from './formSettingsComponent'

export default function FormSettingsDrawer({ formId, onClose }) {
    const [showDrawer, setShowDrawer] = React.useState(false)

    return (
        <div>
            <CustomButton
                className='py-1 px-2 flex gap-x-1 rounded-full'
                onClick={() => setShowDrawer(!showDrawer)}
            >
                <SettingsIcon size={14} />
                <span>Workflow Automation</span>
            </CustomButton>
            <Drawer title='Form Settings'
                size='large'
                style={{
                    padding: 0,
                    margin: 0
                }}
                open={showDrawer}
                onClose={() => {
                    if (onClose && typeof onClose === 'function') {
                        onClose()
                    }
                    setShowDrawer(false)
                }}
                width={"100%"}
                bodyStyle={{
                    padding: '5px',
                    margin: '0'
                }}
            >
                <div className='flex divide-x gap-x-2.5 max-h-[100%]'>
                    <div className='flex flex-row divide-x gap-x-2.5 max-h-[100%]'>
                        <FormSettingsComponent formId={formId} showTitle={true} />
                    </div>
                    <div className='px-2 flex-1 max-h-screen overflow-y-auto'>
                        <FormAutomationSetting formId={formId} />
                    </div>
                </div>
            </Drawer >
        </div >
    )
}

function FormAutomationSetting({
    formId
}) {
    const [loading, setLoading] = useState(true)
    const [workflow, setWorkflow] = useState(null)
    const [creating, setCreating] = useState(false)
    const [noWorkflow, setNoWorkflow] = useState(false)
    const [count, setCount] = useState(0)
    const { getFormWorkflow, createNewFormWorkflow } = useWorkflows()

    useEffect(() => {
        setLoading(true)
        getFormWorkflow(formId).then((response) => {
            console.log('response', response)
            const success = response.success
            if (success) {
                setWorkflow(response.data)
                setNoWorkflow(false)
            } else {
                setNoWorkflow(true)
            }
        }).finally(() => {
            setLoading(false)
        }).catch(() => {
            setNoWorkflow(true)
            setLoading(false)
        })
    }, [count])
    function reload() {
        setCount((e) => e + 1)
    }

    function createFormAutomationWorkflow() {
        setCreating(true)
        createNewFormWorkflow(formId, {}).then((response) => {
            reload()
        }).finally(() => {
            setCreating(false)
        })
    }

    if (loading) return <div>Loading...</div>
    if (noWorkflow) return <div className='w-full flex items-center justify-center flex-col'>
        <div className='text-center'>
            <h1 className='text-lg font-semibold'>
                No Form Automation workflow found
            </h1>
            <p className='font-light text-gray-400 text-sm'>
                Create a new workflow to automate actions on form submission
            </p>
        </div>
        <div>
            <CustomButton
                disabled={creating}
                className='py-2 px-3.5 flex'
                onClick={() => {
                    if (creating) return
                    createFormAutomationWorkflow()
                }}
            >
                <PlusIcon size={18} />
                {creating ? 'Creating...' : 'Create Workflow'}
            </CustomButton>
        </div>
    </div>

    return (
        <ReactFlowProviderComponent
            workflow={workflow}
            onlyTriggerCategory={[
                'forms'
            ]} />
    )
}

