/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { useState } from 'react'
import { Modal } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as BulkContact } from 'assets/icons/bulkContact.svg'
import { ReactComponent as SingleContact } from 'assets/icons/singleContact.svg'
import { isMobile } from 'react-device-detect'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'
// import CustomButton from 'components/CustomButton'

const CardRadio = ({ id, title, Icon, onClick, selected }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={clsx(
        'group p-4 md:p-0 cursor-pointer flex flex-col w-full items-center justify-center h-[120px] bg-menuGray rounded overflow-hidden border transition-all duration-200',
        selected 
          ? 'border-softBlack shadow-md bg-softBlack/5' 
          : 'border-transparent hover:border-softBlack/50 hover:bg-softBlack/10'
      )}
    >
      {Icon && (
        <span className={clsx(
          'px-1.5 flex items-center justify-center transition-colors duration-200 scale-100',
          selected ? 'text-softBlack' : 'text-softBlack/80 group-hover:text-softBlack'
        )}>
          {Icon}
        </span>
      )}
      <span className={clsx(
        'mt-4 font-light text-base italic transition-colors duration-200',
        selected ? 'text-softBlack' : 'text-softBlack/80 group-hover:text-softBlack'
      )}>
        {title}
      </span>
    </div>
  )
}

const ContactEditMethodModal = ({
  show,
  setShow,
  onMethodSelected = (selected) => { }
}) => {
  const [selectedRadio, setSelectedRadio] = useState('')

  const handleClose = () => {
    setShow()
  }

  const handleCardClick = (value) => {
    setSelectedRadio(value)
    handleClose()
    onMethodSelected(value)

    // Track contact create type selected
    mixpanel.track(MIXPANEL_EVENTS.CONTACT_CREATE_TYPE_SELECTED, {
      selectedUserCreateMethod: value
    })
  }

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby='Contact Edit Method Selection Modal'
      aria-describedby='Contact Edit Method Selection Modal description'
    >
      <div
        className='font-openSans relative bg-white p-5 md:p-7 lg:p-10 text-center flex flex-col items-center justify-center text-softBlack rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 overflow-hidden'
        style={{
          width: isMobile ? '95%' : 1015,
          maxHeight: isMobile ? '95vh' : 400
        }}
      >
        <button className='absolute right-5 md:right-7 lg:right-10 top-5 md:top-7 lg:top-10' onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className='font-bold text-xl text-softBlack'>New Contact</h1>
        <p className='font-normal text-base text-softBlack mt-2 md:mt-8'>
          Choose the method you want to use to create the buyer
        </p>
        <div className='w-full flex flex-col md:flex-row md:mx-10 mt-2 md:mt-8 gap-4 md:gap-x-6'>
          <CardRadio
            id='create-buyer'
            key='Buyer'
            title='Create Buyer'
            selected={selectedRadio === 'Buyer'}
            onClick={() => handleCardClick('Buyer')}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-broker'
            key='LeadBroker'
            title='Create Broker'
            selected={selectedRadio === 'LeadBroker'}
            onClick={() => handleCardClick('LeadBroker')}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-referral-agent'
            key='referral'
            title='Referral Agent'
            selected={selectedRadio === 'referral'}
            onClick={() => handleCardClick('referral')}
            Icon={<SingleContact />}
          />
          <CardRadio
            id='create-bulk'
            key='bulk'
            title='Bulk Upload Create Contacts'
            selected={selectedRadio === 'bulk'}
            onClick={() => handleCardClick('bulk')}
            Icon={<BulkContact />}
          />
        </div>
      </div>
    </Modal>
  )
}

export default ContactEditMethodModal
