// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import CustomRadio from 'components/CustomRadio'

import LineText from './LineText'
import { useSelector } from 'react-redux'
import { getProjects } from 'store/actions/projectActions'
import OptionCard from 'components/OptionCard'
import BottomActions from './BottomActions'
import { CreateOfferContextInventory } from '../context/CreateOfferContextInventory'

const SelectCustomSectionV2 = ({ _id, name, setStep }) => {
  const { selectedAdditionalOption, setSelectedAdditionalOption } = useContext(CreateOfferContextInventory)
  const appProject = useSelector((state) => state.appReducer.appProject)
  const [purchaseAdditionalOption, setPurchaseAdditionalOption] = useState(true)
  const [customSectionOptions, setCustomSectionOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getProjects(appProject).then((project) => {
      const { options } = project
      setCustomSectionOptions([...options.filter((option) => (option.type === _id))])
      setIsLoading(false)
    }).catch((error) => {
      console.error(error)
      setIsLoading(false)
    })
  }, [appProject])

  const onBack = () => setStep((prev) => prev - 1)

  const onNext = () => {
    ; ((onlySelectedOptions()?.length > 0 && purchaseAdditionalOption) ||
      !purchaseAdditionalOption) &&
      setStep((prev) => prev + 1)
  }

  const onlySelectedOptions = () =>
    selectedAdditionalOption?.[_id]?.filter((option) => option.isSelected)

  const onChangeOption = (value) => setPurchaseAdditionalOption(value)

  const onUpdateCheck = (id, value) => {
    const updatedData = selectedAdditionalOption?.[_id]?.map((option) =>
      option._id === id
        ? { ...option, isSelected: value, count: value ? 1 : 0 }
        : option
    )
    setSelectedAdditionalOption({
      ...selectedAdditionalOption,
      [_id]: updatedData
    })
  }

  const onIncreaseCount = (id, value) => {
    const updatedData = selectedAdditionalOption?.[_id]?.map((option) =>
      option._id === id
        ? { ...option, count: value, isSelected: value }
        : option
    )
    setSelectedAdditionalOption({
      ...selectedAdditionalOption,
      [_id]: updatedData
    })
  }

  const onDecreaseCount = (id, value) => {
    if (value >= 0) {
      const updatedData = selectedAdditionalOption?.[_id]?.map((option) =>
        option._id === id
          ? { ...option, count: value, isSelected: value }
          : option
      )
      setSelectedAdditionalOption({
        ...selectedAdditionalOption,
        [_id]: updatedData
      })
    }
  }

  useEffect(() => {
    if (!(selectedAdditionalOption?.[_id]?.length > 0)) {
      const temp = customSectionOptions?.map((option) => ({
        ...option,
        additionalFields: Object.entries(option.additionalFields).reduce((acc, [key, value]) => {
          acc[key] = Array.isArray(value) ? value.map((item) => ({ checked: false, value: item })) : value
          return acc
        }, {}),
        isSelected: false,
        count: 0
      }))
      setSelectedAdditionalOption({
        ...selectedAdditionalOption,
        [_id]: temp
      })
    }
  }, [customSectionOptions])

  const handleAdditionalCheckboxChange = (additionalFieldKey, customSectionItemIndex, checkboxIndex) => {
    const temp = selectedAdditionalOption?.[_id]?.map((option, index) => {
      if (customSectionItemIndex === index) {
        return {
          ...option,
          additionalFields: {
            ...option.additionalFields,
            ...(
              option?.additionalFields?.[additionalFieldKey]?.length
                ? {
                    [additionalFieldKey]: [
                      ...option?.additionalFields?.[additionalFieldKey]?.map?.((v, i) => {
                        if (i === checkboxIndex) {
                          return {
                            ...v,
                            checked: !v.checked
                          }
                        }
                        return v
                      })
                    ]
                  }
                : {}
            )
          }
        }
      }

      return option
    })
    setSelectedAdditionalOption({
      ...selectedAdditionalOption,
      [_id]: temp
    })
  }

  return (
    <div className='h-full flex flex-col gap-6'>
      <div className="pb-4 border-b-2 border-softBlack">
        <p className="my-0 text-xl font-bold">{name}</p>
      </div>
      <div className="mt-4">
        <h2 className="font-medium text-lg">
          Your Unit has no {name.toLowerCase()} Spot included. Would you like to purchase a {name.toLowerCase()} spot?
        </h2>
        <div className="flex gap-10 mt-6">
          <CustomRadio
            label="Yes"
            checked={purchaseAdditionalOption}
            onClick={() => onChangeOption(true)}
          />
          <CustomRadio
            label="No"
            checked={!purchaseAdditionalOption}
            onClick={() => onChangeOption(false)}
          />
        </div>
      </div>

      {purchaseAdditionalOption && (
        <>
          <LineText title={`select ${name.toLowerCase()}`} />

          <div className="grid grid-cols-2 gap-6">
            {isLoading &&
              Array.from(Array(3).keys()).map((a, index) => (
                <Skeleton key={index} height={280} width="100%" />
              ))}
            {!isLoading &&
              selectedAdditionalOption?.[_id]?.map((option, index) => (
                <OptionCard
                  key={index}
                  index={index}
                  checked={selectedAdditionalOption?.[_id][index].isSelected}
                  setChecked={() => {
                    onUpdateCheck(option._id, !option.isSelected)
                  }}
                  count={selectedAdditionalOption?.[_id][index].count}
                  increaseCount={() => {
                    onIncreaseCount(option._id, option.count + 1)
                  }}
                  decreaseCount={() => {
                    onDecreaseCount(option._id, option.count - 1)
                  }}
                  isActive={false}
                  checkboxTitle={`Add ${name.toLowerCase()}`}
                  id={option._id}
                  description={option?.description ?? ''}
                  imgSrc={option.images?.[0]}
                  price={option.value}
                  title={option.name}
                  additionalFields={option?.additionalFields}
                  type={option?.type}
                  isShowExtraCheckbox={Object.entries(option?.additionalFields).filter(([_, value]) => Array.isArray(value))?.length > 0}
                  additionalArrays={Object.entries(option?.additionalFields).filter(([_, value]) => Array.isArray(value)).map(([key, values]) => ({ key, values })) ?? []}
                  setAdditionalArrays={handleAdditionalCheckboxChange}
                />
              ))}
          </div>
        </>
      )}
      <BottomActions
        hasYPadding={purchaseAdditionalOption}
        onBack={onBack}
        onNext={onNext}
        isNextBtnDisabled={!((onlySelectedOptions()?.length > 0 && purchaseAdditionalOption) || !purchaseAdditionalOption)}
      />
    </div>
  )
}

export default SelectCustomSectionV2
