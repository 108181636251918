/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as MailIcon } from '../../assets/icons/mail.svg'
// import EmailsCard from 'components/EmailCard'
import EmailsCard from 'components/EmailCard/version_2'
import EmailModal from './EmailModal'
import Skeleton from 'react-loading-skeleton'
import { getUserThreads, getUserEmails, getSharedEmailsByUser } from 'store/actions/usersActions'
import { isMobile } from 'react-device-detect'
import { sanitizeCode } from 'utils'
import CustomButton from './CustomButton'
import mixpanel, { MIXPANEL_EVENTS } from 'services/mixpanel'

const EmailTab = ({ contact, selectedEmailThread }) => {
  const [showEmailBox, setShowEmailBox] = useState(false)
  const [listOfEmails, setListOfEmails] = useState([])
  const [gettingListOfEmails, setGettingListOfEmails] = useState(false)
  const [selectedEmailIndex, setSelectedEmailIndex] = useState(0)
  const [selectedThreadContent, setSelectedThreadContent] = useState('')
  const [composingEmailSubject, setComposingEmailSubject] = useState('')
  const [showMessageList, setShowMessageList] = useState(isMobile)
  const [selectedThreadId, setSelectedThreadId] = useState('')
  const [gettingThreadContent, setGettingThreadContent] = useState(false)
  const [composingEmailBody, setComposingEmailBody] = useState('')
  const [composingEmailRecipient, setComposingEmailRecipient] = useState([])
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedEmail, setSelectedEmail] = useState({})
  const [activeTab, setActiveTab] = useState('personal')
  const [sharedEmails, setSharedEmails] = useState([])
  const appProject = useSelector((state) => state.appReducer.appProject)
  const { providerToken } = useSelector((state) => state.authReducer.userObject)
  const tabMenus = [
    {
      id: 'personal',
      name: 'Personal'
    },
    {
      id: 'shared',
      name: 'Shared'
    }
  ]

  const refLocalListOfEmails = useRef(listOfEmails)

  const updateListOfEmails = (newState) => {
    refLocalListOfEmails.current = newState
    setListOfEmails(newState)
  }

  const getEmails = useCallback(() => {
    if (!contact?.id) return
    setGettingListOfEmails(true)
    if (activeTab === 'personal') {
      if(providerToken) {
      getUserThreads({ threadId: contact?.id, search: searchFilter })
        .then((newEmails) => {
          const currentEmails = [...newEmails]
          const listOfEmails = currentEmails.map((email) => ({
            lastMessageTimestamp: email.earliestMessageDate || email.latestMessageReceivedDate,
            messages: [email.latestDraftOrMessage],
            snippet: email.snippet,
            subject: email.subject
          }))

          setGettingListOfEmails(false)
          updateListOfEmails(listOfEmails)
        })
        .catch(() => setGettingListOfEmails(false))
      } else {
      setGettingListOfEmails(false)
        setListOfEmails([])
        setSelectedEmailIndex(-1)
      }
    } else {
      fetchSharedEmails()
    }
  }, [contact?.id, searchFilter, activeTab])

  useEffect(() => {
    setSelectedEmailIndex(-1)
    if (listOfEmails.length) {
      if (selectedEmailThread) {
        const selectedEmailIndex = listOfEmails.findIndex((email) => email.messages[0].threadId === selectedEmailThread)
        if (selectedEmailIndex === -1) {
          setTimeout(() => {
            setSelectedEmailIndex(0)
            setSelectedEmail(listOfEmails[0].messages.map((elm) => ({
              cc: elm.cc ?? [],
              from: elm.from || [],
              to: elm.to || []
            })))
          }, 500)
        } else {
          setSelectedEmailIndex(selectedEmailIndex)
        }
      } else {
        setTimeout(() => {
          setSelectedEmailIndex(0)
          setSelectedEmail(listOfEmails[0].messages.map((elm) => ({
            cc: elm.cc ?? [],
            from: elm.from || [],
            to: elm.to || []
          })))
        }, 500)
      }
    }
  }, [listOfEmails?.length])

  useEffect(() => {
    getEmails()
  }, [getEmails])

  useEffect(() => {
    if (selectedEmailIndex >= 0) {
      const selectedEmailThreadId =
        refLocalListOfEmails.current?.[selectedEmailIndex]?.messages?.[0]
          ?.threadId
      setSelectedThreadId(selectedEmailThreadId)
    }
  }, [selectedEmailIndex])

  useEffect(() => {
    if (selectedThreadId) {
      if (activeTab === 'personal') {
        setGettingThreadContent(true)
        getUserEmails({ id: selectedThreadId })
          .then(({ data }) => {
            setSelectedThreadContent({
              subject: data[0].subject,
              senderName: `${data[0].from[0].name || data[0].from[0].email}`,
              senderEmail: data[0].from[0].email,
              items: data.map((email, index) => {
                const emailDate = new Date((email.createdAt || email.date) * 1000)
                return {
                  id: index,
                  messageId: email.id,
                  snippet: email.snippet,
                  threadId: email.threadId,
                  author: email.from[0].name || 'Unknown sender',
                  date: `${emailDate.toLocaleDateString('en-US', {
                    weekday: 'long'
                  })} at ${emailDate.toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}`,
                  datetime: email?.createdAt || email?.date,
                  body: sanitizeCode(email.body),
                  files: email.attachments
                }
              })
            })
            setGettingThreadContent(false)
          })
          .catch(() => setGettingThreadContent(false))
      } else {
        if (sharedEmails.length > 0) {
          const getSelectedSharedEmail = sharedEmails.find(
            (email) => email.threadId === selectedThreadId
          )?.data[0]?.data
          setSelectedThreadContent({
            subject: getSelectedSharedEmail?.[0]?.subject,
            senderName: `${
              getSelectedSharedEmail?.[0]?.from[0]?.name
                ? getSelectedSharedEmail?.[0]?.from[0]?.name
                : getSelectedSharedEmail?.[0]?.from[0]?.email
            }`,
            senderEmail: getSelectedSharedEmail?.[0]?.from[0]?.email,
            items: getSelectedSharedEmail?.map((email, index) => {
              const emailDate = new Date((email?.createdAt || email?.date) * 1000)
              return {
                id: index,
                messageId: email.id,
                snippet: email.snippet,
                threadId: email.threadId,
                author: email.from[0]?.name || 'Unknown sender',
                date: `${emailDate.toLocaleDateString('en-US', {
                  weekday: 'long'
                })} at ${emailDate.toLocaleTimeString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}`,
                datetime: email?.createdAt || email?.date,
                body: sanitizeCode(email.body),
                files: email.attachments
              }
            })
          })
          setGettingThreadContent(false)
        }
      }
    }
  }, [selectedThreadId, activeTab, sharedEmails])

  const fetchSharedEmails = async () => {
    try {
      const response = await getSharedEmailsByUser(contact?._id)
      setSharedEmails(response)
      const formattedEmails = response.map((data) => {
        let email = data?.data[0]?.data[0]
        if(!email) return null
        return {
          subject: email.subject || 'Untitled',
          lastMessageTimestamp: new Date(data.updatedAt).getTime() / 1000,
          messages: [email],
          snippet: email.snippet
        }
      })
      const filteredEmails = formattedEmails.filter((email) => email)
      setGettingListOfEmails(false)
      updateListOfEmails(filteredEmails)
    } catch (error) {
      console.error('Error fetching shared emails:', error)
      setGettingListOfEmails(false)
    }
  }

  const childProps = {
    selectedEmailIndex,
    setSelectedEmailIndex,
    selectedThreadContent,
    setSelectedThreadContent,
    composingEmailSubject,
    setComposingEmailSubject,
    showMessageList,
    setShowMessageList,
    isMobile,
    refLocalListOfEmails,
    gettingListOfEmails,
    getEmails,
    selectedThreadId,
    setSelectedThreadId,
    showEmailBox,
    setShowEmailBox,
    gettingThreadContent,
    setGettingThreadContent,
    composingEmailBody,
    setComposingEmailBody,
    composingEmailRecipient,
    setComposingEmailRecipient,
    listOfEmails,
    searchFilter,
    setSearchFilter,
    showFilterOption: false,
    showLoadMore: false,
    selectedEmail,
    setSelectedEmail,
    activeTab,
    tabMenus,
    setActiveTab,
    providerToken
  }

  const handleCreateEmailClick = () => {
    // Track start email creation event
    mixpanel.track(MIXPANEL_EVENTS.EMAIL_CREATE, {
      emailMode: 'create'
    })

    setShowEmailBox(true)
  }

  return (
    <>
      {gettingListOfEmails && (
        <div className='h-full flex flex-col gap-6 p-4 pt-8'>
          <Skeleton height={100} />
        </div>
      )}
      {!gettingListOfEmails && (
        <div className='font-openSans flex flex-col h-full w-full bg-white rounded overflow-y-auto'>
          {/* {!listOfEmails?.length && (
            <div className='font-openSans flex flex-col justify-center items-center h-full w-full bg-white rounded'>
              <MailIcon />
              <div className='font-semibold mb-2 mt-4'>
                You don't have any emails yet
              </div>
              <div className='text-sm text-softBlack70 mb-4'>
                {`Send your first email to ${contact?.firstName ?? '...'}`}
              </div>
              <CustomButton handleClick={handleCreateEmailClick}>
                <span className='font-semibold px-4 py-2 rounded text-base'>
                  Create Email
                </span>
              </CustomButton>
            </div>
          )} */}
          {<EmailsCard childProps={childProps} />}
          {showEmailBox && (
            <EmailModal
              show={showEmailBox}
              composingEmailSubject={composingEmailSubject}
              messageId={selectedThreadContent?.items?.[0]?.messageId}
              handleClose={() => setShowEmailBox(!showEmailBox)}
              contact={contact}
              onEmailSent={() => {
                getEmails()
              }}
              setComposingEmailSubject={setComposingEmailSubject}
              setComposingEmailRecipient={setComposingEmailRecipient}
            />
          )}
        </div>
      )}
    </>
  )
}

export default EmailTab
