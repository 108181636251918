import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { ADMIN_OFFERS } from 'routes'

import CancelModal from './CancelModal'
import CustomButton from 'components/CustomButton'

const Header = () => {
  const history = useHistory()
  const [isOpenCancelModal, setIsOpenCancelModal] = useState(false)

  const onCancel = () => {
    setIsOpenCancelModal(true)
  }

  // const onSaveExit = () => {
  //   setIsOpenCancelModal(false)
  //   history.push(ADMIN_OFFERS)
  // }

  const onConfirm = () => {
    setIsOpenCancelModal(false)
    history.push(ADMIN_OFFERS)
  }

  return (
    <>
      <div className='bg-white w-full fixed flex justify-between items-center px-6 py-2.5 border-b-2 border-softBlack'>
        <p className='my-0 text-2xl font-bold'>Buy Unit</p>
        <div className='flex flex-row gap-3'>
          {/* <CustomButton variant='outline' handleClick={onSaveExit}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              Save & Exit
            </span>
          </CustomButton> */}
          <CustomButton variant='outline' handleClick={onCancel}>
            <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
              Cancel
            </span>
          </CustomButton>
        </div>
      </div>
      <CancelModal
        {...{ isOpenCancelModal, setIsOpenCancelModal, onConfirm }}
      />
    </>
  )
}

export default Header
