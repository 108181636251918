/* eslint-disable */
import { getCustomForm, updateCustomFormSettings } from 'store/actions/customFormActions'
import CustomInput from 'components/CustomInput'
import { getProjects } from 'store/actions/projectActions'
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect'
import { getUserLists } from 'store/actions/usersActions'
import React, { useCallback, useEffect } from 'react'
import CustomButton from 'components/CustomButton'
import { getEmailTemplates } from 'store/actions/emailTemplateActions'
import ToggleSwitch from 'components/ToggleSwitch'
import CreateListDialog from 'pages/lists/createList'
import { api } from 'services/api'

export default function FormSettingsComponent({ formId, showTitle = false, showListCreate = false, showLeadSourceCreate = false }) {
    const [form, setForm] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [saving, setSaving] = React.useState(false)
    const [loadingLists, setLoadingLists] = React.useState(true)
    const [lists, setLists] = React.useState([])
    const [loadingProjects, setLoadingProjects] = React.useState(true)
    const [projects, setProjects] = React.useState([])
    const [isLoadingLeadSource, setLoadingLeadSources] = React.useState(true)
    const [leadSources, setLeadSources] = React.useState([])
    const [recaptchaEnabled, setRecaptchaEnabled] = React.useState(false)
    const [formDataReload, setFormDataReload] = React.useState(0)
    const [listDataReload, setListDataReload] = React.useState(0)
    const [formSettings, setFormSetting] = React.useState({
        projectId: null,
        leadSource: 'Website',
        usersLists: [],
        autoReplyEmailTemplate: '',
        successRedirect: '',
        alreadyExistRedirect: '',
        errorRedirect: '',
        title: 'form title',
        recaptchaEnabled: false
    })
    const [sendGridOptions, setSendGridOptions] = React.useState([])
    const [isFetchingSendGridOptions, setIsFetchingSendGridOptions] = React.useState(true)

    const getAllTemplates = () => {
        setIsFetchingSendGridOptions(true)
        getEmailTemplates().then((response) => {
            setSendGridOptions([...response.map((elm) => ({ label: elm.name, value: elm.sendGridTemplateId }))])
            setIsFetchingSendGridOptions(false)
        }).catch((error) => console.log(error))
    }

    useEffect(() => {
        getAllTemplates()
    }, [])

    const reloadLeadSources = useCallback(() => {
        setLoadingLeadSources(true)
        const projectId = formSettings?.projectId
        api.get(`dropdowns/${projectId}/leadSource`).then((response) => {

            const data = response.data
            if (Array.isArray(data)) {
                const item = {
                    _id: "",
                    name: "",
                    values: []
                }
                const values = data.reduce((acc, curr) => {
                    const itemValues = curr.values
                    if (!acc) {
                        acc = []
                    }
                    return acc.concat(itemValues)
                }, [])
                // @ts-ignore
                const uniqueValues = [...new Set(values)]
                // @ts-ignore
                const finalValues = uniqueValues.map((value) => ({ value, label: value }))
                setLeadSources(finalValues ?? [])
            } else {
                const values = response.data?.values?.map((value) => ({ value, label: value }))
                setLeadSources(values ?? [])
            }
        }).finally(() => {
            setLoadingLeadSources(false)
        })
    }, [formSettings.projectId])

    useEffect(() => {
        reloadLeadSources()
    }, [])

    useEffect(() => {
        reloadLeadSources()
    }, [formSettings.projectId])

    useEffect(() => {
        setFormSetting({
            ...formSettings,
            recaptchaEnabled: recaptchaEnabled
        })
    }, [recaptchaEnabled])

    useEffect(() => {
        setLoadingProjects(true)
        getProjects().then((response) => {
            setProjects([
                {
                    _id: null,
                    projectName: '(default) Corporate Project',
                },
                ...response])
        }).finally(() => {
            setLoadingProjects(false)
        })
    }, [
        formId,
    ])

    useEffect(() => {
        setLoadingLists(true)
        const filter = {}
        if (formSettings.projectId) {
            filter.projectId = formSettings.projectId
        }
        getUserLists({
            limit: 10000,
            ...filter
        }).then((response) => {
            const lists = response?.data
            let staticLists = lists.filter((list) => {
                return list?.listType !== 'smart'
            }).filter((list) => list?.deleted !== true)

            const currentProjectId = formSettings.projectId
            if (currentProjectId) {
                staticLists = staticLists.filter((list) => {
                    if (!list?.projectId) return false
                    const projectId = typeof list?.projectId === 'object' ? list?.projectId?._id : list?.projectId
                    if (!projectId) return false
                    return list?.isCorporate === false && projectId === currentProjectId
                })
            } else {
                staticLists = staticLists.filter((list) => {
                    return list?.isCorporate === true || !list?.projectId
                })
            }
            setLists(staticLists.map(e => {
                return {
                    _id: e._id,
                    name: e.title
                }
            }))
        }).finally(() => {
            setLoadingLists(false)
        })
    }, [
        formId, formSettings.projectId, listDataReload
    ])

    useEffect(() => {
        if (formId) {
            getCustomForm(formId).then((response) => {
                const form = response.data
                const settings = form.settings || {
                    projectId: null,
                    leadSource: 'Website',
                    usersLists: [],
                    autoReplyEmailTemplate: '',
                    successRedirect: '',
                    alreadyExistRedirect: '',
                    errorRedirect: '',
                    title: '',
                    recaptchaEnabled: false
                }
                setFormSetting({
                    ...settings,
                    title: form.name ?? 'Form title'
                })
                setForm(form)
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [
        formId, formDataReload
    ])

    if (!form) return <div></div>

    const disabled = saving || loading

    function saveFormSettings() {
        if (saving) return
        setSaving(true)
        const settings = {
            ...form.settings,
            ...formSettings
        }
        updateCustomFormSettings(formId, settings).then((response) => {
            setFormDataReload(e => e + 1)
        }).finally(() => {
            setSaving(false)
        })
    }

    const selectedLists = []
    if (lists && formSettings?.usersLists?.length > 0) {
        formSettings.usersLists.map((listId) => {
            const result = lists.findIndex((list) => list?._id === listId)
            if (result !== -1) {
                selectedLists.push({
                    value: listId,
                    label: lists[result].name
                })
            }
            return true
        })
    }
    return (
        <div>
            <div className='flex flex-row divide-x gap-x-2.5 max-h-[100%]'>
                <div className='max-w-xs px-2 py-4 flex-1 overflow-y-auto'>
                    {showTitle && (
                        <h1 className='text-sm font-semibold'>{form.name}</h1>
                    )}
                    <p className='font-light text-gray-400 text-sm'>
                        Manage form and automation settings for this form. You can set project, lead source, users list, auto reply email template and redirect URL(s) on different events.
                    </p>
                    <div className='flex flex-col gap-y-2 divide-y my-3'>
                        <div className=''>
                            <label className='text-sm font-semibold'>Form Title</label>
                            <input
                                defaultValue={formSettings.title}
                                value={formSettings.title}
                                onChange={(e) => {
                                    setFormSetting({
                                        ...formSettings,
                                        title: e.target.value
                                    })
                                }}
                            />
                        </div>
                        <div className='py-2'>
                            {loadingProjects && <div>Loading Projects...</div>}
                            {!loadingProjects && projects?.length > 0 && (
                                <CustomSelect
                                    label="Choose Project"
                                    options={projects.map((project) => ({
                                        value: project._id,
                                        label: project.projectName
                                    }))}
                                    value={formSettings.projectId ? [formSettings.projectId] : []}
                                    handleChange={(value) => {
                                        setFormSetting({
                                            ...formSettings,
                                            projectId: value,
                                            usersLists: []
                                        })
                                    }}
                                    optionFilterProp="label"
                                />
                            )}
                        </div>
                        <div className='py-2'>
                            {loadingLists && <div className='w-full h-[66px] flex justify-center items-center text-center'>Loading Lists...</div>}
                            {!loadingLists && lists?.length == 0 && (
                                <div className='text-red-600'>
                                    No lists found for this project
                                </div>
                            )}
                            {!loadingLists && lists?.length > 0 && (
                                <CustomSelect
                                    mode='tags'
                                    label="Add Users to Lists when they submit this form"
                                    options={lists.map((list) => ({
                                        value: list._id,
                                        label: list.name
                                    }))}
                                    value={selectedLists}
                                    handleChange={(indexes) => {
                                        const listIds = indexes.map((index) => {
                                            const indexValue = Number(index)
                                            if (isNaN(indexValue)) return index
                                            const result = lists[index]?._id || null
                                            return result
                                        }).filter((e) => e)
                                        // remove duplicates
                                        const finalLists = []
                                        listIds.map((listId) => {
                                            if (!finalLists.includes(listId)) {
                                                finalLists.push(listId)
                                            }
                                            return true
                                        })
                                        setFormSetting({
                                            ...formSettings,
                                            usersLists: finalLists
                                        })
                                    }}
                                />
                            )}
                            {!loadingLists && showListCreate && (
                                <p className="w-full text-center text-softBlack_70/50 text-xs font-normal uppercase leading-[0.16px] my-2.5">
                                    OR
                                </p>
                            )}
                            {!loadingLists && showListCreate && (
                                <div className='flex items-center w-full justify-between'>
                                    <CreateListDialog
                                        projectId={formSettings.projectId}
                                        onClose={() => {
                                            setListDataReload(e => e + 1)
                                        }}
                                        isCorporate={formSettings.projectId === null}
                                        refreshData={() => {
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='py-2'>
                            {isLoadingLeadSource && <div>Loading Lead Sources...</div>}
                            {!isLoadingLeadSource && leadSources?.length == 0 && (
                                <div className='text-red-600'>
                                    No lead sources found for this project
                                </div>
                            )}
                            {!isLoadingLeadSource && leadSources?.length > 0 && (
                                <CustomSelect
                                    label='Lead Source'
                                    options={leadSources}
                                    value={formSettings.leadSource}
                                    handleChange={(value) => {
                                        setFormSetting({
                                            ...formSettings,
                                            leadSource: value
                                        })
                                    }}
                                />
                            )}
                        </div>
                        <div className='py-2 flex flex-col gap-3'>
                            <p className="my-2 text-sm font-normal leading-[0.16px]">
                                Auto Reply Email Template-ID (SendGrid)
                            </p>
                            <CustomSelect
                                label="Select email template"
                                loading={isFetchingSendGridOptions}
                                options={sendGridOptions}
                                value={formSettings.autoReplyEmailTemplate}
                                handleChange={(value) => {
                                    setFormSetting({
                                        ...formSettings,
                                        autoReplyEmailTemplate: value
                                    })
                                }}
                                optionFilterProp="label"
                                placeholder="d-1234567890abcdef"
                            />
                            <p className="w-full text-center my-2 text-softBlack_70/50 text-xs font-normal uppercase leading-[0.16px]">
                                OR
                            </p>
                            <CustomInput
                                type='text'
                                classes='max-h-14'
                                label='Enter custom email template ID'
                                placeholder='d-1234567890abcdef'
                                onChange={(e) => {
                                    setFormSetting({
                                        ...formSettings,
                                        autoReplyEmailTemplate: e.target.value
                                    })
                                }}
                                value={formSettings.autoReplyEmailTemplate}
                                containerClasses={undefined}
                                disabled={disabled}
                                labelClassName='text-softBlack_70/50 !text-xs !font-normal'
                            />
                        </div>
                        <div className='py-2'>
                            <div>
                                Redirect URL(s) on different events
                            </div>
                            <CustomInput
                                type='text'
                                classes='max-h-14'
                                label='Success Redirect'
                                placeholder='https://example.com/success'
                                onChange={(e) => {
                                    setFormSetting({
                                        ...formSettings,
                                        successRedirect: e.target.value
                                    })
                                }}
                                value={formSettings.successRedirect}
                                containerClasses={undefined}
                                disabled={disabled}
                            />

                            <CustomInput
                                type='text'
                                classes='max-h-14'
                                label='Already Exist Redirect'
                                placeholder='https://example.com/already-exist'
                                onChange={(e) => {
                                    setFormSetting({
                                        ...formSettings,
                                        alreadyExistRedirect: e.target.value
                                    })
                                }}
                                value={formSettings.alreadyExistRedirect}
                                containerClasses={undefined}
                                disabled={disabled}
                            />

                            <CustomInput
                                type='text'
                                classes='max-h-14'
                                label='Error Redirect'
                                placeholder="https://example.com/error"
                                onChange={(e) => {
                                    setFormSetting({
                                        ...formSettings,
                                        errorRedirect: e.target.value
                                    })
                                }}
                                value={formSettings.errorRedirect}
                                containerClasses={undefined}
                                disabled={disabled} />

                        </div>
                        <div className='flex items-center justify-between py-2'>
                            <div>
                                Google reCAPTCHA
                                <p className='text-[12px] text-gray-400'>
                                    Enable Google reCAPTCHA to prevent spam submissions on this form
                                </p>
                            </div>
                            <ToggleSwitch checked={formSettings?.recaptchaEnabled} setChecked={setRecaptchaEnabled} />
                        </div>
                    </div>
                    <div className='flex'>
                        <CustomButton
                            disabled={saving}
                            className='py-2 px-3.5'
                            onClick={() => {
                                saveFormSettings()
                            }}
                        >
                            {saving ? 'Saving...' : 'Save'}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

