/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import InventoryListViewWrapper from "../InventoryListViewWrapper";
import { groupBy } from "components/Inventory";
import useGetInventories from "hooks/useGetInventories";
import InventoryItem from "../InventoryItem";
import Skeleton from "react-loading-skeleton";
import InventoryItemV2 from "../InventoryItemV2";
import InventoryListViewWrapperV2 from "../NewInventory/InventoryListViewWrapperV2";
import { checkV2Inventory } from "utils/checkV2Inventory";
import { getActiveInventoryStatuses } from "store/actions/inventoryActions";

const InventoryListViewMain = ({
  projectId,
  state,
  buildingId,
  isFullHeight = false,
  inventories,
  inventoryCardFields,
  loading,
  refetch
}) => {

  function removePrefix(str, prefix) {
    return str.replace(new RegExp(`^${prefix}`, "i"), "").replace("Data", "");
  }

  const [activeStatuses, setActiveStatuses] = useState([]);
  useEffect(() => {
    const fetchActiveStatuses = async () => {
      try {
        const statuses = await getActiveInventoryStatuses(projectId);
        setActiveStatuses(statuses);
      } catch (error) {
        console.error('Failed to fetch active statuses:', error);
      }
    };

    if (checkV2Inventory()) {
      fetchActiveStatuses();
    }
  }, [projectId]);

  return (
    <>
      <div className="ml-6 py-2">
      {state.selectedInventoryView === 'availabilityStatus' && (
              <div className='flex-1 h-full overflow-y-auto mb-3 thin-horizontal-scrollbar flex items-center'>
                <div className='w-full flex items-center overflow-x-auto gap-5'>
                  {checkV2Inventory() ? (
                    activeStatuses.map((status) => (
                      <div
                        key={status.id}
                        className='rounded-md items-center gap-2 flex'
                      >
                        <div
                          className='w-3 h-3 rounded-full'
                          style={{
                            backgroundColor: status.color || '#808080',
                            border: `1px solid ${status.color || '#808080'}`,
                          }}
                        />
                        <p className='text-zinc-900 text-sm font-semibold leading-tight mb-0 truncate'>
                          {status.name}
                        </p>
                      </div>
                    ))
                  ) : (
                    typesOfStatus.map((status) => (
                      <div
                        key={status.id}
                        className='rounded-md items-center gap-2 flex'
                      >
                        <div
                          className='w-3 h-3 rounded-full'
                          style={{
                            backgroundColor: state.statusColors[status.id.toLowerCase()].bgColor,
                            border: `1px solid ${state.statusColors[status.id.toLowerCase()].borderColor}`,
                          }}
                        />
                        <p className='text-zinc-900 text-sm font-semibold leading-tight mb-0 truncate'>
                          {status.name}
                        </p>
                      </div>
                    ))
                  )}
                </div>
              </div>
            )}
       
      </div>
      <InventoryListViewWrapperV2
        inventoryItems={inventories}
        inventoryCardFields={inventoryCardFields}
        loading={loading}
        type="Unit"
        refetch={refetch}
        isFullHeight={isFullHeight}
        selectedBuilding={buildingId}
      />
    </>
  );
};

export default InventoryListViewMain;
