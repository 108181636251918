// @ts-nocheck
/*eslint-disable */
import React, { memo, useContext, useEffect, useMemo, useState } from 'react'
import CustomButton from 'components/CustomButton'
import { createOrUpdateProject, deleteProjectOption, getProjects } from 'store/actions/projectActions'
import { useDispatch, useSelector } from 'react-redux'
import CreateProjectContext from '../context/CreateProjectProvider'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import { Oval } from 'react-loader-spinner'
import { Plus, Trash } from 'lucide-react'
import { v4 as uuidv4 } from 'uuid'
import { LottieWrapper } from 'components'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'
import CustomModal from 'ui/customModal'
import { UPDATE_CUSTOM_SECTIONS } from 'store/actions/actionTypes'

const DEFAULT_FIELD_TYPE_OPTIONS = [
  {
    value: 'text',
    label: 'Text'
  },
  {
    value: 'textarea',
    label: 'Textarea'
  },
  {
    value: 'number',
    label: 'Number'
  },
  {
    value: 'array',
    label: 'Array'
  },
  // {
  //   value: 'count',
  //   label: 'Count'
  // }
  // {
  //   value: 'images',
  //   label: 'Images'
  // }
]

const FIELD_OPTIONS = [
  {
    title: 'Name',
    fieldType: 'text',
    customFieldName: 'name'
  },
  {
    title: 'Price',
    fieldType: 'number',
    customFieldName: 'value'
  },
  {
    title: 'Max Amount Per Unit',
    fieldType: 'count',
    customFieldName: 'maxAmountPerUnit'
  },
  {
    title: 'Description',
    fieldType: 'textarea',
    customFieldName: 'description'
  },
  {
    title: 'Images',
    fieldType: 'images',
    customFieldName: 'images'
  }
]

const DEFAULT_FIELDS_THAT_CANNOT_BE_DELETED = [
  'name',
  'value',
  'maxAmountPerUnit'
]

const convertToCamelCase = (str) => {
  let words = str.split(/[\s-_]+/);

  words[0] = words[0].toLowerCase();
  for (let i = 1; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  return words.join('');
}

const CreateOrUpdateCustomSection = memo(({
  index,
  onOk,
  customSectionDetails,
  loading,
  handleInputChangeOfCustomSection,
  handleAddField,
  handleInputChangeOfField,
  handleRemoveField,
  handleRemoveCustomSection
}) => {
  const isValidate = useMemo(() => {
    if (!customSectionDetails.title || customSectionDetails.title.trim() === '') {
      return false
    } else if (customSectionDetails?.dataFields?.filter((field) => (!field.isDeleted))?.length === 0 || !!(customSectionDetails.dataFields?.filter((field) => (!field.isDeleted)).filter((field) => (!field.title?.length > 0 || field.title.trim() === '' || !field.fieldType?.length > 0 || field.fieldType.trim() === ''))?.length)) {
      return false
    }
    return true
  }, [customSectionDetails])

  const filteredFieldOptions = useMemo(() => {
    return FIELD_OPTIONS
      .filter((field) => (!(customSectionDetails.dataFields.filter(sectionDetail => sectionDetail.title === field.title)?.length > 0)))
      .map((field) => ({
        value: field.title,
        label: field.title
      }))
  }, [customSectionDetails])

  return (
    <div className='w-full min-h-[300px] flex flex-col gap-4 rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden p-3'>
      <h2 className='text-2xl mb-0'>{customSectionDetails?.title?.length > 0 ? customSectionDetails?.title : `Untitled-${index}`}</h2>
      <div className='grid grid-cols-9 gap-4'>
        <CustomInput
          label='Title *'
          placeholder='Enter title'
          labelClassName='text-xs mb-[6px]'
          value={customSectionDetails.title ?? ''}
          onChange={(e) => handleInputChangeOfCustomSection(customSectionDetails.key, 'title', e.target.value)}
          type='text'
          disabled={loading}
          containerClasses='col-span-4'
        />
      </div>
      <h3 className='text-lg mb-0'>Fields</h3>
      <div className='flex flex-col gap-4 rounded-xl border border-zinc-300 bg-card text-card-foreground bg-white overflow-hidden p-4'>
        <div className='flex flex-col gap-1'>
          <h4 className='text-base mb-0'>Default Fields</h4>
          <p className='mb-0'>You can add maximum 5 default fields (Name, Price, Max amount per unit - these fields cannot be deleted).</p>
        </div>
        <div className='w-full flex flex-col gap-4'>
          {
            customSectionDetails?.dataFields?.filter((field) => (!(field?.isAdditional) && !(field?.isDeleted)))?.length > 0
              ? <>
                {
                  customSectionDetails.dataFields?.filter((field) => (!(field?.isAdditional) && !(field?.isDeleted))).map((field, index) => (
                    <div key={index} className='w-full flex items-end gap-4'>
                      <div className='flex-1'>
                        <CustomSelect
                          value={field.title ?? ''}
                          handleChange={(newValue) => handleInputChangeOfField(customSectionDetails.key, field.key, 'title', newValue, true)}
                          options={filteredFieldOptions}
                          showSearch
                          placeholder='Select Field name'
                          label='Select Field name *'
                          className='border-none'
                          disabled={DEFAULT_FIELDS_THAT_CANNOT_BE_DELETED.includes(field.customFieldName)}
                        />
                      </div>
                      <CustomInput
                        label='Field Type'
                        placeholder='Field Type'
                        labelClassName='text-xs mb-[6px]'
                        value={field?.fieldType ?? ''}
                        onChange={() => { }}
                        type='text'
                        disabled={true}
                        containerClasses='flex-1'
                        disabledClassName='opacity-100'
                        classes='!mb-0'
                      />
                      <div className='col-span-2 flex items-end justify-end'>
                        <CustomButton
                          variant='danger'
                          handleClick={() => handleRemoveField(customSectionDetails.key, field.key)}
                          disabled={loading || (DEFAULT_FIELDS_THAT_CANNOT_BE_DELETED.includes(field.customFieldName))}
                          className='w-fit'
                        >
                          <span className='font-medium py-2.5 px-4 text-base flex gap-2 items-center truncate'>
                            <Trash className='text-white w-5 h-5' />
                            Remove
                          </span>
                        </CustomButton>
                      </div>
                    </div>
                  ))
                }
              </>
              : <div className='w-full flex justify-center items-center h-10 rounded bg-zinc-100'>No fields found, add new one</div>
          }

        </div>
        {
          customSectionDetails?.dataFields?.filter((field) => (!(field?.isAdditional) && !(field?.isDeleted)))?.length < 5 &&
          <div className='w-full flex justify-center'>
            <CustomButton
              variant='ghost'
              handleClick={() => handleAddField(customSectionDetails.key)}
              disabled={loading}
            >
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                <Plus className='text-black w-5 h-5' />
                Add new Field
              </span>
            </CustomButton>
          </div>
        }
      </div>
      <div className='flex flex-col gap-4 rounded-xl border border-zinc-300 bg-card text-card-foreground bg-white overflow-hidden p-4'>
        <h4 className='text-base mb-0'>Additional Fields</h4>
        <div className='w-full flex flex-col gap-4'>
          {
            customSectionDetails?.dataFields?.filter((field) => (field?.isAdditional && !(field?.isDeleted)))?.length > 0
              ? <>
                {
                  customSectionDetails.dataFields?.filter((field) => (field?.isAdditional && !(field?.isDeleted))).map((field, index) => (
                    <div key={index} className='w-full flex items-end gap-4'>
                      <CustomInput
                        label='Field name *'
                        placeholder='Enter field name'
                        labelClassName='text-xs mb-[6px]'
                        value={field.title ?? ''}
                        onChange={(e) => handleInputChangeOfField(customSectionDetails.key, field.key, 'title', e.target.value)}
                        type='text'
                        disabled={loading}
                        containerClasses='flex-1'
                        classes='!mb-0'
                      />
                      <div className='flex-1'>
                        <CustomSelect
                          value={field?.fieldType ?? ''}
                          handleChange={(newValue) => handleInputChangeOfField(customSectionDetails.key, field.key, 'fieldType', newValue)}
                          options={DEFAULT_FIELD_TYPE_OPTIONS}
                          showSearch
                          placeholder='Select Field Type'
                          label='Select Field Type *'
                          className='border-none'
                          disabled={loading}
                        />
                      </div>
                      <div className='col-span-1 flex items-end justify-end'>
                        <CustomButton
                          variant='danger'
                          handleClick={() => handleRemoveField(customSectionDetails.key, field.key)}
                          disabled={loading}
                          className='w-full'
                        >
                          <span className='font-medium py-2.5 px-4 text-base flex gap-2 items-center truncate'>
                            <Trash className='text-white w-5 h-5' />
                            Remove
                          </span>
                        </CustomButton>
                      </div>
                    </div>
                  ))
                }
              </>
              : <div className='w-full flex justify-center items-center h-10 rounded bg-zinc-100'>No fields found, add new one</div>
          }
        </div>
        <div className='w-full flex justify-center'>
          <CustomButton
            variant='ghost'
            handleClick={() => handleAddField(customSectionDetails.key, true)}
            disabled={loading}
          >
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              <Plus className='text-black w-5 h-5' />
              Add new Field
            </span>
          </CustomButton>
        </div>
      </div>
      <div className='flex-1 w-full mt-2 flex items-end justify-center md:justify-end gap-3'>
        <CustomButton handleClick={onOk} disabled={!isValidate || loading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {customSectionDetails?._id ? 'Save' : 'Create'}
          </span>
        </CustomButton>
        <CustomButton variant='danger' handleClick={() => handleRemoveCustomSection(customSectionDetails.key)} disabled={loading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            <Trash className='text-white w-5 h-5' />
            Delete
          </span>
        </CustomButton>
      </div>
    </div>
  )
})

const ConfirmDeleteModal = memo(({
  showModal,
  onCancel,
  onOk,
  isLoading,
  message = 'Do you want to delete?'
}) => (
  <CustomModal
    showModal={showModal}
    onCancel={onCancel}
    onOk={onOk}
    minHeight={false}
  >
    <div className='min-w-[95%] md:min-w-[400px] flex flex-col gap-4'>
      <h2 className='text-2xl mb-0'>Are you sure?</h2>
      <h4 className='text-xl mb-2'>{message}</h4>
      <div className='w-full mt-2 flex items-center justify-center md:justify-end gap-3'>
        <CustomButton variant='outline' handleClick={onCancel} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            Cancel
          </span>
        </CustomButton>
        <CustomButton handleClick={onOk} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            {isLoading && <Oval height='18' width='18' color='white' />}
            Yes, delete it.
          </span>
        </CustomButton>
      </div>
    </div>
  </CustomModal>
))

const LoadingUI = () => (
  <div className='flex flex-col items-center justify-center w-full flex-1'>
    <div
      role='presentation'
      style={{
        height: 150,
        width: 150,
        margin: '0 auto'
      }}
    >
      <LottieWrapper
        animation={successAnimation}
        autoplay
        loop={true}
        className='-mt-40 md:mt-0 max-w-sm'
      />
    </div>
    <h1 className='mt-4 text-[16px] truncate text-center'>
      Loading...
    </h1>
  </div>
)

const EditProjectOffer = () => {
  const dispatch = useDispatch()
  const projectContext = useContext(CreateProjectContext)
  const newProjectId = projectContext.newProjectId
  const appProject = useSelector((state) => state.appReducer.appProject)
  const projectId = newProjectId || appProject
  const [projectData, setProjectData] = useState({})
  const [customSections, setCustomSections] = useState([])
  const [isCreating, setIsCreating] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmDeleteModalForFieldOpen, setIsConfirmDeleteModalForFieldOpen] = useState(false)
  const [isConfirmDeleteModalForCustomSectionOpen, setIsConfirmDeleteModalForCustomSectionOpen] = useState(false)
  const [selectedCustomSectionKey, setSelectedCustomSectionKey] = useState('')
  const [selectedFieldKey, setSelectedFieldKey] = useState('')

  const onSubmit = (crrCustomSections) => {
    setIsCreating(true)
    const tempCustomSections = crrCustomSections
      .map((section) => {
        const { key, ...restOfTheData } = section
        const tempDataFields = section.dataFields
          .filter((field) => (field.title?.length > 0 && field.fieldType?.length > 0))
          .map((field) => {
            const { key, ...restOfTheFieldData } = field
            return {
              ...restOfTheFieldData
            }
          })
        if ((section.title?.length > 0) && tempDataFields?.length > 0) {
          return {
            ...restOfTheData,
            dataFields: tempDataFields
          }
        }
        return null
      })
      .filter((e) => e !== null)

    const params = { ...projectData, customSections: tempCustomSections }

    createOrUpdateProject({ params })
      .then((res) => {
        setIsCreating(false)
        dispatch({
          type: UPDATE_CUSTOM_SECTIONS,
          payload: res.customSections?.filter((e) => !e?.isDeleted)
        })
        fetchProjectData()
      })
      .catch(() => {
        setIsCreating(false)
      })
  }

  const fetchProjectData = () => {
    setIsLoading(true)
    getProjects(projectId).then((project) => {
      const { customSections = [] } = project
      const tempCustomSections = customSections?.map((section) => {
        const tempDataFields = section.dataFields.filter((field) => !field?.isDeleted).map((field) => ({
          ...field,
          key: uuidv4(),
        }))
        return {
          ...section,
          dataFields: tempDataFields,
          key: uuidv4()
        }
      })
      setCustomSections(tempCustomSections)
      setProjectData(project)
      setIsLoading(false)
    })
  }

  useEffect(() => {
    fetchProjectData()
  }, [projectId])

  const handleAddCustomSection = () => {
    const newSection = {
      key: uuidv4(),
      title: '',
      dataFields: FIELD_OPTIONS.filter((field) => (DEFAULT_FIELDS_THAT_CANNOT_BE_DELETED.includes(field.customFieldName))).map((field) => ({
        key: uuidv4(),
        ...field,
        isAdditional: false
      }))
    }
    setCustomSections([
      ...customSections,
      newSection
    ])
  }

  const handleInputChangeOfCustomSection = (key, fieldName, value) => {
    const temp = customSections.map((section) => {
      if (section.key === key) {
        return {
          ...section,
          [fieldName]: value
        }
      }
      return section
    })
    setCustomSections([...temp])
  }

  const handleRemoveCustomSection = (customSectionKey) => {
    setIsDeleting(true)
    const tempCustomSections = customSections.map((section) => {
      if (section.key === customSectionKey) {
        return {
          ...section,
          isDeleted: true
        }
      }
      return section
    })
    const customSectionsId = customSections.filter((section) => section.key === customSectionKey)?.[0]?._id
    setCustomSections([...tempCustomSections])
    if (customSectionsId) {
      const tempOptions = projectData.options.filter((option) => option.type === customSectionsId).map((option) => ({
        optionId: option._id, projectId: projectData._id
      }))
      if (tempOptions?.length > 0) {
        tempOptions.forEach((params) => {
          if (params.optionId) {
            deleteProjectOption(params)
          }
        })
      }

      setIsCreating(true)
      const filteredCustomSections = tempCustomSections
        .map((section) => {
          const { key, ...restOfTheData } = section
          const tempDataFields = section.dataFields
            .filter((field) => (field.title?.length > 0 && field.fieldType?.length > 0))
            .map((field) => {
              const { key, ...restOfTheFieldData } = field
              return {
                ...restOfTheFieldData,
                isDeleted: true
              }
            })
          if ((section.title?.length > 0) && tempDataFields?.length > 0) {
            return {
              ...restOfTheData,
              dataFields: tempDataFields
            }
          }
          return null
        })
        .filter((e) => e !== null)

      const filteredOptions = projectData.options.filter((option) => option.type !== customSectionsId)

      const params = { ...projectData, options: filteredOptions, customSections: filteredCustomSections }

      createOrUpdateProject({ params })
        .then((res) => {
          setIsCreating(false)
          dispatch({
            type: UPDATE_CUSTOM_SECTIONS,
            payload: res.customSections?.filter((e) => !e?.isDeleted)
          })
          fetchProjectData()
        })
        .catch(() => {
          setIsCreating(false)
        })
    }
    setIsConfirmDeleteModalForCustomSectionOpen(false)
    setSelectedCustomSectionKey('')
    setIsDeleting(false)
  }

  const handleAddField = (customSectionsKey, isAdditional = false) => {
    const temp = customSections.map((section) => {
      if (customSectionsKey === section.key) {
        return {
          ...section,
          dataFields: [
            ...section.dataFields,
            {
              key: uuidv4(),
              title: '',
              fieldType: '',
              customFieldName: '',
              isAdditional
            }
          ]
        }
      }
      return section
    })
    setCustomSections([...temp])
  }

  const handleInputChangeOfField = (customSectionKey, dataFieldsKey, fieldName, value, isDefault = false) => {
    const tempCustomSections = customSections.map((section) => {
      if (customSectionKey === section.key) {
        const tempDataFields = section.dataFields.map((field) => {
          if (field.key === dataFieldsKey) {
            if (fieldName?.length > 0) {
              if (fieldName === 'title') {
                return {
                  ...field,
                  [fieldName]: value,
                  customFieldName: convertToCamelCase(value),
                  ...isDefault ? { fieldType: FIELD_OPTIONS.filter(elm => elm.title === value)[0]?.fieldType } : {}
                }
              } else {
                return {
                  ...field,
                  [fieldName]: value
                }
              }
            }
            return {
              ...field,
              ...FIELD_OPTIONS.filter((field) => (field.title === value))[0]
            }
          }
          return field
        })
        return {
          ...section,
          dataFields: [...tempDataFields]
        }
      }
      return section
    })
    setCustomSections([...tempCustomSections])
  }

  const handleRemoveField = (customSectionKey, dataFieldsKey) => {
    setIsDeleting(true)
    const tempCustomSections = customSections.map((section) => {
      if (customSectionKey === section.key) {
        const tempDataFields = section.dataFields.map((field) => {
          if (field.key === dataFieldsKey) {
            return (
              {
                ...field,
                isDeleted: true
              }
            )
          }
          return field
        })
        return {
          ...section,
          dataFields: [...tempDataFields]
        }
      }
      return section
    })
    setCustomSections([...tempCustomSections])
    setIsConfirmDeleteModalForFieldOpen(false)
    setSelectedCustomSectionKey('')
    setSelectedFieldKey('')
    setIsDeleting(false)
  }

  return (
    <div className='min-h-full relative flex flex-col'>
      <div className='px-6 py-3 flex justify-between items-center'>
        <span className='font-semibold text-3xl'>Project's Offer Setting</span>
      </div>
      <div className='border-b-2 border-black' />
      <div className='min-h-[calc(100vh-126px)] relative p-6 flex flex-col gap-6'>
        <h2 className='text-2xl mb-0'>Custom Sections</h2>
        {
          isLoading
            ? <LoadingUI />
            : <>
              {
                customSections?.filter((e) => !e?.isDeleted)?.length > 0
                  ? <>
                    {
                      customSections?.filter((e) => !e?.isDeleted).map((section, index) => (
                        <CreateOrUpdateCustomSection
                          key={index}
                          index={index + 1}
                          customSectionDetails={section}
                          loading={isCreating}
                          onOk={() => onSubmit(customSections)}
                          handleInputChangeOfCustomSection={handleInputChangeOfCustomSection}
                          handleAddField={handleAddField}
                          handleInputChangeOfField={handleInputChangeOfField}
                          handleRemoveField={(customSectionKey, fieldKey) => {
                            setSelectedCustomSectionKey(customSectionKey)
                            setSelectedFieldKey(fieldKey)
                            setIsConfirmDeleteModalForFieldOpen(true)
                          }}
                          handleRemoveCustomSection={(customSectionKey) => {
                            setSelectedCustomSectionKey(customSectionKey)
                            setIsConfirmDeleteModalForCustomSectionOpen(true)
                          }}
                        />
                      ))
                    }
                  </>
                  : <div className='w-full min-h-[150px] flex justify-center items-center rounded-xl border bg-card text-lg font-medium text-card-foreground shadow bg-white overflow-hidden p-3'>
                    No custom section found, add new one
                  </div>
              }
              <div className='w-full flex justify-center'>
                <CustomButton variant='ghost' handleClick={handleAddCustomSection} disabled={isLoading}>
                  <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                    {isLoading
                      ? <Oval height='18' width='18' color='black' />
                      : <Plus className='text-black w-5 h-5' />
                    }
                    Create custom section
                  </span>
                </CustomButton>
              </div>
            </>
        }
      </div>
      <ConfirmDeleteModal
        showModal={isConfirmDeleteModalForFieldOpen}
        message='Do you want to delete the field?'
        onCancel={() => {
          setSelectedCustomSectionKey('')
          setSelectedFieldKey('')
          setIsConfirmDeleteModalForFieldOpen(false)
        }}
        onOk={() => handleRemoveField(selectedCustomSectionKey, selectedFieldKey)}
        isLoading={isDeleting}
      />
      <ConfirmDeleteModal
        showModal={isConfirmDeleteModalForCustomSectionOpen}
        message='Do you want to delete the custom section?'
        onCancel={() => {
          setSelectedCustomSectionKey('')
          setIsConfirmDeleteModalForCustomSectionOpen(false)
        }}
        onOk={() => handleRemoveCustomSection(selectedCustomSectionKey)}
        isLoading={isDeleting}
      />
    </div>
  )
}

export default EditProjectOffer
