import { api, ApiErrors } from 'services/api'
import { message } from 'antd'
import {
  CREATE_PROJECT_MANAGE_SUBDRAWER,
  CREATE_PROJECT_CLOSE_SUBDRAWER,
  CREATE_PROJECT_MANAGE_THIRDRAWER,
  CREATE_PROJECT_CLOSE_THIRDRAWER
} from './actionTypes'
import { isArray } from 'lodash'

export const getProjects = (id = '') => new Promise((resolve, reject) => {
  api.get(`projects/${id}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateProject = (props) => new Promise((resolve, reject) => {
  const { extraData, ...projectValues } = props

  const { _id, ...projectParams } = projectValues.params

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `projects/${_id}` : 'projects'

  api[verb](url, projectParams)
    .then(async (response) => {
      if (response.ok) {
        message.success(`${projectParams.projectName} was ${_id ? 'successfully updated' : 'created successfully'}`)

        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProject = (id) => new Promise((resolve, reject) => {
  api.delete(`/projects/${id}`)
    .then((response) => {
      if (response.ok) {
        message.success('Project deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const manageSubDrawer = (props) => (dispatch) => {
  dispatch({
    type: CREATE_PROJECT_MANAGE_SUBDRAWER,
    payload: props
  })
}

export const closeSubDrawer = () => (dispatch) => {
  dispatch({
    type: CREATE_PROJECT_CLOSE_SUBDRAWER
  })
}

export const manageThirdDrawer = (props) => (dispatch) => {
  dispatch({
    type: CREATE_PROJECT_MANAGE_THIRDRAWER,
    payload: props
  })
}

export const closeThirdDrawer = () => (dispatch) => {
  dispatch({
    type: CREATE_PROJECT_CLOSE_THIRDRAWER
  })
}

export const getProjectOption = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId } = props

  api.get(`/projects/${projectId}/options/${optionId}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateProjectOption = (props) => new Promise((resolve, reject) => {
  const { _id, projectId, name, description, value, images, type, additionalFields, maxAmountPerUnit } = props

  const params = new FormData()

  if (images) {
    const arrayOfImages = images.map((image) => image)
    arrayOfImages.forEach((image) => params.append('images', image))
  }

  params.append('name', name)
  params.append('value', value)
  params.append('description', description)
  params.append('type', type)
  params.append('maxAmountPerUnit', maxAmountPerUnit)

  if (additionalFields) {
    Object.entries(additionalFields).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          params.append(`additionalFields[${key}][]`, item)
        })
      } else {
        params.append(`additionalFields[${key}]`, value)
      }
    })
  }

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id ? `projects/${projectId}/options/${_id}` : `projects/${projectId}/options`

  // If is editing, pass the props to the API
  // formData is not accepted at put
  api[verb](url, _id ? props : params)
    .then((response) => {
      if (response.ok) {
        // message.success(`${props.name} was ${_id ? 'successfully updated' : 'created successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectOption = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId } = props

  api.delete(`/projects/${projectId}/options/${optionId}`)
    .then((response) => {
      if (response.ok) {
        message.success('Project option deleted successfully')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const changeProjectImages = (props) => new Promise((resolve, reject) => {
  const { logoImage, backgroundImage, images } = props

  const params = new FormData()

  if (logoImage) {
    params.append('logoImage', logoImage)
  }

  if (backgroundImage) {
    params.append('backgroundImage', backgroundImage)
  }

  if (images) {
    if (isArray(images)) {
      images.forEach((image) => params.append('images', image))
    } else {
      params.append('images', images)
    }
  }

  api.post(`/projects/${props.projectId}/images`, params)
    .then((response) => {
      if (response.ok) {
        message.success('Image successfully updated')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectImage = (props) => new Promise((resolve, reject) => {
  const { projectId, url } = props

  api.delete(`projects/${projectId}/images`, {}, {
    data: {
      url
    }
  })
    .then((response) => {
      if (response.ok) {
        message.success('Image successfully deleted')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectLogoImage = (props) => new Promise((resolve, reject) => {
  const { projectId, url } = props

  api.delete(`projects/logo/${projectId}`, {}, {
    data: {
      url
    }
  })
    .then((response) => {
      if (response.ok) {
        message.success('Logo successfully deleted')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectBackgroundImage = (props) => new Promise((resolve, reject) => {
  const { projectId, url } = props

  api.delete(`projects/backgroundImage/${projectId}`, {}, {
    data: {
      url
    }
  })
    .then((response) => {
      if (response.ok) {
        message.success('Background image successfully deleted')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const changeProjectOptionImages = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId, images } = props

  const params = new FormData()

  if (images) {
    if (isArray(images)) {
      images.forEach((image) => params.append('images', image))
    } else {
      params.append('images', images)
    }
  }

  api.post(`projects/${projectId}/options/${optionId}/images`, params)
    .then((response) => {
      if (response.ok) {
        message.success('Image successfully updated')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectOptionImage = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId, url } = props

  api.delete(`projects/${projectId}/options/${optionId}/images`, {}, {
    data: {
      url
    }
  })
    .then((response) => {
      if (response.ok) {
        message.success('Image successfully deleted')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getProjectOptionContractDetail = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId, contractId } = props

  api.get(`projects/${projectId}/options/${optionId}/contractDetail/${contractId}`)
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateProjectOptionContractDetail = (props) => new Promise((resolve, reject) => {
  const {
    _id, projectId, optionId, ...params
  } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = _id
    ? `projects/${projectId}/options/${optionId}/contractDetail/${_id}`
    : `projects/${projectId}/options/${optionId}/contractDetail`

  // If is editing, pass the props to the API
  // formData is not accepted at put
  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        // message.success(`Contract was ${_id ? 'successfully saved' : 'saved successfully'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteProjectOptionContractDetail = (props) => new Promise((resolve, reject) => {
  const { projectId, optionId, contractDetailId } = props

  api.delete(`/projects/${projectId}/options/${optionId}/contractDetail/${contractDetailId}`)
    .then((response) => {
      if (response.ok) {
        message.success('Contract deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getImageColors = (image) => new Promise((resolve, reject) => {
  api.post('projects/images/colors', { url: image })
    .then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const addUsersToProject = ({ previousData }) => new Promise((resolve, reject) => {
  api.post('projects/users', { data: previousData })
    .then((response) => {
      if (response.ok) {
        message.success('Users added to project successfully')
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
