/* eslint-disable no-unused-vars */
import React from 'react'
import NewReservation from './NewReservation/NewReservation'
import NewVIPLotReservation from './NewReservation/NewVIPLotReservation'
import { checkV2Inventory } from 'utils/checkV2Inventory'
import NewVIPLotReservationV2 from './NewReservation/NewVIPLotReservationV2'

const CreateReservationWrapper = () => {
  return (
    <div className='h-full'>
      {/* <NewReservation /> */}
      {
        checkV2Inventory() ? <NewVIPLotReservationV2 /> : <NewVIPLotReservation />
      }
      {/* <NewVIPLotReservation /> */}
    </div>
  )
}

export default CreateReservationWrapper
