// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as CrossIcon } from "assets/icons/cross.svg";
import { Oval } from "react-loader-spinner";

import { Modal } from "@material-ui/core";
import CustomInput from "components/CustomInput";
import CustomSelect from "components/CustomSelect";
import { createOrUpdateUnit } from "store/actions/unitActions";
import useGetFloorPlans from "hooks/useGetFloorPlans";
import useGetUnitGroups from "hooks/useGetUnitGroups";
import { useSelector } from "react-redux";
import { isObject } from "lodash";
import useCurrencyList from "hooks/useCurrencyList";
import ProjectFileSelector from "components/ProjectFileSelector";
import CustomButton from "components/CustomButton";
import {
  createOrUpdateInventory,
  getActiveInventoryStatuses,
  getInventorySettings,
} from "store/actions/inventoryActions";
import { off } from "process";
import toast from "react-hot-toast";
import UnitFileUploader from "components/UnitFileUploader";

const AddInventoryModal = ({
  show,
  setShow,
  editDetails,
  setEditDetails,
  refetch,
  building,
}) => {
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject);
  const mountedRef = useRef(true);
  const [inventoryFields, setInventoryFields] = useState([]);
  const { floorPlans } = useGetFloorPlans();
  const [inventoryType, setInventoryType] = useState("");
  const { unitGroups } = useGetUnitGroups();
  const { formattedCurrencyList: currencyData } = useCurrencyList();
  const initialEmptyValues = {
    project: appProject,
    lotData: {},
    condoData: {},
    townhomeData: {},
    building: building,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });
  const [activeInventoryStatuses, setActiveInventoryStatuses] = useState([]);

  useEffect(() => {
    
    const fetchStatuses = async () => {
      try {
        const data = await getActiveInventoryStatuses(appProject);
          setActiveInventoryStatuses(data);
      } catch (error) {
        console.error('Error fetching inventory statuses:', error);
      }
    };

    fetchStatuses();
  }, []);

  useEffect(() => {
    if (!mountedRef.current) return;
    
    const fetchSettings = async () => {
      try {
        const data = await getInventorySettings(appProject);
        if (mountedRef.current) {
          let type = data[0].type;
          setInventoryType(type);
          let fields = Object.keys(data[0][type + "Data"]).reduce((acc, key) => {
            if (key === "additionalFields") {
              const additionalFieldsMapped = data[0][type + "Data"][key].map(
                (item) => {
                  if (item.enabled === true) {
                    return {
                      label: item.name,
                      key: item.id,
                      flag: "additionalFields",
                      type: item?.type ?? 'text',
                      placeholder: `Enter ${item.name}`,
                    };
                  }
                  return null;
                }
              ).filter(Boolean); // Remove undefined values
              return [...acc, ...additionalFieldsMapped];
            } else {
              if (data[0][type + "Data"][key].enabled === true) {
                return [
                  ...acc,
                  {
                    label: key,
                    key: key,
                    type: data[0][type + "Data"][key]?.type || 'text',
                    placeholder: `Enter ${key}`,
                  },
                ];
              }
              return acc;
            }
          }, []);
          let filter = fields.filter((item) => item !== undefined);
          setInventoryFields(filter);
        }
      } catch (error) {
        console.error('Error fetching inventory settings:', error);
      }
    };

    fetchSettings();
  }, [appProject]);

  useEffect(() => {
    if (!mountedRef.current) return;
    
    if (process.env.REACT_APP_INENVETORY_SETTINGS_UI) {
      let key = {
        lot: "lotData",
        condo: "condoData",
        townhome: "townhomeData",
      }[editDetails?.type];
      
      if (mountedRef.current) {
        setValues({
          ...value,
          ...editDetails,
          [key]: {
            ...value[key],
            ...editDetails[key],
          },
        });
      }
    } else {
      if (mountedRef.current) {
        setValues(initialEmptyValues);
      }
    }
  }, [editDetails]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const TEMP_AVAILABILITY_STATUS = {
    available: "available",
    open: "Open",
    sold: "sold",
    soldFirm: "SoldFirm",
    developerHeld: "DeveloperHeld",
    reserved: "reserved",
    reservedBroker: "ReservedBroker",
    reservedBuyer: "ReservedBuyer",
    closed: "Closed",
    underContract: "UnderContract",
    offerAccepted: "OfferAccepted",
  };

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value };
    }),
  ];

  const balconyOptions = [
    { id: "true", value: "Yes" },
    { id: "false", value: "No" },
  ];

  const LOT_TYPE_OPTION = [
    { id: "Large", value: "Large" },
    { id: "Standard", value: "Standard" },
    { id: "Medium", value: "Medium" },
    { id: "Villa", value: "Villa" },
  ];

  const COLLECTION_OPTION = [
    { id: "Manor", value: "Manor" },
    { id: "Estate", value: "Estate" },
    { id: "Villa", value: "Villa" },
  ];

  const DOCK_TYPE_OPTION = [
    { id: "dock-type-1", value: "Dock type 1" },
    { id: "dock-type-2", value: "Dock type 2" },
    { id: "dock-type-3", value: "Dock type 3" },
  ];

  const YES_NO_OPTION = [
    { id: true, value: "Yes" },
    { id: false, value: "No" },
  ];

  const STR_OPTION = [
    { id: "STR", value: "STR" },
    { id: "NON-STR", value: "NON-STR" },
  ];

  const MOORING_LINE_TYPE_OPTIONS = [
    { id: "B", value: "B" },
    { id: "C", value: "C" },
    { id: "D", value: "D" },
    { id: "E", value: "E" },
    { id: "N/A", value: "N/A" },
  ];

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      const temp = {
        // @ts-ignore
        ...value,
        type: inventoryType,
      };
      createOrUpdateInventory(temp)
        .then((data) => {
          refetch();
          setLoading(false);
          setShow(false);
          setEditDetails({});
          toast.success(
            `Inventory ${value?._id ? "updated" : "added"} successfully`
          );
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    }
  };

  const onChange = (fieldName, newValue, name) => {
    if (inventoryType === "condo") {
      setValues({
        ...value,
        [fieldName]: name ? name : newValue,
        condoData: {
          ...value.condoData,
          [fieldName]: newValue,
        },
      });
    } else if (inventoryType === "townhome") {
      setValues({
        ...value,
        [fieldName]: name ? name : newValue,
        townhomeData: {
          ...value.townhomeData,
          [fieldName]: newValue,
        },
      });
    } else {
      setValues({
        ...value,
        [fieldName]: name ? name : newValue,
        lotData: {
          ...value.lotData,
          [fieldName]: newValue,
        },
      });
    }
  };

  const onLotDataChange = (fieldName, newValue) => {
    setValues({
      ...value,
      lotData: {
        ...value.lotData,
        [fieldName]: newValue,
      },
    });
  };
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    setEditDetails({});
  };

  const validate = () => {
    // for (const key in inventoryFields) {
    //   if (!value[inventoryFields[key]]) {
    //     return false
    //   }
    // }
    return true;
  };

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0];
    onChange("status", temp.id);
  };

  const handleContractPDFChange = (contractObject) => {
    onChange("contractPdf", contractObject._id ?? contractObject.id);
  };

  const handleParkingPDFChange = (parkingObject) => {
    onChange("parkingPdf", parkingObject._id ?? parkingObject.id);
  };

  const handleCurrencyChange = (id) => {
    const temp = currencyData.filter((option) => option.id === id)[0];
    onChange("priceCurrencyType", temp.id);
  };

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0];
    onChange("balcony", temp.id);
  };

  const handleImageChange = (image) => {
    onChange("images", [image.url]);
  };

  const handleLotDataSelectChange = (collection, fieldName, id) => {
    const temp = collection.filter((option) => option.id === id)[0];
    onLotDataChange(fieldName, temp.id);
  };

  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div
        className="font-openSans relative bg-grey-8 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-10 px-8 rounded shadow-lg max-h-[calc(100vh-150px)] flex flex-col"
        style={{ width: 1015 }}
      >
        <button className="absolute right-10" onClick={handleClose}>
          <CrossIcon />
        </button>
        <h1 className="font-bold text-xl text-center p-0 m-0 mb-6">
          {!value?._id ? "Add" : "Update"} {inventoryType}
        </h1>
        <div className="w-full px-2 grid grid-cols-6 gap-x-3 h-full overflow-y-auto thin-scrollbar">
          {inventoryFields.map((field) => {
            return (
              <div className="grid col-span-3 gap-6 mb-4">
                {field.type === "text" || field.type === 'number' ? (
                  <CustomInput
                    label={field?.label}
                    placeholder={field?.placeholder}
                    labelClassName="italic"
                    type={field?.type}
                    value={
                      field?.flag === "additionalFields"
                        ? value?.[
                            inventoryType === "condo"
                              ? "condoData"
                              : inventoryType === "townhome"
                              ? "townhomeData"
                              : "lotData"
                          ]?.additionalFields?.[field?.key]
                        : value?.[
                            inventoryType === "condo"
                              ? "condoData"
                              : inventoryType === "townhome"
                              ? "townhomeData"
                              : "lotData"
                          ]?.[field?.key]
                    }
                    onChange={(e) => {
                      if (field.flag === "additionalFields") {
                        let newValue = e.target.value;
                        let id = field.key;
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            additionalFields: {
                              ...value[inventoryType + "Data"].additionalFields,
                              [id]: newValue,
                            },
                          },
                        });
                      } else {
                        onChange(field.key, e.target.value);
                      }
                    }}
                    required={"required"}
                    errorMessage={`Please enter ${field?.label}`}
                    containerClasses={undefined}
                    classes={undefined}
                  />
                ) : field.type === 'file' ? (
                  <UnitFileUploader
                    title={field?.label}
                    images={field?.flag === "additionalFields"
                      ? value?.[inventoryType + "Data"]?.additionalFields?.[field?.key]
                      : value?.[inventoryType + "Data"]?.[field?.key]
                    }
                    setDocumentList={(images) => {
                      if (field.flag === "additionalFields") {
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            additionalFields: {
                              ...value[inventoryType + "Data"].additionalFields,
                              [field.key]: images
                            },
                          },
                        });
                      } else {
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            [field.key]: images
                          },
                        });
                      }
                    }}
                    unit={value}
                    refetch={refetch}
                  />
                ) : field.type === 'dropdown' && field.key === 'floorPlan' ? (
                  <CustomSelect
                    label={field?.label}
                    options={floorPlans}
                    inputClasses='h-11'
                    selectedOption={
                      isObject(value?.[inventoryType + "Data"]?.[field.key]) 
                        ? value?.[inventoryType + "Data"]?.[field.key]?._id 
                        : value?.[inventoryType + "Data"]?.[field.key]
                    }
                    toIterateField='name'
                    setSelectedOption={(newValue) => {
                      if (field.flag === "additionalFields") {
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            additionalFields: {
                              ...value[inventoryType + "Data"].additionalFields,
                              [field.key]: newValue
                            },
                          },
                        });
                      } else {
                        setValues({
                          ...value,
                          [inventoryType + "Data"]: {
                            ...value[inventoryType + "Data"],
                            [field.key]: newValue
                          },
                        });
                      }
                    }}
                    labelClasses={undefined}
                  />
                ) : (
                  <CustomSelect
                  label={field?.label}
                  inputClasses="h-11"
                  labelClasses={undefined}
                  toIterateField="name"
                  options={activeInventoryStatuses}
                  selectedOption={
                    activeInventoryStatuses.find((item) => item.name === value[field?.key])?.id
                  }
                  setSelectedOption={(option) => {
                    let name = activeInventoryStatuses.find((item) => item.id === option)?.name;
                    onChange(field.key, option, name);
                  }}
                />
                )}
              </div>
            );
          })}
        </div>
        <CustomButton
          handleClick={handleSubmit}
          disabled={!validate() || loading}
          className="m-auto"
        >
          <span className="font-medium py-3 px-6 text-base flex gap-2 items-center">
            {loading && <Oval height="18" width="18" color="white" />}
            {value?._id ? "Update" : "Add"} {inventoryType}
          </span>
        </CustomButton>
      </div>
    </Modal>
  );
};

export default AddInventoryModal;
