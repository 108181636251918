/* eslint-disable */
// @ts-nocheck
import React, { useMemo, useState } from 'react'
import useGetProjectUnits from 'hooks/useGetProjectUnits'
import InventoryListViewWrapper from '../InventoryListViewWrapper'
import { groupBy, typesOfStatus } from 'components/Inventory'

const UnitsInventoryListView = ({ projectId, buildingId, isFullHeight = false, inventories, refetch, loading, state }) => {
  // const { units, unitsLoading, refetchUnits } = useGetProjectUnits(projectId)
  // const [searchQuery, setSearchQuery] = useState('')

  // const filteredUnits = useMemo(() => {
  //   const unitsData = units
  //     .filter((elm) => elm.building._id === buildingId)
  //     .filter((elm) => searchQuery ? elm.unitNumber.toString().includes(searchQuery.toLowerCase()) : true)
  //     .sort((a, b) => a.unitNumber - b.unitNumber)

  //   const uCards = [...unitsData]
  //   const grouped = groupBy(uCards, (inventoryItem) => inventoryItem.floorNumber)
  //   // @ts-ignore
  //   const groupedKeyValuePair = {}
  //   grouped.forEach((value, key) => {
  //     return (groupedKeyValuePair[key] = value)
  //   })
  //   return ({ ...groupedKeyValuePair })
  // }, [units, buildingId, refetchUnits, searchQuery])

  return (
    <>
      <div className='ml-6 py-2'>
      {state.selectedInventoryView === 'availabilityStatus' && (
              <div className='flex-1 h-full overflow-y-auto mb-3 thin-horizontal-scrollbar flex items-center'>
                <div className='w-full flex items-center overflow-x-auto gap-5'>
                  {typesOfStatus.map((status) => (
                    <div
                      key={status.id}
                        className='rounded-md items-center gap-2 flex'
                      >
                        <div
                          className='w-3 h-3 rounded-full'
                          style={{
                            backgroundColor: state.statusColors[status.id.toLowerCase()].bgColor,
                            border: `1px solid ${state.statusColors[status.id.toLowerCase()].borderColor}`,
                          }}
                        />
                        <p className='text-zinc-900 text-sm font-semibold leading-tight mb-0 truncate'>
                          {status.name}
                        </p>
                      </div>
                    ))
                  }
                </div>
              </div>
            )}
       
      </div>
      <InventoryListViewWrapper
        inventoryItems={inventories}
        loading={loading}
        type='Unit'
        refetch={refetch}
        isFullHeight={isFullHeight}
        selectedBuilding={buildingId}
      />
    </>
  )
}

export default UnitsInventoryListView
