import React, { useCallback, useEffect, useState, useMemo } from 'react'
import propTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import useSearchParams from 'hooks/useSearchParams'
import { FadedAnimatedDiv } from 'components'
import styled from 'styled-components'
import Contract from './contract'
import { getPublicOfferData } from 'store/actions/offerActions'
import WelcomeScreen from 'components/PublicContractSigningScreen/WelcomeScreen'
import ThankYouScreen from 'components/PublicContractSigningScreen/ThankYouScreen'

export const ScreenWrapper = styled.div``

const AnimatedDiv = ({ children, key }) => (
  <FadedAnimatedDiv
    key={key}
    className='h-full w-full flex flex-col items-center justify-center'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
    {children}
  </FadedAnimatedDiv>
)

// http://localhost:3000/public/sign?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvZm.ddsds.joxNjgzNTU1MzgxfQ.9pT7oWdE73w1wPfwB34x5Ie0rTwDuNhtDq40IQJvlRg

const BuyerSignContract = () => {
  // @ts-ignore
  const searchParams = useSearchParams()
  const token = searchParams.get('token')
  const version = searchParams.get('version') || 1
  const inventoryKey = version && Number(version) === 2 ? 'inventory' : 'unit'
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [contractData, setContractData] = useState(null)

  const fetchDataFromToken = async () => {
    if (token?.length > 0) {
      try {
        setIsLoadingData(true)
        const result = await getPublicOfferData(token, Number(version))
        setContractData(result)
      } catch (e) {
        setContractData(null)
      } finally {
        setIsLoadingData(false)
      }
    }
  }

  useEffect(() => {
    fetchDataFromToken()
  }, [token])

  const handleSignNowClick = useCallback(() => {
    if (isLoadingData) {
      return
    }
    setCurrentStep(1)
  }, [isLoadingData])

  const handleCloseContractScreen = useCallback(() => {
    if (isLoadingData) {
      return
    }
    setCurrentStep(2)
  }, [isLoadingData])

  const handleGoBack = () => {
    setCurrentStep(0)
    fetchDataFromToken()
  }

  const contractsText = useMemo(() => {
    if (contractData?.offer?.contracts?.length > 0) {
      const contractNames = contractData.offer.contracts.map((elm) => (elm.contractTemplate.name)).join(', ')
      return <p className='text-lg m-0'>
        Your
        <strong className='font-bold'>
          {` ${contractNames} `}
        </strong>
        {contractData.offer.contracts.length > 1 ? 'are' : 'is'} ready for signing.
      </p>
    }
    return <p className='text-lg m-0'>
      Oops! It looks like there are no contracts available for you to sign right now.
    </p>
  }, [contractData])

  const thankyouScreenDescription = useMemo(() => {
    if (contractData?.offer?.contracts?.length > 0) {
      const contractNames = contractData.offer.contracts.map((elm) => (elm.contractTemplate.name)).join(', ')
      return <p className='text-lg m-0'>
        Congratulations on successfully signing the
        <strong className='font-bold'>
          {` ${contractNames}! `}
        </strong>
        We're excited to move forward, and our team will be in touch shortly to guide you through the next steps in the process.
      </p>
    }
    return <p className='text-lg m-0'>
      Congratulations on successfully signing the contract! We're excited to move forward, and our team will be in touch shortly to guide you through the next steps in the process.
    </p>
  }, [contractData])

  return (
    <ScreenWrapper
      style={{ background: '#f8f8f9' }}
      className='h-screen w-screen'
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        {currentStep === 0 && (
          <AnimatedDiv key='welcome'>
            <WelcomeScreen
              isLoadingData={isLoadingData}
              onClick={handleSignNowClick}
              projectLogo={contractData?.offer?.[inventoryKey]?.building?.project?.logoImage ?? ''}
              projectName={contractData?.offer?.[inventoryKey]?.building?.project?.projectName ?? ''}
              userDetails={contractData?.userDetails ?? {}}
              contractsText={contractsText}
              showSignNowButton={contractData?.offer?.contracts?.length > 0}
            />
          </AnimatedDiv>
        )}
        {currentStep === 1 && (
          <FadedAnimatedDiv key='contractsReview' style={{ height: '100%' }}>
            <Contract
              contractData={contractData}
              onClose={handleCloseContractScreen}
              handleGoBack={handleGoBack}
            />
          </FadedAnimatedDiv>
        )}
        {currentStep === 2 && (
          <AnimatedDiv key='thankyou'>
            <ThankYouScreen
              handleGoBack={handleGoBack}
              projectLogo={contractData?.offer?.[inventoryKey]?.building?.project?.logoImage ?? ''}
              projectName={contractData?.offer?.[inventoryKey]?.building?.project?.projectName ?? ''}
              description={thankyouScreenDescription}
              userDetails={contractData?.userDetails ?? {}}
            />
          </AnimatedDiv>
        )}
      </AnimatePresence>
    </ScreenWrapper>
  )
}

export default BuyerSignContract

AnimatedDiv.propTypes = {
  children: propTypes.node,
  key: propTypes.string
}
