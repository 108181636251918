/* eslint-disable */
// @ts-nocheck

import React, { useCallback, useState, useMemo } from 'react'
import {
  ContractThumbnailWrapper,
  PageControlWrapper,
  ContractThumbnailDocumentWrapper
} from '../styles'
import PdfDocument from './PdfDocument'
// import PdfPagination from './PdfPagination'
import { DOCUSEAL_ROLES } from 'utils'
import { Check } from 'lucide-react'
import { CONTRACT_ROLES_CONFIG, CONTRACT_STATUS_CONFIG, CONTRACT_STATUSES } from '../constants'

const ContractThumbnail = ({
  contractData,
  index,
  selected = false,
  onSelectDoc,
  onChangePdfPage,
  onLoadSuccess,
  isGroupedContracts = false,
  // isAllSigned = false,
  pdfPageNumber,
  setPdfPageNumber,
}) => {
  const { url, signatures, contractTemplate } = contractData

  const DocumentStatus = useCallback(
    ({ signature }) => {
      if (!signatures?.length) {
        return null
      }
      const status = signature?.status || 'Pending'
      const signatureUserType = signature.userType
      return (
        <div className='w-full mx-auto flex gap-2 items-center'>
          <div className='rounded font-semibold truncate'>
            {CONTRACT_ROLES_CONFIG[signatureUserType]}:
          </div>
          <p className={`w-fit text-center text-xs font-semibold mb-0 px-2.5 py-1 rounded-full ${CONTRACT_STATUS_CONFIG?.[status]?.bgColor || 'bg-zinc-300'} ${CONTRACT_STATUS_CONFIG?.[status]?.textColor || 'bg-zinc-900'}`}>
            {CONTRACT_STATUS_CONFIG?.[status]?.label}
          </p>
        </div>
      )
    },
    [signatures]
  )

  const isAllSigned = useMemo(() => {
    if (!signatures?.length) {
      return false
    }

    return signatures?.every((signature) => signature.status === CONTRACT_STATUSES.SIGNED)
  }, [signatures])

  return (
    <ContractThumbnailWrapper
      key={index}
      onClick={() => {
        setPdfPageNumber(1)
        onSelectDoc(contractData)
      }}
      className={`w-full flex flex-col gap-2 bg-white border-2 ${selected ? 'border-zinc-900' : 'border-zinc-200'} rounded-md transition-all`}
    >
      {isGroupedContracts && (
        <span className='w-full text-left text-base font-bold'>{`${index + 1}. ${contractTemplate?.name}`}</span>
      )}
      <ContractThumbnailDocumentWrapper className='min-h-[152px] min-w-[115px] flex justify-center items-center border shadow-md'>
        <PageControlWrapper
          pdfPageNumber={pdfPageNumber}
        >
          <PdfDocument
            documentProps={{ file: url }}
            pageNumber={selected ? pdfPageNumber : 1}
            pageProps={{ height: 150 }}
          // onLoadSuccess={handleLoadSuccess}
          />
          {isAllSigned && (
            <div className='absolute shadow-lg h-16 w-16 rounded-full bg-green-500 flex justify-center items-center p-3'>
              <Check className='w-10 h-10 text-white' strokeWidth={2.5} />
            </div>
          )}
        </PageControlWrapper>
      </ContractThumbnailDocumentWrapper>
      <div className='w-full mt-4 flex flex-col gap-2'>
        {signatures
          ?.map((signature, index) => (
            <DocumentStatus key={index} signature={signature} />
          ))}
      </div>
    </ContractThumbnailWrapper>
  )
}

export default React.memo(ContractThumbnail)
